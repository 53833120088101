import { useState, useEffect } from 'react';

const useStatusState = (defaultValue) => {
    const storageKey = 'statusBudget';

    defaultValue = defaultValue ? defaultValue : '';

    const [statusBudget, setStatusBudget] = useState(defaultValue);

    const setPersistedStatus = (value) => {
        localStorage.setItem(storageKey, JSON.stringify(value));
        setStatusBudget(value);
    };

    useEffect(() => {
        const storedValue = localStorage.getItem(storageKey);
        const initialValue = storedValue && storedValue !== 'undefined' ? JSON.parse(storedValue) : defaultValue;

        if (initialValue !== defaultValue) {
            setPersistedStatus(initialValue);
        }
    }, []);

    return [statusBudget, setPersistedStatus];
};

export default useStatusState;
