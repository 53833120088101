import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery,
    MenuItem,
    TextField
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useNavigate } from 'react-router';

import { useApi } from 'Service/axios';
import { display } from '@mui/system';

/////////////////////////////////////////////////////////////////////////////////

const EditBudgetPage = ({ id, setOpenEdit }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const api = useApi();

    const [emailData, setEmailData] = useState([]);
    const [emailId, setEmailId] = useState(null);
    const budgetCategories = ['Estudo', 'Compra'];

    // useEffect(() => {
    //     const getUser = async (id) => {
    //         const response = await api.getUserById(id);
    //         setUserData(response.data.user);
    //         return response;
    //     };

    //     getUser(id);
    // }, [id]);

    // useEffect(() => {
    //     if (userData) {
    //         setUserId(userData.id);
    //     }
    // }, [userData]);

    // const handleUpdate = async (values, userId) => {
    //     try {
    //         const response = await api.updateUser(values, id);
    //         return response;
    //     } catch (error) {
    //         throw new Error(error.message);
    //     }
    // };

    const [teste, setTeste] = useState(null);

    useEffect(() => {
        const get = async (id) => {
            const response = await api.getBudgetById(id);
            const data = new Date(response.data.endForecast);
            const dia = (data.getDate() + 1).toString().padStart(2, '0');
            const mes = (data.getMonth() + 1).toString().padStart(2, '0');
            const ano = data.getFullYear().toString();
            response.data.endForecast = `${ano}-${mes}-${dia}`;

            setTeste(response.data);
            return response;
        };
        get(id);
    }, [id]);

    const handleUpdate = async (values) => {
        try {
            const response = await api.updateBudget(id, values);
            window.location.reload();
            setOpenEdit(false);
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const [actor, setActor] = useState([]);
    const [estimators, setEstimators] = useState([]);

    useEffect(() => {
        const valor = 'Vendas';
        const estimators = 'Orcamentos';
        const get = async (value) => {
            const response = await api.getByActor(value);
            setActor(response.data);
            return response;
        };
        get(valor);

        const getEstimator = async (value) => {
            const response = await api.getByActor(value);
            setEstimators(response.data);
            return response;
        };
        getEstimator(estimators);
    }, []);

    console.log(teste);
    return (
        <>
            {teste !== null && (
                <Formik
                    initialValues={{
                        business: teste.business,
                        seller: teste.userId,
                        budgetEstimator: teste.estimatorId,
                        endForecast: teste.endForecast,
                        preBudgetCode: teste.preBudgetCode,
                        budgetReviewCode: teste.budgetReviewCode,
                        emailCC: teste.emailCC,
                        budgetCategory: teste.budgetCategory,
                        submit: null,
                        budgetObservation: teste.budgetObservation
                    }}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                                handleUpdate(values);
                            } else {
                                setErrors({ submit: response.message });
                                setStatus({ success: false });
                                setSubmitting(false);
                            }
                        } catch (err) {
                            console.error(err);
                            setErrors({ submit: err.message });
                            setStatus({ success: false });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-business-register">Negócio</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-business-register"
                                    type="text"
                                    value={values.business}
                                    name="business"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-seller-register">Vendedor*</InputLabel>
                                <Select
                                    style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                    id="outlined-adornment-seller-register"
                                    type="text"
                                    value={values.seller}
                                    name="seller"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{ required: true }}
                                    required
                                >
                                    {actor.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-budgetEstimator-register">Orçamentista*</InputLabel>
                                <Select
                                    style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                    id="outlined-adornment-budgetEstimator-register"
                                    type="text"
                                    value={values.budgetEstimator}
                                    name="budgetEstimator"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{ required: true }}
                                    required
                                >
                                    {estimators.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-endForecast-register">Previsão de Término*</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-endForecast-register"
                                    type="date"
                                    value={values.endForecast}
                                    name="endForecast"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                            </FormControl>

                            <div style={{ display: 'flex' }}>
                                <FormControl fullWidth sx={{ ...theme.typography.customInput, marginRight: '10px' }}>
                                    <InputLabel htmlFor="outlined-adornment-subject-register">Código Pré-orçamento</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-subject-register"
                                        type="text"
                                        value={values.preBudgetCode}
                                        name="preBudgetCode"
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-subject-register">Código da revisão do orçamento</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-subject-register"
                                        type="text"
                                        value={values.budgetReviewCode}
                                        name="budgetReviewCode"
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                </FormControl>
                            </div>
                            {/* 
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-emailCC-register">C/c</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-emailCC-register"
                                    type="email"
                                    value={values.emailCC}
                                    name="emailCC"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                            </FormControl> */}

                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-budgetCategory-register">Categoria</InputLabel>
                                <Select
                                    style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                    id="outlined-adornment-budgetCategory-register"
                                    type="text"
                                    value={values.budgetCategory}
                                    name="budgetCategory"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{ required: true }}
                                    required
                                >
                                    {budgetCategories.map((category) => (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="outlined-adornment-observacao-register"
                                    label="Observação"
                                    value={values.budgetObservation}
                                    name="budgetObservation"
                                    onChange={handleChange}
                                    multiline
                                    variant="outlined"
                                    inputProps={{ style: { height: '100px', overflow: 'auto', minWidth: '520px' } }}
                                />
                            </FormControl>

                            <Grid container alignItems="center" justifyContent="space-between"></Grid>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Salvar
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditBudgetPage;
