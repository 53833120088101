import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

export function MachineSchedule() {
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [allMachines, setAllMachines] = useState([]);

    async function getAllMachines() {
        try {
            const { data } = await api.getLocalMachines();

            setAllMachines(data);
        } catch (error) {
            console.log('🚀 ~ getAllMachines ~ error:', error);
        }
    }

    useEffect(() => {
        // getDemands();
        getAllMachines();
    }, []);

    return (
        <MainCard title="Controle de programação de ordens">
            <Grid container spacing={gridSpacing}>
                <Box>
                    <Autocomplete
                        disablePortal
                        options={allMachines}
                        getOptionLabel={(option) => option.descricao}
                        sx={{ width: '200px', position: 'absolute', top: 10, right: 10 }}
                        onChange={(e, newValue) => {
                            setSelectedMachine(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Selecione uma maquina" />}
                    />
                </Box>
            </Grid>
        </MainCard>
    );
}
