import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4
};

export function InsertModal({ getAllSectors }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [allSectorMega, setAllSectorMega] = React.useState([]);
    const api = useApi();
    const [sectorSelected, setSectorSelected] = React.useState('');

    async function getSectorToMega() {
        try {
            const { data } = await api.getSectorMega();
            setAllSectorMega(data);
        } catch (error) {
            console.log('🚀 ~ getSectorToMega ~ error:', error);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const toastId = toast.loading('Inserindo setor...');
        if (!sectorSelected) {
            return toast.update(toastId, {
                render: 'Selecione um setor',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
        try {
            await api.insertSectorMega(sectorSelected?.CEL_IN_CODIGO);
            toast.update(toastId, {
                render: 'Setor inserido com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            setOpen(false);
            setSectorSelected('');
            getAllSectors();
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao inserir setor',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    useEffect(() => {
        getSectorToMega();
    }, []);

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Inserir setor
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Incluir setor</h3>
                    <form onSubmit={handleSubmit}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allSectorMega}
                            getOptionLabel={(option) => option.CEL_ST_NOME}
                            sx={{ marginBottom: 2 }}
                            onChange={(event, newValue) => {
                                setSectorSelected(newValue);
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} label="Setor" />}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button type="submit" variant="contained" sx={{ width: '200px' }}>
                                Confirmar
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
