function budgetStatus(status) {
    const statuses = {
        0: 'Em fila',
        1: 'Em andamento',
        2: 'Em andamento',
        3: 'Pausado',
        4: 'Finalizado'
    };

    return statuses[status];
}

export default budgetStatus;
