// material-ui

import logo from '../assets/images/Logos/refrin.png';
import LogoNew from '../assets/images/Logos/loginWithoutBg.png';

const Logo = (props) => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <>
            <img src={LogoNew} alt="Refrin" width={122} />
        </>
    );
};

export default Logo;
