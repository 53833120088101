import { Button, Dialog, DialogContent, Drawer, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';

const EditEnconders = ({ open, setOpen, get, current }) => {
    console.log('🚀 ~ file: EditEnconders.js:8 ~ EditEnconders ~ current:', current);
    const api = useApi();
    const handleClose = () => setOpen(false);
    const [name, setName] = useState('');
    const [ativoRadio, setAtivoRadio] = useState('');
    const [errorMessage, setErrorMesage] = useState('');

    useEffect(() => {
        setName(current ? current.codificator : '');
        setAtivoRadio(current ? (current.status ? 'Ativo' : 'Inativo') : '');
    }, [current]);

    const handleChangeRadio = (e) => {
        const value = e.target.value;
        setAtivoRadio(value);
        setErrorMesage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(name);
        console.log(ativoRadio);
        if (name === '' || ativoRadio === '') return setErrorMesage('Todos os campos devem ser preenchidos');
        const payload = {
            codificator: name,
            status: ativoRadio === 'Ativo' ? true : false
        };
        console.log('🚀 ~ file: AddEncoders.js:28 ~ handleSubmit ~ payload:', payload);
        try {
            await api.updateCodficator(current?.id, payload);
            setOpen(false);
            get();
        } catch (error) {
            console.log('🚀 ~ file: AddEncoders.js:32 ~ handleSubmit ~ error:', error.message);
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <div style={{ width: '400px', padding: '20px 30px' }}>
                <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Inserir codificador</h2>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <TextField
                        type="text"
                        label="Nome"
                        onChange={(e) => {
                            setName(e.target.value);
                            setErrorMesage('');
                        }}
                        value={name}
                        sx={{ width: '100%', marginBottom: '10px' }}
                    />

                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={ativoRadio}
                            onChange={(e) => handleChangeRadio(e)}
                        >
                            <FormControlLabel value={'Ativo'} control={<Radio />} label="Ativo" />
                            <FormControlLabel value={'Inativo'} control={<Radio />} label="Inativo" />
                        </RadioGroup>
                    </FormControl>
                    <Button variant="contained" sx={{ width: '100%', marginTop: '40px', marginBottom: '20px' }} type="submit">
                        Salvar
                    </Button>
                    <div>
                        <span style={{ color: 'red' }}> {errorMessage}</span>
                    </div>
                </form>
            </div>
        </Drawer>
    );
};

export default EditEnconders;
