import { Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ScheduledModal from './components/ScheduledModal';
import { useNavigate } from 'react-router';
import SendIcon from '@mui/icons-material/Send';
import { formatacaoData } from 'utils/formatacaoData';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toast } from 'react-toastify';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { LoadAnimated } from 'ui-component/LoadAnimated';
import { Box } from '@mui/system';

const ScheduledPage = () => {
    const api = useApi();
    const [row, setRow] = useState([]);
    const navigate = useNavigate();
    const [currentRow, setCurrentRow] = useState({});
    const [loading, setLoading] = useState(false);

    const getScheduleData = async () => {
        setLoading(true);
        try {
            const { data } = await api.getScheduled();
            const row = data.map((item) => ({
                ...item,
                cliente: item?.cliente,
                construction: item?.obra,
                id: item?.id,
                confirm: item?.confirmacao_solicitacao_coletum?.confirmacao
            }));

            const orderByConfirmData = row.sort((a, b) => {
                const dateA = new Date(a.confirm?.split('/')?.reverse()?.join('/'));
                const dateB = new Date(b.confirm?.split('/')?.reverse()?.join('/'));
                return dateA - dateB;
            });
            setRow(orderByConfirmData);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ getScheduleData ~ error:', error);
        }
    };

    useEffect(() => {
        getScheduleData();
    }, []);

    const columns = [
        { field: 'id', headerName: 'Códgo', flex: 0.5, renderCell: (params) => <p>{`E ${params.id}`}</p> },
        { field: 'pedido', headerName: 'PDV', flex: 0.5 },
        {
            field: 'cliente',
            headerName: 'Cliente',
            flex: 2
        },

        {
            field: 'construction',
            headerName: 'Obra',
            flex: 1
        },
        {
            field: 'confirm',
            headerName: 'Data de confirmação',
            flex: 1,
            renderCell: (params) => <p>{`${formatacaoData(params.row.confirm)}`}</p>
        },
        {
            field: 'transporte',
            headerName: 'Transporte',
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => (
                <div>
                    <Tooltip
                        title="Registrar"
                        onClick={() => {
                            setCurrentRow(params.row);
                            navigate(`/main/expedicao/coleta/${params.row.id}`);
                        }}
                    >
                        <IconButton>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Excluir"
                        onClick={() => {
                            setIdDelete(params.row.confirmacao_solicitacao_coleta_id);
                            setOpenDelete(true);
                        }}
                    >
                        <IconButton>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    //codgo para o delete
    const [openDelete, setOpenDelete] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);

    const deleteScheduledExpedition = async () => {
        setLoadingDeleteModal(true);
        try {
            await api.deleteScheduledExpedition(idDelete);
            setOpenDelete(false);
            getScheduleData();
            toast.success('Expedição excluida com sucesso');
            setLoadingDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ deleteRequestExpedition ~ error:', error);
            setLoadingDeleteModal(false);
        }
    };

    // in progress
    const columnsInProgress = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'client', headerName: 'Cliente', flex: 1 },
        { field: 'driver', headerName: 'Motorista', flex: 1 },
        { field: 'plate', headerName: 'Placa', flex: 1 },
        { field: 'doca', headerName: 'Doca', flex: 1 },
        { field: 'type', headerName: 'Tipo', flex: 0.5 }
    ];

    const [rowInProgress, setRowInProgress] = useState([]);

    async function getInProgress() {
        setLoading(true);
        try {
            const { data } = await api.getInProgressExpedition();
            console.log('🚀 ~ getRequests ~ data:', data);
            const row = data?.expedicoes.map((item) => ({
                ...item,
                client: item?.cliente,
                id: `E ${item?.id}`,
                type: 'expedicao',
                driver: item?.Registro_transporte?.motorista,
                plate: item?.Registro_transporte?.placa,
                doca: item?.Registro_transporte?.Liberacao_transporte?.local ? item?.Registro_transporte?.Liberacao_transporte?.local : '-'
            }));

            const rowAvulsos = data?.registros.map((item) => ({
                ...item,
                client: item?.cliente,
                id: `A ${item?.id}`,
                pedidoAvulso: item?.LiberacaoTransporteAvulso.pedido,
                type: 'avulso',
                idWithoutString: item?.id,
                driver: item?.Registro_transporte?.motorista,
                plate: item?.Registro_transporte?.placa,
                doca: item?.Registro_transporte?.Liberacao_transporte?.local ? item?.Registro_transporte?.Liberacao_transporte?.local : '-'
            }));

            const combineArrays = [...row, ...rowAvulsos];
            setRowInProgress(combineArrays);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ getRequests ~ error:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getInProgress();
    }, []);

    return (
        <MainCard title="Portaria">
            <ConfirmDeleteModal
                open={openDelete}
                setOpen={setOpenDelete}
                id={idDelete}
                message={'Deseja mesmo excluir essa expedição agendada ?'}
                func={deleteScheduledExpedition}
                loading={loadingDeleteModal}
            />
            <>
                <div style={{ width: '100%' }}>
                    {loading ? (
                        <Box
                            sx={{
                                marginTop: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                width: '100%'
                            }}
                        >
                            <LoadAnimated />
                        </Box>
                    ) : row.length > 0 ? (
                        <DataGrid rows={row} columns={columns} sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }} hideFooter />
                    ) : (
                        <p style={{ marginLeft: '20px' }}>Nenhuma coleta confirmada</p>
                    )}

                    {rowInProgress.length > 0 ? (
                        <>
                            <h3>Em Carregamento</h3>
                            <DataGrid
                                rows={rowInProgress}
                                columns={columnsInProgress}
                                sx={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}
                                hideFooter
                            />
                        </>
                    ) : (
                        <p style={{ marginLeft: '20px' }}>Nenhuma coleta em andamento</p>
                    )}
                </div>
            </>
        </MainCard>
    );
};

export default ScheduledPage;
