// assets
import { IconMail, IconArchive, IconWallet } from '@tabler/icons';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const icons = {
    IconMail,
    IconArchive,
    IconWallet
};

const role = localStorage.getItem('role');

let budget;

if (role === 'Orcamentista') {
    budget = {
        id: 'request',
        title: 'Orçamentos',
        type: 'group',
        children: [
            {
                id: 'budget',
                title: 'Atendimento',
                type: 'item',
                url: '/main/budget',
                icon: icons.IconWallet,
                breadcrumbs: false
            }
        ]
    };
} else {
    budget = {
        id: 'request',
        title: 'Orçamentos',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/main/graph-orcamento',
                icon: EqualizerIcon,
                breadcrumbs: false
            },
            {
                id: 'request',
                title: 'Solicitações',
                type: 'item',
                url: '/main/request',
                icon: icons.IconMail,
                breadcrumbs: false
            },
            {
                id: 'archived',
                title: 'Arquivados',
                type: 'item',
                url: '/main/archive',
                icon: icons.IconArchive,
                breadcrumbs: false
            },
            {
                id: 'budget',
                title: 'Atendimento',
                type: 'item',
                url: '/main/budget',
                icon: icons.IconWallet,
                breadcrumbs: false
            }
        ]
    };
}

export default budget;
