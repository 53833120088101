import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import ReleaseOfTransport from './ReleaseOfTransport';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

export default function FadeMenu({ rows, get }) {
    const api = useApi();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openReleaseOfTransport, setOpenReleaseOfTransport] = React.useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const close = async (id) => {
        console.log(rows);
        try {
            const { data } = await api.getCurentTransport(id);

            await api.releaseClosure(Number(data?.registro?.id));
            toast.success('Encerramento realizado com sucesso');
            get();
        } catch (error) {
            console.log('🚀 ~ file: Menu.js:28 ~ close ~ error:', error);
        }
    };

    return (
        <div>
            {rows.status !== 'Finalizada' && (
                <>
                    <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ color: '#181818' }}
                    >
                        <MoreVertIcon />
                    </Button>
                    <ReleaseOfTransport open={openReleaseOfTransport} setOpen={setOpenReleaseOfTransport} currentRow={rows} get={get} />

                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button'
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        {rows.status === 'Em carregamento' && <MenuItem onClick={() => close(rows.id)}>Encerrar</MenuItem>}
                        {rows.status === 'Finalizada' && <MenuItem></MenuItem>}
                        {rows.status === 'Solicitada' && (
                            <MenuItem onClick={() => toast.warn('Aguardando confirmação')}>Aguardando confirmação ...</MenuItem>
                        )}
                        {rows.status !== 'Em carregamento' && rows.status !== 'Finalizada' && rows.status !== 'Solicitada' && (
                            <div>
                                <MenuItem
                                    onClick={() => {
                                        if (rows.status === 'Portaria') {
                                            navigate(`/main/expedicao/coleta-edit/${rows.id}`);
                                        } else {
                                            navigate(`/main/expedicao/coleta/${rows.id}`);
                                        }
                                    }}
                                >
                                    Registro de Transp.
                                </MenuItem>
                                {rows.status === 'Portaria' && (
                                    <>
                                        <MenuItem onClick={() => setOpenReleaseOfTransport(true)}>Liberação do Transp.</MenuItem>
                                    </>
                                )}
                            </div>
                        )}
                    </Menu>
                </>
            )}
        </div>
    );
}
