import { Autocomplete, Button, Dialog, DialogTitle, FormControl, TextField } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import formater from 'utils/formatDate';

const ReleaseOfTransport = ({ open, setOpen, currentRow, get }) => {
    console.log('🚀 ~ ReleaseOfTransport ~ currentRow:', currentRow);
    const api = useApi();
    const currentUserName = localStorage.getItem('name');
    const [data, setData] = useState([]);

    const localData = [
        { id: 1, type: 'DOCA 01' },
        { id: 2, type: 'DOCA 02' },
        { id: 3, type: 'DOCA 03' },
        { id: 4, type: 'DOCA TDC' },
        { id: 6, type: 'EXPEDIÇÃO' },
        { id: 7, type: 'RECEPÇÃO' }
    ];

    // const getData = async () => {
    //     try {
    //         const { data } = await api.getCurentTransport(currentRow.id);
    //         console.log('🚀 ~ getData ~ data:', data);

    //         setData(data.registro);
    //     } catch (error) {
    //         console.log('🚀 ~ file: ReleaseOfTransport.js:18 ~ getData ~ error:', error);
    //     }
    // };

    const sendEmail = async (id) => {
        const payloadEmail = {
            email: currentRow.email,
            usuarioLogado: currentUserName
        };
        try {
            await api.releaseEmail(id, payloadEmail);
        } catch (error) {
            console.log('🚀 ~ file: ReleaseOfTransport.js:35 ~ cosntsendEmail= ~ error:', error);
        }
    };
    const [loading, setLoading] = useState(false);
    const transportReleaseRecord = async (values) => {
        setLoading(true);
        const payload = {
            transporte_solicitacao: values?.transportRegister,
            data_confirmacao: currentRow?.Registro_transporte?.data_confirmacao,
            transportadora_portaria: values?.shippingCompany,
            local: values?.local,
            transporte_portaria: values?.transport,
            observacao: values?.observation,
            responsavel_id: currentRow?.usuario_responsavel_id
        };

        try {
            const res = await api.transportRelease(currentRow?.Registro_transporte?.id, payload);
            console.log('🚀 ~ file: ReleaseOfTransport.js:57 ~ transportReleaseRecord ~ data:', res.data);
            toast.success('Liberação concluida');
            sendEmail(res.data?.liberacaoTransporte?.id);
            setOpen(false);
            setLoading(false);
            get();
        } catch (error) {
            console.log('🚀 ~ file: ReleaseOfTransport.js:43 ~ transportReleaseRecord ~ error:', error);
        }
    };

    // useEffect(() => {
    //     getData();
    // }, []);
    return (
        <Dialog onClose={() => setOpen(false)} open={open}>
            <div style={{ width: '550px', padding: '10px' }}>
                <DialogTitle fontSize={25} textAlign={'center'}>
                    Liberação do Transporte
                </DialogTitle>
                <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <h4>Agrupamento: {currentRow?.agrupamento?.map((item) => `${item} | `)}</h4>
                    <h4>Cliente: {currentRow?.cliente}</h4>
                </div>

                <Formik
                    initialValues={{
                        transport: currentRow?.Registro_transporte?.transporte || '',
                        confirmData: formater(currentRow?.Registro_transporte?.data_confirmacao) || '',
                        shippingCompany: currentRow?.Registro_transporte?.transportadora || '',
                        local: '',
                        observation: '',
                        transportRegister: currentRow?.transporte
                    }}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        transportReleaseRecord(values);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{ padding: '5px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                        >
                            <h3>Dados Agendados</h3>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl>
                                    <TextField
                                        sx={{ width: '300px' }}
                                        disabled
                                        label="Transporte"
                                        name="transportRegister"
                                        value={values.transportRegister}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        disabled
                                        label="Data de confirmação"
                                        name="confirmData"
                                        value={values.confirmData}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </div>

                            <h3>Dados Portaria</h3>
                            <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                                <FormControl>
                                    <TextField
                                        disabled
                                        label="Transportadora"
                                        name="shippingCompany"
                                        value={values.shippingCompany}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        disabled
                                        label="Transporte"
                                        name="transport"
                                        value={values.transport}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </div>
                            <div style={{ marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="local"
                                        options={localData}
                                        sx={{ width: 230 }}
                                        getOptionLabel={(option) => option.type}
                                        onChange={(e, newValue) => {
                                            if (newValue) setValues({ ...values, local: newValue.type });
                                        }}
                                        value={localData.find((option) => option.type === values.local) || null}
                                        renderInput={(params) => <TextField {...params} label="Local" name="local" value={values.local} />}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        multiline
                                        rows={3}
                                        sx={{ width: '100%' }}
                                        label="Observação"
                                        name="observation"
                                        value={values.observation}
                                        inputProps={{ style: { padding: '12px' } }}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </div>
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button variant="contained" color="error">
                                    Cancelar
                                </Button>
                                <Button variant="contained" type="submit" disabled={loading}>
                                    {loading ? 'Liberando...' : 'Liberar'}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
};

export default ReleaseOfTransport;
