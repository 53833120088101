import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, display } from '@mui/system';
import { formatarCPF, formatarPlaca, formatarRG, formatarTelefone } from 'utils/regex';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { uf } from 'utils/uf';
import { useEffect } from 'react';
import { ModalEmailsCC } from 'ui-component/ModalEmailsCC';
import { typeOfTransport } from 'utils/typeOfTransport';

const LooseTransport = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [openModal, setOpenModal] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [currentLooseTransport, setCurrentLooseTransport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cityResults, setCityResults] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [list, setList] = useState([]);

    const handleCityResults = async () => {
        try {
            const { data } = await api.getCitiesByIbge(selectedState);
            console.log('🚀 ~ handleCityResults ~ data:', data);
            setCityResults(data);
        } catch (error) {
            console.log('🚀 ~ handleCityResults ~ error:', error);
        }
    };

    useEffect(() => {
        if (selectedState) {
            handleCityResults();
        }
    }, [selectedState]);

    const material = [
        { id: 1, type: 'ENTREGA FORNECEDOR' },
        { id: 2, type: 'DISTRIBUIÇÃO' }
    ];

    const responsavel = localStorage.getItem('id');

    const looseTransportFunc = async (values) => {
        setLoading(true);
        const payload = {
            material: values.materialForm,
            cliente: values.client,
            motorista: values.driversName,
            telefone: values.driversTel,
            cpf: values.driversCPF,
            rg: values.driversRG,
            transportadora: values.shippingCompany,
            placa: values.licensePlate,
            transporte: values.transport,
            observacao: values.observation,
            responsavel_id: parseInt(responsavel),
            email: values.email,
            cidade_destino: values.city,
            uf_destino: values.uf,
            ccEmails: list
        };

        try {
            const { data } = await api.looseTransport(payload);
            console.log('🚀 ~ looseTransportFunc ~ data:', data);

            toast.success('Transport avulso registrado com sucesso');
            // setOpenModal(true);
            // setDataModal(data.Registro);
            setCurrentLooseTransport(payload);
            navigate(-1);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ file: index.js:49 ~ looseTransport ~ error:', error);
        }
    };

    const validationSchema = Yup.object().shape({
        materialForm: Yup.string().required('Campo obrigatório'),
        client: Yup.string().required('Campo obrigatório'),
        driversName: Yup.string().required('Campo obrigatório'),
        driversTel: Yup.string().required('Campo obrigatório'),
        driversCPF: Yup.string().required('Campo obrigatório'),
        driversRG: Yup.string().required('Campo obrigatório'),
        shippingCompany: Yup.string().required('Campo obrigatório'),
        licensePlate: Yup.string().required('Campo obrigatório'),
        transport: Yup.string().required('Campo obrigatório'),
        observation: Yup.string(),
        email: Yup.string(),
        city: Yup.string().required('Campo obrigatório')
    });

    return (
        <MainCard title="Registro de Transporte Avulso">
            <Grid container spacing={gridSpacing}>
                <Formik
                    initialValues={{
                        materialForm: '',
                        client: '',
                        driversName: '',
                        driversTel: '',
                        driversCPF: '',
                        driversRG: '',
                        shippingCompany: '',
                        licensePlate: '',
                        transport: '',
                        observation: '',
                        responsavel: responsavel,
                        email: '',
                        city: '',
                        uf: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        looseTransportFunc(values);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => {
                        return (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ padding: '5px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                            >
                                <h3>Dados Portaria</h3>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="material"
                                            options={material}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.type}
                                            value={material.find((item) => item.type === values.materialForm)}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, materialForm: newValue.type });
                                                }
                                            }}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Material"
                                                    name="materialForm"
                                                    value={values.materialForm}
                                                    error={touched.materialForm && Boolean(errors.materialForm)}
                                                    helperText={touched.materialForm && errors.materialForm}
                                                />
                                            )}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            sx={{ width: '400px' }}
                                            value={values.client}
                                            name="client"
                                            label="Cliente"
                                            onChange={handleChange}
                                            error={touched.client && Boolean(errors.client)}
                                            helperText={touched.client && errors.client}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            sx={{ width: '350px' }}
                                            value={values.email}
                                            type="email"
                                            name="email"
                                            label="E-mail"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <ModalEmailsCC list={list} setList={setList} />
                                </Box>

                                <Box sx={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '200px' }}
                                            value={values.driversName}
                                            name="driversName"
                                            label="Nome do Motorista"
                                            onChange={handleChange}
                                            error={touched.driversName && Boolean(errors.driversName)}
                                            helperText={touched.driversName && errors.driversName}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="uf"
                                            options={uf}
                                            sx={{ width: 100 }}
                                            getOptionLabel={(option) => option?.sigla}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, uf: newValue?.sigla });
                                                }
                                                setSelectedState(newValue?.sigla);
                                            }}
                                            value={uf.find((item) => item.sigla === values.uf) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="UF"
                                                    name="uf"
                                                    value={values.uf}
                                                    error={touched.uf && Boolean(errors.uf)}
                                                    helperText={touched.uf && errors.uf}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="city"
                                            options={cityResults}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.nome}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, city: newValue.nome });
                                                }
                                            }}
                                            value={cityResults.find((item) => item.nome === values.city) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Cidade"
                                                    name="city"
                                                    value={values.city}
                                                    error={touched.city && Boolean(errors.city)}
                                                    helperText={touched.city && errors.city}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '145px' }}
                                            value={values.driversTel}
                                            name="driversTel"
                                            label="Telefone"
                                            onChange={(e) => setValues({ ...values, driversTel: formatarTelefone(e.target.value) })}
                                            error={touched.driversTel && Boolean(errors.driversTel)}
                                            helperText={touched.driversTel && errors.driversTel}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '150px' }}
                                            value={values.driversCPF}
                                            name="driversCPF"
                                            label="CPF"
                                            onChange={(e) => setValues({ ...values, driversCPF: formatarCPF(e.target.value) })}
                                            error={touched.driversCPF && Boolean(errors.driversCPF)}
                                            helperText={touched.driversCPF && errors.driversCPF}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '180px' }}
                                            value={values.driversRG}
                                            name="driversRG"
                                            label="RG"
                                            onChange={(e) => setValues({ ...values, driversRG: e.target.value })}
                                            error={touched.driversRG && Boolean(errors.driversRG)}
                                            helperText={touched.driversRG && errors.driversRG}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '465px' }}
                                            value={values.shippingCompany}
                                            name="shippingCompany"
                                            label="Transportadora"
                                            onChange={handleChange}
                                            error={touched.shippingCompany && Boolean(errors.shippingCompany)}
                                            helperText={touched.shippingCompany && errors.shippingCompany}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            sx={{ width: '200px' }}
                                            value={values.licensePlate}
                                            name="licensePlate"
                                            label="Placa"
                                            onChange={(e) => setValues({ ...values, licensePlate: formatarPlaca(e.target.value) })}
                                            error={touched.licensePlate && Boolean(errors.licensePlate)}
                                            helperText={touched.licensePlate && errors.licensePlate}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="transport"
                                            options={typeOfTransport}
                                            sx={{ width: 323 }}
                                            getOptionLabel={(option) => option.type}
                                            value={typeOfTransport.find((item) => item.type === values.transport)}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, transport: newValue.type });
                                                }
                                            }}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tipo de transport"
                                                    name="transport"
                                                    sx={{ width: '340px' }}
                                                    value={values.transport}
                                                    error={touched.transport && Boolean(errors.transport)}
                                                    helperText={touched.transport && errors.transport}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginTop: '10px', width: '100%' }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Observação"
                                            sx={{ width: '100%' }}
                                            multiline
                                            rows={4}
                                            value={values.observation}
                                            name="observation"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'end' }}>
                                    <Button type="submit" disabled={loading} variant="contained" sx={{ width: 200, marginBottom: '200px' }}>
                                        {loading ? 'Carregando...' : 'Registrar'}
                                    </Button>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </Grid>
        </MainCard>
    );
};

export default LooseTransport;
