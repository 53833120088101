import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
// assets
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

/////////////////////////////////////////////////////////////////////////////////

const EditEmailForm = ({ id, setOpenEdit }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const api = useApi();

    const [emailData, setEmailData] = useState([]);
    const [emailId, setEmailId] = useState(null);
    const budgetCategories = ['Estudo', 'Compra'];

    const [isDisabled, setIsDisabled] = useState(false);

    const [teste, setTeste] = useState([]);

    useEffect(() => {
        const get = async (id) => {
            const response = await api.getMailById(id);
            setTeste(response.data);
            return response;
        };
        get(id);
    }, []);

    const handleUpdate = async (values, teste) => {
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (key !== 'emailCC' && key !== 'budgetObservation' && key !== 'subject') {
                acc[key] = value;
            }
            return acc;
        }, {});
        const isAnyFieldEmpty = Object.values(filteredValues).some((value) => value === '');
        if (isAnyFieldEmpty) {
            window.alert('Campos obrigatórios faltando');
            return;
        }

        setIsDisabled(true);
        try {
            const userId = values.seller;
            const estimatorId = values.estimator;
            const response = await api.createBudget(userId, estimatorId, teste, values);
            toast.success('E-mail classificado com sucesso!');
            window.location.reload();
            if (response.status === 201 && teste.from !== 'Reply') {
                setIsDisabled(false);
                await api.reactivateMail(
                    teste.id,
                    teste.from,
                    response.data.budget.id,
                    response.data.budget.emailCC,
                    response.data.budget.subject
                );
            }

            return response;
        } catch (error) {
            setIsDisabled(false);
            toast.error('Remetente inexistente!');
        }
    };

    const [actor, setActor] = useState([]);
    const [estimators, setEstimators] = useState([]);
    useEffect(() => {
        const valor = 'Vendas';
        const estimators = 'Orcamentos';
        const get = async (value) => {
            const response = await api.getByActor(value);
            setActor(response.data);
            return response;
        };
        get(valor);

        const getEstimator = async (value) => {
            const response = await api.getByActor(value);
            setEstimators(response.data);
            return response;
        };
        getEstimator(estimators);
    }, []);

    return (
        <Formik
            initialValues={{
                subject: '',
                business: '',
                seller: '',
                estimator: '',
                forecast: '',
                preBudgetCode: '',
                budgetReviewCode: '',
                emailCC: '',
                budgetCategory: '',
                budgetObservation: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                business: Yup.string().required('Email obrigatório'),
                seller: Yup.string().required('Vendedor obrigatório'),
                estimator: Yup.string().required('Orçamentista obrigatório'),
                forecast: Yup.string().required('Data de término obrigatório'),
                preBudgetCode: Yup.string().required('Código pré orçamento obrigatório'),
                budgetReviewCode: Yup.string().required('Código de Revisão obrigatório'),
                budgetCategory: Yup.string().required('Categoria de atendimento obrigatório')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        await handleUpdate(values, teste);
                    } else {
                        setErrors({ submit: response.message });
                        setStatus({ success: false });
                        setSubmitting(false);
                    }
                } catch (err) {
                    console.error(err);
                    setErrors({ submit: err.message });
                    setStatus({ success: false });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <FormControl fullWidth error={Boolean(touched.business && errors.business)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-business-register">Negócio</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-business-register"
                            type="text"
                            value={values.business}
                            name="business"
                            onChange={handleChange}
                            inputProps={{}}
                        />
                        {touched.business && errors.business && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {errors.business}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl fullWidth error={Boolean(touched.seller && errors.seller)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-seller-register">Vendedor*</InputLabel>
                        <Select
                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                            id="outlined-adornment-seller-register"
                            type="text"
                            value={values.seller}
                            name="seller"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{ required: true }}
                            required
                        >
                            {actor.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.username}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.seller && errors.seller && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {errors.seller}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl fullWidth error={Boolean(touched.estimator && errors.estimator)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-estimator-register">Orçamentista*</InputLabel>
                        <Select
                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                            id="outlined-adornment-estimator-register"
                            type="text"
                            value={values.estimator}
                            name="estimator"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{ required: true }}
                            required
                        >
                            {estimators.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.username}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.estimator && errors.estimator && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {errors.estimator}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl fullWidth error={Boolean(touched.forecast && errors.forecast)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-forecast-register">Previsão de Término*</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-forecast-register"
                            type="date"
                            value={values.forecast}
                            name="forecast"
                            onChange={handleChange}
                            inputProps={{}}
                        />
                        {touched.forecast && errors.forecast && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {errors.forecast}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <div style={{ display: 'flex' }}>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.preBudgetCode && errors.preBudgetCode)}
                            sx={{ ...theme.typography.customInput, marginRight: '10px' }}
                        >
                            <InputLabel htmlFor="outlined-adornment-prebudgetcode">Código Pré-orçamento</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-prebudgetcode"
                                type="number"
                                value={values.preBudgetCode}
                                name="preBudgetCode"
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.preBudgetCode && errors.preBudgetCode && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.preBudgetCode}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.budgetReviewCode && errors.budgetReviewCode)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-subject-register">Código da revisão do orçamento</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-subject-register"
                                type="text"
                                value={values.budgetReviewCode}
                                name="budgetReviewCode"
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.budgetReviewCode && errors.budgetReviewCode && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.budgetReviewCode}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>

                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-subject-register">C/c</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-subject-register"
                            type="email"
                            value={values.emailCC}
                            name="emailCC"
                            onChange={handleChange}
                            inputProps={{}}
                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        error={Boolean(touched.budgetCategory && errors.budgetCategory)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-subject-register">Categoria</InputLabel>
                        <Select
                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                            id="outlined-adornment-estimator-register"
                            type="text"
                            value={values.budgetCategory}
                            name="budgetCategory"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{ required: true }}
                            required
                        >
                            {budgetCategories.map((category) => (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.budgetCategory && errors.budgetCategory && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {errors.budgetCategory}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl>
                        <TextField
                            id="outlined-adornment-observacao-register"
                            label="Observação"
                            value={values.observacao}
                            name="budgetObservation"
                            onChange={handleChange}
                            multiline
                            rows={4} // Defina o número desejado de linhas visíveis
                            variant="outlined"
                            inputProps={{ style: { overflow: 'auto', minWidth: '360px' } }}
                        />
                    </FormControl>

                    <Grid container alignItems="center" justifyContent="space-between"></Grid>

                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button disabled={isDisabled} fullWidth size="large" type="submit" variant="contained" color="primary">
                                {!isDisabled ? 'Salvar' : 'Aguarde...'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default EditEmailForm;
