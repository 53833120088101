import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { IconArrowRight } from '@tabler/icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useApi } from 'Service/axios';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { ModalNextPage } from './components/ModalNextPage';
import formater from 'utils/formatDate';
import { SelectedDemandsModal } from './components/SelectedDemandsModal';

export function Demands() {
    const api = useApi();
    const navigate = useNavigate();
    const [allCells, setAllCells] = useState([]);
    const [allDemands, setAllDemands] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const columns = [
        { field: 'id', headerName: 'Agrupamentos', flex: 1 },
        { field: 'AGN_ST_FANTASIA', headerName: 'Cliente', flex: 1 },
        { field: 'CEL_DT_PROGRAMACAO', headerName: 'Data de necesidade', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ModalNextPage id={params.row.id} />
                    </div>
                );
            }
        }
    ];

    async function getAllCells() {
        try {
            const { data } = await api.getLocalWorkCell();

            setAllCells(data);
        } catch (error) {
            console.log('🚀 ~ getAllCells ~ error:', error);
        }
    }

    async function getDemands() {
        try {
            const { data } = await api.getGroupedDemands();
            const rows = data.map((item) => ({
                ...item,
                id: item?.ORD_ST_ALTERNATIVO,
                CEL_DT_PROGRAMACAO: formater(item.CEL_DT_PROGRAMACAO)
            }));
            setAllDemands(rows);
        } catch (error) {
            console.log('🚀 ~ getDemands ~ error:', error);
        }
    }

    useEffect(() => {
        getAllCells();
        getDemands();
    }, []);

    return (
        <MainCard title="Demandas" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* <Box sx={{ margin: '20px' }}>
                    <Autocomplete
                        disablePortal
                        options={allCells}
                        getOptionLabel={(option) => option.descricao}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Célula de trabalho" />}
                    />
                </Box> */}
                <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
                    <SelectedDemandsModal />
                </Box>
                {allDemands.length > 0 && (
                    <DataGrid
                        rows={allDemands}
                        columns={columns}
                        // checkboxSelection
                        // onRowSelectionModelChange={(newRowSelectionModel) => {
                        //     setSelectedItems(newRowSelectionModel);
                        //     setDemandsSelected(newRowSelectionModel);
                        // }}
                        // rowSelectionModel={selectedItems}
                        hideFooter
                        sx={{ maxHeight: 'calc(100vh - 230px)' }}
                    />
                )}

                {selectedItems.length > 0 && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
                        <Button variant="outlined" onClick={() => navigate('/main/pcp/planejamento')}>
                            Programar <IconArrowRight />
                        </Button>
                    </Box>
                )}
            </Grid>
        </MainCard>
    );
}
