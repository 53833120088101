// assets
import { IconCoin, IconGraph, IconShoppingCart } from '@tabler/icons';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const icons = {
    IconCoin,
    IconGraph,
    IconShoppingCart
};

const budget = {
    id: 'sales',
    title: 'Comercial',
    type: 'group',
    children: [
        {
            id: 'painel',
            title: 'Dashboard',
            type: 'item',
            url: '/main/painel',
            icon: EqualizerIcon,
            breadcrumbs: false
        },
        {
            id: 'sales',
            title: 'Orçamento de Venda',
            type: 'item',
            url: '/main/sales',
            icon: icons.IconCoin,
            breadcrumbs: false
        },
        {
            id: 'salesOrder',
            title: 'Pedido de Venda',
            type: 'item',
            url: '/main/sales_order',
            icon: icons.IconShoppingCart,
            breadcrumbs: false
        }
    ]
};

export default budget;
