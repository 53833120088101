import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useMediaQuery
} from '@mui/material';

// third party
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
// assets
import { useApi } from 'Service/axios';

/////////////////////////////////////////////////////////////////////////////////

const EditSectorForm = ({ sectorID, setOpenEdit }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const api = useApi();

    const [sector, setSector] = useState(null);
    const getSectorById = async (sectorID) => {
        const response = await api.getSectorById(sectorID);
        setSector(response.data.sector);
    };

    const handleUpdate = async (sectorID, values) => {
        try {
            const response = await api.updateSector(sectorID, values);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSectorById(sectorID);
    }, []);

    return (
        <>
            {sector !== null && (
                <Formik
                    initialValues={{
                        sectorName: sector?.sectorName,
                        submit: null
                    }}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleUpdate(sectorID, values);
                        } catch (err) {
                            console.error(err);
                            setErrors({ submit: err.message });
                            setStatus({ success: false });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl
                                fullWidth
                                sx={{ ...theme.typography.customInput }}
                                error={Boolean(touched.sectorName && errors.sectorName)}
                            >
                                <InputLabel htmlFor="outlined-adornment-sectorName-register">Nome do Setor</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-sectorName-register"
                                    type="text"
                                    value={values.sectorName}
                                    name="sectorName"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.sectorName && errors.sectorName && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.sectorName}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            <Box sx={{ marginTop: '2em' }}>
                                <AnimateButton>
                                    <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
                                        Atualizar
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditSectorForm;
