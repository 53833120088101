import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import ReplayIcon from '@mui/icons-material/Replay';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import { TextField } from '@mui/material';
import moment from 'moment';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

export default function TimeLineCodificator({ codificationEvents, get }) {
    const role = localStorage.getItem('role');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [editedDate, setEditedDate] = React.useState('');
    const api = useApi();

    function handleClick(event, eventItem) {
        if (role === 'Codificador') return;
        setAnchorEl(event.currentTarget);
        setSelectedEvent(eventItem);
    }

    function handleClose() {
        setAnchorEl(null);
        setSelectedEvent(null);
        setEditedDate('');
        setSelectedEvent(null);
    }

    function format(data) {
        const dataObjeto = new Date(data);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };
        return dataObjeto.toLocaleString('pt-BR', options);
    }

    function CurrentIcon(type) {
        if (type === 'iniciado') return <HomeIcon />;
        if (type === 'pausado') return <PauseIcon />;
        if (type === 'finalizado') return <StopIcon />;
        if (type === 'retomado') return <ReplayIcon />;
        if (type === 'criado') return <BlurOnIcon />;
    }

    function getEventMessage(event) {
        if (event.eventType === 'pausado') {
            return (
                <p style={{ wordBreak: 'break-all' }}>
                    Pausando a atividade: <b>{event?.activityType}</b> <br />
                    Observação: {event?.observation}
                </p>
            );
        } else if (event.eventType === 'iniciado') {
            return (
                <p style={{ wordBreak: 'break-all' }}>
                    Iniciando a atividade: <b>{event?.activityType}</b>
                </p>
            );
        } else if (event.eventType === 'retomado') {
            return (
                <p style={{ wordBreak: 'break-all' }}>
                    Retomando a atividade: <b>{event?.activityType}</b>{' '}
                </p>
            );
        } else if (event.eventType === 'criado') {
            return 'Apontamento criado';
        } else if (event.eventType === 'finalizado') {
            return (
                <p style={{ wordBreak: 'break-all' }}>
                    Finalizando a atividade: <b>{event?.activityType}</b> <br />
                    Observação: {event?.observation}
                </p>
            );
        }
    }

    function getEventColor(eventType) {
        switch (eventType) {
            case 'iniciado':
                return 'primary';
            case 'pausado':
                return 'warning';
            case 'finalizado':
                return 'error';
            default:
                return 'grey';
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const formatDateForInput = (date) => {
        return moment(date).format('YYYY-MM-DDTHH:mm');
    };

    async function handleEditEvent(event) {
        const payload = {
            newEventDate: editedDate
        };
        const toastId = toast.loading('Editando data do evento...');
        try {
            await api.editDateCodification(event.id, payload);
            toast.update(toastId, {
                render: 'Data do evento editada com sucesso!',
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: 3000
            });
            get();
            handleClose();
        } catch (error) {
            console.log('🚀 ~ handleEditEvent ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao editar data do evento!',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <div>
            <h2 style={{ textAlign: 'center' }}>TimeLine</h2>
            <Timeline position="alternate">
                {codificationEvents?.map((event, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                            {format(event.eventDate)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                                sx={{ cursor: 'pointer' }}
                                color={getEventColor(event.eventType)}
                                onClick={(e) => handleClick(e, event)}
                            >
                                {CurrentIcon(event.eventType)}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                                {event.eventType}
                            </Typography>
                            <Typography>{getEventMessage(event)}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div style={{ padding: '10px' }}>
                    <Typography variant="h4">Editar data do evento</Typography>
                    {selectedEvent && (
                        <div>
                            <Typography variant="body1">Tipo: {selectedEvent.eventType}</Typography>
                            <Typography variant="body1">Atividade: {selectedEvent.activityType}</Typography>
                            <TextField
                                type="datetime-local"
                                value={editedDate || formatDateForInput(selectedEvent?.eventDate)}
                                onChange={(event) => setEditedDate(event.target.value)}
                            />
                            <IconButton onClick={() => handleEditEvent(selectedEvent)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    );
}
