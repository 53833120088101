import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Modal,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import Grafico from '../../../assets/images/Card/grafico.png';
import Bi from '../../../assets/images/Card/bi.png';
import PreOrcImg from '../../../assets/images/Card/pre_orcamento.png';
import { Link } from 'react-router-dom';
import { RequestQuote, Payments } from '@mui/icons-material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsertChartIcon from '@mui/icons-material/InsertChart';

const PainelPcp = () => {
    return (
        <MainCard title="Painéis de Gestão de PCP">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em', width: '100%', flexWrap: 'wrap', gap: '2em' }}>
                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Carga Fábrica</Typography>
                            <Button type="button" variant="contained" size="small">
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/carga-fabrica'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Gestão de Obras</Typography>
                            <Button type="button" variant="contained" size="small">
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/gestao-obra'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Codificação</Typography>
                            <Button type="button" variant="contained" size="small">
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/codificacao'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Controle de Projetos</Typography>
                            <Button
                                type="button"
                                variant="contained"
                                size="small"
                                sx={{
                                    marginLeft: '10px'
                                }}
                            >
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/controle-projetos'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Produção Seriada</Typography>
                            <Button type="button" variant="contained" size="small">
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/producao-seriada'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Visão Geral</Typography>
                            <Button type="button" variant="contained" size="small">
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/visao-geral'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Solicitações de Compras</Typography>
                            <Button
                                type="button"
                                variant="contained"
                                size="small"
                                sx={{
                                    marginLeft: '10px'
                                }}
                            >
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/solicitacao-compra'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            minWidth: '230px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            border: '1px solid rgba(0,0,0,0.34)',
                            borderRadius: '7px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid rgba(0,0,0,0.43)',
                                backgroundColor: 'rgba(0,0,0,0.23)',
                                borderRadius: '7px'
                            }}
                        >
                            <InsertChartIcon style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '.4em',
                                marginTop: '.7em'
                            }}
                        >
                            <Typography variant="h4">Controle de Pinturas Externas</Typography>
                            <Button
                                type="button"
                                variant="contained"
                                size="small"
                                sx={{
                                    marginLeft: '10px'
                                }}
                            >
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/graph-pcp/pintura-externa'}>
                                    Acessar
                                </Link>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default PainelPcp;
