import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Button, CircularProgress, Grid, TableSortLabel, TextField, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import TablePagination from '@mui/material/TablePagination';
import EmailForm from './Form/EmailForm';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Snackbar from '@mui/material/Snackbar';
import { Box } from '@mui/system';
import CircularWithValueLabel from 'ui-component/Progress/ProgressModal';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getAccessLevel } from 'utils/getAccessLevel';

function Request() {
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openSnakBar, setOpenSnackBar] = useState(false);
    const [openSnakBarDeleted, setOpenSnackBarDeleted] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [filteredMails, setFilteredMails] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [mails, setMails] = useState([]);
    const [mailsLoading, setMailsLoading] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    const api = useApi();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenEdit = (e, id) => {
        e.preventDefault();
        setOpenEdit(true);
        setId(id);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        window.location.reload();
    };

    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleConfirmDelete = async () => {
        setOpenSnackBarDeleted(true);
    };

    const handleClickSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleCloseSnackBarDeleted = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBarDeleted(false);
    };

    const handleCancelDeleteDialog = () => {
        handleCloseDelete();
    };

    const handleConfirmDeleteDialog = async () => {
        try {
            await api.changeMailStatus(id);
            handleConfirmDelete();
            window.location.reload();
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const getMails = async () => {
        try {
            setLoading(true);
            const response = await api.getAllMail();
            const mails = response.data.map((mail) => {
                const dataHora = new Date(mail.date);
                const opcoes = {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                const dataHoraFormatada = dataHora.toLocaleString('pt-BR', opcoes);
                return { ...mail, date: dataHoraFormatada };
            });
            setMails(mails);
            setLoading(false);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleCheckNewEmails = async () => {
        try {
            setCircularProgress(true);

            setMailsLoading(true);
            await api.checkAndSaveMail();
            // await getMails();

            setTimeout(() => {
                setCircularProgress(false);
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Erro ao verificar novos e-mails: ', error);
            setCircularProgress(false);
        }
    };

    const handleSearch = () => {
        try {
            const filteredMails = mails.filter((mail) => mail.from.toLowerCase().includes(searchValue.toLowerCase()));
            setFilteredMails(filteredMails);
            setPage(0);
            setSortColumn(null);
            setSortDirection(null);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const convertDate = (str) => {
        const [date, time] = str.split(', ');
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}T${time}`).getTime();
    };

    const handleSort = (column) => {
        let direction = 'asc';
        if (sortColumn === column && sortDirection === 'asc') {
            direction = 'desc';
        }
        setSortColumn(column);
        setSortDirection(direction);

        const sortedMails = [...mails].sort((a, b) => {
            if (column === 'from') {
                if (direction === 'asc') {
                    return a.from.localeCompare(b.from);
                } else {
                    return b.from.localeCompare(a.from);
                }
            } else if (column === 'date') {
                if (direction === 'asc') {
                    return convertDate(a.date) - convertDate(b.date);
                } else {
                    return convertDate(b.date) - convertDate(a.date);
                }
            } else if (column === 'subject') {
                if (direction === 'asc') {
                    return a.subject.localeCompare(b.subject);
                } else {
                    return b.subject.localeCompare(a.subject);
                }
            }
        });

        setFilteredMails(sortedMails);
        console.log(sortedMails);
        setPage(0);
    };

    useEffect(() => {
        getMails();
    }, []);

    useEffect(() => {
        const filteredMails = mails.filter((mail) => mail.from.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredMails(filteredMails);
        setPage(0);
    }, [searchValue, mails]);

    // datagrid
    const columns = [
        { field: 'from', headerName: 'Remetente', flex: 2, sortable: true },
        { field: 'subject', headerName: 'Assunto', flex: 2, sortable: true },
        { field: 'date', headerName: 'Data', flex: 1, sortable: true },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton disabled={getAccessLevel() === 'Vendedor'} onClick={(e) => handleClickOpenEdit(e, params.row.id)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={getAccessLevel() === 'Vendedor'} onClick={(e) => handleClickOpenDelete(e, params.row.id)}>
                        <ArchiveIcon />
                    </IconButton>
                </>
            )
        }
    ];

    return (
        <MainCard title={'Caixa de entrada'} sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    {circularProgress && (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9999
                            }}
                        >
                            <CircularWithValueLabel />
                        </div>
                    )}

                    <div style={{ position: 'absolute', top: '20px', right: '0' }}>
                        {getAccessLevel() !== 'admComercial' && (
                            <Button
                                variant="contained"
                                disabled={mailsLoading}
                                color="primary"
                                onClick={handleCheckNewEmails}
                                style={{ marginRight: '10px' }}
                            >
                                {!mailsLoading ? 'Verificar novos Emails' : 'Aguarde...'}
                            </Button>
                        )}

                        {/* <TextField
                        label="Buscar por Remetente"
                        variant="outlined"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        style={{ width: '400px', marginRight: '50px' }}
                    /> */}
                    </div>
                </div>

                {loading ||
                    (mailsLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </div>
                    ))}

                {!loading && !mailsLoading && filteredMails.length > 0 && (
                    <DataGrid
                        rows={filteredMails}
                        columns={columns}
                        pageSize={rowsPerPage}
                        sx={{ maxHeight: 'calc(100vh - 230px)' }}
                        // rowsPerPageOptions={[10, 25, 50, 100]}

                        onSortModelChange={(model) => {
                            const sortModel = model[0];
                            if (sortModel) {
                                handleSort(sortModel.field);
                            }
                        }}
                    />
                )}

                {!mails.length > 0 && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography>Nenhuma solicitação nova no momento</Typography>
                    </Box>
                )}

                <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md" fullWidth>
                    <DialogTitle>Editar Email</DialogTitle>
                    <DialogContent>
                        <EmailForm id={id} handleCloseEdit={handleCloseEdit} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit}>Fechar</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDelete} onClose={handleCloseDelete} maxWidth="sm" fullWidth>
                    <DialogTitle>Arquivar Email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Tem certeza de que deseja arquivar este email?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                        <Button onClick={handleConfirmDeleteDialog}>Arquivar</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={openSnakBar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackBar}
                    message="Email atualizado com sucesso!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />

                <Snackbar
                    open={openSnakBarDeleted}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackBarDeleted}
                    message="Email arquivado com sucesso!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </Grid>
        </MainCard>
    );
}

export default Request;
