import { Autocomplete, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

import { Formik } from 'formik';
import { Box } from '@mui/system';
import { formatarCPF, formatarData, formatarPlaca, formatarRG, formatarTelefone } from 'utils/regex';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { date } from 'utils/date';
import { formatacaoData } from 'utils/formatacaoData';
import * as Yup from 'yup';
import { uf } from 'utils/uf';
import { typeOfTransport } from 'utils/typeOfTransport';

const TransportRecord = () => {
    const { id } = useParams();
    const api = useApi();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const responsavel = localStorage.getItem('id');
    const [loading, setLoading] = useState(false);

    const materialList = [{ type: 'DISTRIBUIÇÃO' }, { type: 'DUTO' }];

    const getId = async () => {
        try {
            const { data } = await api.getCollectRequestID(id);
            console.log('🚀 ~ file: TransportRecord.js:38 ~ getId ~ data:', data);

            const dataRequest = {
                confirmacao_coleta: data.confirmacao_solicitacao_coletum,
                grouping: data?.agrupamento,
                order: data?.pedido,
                contact: data?.contato,
                email: data?.email,
                client: data?.cliente,
                constructions: data?.obra,
                confirm: formatacaoData(data?.confirmacao_solicitacao_coletum?.confirmacao),
                period: data?.periodo,
                transport: data?.transporte,
                confirmData: data?.confirmacao_solicitacao_coletum?.confirmacao
            };
            setData(dataRequest);
            setSelectedState(dataRequest?.confirmacao_coleta.uf_destino);
        } catch (error) {
            console.log('🚀 ~ file: TransportRecord.js:39 ~ getId ~ error:', error);
        }
    };

    useEffect(() => {
        getId();
    }, []);

    const handleSubmitFunc = async (values) => {
        setLoading(true);
        const payload = {
            agrupamentos: values.agrupamento,
            pedido: values.order,
            contato: values.contact,
            data_confirmacao: data.confirmData,
            cliente: values.client,
            periodo: values.period,
            obra: values.constructions,
            transporte: values.transportConfirm,
            motorista: values.driverName,
            telefone: values.tel,
            cpf: values.cpf,
            rg: values.rg,
            transportadora: values.shippingCompany,
            placa: values.licensePlate,
            material: 'DUTO',
            observacao: values.observation,
            responsavel_id: responsavel,
            frotista: values.frotista,
            cidade_destino: values.city
        };

        try {
            const { data } = await api.transportRegister(id, payload);
            navigate(-1);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ file: TransportRecord.js:71 ~ handleSubmit ~ error:', error);
            toast.error('Verifique o status');
        }
    };

    const typeDriver = [
        { id: 1, type: 'Frotista' },
        { id: 2, type: 'Autônomo' }
    ];

    const schema = Yup.object().shape({
        driverName: Yup.string().required('Nome do motorista é obrigatório'),
        cpf: Yup.string().required('CPF é obrigatório'),
        rg: Yup.string().required('RG é obrigatório'),
        shippingCompany: Yup.string().required('Transportadora é obrigatória'),
        frotista: Yup.string().required('Frotista é obrigatório'),
        transportConfirm: Yup.string().required('Transporte é obrigatório'),
        licensePlate: Yup.string().required('Placa é obrigatória'),
        material: Yup.string().required('Material é obrigatório')
    });
    const [cityResults, setCityResults] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const handleCityResults = async () => {
        try {
            const { data } = await api.getCitiesByIbge(selectedState);
            console.log('🚀 ~ handleCityResults ~ data:', data);
            setCityResults(data);
        } catch (error) {
            console.log('🚀 ~ handleCityResults ~ error:', error);
        }
    };

    useEffect(() => {
        if (selectedState) {
            handleCityResults();
        }
    }, [selectedState]);

    return (
        <MainCard title="Registro de transporte">
            <Grid container spacing={gridSpacing}>
                {data && (
                    <Formik
                        initialValues={{
                            agrupamento: data?.grouping || '',
                            order: data?.order || '',
                            contact: data?.contact || '',
                            email: data?.email || '',
                            client: data?.client,
                            constructions: data?.constructions || '',
                            confirm: data?.confirm || '',
                            period: data?.period || '',
                            transport: data?.transport || '',
                            driverName: data?.confirmacao_coleta?.motorista || '',
                            tel: data?.confirmacao_coleta?.telefone || '',
                            cpf: data?.confirmacao_coleta?.cpf || '',
                            rg: data?.confirmacao_coleta?.rg || '',
                            shippingCompany: data?.confirmacao_coleta?.transportadora || '',
                            transportConfirm: data?.transport || '',
                            licensePlate: data?.confirmacao_coleta?.placa || '',
                            material: data?.confirmacao_coleta?.material || '',
                            observation: '',
                            frotista: data?.confirmacao_coleta?.frotista || '',
                            city: data?.confirmacao_coleta?.cidade_destino || '',
                            uf: data?.confirmacao_coleta?.uf_destino || ''
                        }}
                        validationSchema={schema}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            // console.log(values);
                            handleSubmitFunc(values);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ padding: '15px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                            >
                                <h3>Dados agendados - E{id}</h3>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            disabled
                                            sx={{
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            label="Agrupamento"
                                            name="agrupamento"
                                            value={values.agrupamento}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            sx={{
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="PDV"
                                            name="order"
                                            value={values.order}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            disabled
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            label="Contato"
                                            name="contact"
                                            value={values.contact}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            variant="filled"
                                            label="Data de Confirmação"
                                            name="confirm"
                                            value={values.confirm}
                                            disabled
                                            type="data"
                                            onChange={handleChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            sx={{
                                                width: '600px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Cliente"
                                            name="client"
                                            value={values.client}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            sx={{
                                                width: '400px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Periodo"
                                            name="period"
                                            value={values.period}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '600px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            variant="filled"
                                            disabled
                                            label="Obra"
                                            name="constructions"
                                            value={values.constructions}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '400px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            variant="filled"
                                            disabled
                                            label="Transport"
                                            name="transport"
                                            value={values.transport}
                                        />
                                    </FormControl>
                                </Box>
                                <Divider sx={{ margin: '10px' }} />
                                <h3>Dados portaria</h3>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl error={Boolean(errors.driverName)}>
                                        <InputLabel htmlFor="email-id">Nome do motorista</InputLabel>
                                        <OutlinedInput
                                            sx={{ width: '300px', borderColor: touched.driverName && errors.driverName ? 'red' : '' }}
                                            label="Nome do motorista"
                                            name="driverName"
                                            value={values.driverName}
                                            onChange={handleChange}
                                        />
                                        {errors.driverName && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.driverName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="email-id">Telefone</InputLabel>
                                        <OutlinedInput
                                            sx={{ width: '230px', borderColor: touched.tel && errors.tel ? 'red' : '' }}
                                            label="Telefone"
                                            name="tel"
                                            value={values.tel}
                                            onChange={(e) => setValues({ ...values, tel: formatarTelefone(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px', borderColor: touched.cpf && errors.cpf ? 'red' : '' }}
                                            label="CPF"
                                            name="cpf"
                                            value={values.cpf}
                                            onChange={(e) => setValues({ ...values, cpf: formatarCPF(e.target.value) })}
                                            error={touched.cpf && Boolean(errors.cpf)}
                                            helperText={touched.cpf && errors.cpf}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px', borderColor: touched.rg && errors.rg ? 'red' : '' }}
                                            label="RG"
                                            name="rg"
                                            value={values.rg}
                                            onChange={(e) => setValues({ ...values, rg: e.target.value })}
                                            error={touched.rg && Boolean(errors.rg)}
                                            helperText={touched.rg && errors.rg}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '200px',
                                                borderColor: touched.shippingCompany && errors.shippingCompany ? 'red' : ''
                                            }}
                                            label="Transportadora"
                                            name="shippingCompany"
                                            value={values.shippingCompany}
                                            onChange={handleChange}
                                            error={touched.shippingCompany && Boolean(errors.shippingCompany)}
                                            helperText={touched.shippingCompany && errors.shippingCompany}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typeDriver"
                                            options={typeDriver}
                                            sx={{ width: 160 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, frotista: newValue.type });
                                            }}
                                            value={typeDriver.find((option) => option.type === values.frotista) || null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{ borderColor: touched.frotista && errors.frotista ? 'red' : '' }}
                                                    label="Frotista"
                                                    name="frotista"
                                                    error={touched.frotista && Boolean(errors.frotista)}
                                                    helperText={touched.frotista && errors.frotista}
                                                    value={values.frotista}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typeTransport"
                                            options={typeOfTransport}
                                            sx={{ width: 180 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, transportConfirm: newValue.type });
                                            }}
                                            value={typeOfTransport.find((option) => option.type === values.transportConfirm) || null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Transporte"
                                                    name="transportConfirm"
                                                    value={values.transportConfirm}
                                                    error={touched.transportConfirm && Boolean(errors.transportConfirm)}
                                                    helperText={touched.transportConfirm && errors.transportConfirm}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            id="uf"
                                            options={uf}
                                            sx={{ width: 100 }}
                                            getOptionLabel={(option) => option.sigla}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, uf: newValue.sigla });
                                                    setSelectedState(newValue.sigla);
                                                }
                                            }}
                                            value={uf.find((item) => item.sigla === values.uf) || null}
                                            defaultValue={values.uf}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="UF"
                                                    name="uf"
                                                    error={touched.uf && Boolean(errors.uf)}
                                                    helperText={touched.uf && errors.uf}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="city"
                                            options={cityResults}
                                            sx={{ width: 180 }}
                                            getOptionLabel={(option) => option.nome}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, city: newValue.nome });
                                                }
                                            }}
                                            value={cityResults.find((item) => item.nome === values.city) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Destino da entrega - cidade"
                                                    name="city"
                                                    value={values.city}
                                                    error={touched.city && Boolean(errors.city)}
                                                    helperText={touched.city && errors.city}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '120px' }}
                                            label="Placa"
                                            name="licensePlate"
                                            value={values.licensePlate}
                                            error={touched.licensePlate && Boolean(errors.licensePlate)}
                                            helperText={touched.licensePlate && errors.licensePlate}
                                            onChange={(e) => setValues({ ...values, licensePlate: formatarPlaca(e.target.value) })}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginTop: '16px' }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            multiline
                                            rows={2}
                                            name="observation"
                                            label="Observação"
                                            value={values.observation}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'end' }}>
                                    <Button
                                        onClick={() => {
                                            console.log('passou aqui');
                                            // handleSubmitFunc(values);
                                        }}
                                        type="submit"
                                        disabled={loading}
                                        variant="contained"
                                        sx={{ width: '200px' }}
                                    >
                                        {loading ? 'Registrando...' : 'Confirmar'}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </MainCard>
    );
};

export default TransportRecord;
