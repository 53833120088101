import React, { useState } from 'react';
import { Box, Button, Modal, Autocomplete, TextField } from '@mui/material';
import { FormWrapper, GeproArea } from './styles';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const FilterModal = ({
    data,
    setIsActiveFilter,
    setFilteredData,
    setFilteredTotal,
    setLoad,
    setGeproFilter,
    setInitialDataFilter,
    setFinalDataFilter,
    setOrderFilter
}) => {
    const api = useApi();

    const [open, setOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedFilial, setSelectedFilial] = useState(null);
    const [selectedObra, setSelectedObra] = useState(null);
    const [selectedGepro, setSelectedGepro] = useState(null);
    const [selectedPedido, setSelectedPedido] = useState(null);
    const [uniqueClientData, setUniqueClientData] = useState([]);
    const [uniqueConstructionData, setUniqueConstructionData] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function groupOfClients() {
        const uniqueClient = data.reduce((acc, item) => {
            if (!acc.includes(item.cliente)) {
                acc.push(item.cliente);
            }
            return acc;
        }, []);
        setUniqueClientData(uniqueClient);
    }

    function groupOfConstruction() {
        const uniqueConstruction = data.reduce((acc, item) => {
            if (!acc.includes(item.obra)) {
                acc.push(item.obra);
            }
            return acc;
        }, []);
        setUniqueConstructionData(uniqueConstruction);
    }

    useEffect(() => {
        groupOfClients();
        groupOfConstruction();
    }, [data]);

    const filials = [
        { id: 1, name: 'TEMPMASTER' },
        { id: 2, name: 'MASTERSERVICE' }
    ];

    function handleClear() {
        setSelectedClient(null);
        setSelectedFilial(null);
        setSelectedObra(null);
        setSelectedGepro(null);
        setSelectedPedido(null);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setLoad(true);
        console.log('entrou no submit');
        try {
            const { data } = await api.filteredSeller(
                selectedPedido,
                selectedGepro?.gepro,
                selectedFilial?.name,
                selectedClient,
                selectedObra,
                selectedStartDate,
                selectedEndDate
            );
            console.log('entrou no req');
            if (data?.vendas.length > 0) {
                console.log('entrou nas vendas');
                handleClear();
                setOpen(false);
                setIsActiveFilter(true);
                setFilteredData(data?.vendas);
                setFilteredTotal(data?.somatoria);
                toast.success('Filtro aplicado com sucesso!');
                setGeproFilter(selectedGepro?.gepro ?? null);
                setInitialDataFilter(selectedStartDate ?? null);
                setFinalDataFilter(selectedEndDate ?? null);
                setOrderFilter(selectedPedido ?? null);
            } else {
                toast.error('Nenhum dado encontrado');
            }

            selectedStartDate('');
            selectedEndDate('');
        } catch (error) {
            console.log(error);
        } finally {
            setLoad(false);
        }
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Filtros
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <h2>Filtros</h2>
                    <FormWrapper onSubmit={handleSubmit}>
                        <GeproArea>
                            <Autocomplete
                                options={data.filter((item) => item.pedido !== null)}
                                getOptionLabel={(item) => item.pedido}
                                value={selectedPedido}
                                sx={{ width: '50%' }}
                                onChange={(event, newValue) => {
                                    setSelectedPedido(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="Pedido" />}
                            />

                            <Autocomplete
                                options={data.filter((item) => item.gepro !== null)}
                                getOptionLabel={(item) => item.gepro}
                                value={selectedGepro}
                                sx={{ width: '50%' }}
                                onChange={(event, newValue) => {
                                    setSelectedGepro(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="Gepro" />}
                            />
                        </GeproArea>
                        <Autocomplete
                            options={filials}
                            getOptionLabel={(filial) => filial.name}
                            value={selectedFilial}
                            onChange={(event, newValue) => {
                                setSelectedFilial(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Filial" />}
                        />
                        <Autocomplete
                            options={uniqueClientData}
                            getOptionLabel={(client) => client}
                            value={selectedClient}
                            onChange={(event, newValue) => {
                                setSelectedClient(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />
                        <Autocomplete
                            options={uniqueConstructionData}
                            getOptionLabel={(item) => item}
                            value={selectedObra}
                            onChange={(event, newValue) => {
                                setSelectedObra(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Obra" />}
                        />
                        <GeproArea>
                            <TextField
                                label="De"
                                type="month"
                                value={selectedStartDate}
                                onChange={(event) => setSelectedStartDate(event.target.value)}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '150px' }}
                            />
                            <TextField
                                label="Até"
                                type="month"
                                value={selectedEndDate}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => setSelectedEndDate(event.target.value)}
                                sx={{ width: '150px' }}
                            />
                        </GeproArea>
                        <Button variant="contained" color="secondary" type="submit">
                            Filtrar
                        </Button>
                    </FormWrapper>
                </Box>
            </Modal>
        </div>
    );
};

export default FilterModal;
