import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useMediaQuery
} from '@mui/material';

// third party
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
// assets
import { useApi } from 'Service/axios';

/////////////////////////////////////////////////////////////////////////////////

const EditEmployeeForm = ({ employeeID, setOpenEdit }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const api = useApi();
    const [employee, setEmployee] = useState(null);
    const [setores, setSetores] = useState([]);
    const [filial, setFilial] = useState([]);

    const getSector = async () => {
        const response = await api.getAllSector();
        setSetores(response.data.allSectors);
    };

    const getBranch = async () => {
        const response = await api.getAllBranch();
        setFilial(response.data.allBranch);
    };

    const getEmployeeById = async (employeeID) => {
        const response = await api.getEmployeeById(employeeID);
        setEmployee(response.data.employee);
    };

    const handleUpdate = async (employeeID, values) => {
        try {
            const response = await api.updateEmployee(employeeID, values);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSector();
        getBranch();
        getEmployeeById(employeeID);
    }, []);

    return (
        <>
            {employee !== null && (
                <Formik
                    initialValues={{
                        employeeName: employee?.employeeName,
                        sector: employee?.sector,
                        branch: employee?.branch,
                        submit: null
                    }}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            handleUpdate(employeeID, values);
                        } catch (err) {
                            console.error(err);
                            setErrors({ submit: err.message });
                            setStatus({ success: false });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl
                                fullWidth
                                sx={{ ...theme.typography.customInput }}
                                error={Boolean(touched.employeeName && errors.employeeName)}
                            >
                                <InputLabel htmlFor="outlined-adornment-employeeName-register">Nome do colaborador</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-employeeName-register"
                                    type="text"
                                    value={values.employeeName}
                                    name="employeeName"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.employeeName && errors.employeeName && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.employeeName}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                sx={{ ...theme.typography.customInput }}
                                error={Boolean(touched.sector && errors.sector)}
                            >
                                <InputLabel htmlFor="outlined-adornment-sector-register">Setor</InputLabel>
                                <Select
                                    style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                    id="outlined-adornment-sector-register"
                                    type="text"
                                    value={values.sector}
                                    name="sector"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                >
                                    {setores.length > 0 &&
                                        setores.map((setor) => (
                                            <MenuItem key={setor.id} value={setor.id}>
                                                {setor.sectorName}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {touched.sector && errors.sector && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.sector}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                fullWidth
                                sx={{ ...theme.typography.customInput }}
                                error={Boolean(touched.branch && errors.branch)}
                            >
                                <InputLabel htmlFor="outlined-adornment-branch-register">Filial</InputLabel>
                                <Select
                                    style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                    id="outlined-adornment-branch-register"
                                    type="text"
                                    value={values.branch}
                                    name="branch"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                >
                                    {filial.length > 0 &&
                                        filial.map((filial) => (
                                            <MenuItem key={filial.id} value={filial.id}>
                                                {filial.branchName}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {touched.branch && errors.branch && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.branch}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
                                        Atualizar
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default EditEmployeeForm;
