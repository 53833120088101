import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Badge } from '@mui/material';

const PodiumStep = ({ podium, winner, index }) => {
    const [animationComplete, setAnimationComplete] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    let height = 0;
    if (winner?.id === 2) {
        height = 400; // Tamanho máximo para o primeiro lugar
    } else if (winner?.id === 1) {
        height = 500; // Tamanho máximo para o segundo lugar
    } else if (winner?.id === 3) {
        height = 350; // Tamanho máximo para o terceiro lugar
    }

    const handleAnimationComplete = () => {
        setAnimationComplete(true); // Define a animação como concluída quando a animação terminar
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
            <motion.div
                custom={index}
                initial="hidden"
                animate="visible"
                variants={{
                    visible: (custom) => ({
                        opacity: 1,
                        transition: {
                            delay: 1 + (podium.length - winner.id + 2),
                            duration: 0.75
                        }
                    }),
                    hidden: { opacity: 0 }
                }}
                onAnimationComplete={handleAnimationComplete} // Chamado quando a animação é concluída
                style={{ marginBottom: '1rem', alignSelf: 'center', position: 'relative' }}
            >
                <img
                    src={podium[index]?.imagem}
                    alt=""
                    style={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        border: '1px solid #ccc',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                        width: '5rem ',
                        height: '5rem ',
                        position: 'absolute',
                        top: '-4.5rem',
                        left: '-3rem'
                    }}
                />
            </motion.div>
            <motion.div
                custom={index}
                initial="hidden"
                animate="visible"
                variants={{
                    visible: (custom) => ({
                        height: `${height}px`,
                        opacity: 2,
                        transition: {
                            delay: 1 + (podium.length - winner.place),
                            duration: 2,
                            ease: 'backInOut'
                        }
                    }),
                    hidden: { opacity: 0, height: 0 }
                }}
                style={{
                    background: ' linear-gradient(0deg, rgba(204,38,54,1) 0%, rgba(0,129,186,1) 92% )',
                    display: 'flex',
                    width: '6rem',
                    borderRadius: '0.5rem 0.5rem 0 0',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    position: 'relative'
                }}
            >
                {animationComplete && (
                    <>
                        {' '}
                        <div
                            style={{
                                alignSelf: 'flex-end',
                                color: '#fff',
                                fontWeight: 'bold',
                                position: 'absolute',
                                rotate: '-90deg',
                                top: '190px',
                                width: '250px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '50px'
                            }}
                        >
                            <p>{winner?.vendedor}</p>
                            <p>{winner?.valorFormat}</p>
                        </div>
                        <p style={{ fontSize: '35px' }}>{winner.id === 1 ? '🥇' : winner.id === 2 ? '🥈' : '🥉'}</p>
                    </>
                )}
            </motion.div>
        </div>
    );
};

export default PodiumStep;
