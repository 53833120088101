import { Box, Button, Chip, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import ScaleIcon from '@mui/icons-material/Scale';
import CardDark from './components/CardDark';
import { CardArea, CardWrapper } from './styles';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FilterModal from './components/FilterModal';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import FilterCard from './components/FilterCard';
import { toast } from 'react-toastify';
import { formatacaoData } from 'utils/formatacaoData';

export function OrderManager() {
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalPdv, setTotalPdv] = useState(0);
    const [totalFat, setTotalFat] = useState(0);
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredTotal, setFilteredTotal] = useState([]);
    const [load, setLoad] = useState(false);

    //dados do filtro
    const [orderFilter, setOrderFilter] = useState(null);
    const [initialDataFilter, setInitialDataFilter] = useState(null);
    const [finalDataFilter, setFinalDataFilter] = useState(null);
    const [geproFilter, setGeproFilter] = useState(null);

    const columns = [
        { field: 'cliente', headerName: 'Cliente', width: 200, valueGetter: (params) => params?.row.cliente ?? '-' },
        { field: 'data_emissao', headerName: 'Data Emissão', width: 100, valueGetter: (params) => params?.row.data_emissao ?? '-' },
        { field: 'data_entrega', headerName: 'Data Entrega', width: 100, valueGetter: (params) => params?.row.data_entrega ?? '-' },
        { field: 'filial', headerName: 'Filial', width: 120 },
        {
            field: 'gepro',
            headerName: 'Gepro',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row?.gepro ?? '-'
        },
        { field: 'obra', headerName: 'Obra', width: 200, valueGetter: (params) => params?.row?.obra ?? '-' },
        { field: 'oc', headerName: 'OC', width: 80, valueGetter: (params) => params?.row.oc ?? '-' },
        { field: 'ov', headerName: 'OV', width: 80, valueGetter: (params) => params?.row.ov ?? '-' },
        {
            field: 'pedido',
            headerName: 'Pedido',
            width: 80,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params?.row.pedido ?? '-'
        },
        {
            field: 'perc_exp',
            headerName: '% Expedido',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_exp !== null && !isNaN(params?.row.perc_exp) ? `${Number(params?.row.perc_exp).toFixed(0)}%` : '-'
        },
        {
            field: 'perc_fat',
            headerName: '% Faturado',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_fat !== null && !isNaN(params?.row.perc_fat) ? `${Number(params?.row.perc_fat).toFixed(0)}%` : '-'
        },
        {
            field: 'perc_prod',
            headerName: '% Produzido',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_prod !== null && !isNaN(params?.row.perc_prod) ? `${Number(params?.row.perc_prod).toFixed(0)}%` : '-'
        },
        {
            field: 'perc_prog',
            headerName: '% Programado',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params?.row.perc_prog !== null && !isNaN(params?.row.perc_prog) ? `${Number(params?.row.perc_prog).toFixed(0)}%` : '-'
        },
        {
            field: 'peso_bruto',
            headerName: 'Peso Bruto',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params?.row.peso_bruto ?? '-'
        },
        {
            field: 'pintura',
            headerName: 'Pintura',
            width: 130,
            valueGetter: (params) => params?.row.pintura ?? '-'
        },
        {
            field: 'po',
            headerName: 'PO',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params?.row.po ?? '-'
        },
        { field: 'revisao', headerName: 'Revisao', width: 100, valueGetter: (params) => params?.row.revisao ?? '-' },
        { field: 'status_gepro', headerName: 'Status Gepro', width: 200, valueGetter: (params) => params?.row.status_gepro ?? '-' },
        { field: 'status_pdv', headerName: 'Status PDV', width: 100, valueGetter: (params) => params?.row.status_pdv ?? '-' },
        {
            field: 'total_pdv',
            headerName: 'Total PDV',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params?.row.total_pdv ?? '-'
        },
        {
            field: 'vlr_fat',
            headerName: 'Valor Faturado',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params?.row.vlr_fat ?? '-'
        }
    ];

    const formatterkg = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 });

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        notation: 'compact'
    });

    const formatterStandat = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    async function getSeler() {
        setLoad(true);
        try {
            const { data } = await api.getAllSales();

            const row = data.vendas.map((item) => ({
                ...item,
                data_emissao: formatacaoData(item?.data_emissao),
                data_entrega: formatacaoData(item?.data_entrega),
                peso_bruto: formatterkg.format(item.peso_bruto),
                total_pdv: formatterStandat.format(item.total_pdv),
                vlr_fat: formatterStandat.format(item.vlr_fat)
            }));

            setRows(row);

            const totalWeight = formatterkg.format(data.somatoria[0].peso_bruto_total);
            const totalPdv = formatter.format(data.somatoria[0].total_pdv_total);
            const totalFat = formatter.format(data.somatoria[0].vlr_fat_total);

            setTotalWeight(totalWeight);
            setTotalPdv(totalPdv);
            setTotalFat(totalFat);
        } catch (error) {
            console.log('🚀 ~ getSeler ~ error:', error);
        } finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        getSeler();
    }, []);

    useEffect(() => {
        if (isActiveFilter) {
            if (!filteredData.length) {
                setIsActiveFilter(false);
                toast.error('Nenhum dado encontrado com os filtros aplicados');
                return;
            }

            const row = filteredData.map((item) => ({
                ...item,
                data_emissao: formatacaoData(item?.data_emissao),
                data_entrega: formatacaoData(item?.data_entrega),
                peso_bruto: formatterkg.format(item?.peso_bruto),
                total_pdv: formatterStandat.format(item?.total_pdv),
                vlr_fat: formatterStandat.format(item?.vlr_fat)
            }));
            setRows(row);

            const totalWeight = formatterkg.format(filteredTotal?.peso_bruto_total);
            const totalPdv = formatter.format(filteredTotal?.total_pdv_total);
            const totalFat = formatter.format(filteredTotal?.vlr_fat_total);

            setTotalWeight(totalWeight);
            setTotalPdv(totalPdv);
            setTotalFat(totalFat);
        }

        return () => {
            console.log('Função de limpeza executada');
            // Código de limpeza (opcional)
        };
    }, [isActiveFilter, filteredData, filteredTotal]);

    function handleClearFilter() {
        setIsActiveFilter(false);
        getSeler();
    }

    return (
        <MainCard title={'Gestão de pedidos'}>
            <Grid container spacing={gridSpacing} sx={{ flexDirection: 'column', position: 'relative' }}>
                {load && <ModalLoadAnimated open={load} />}

                <CardArea>
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center', position: 'absolute', right: 0, top: '-55px' }}>
                        {isActiveFilter && filteredData.length > 0 && (
                            <div style={{ position: 'absolute', right: '270px', top: '-15px' }}>
                                <FilterCard
                                    finalData={finalDataFilter}
                                    initialData={initialDataFilter}
                                    project={orderFilter}
                                    gepro={geproFilter}
                                />
                            </div>
                        )}

                        <FilterModal
                            data={rows}
                            setFilteredTotal={setFilteredTotal}
                            setFilteredData={setFilteredData}
                            setIsActiveFilter={setIsActiveFilter}
                            setLoad={setLoad}
                            setGeproFilter={setGeproFilter}
                            setOrderFilter={setOrderFilter}
                            setInitialDataFilter={setInitialDataFilter}
                            setFinalDataFilter={setFinalDataFilter}
                        />
                        {isActiveFilter && (
                            <Stack direction="row" spacing={1}>
                                <Chip color="error" variant="filled" label="Limpar Filtros" onDelete={handleClearFilter} />
                            </Stack>
                        )}
                    </div>

                    <CardWrapper>
                        <CardDark name="PESO TOTAL" icon={<ScaleIcon />} value={`${totalWeight} KG`} />
                        <CardDark name="QTDE PEDIDOS" icon={<BlurOnIcon />} value={`${rows?.length}`} />
                        <CardDark name="TOTAL FATURADO" icon={<LocalAtmIcon />} value={`${totalFat}`} />
                        <CardDark name="TOTAL PEDIDOS" icon={<BlurOnIcon />} value={`${totalPdv}`} />
                    </CardWrapper>
                </CardArea>
                <Box sx={{ maxWidth: '90vw', margin: '0 auto' }}>
                    {rows.length > 0 && (
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                    py: 1
                                },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                    py: '15px'
                                },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                    py: '22px'
                                },
                                maxHeight: '550px',
                                marginTop: '20px',
                                '& .super-app-theme--header': {
                                    backgroundColor: '#d34444',
                                    color: 'white'
                                },
                                [`.${gridClasses.cell}.cold`]: {
                                    backgroundColor: '#e3889192',
                                    color: '#1a3e72'
                                }
                            }}
                            getRowHeight={() => 'auto'}
                            getCellClassName={(params) => {
                                if (
                                    params?.field === 'gepro' ||
                                    params?.field === 'pedido' ||
                                    params?.field === 'perc_exp' ||
                                    params?.field === 'perc_fat' ||
                                    params?.field === 'perc_prod' ||
                                    params?.field === 'perc_prog'
                                ) {
                                    //Retornar cor azul clara
                                    return 'cold';
                                }
                                return '';
                            }}
                        />
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
}
