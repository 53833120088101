import { IconTruckLoading, IconTruck, IconTruckDelivery, IconTruckReturn } from '@tabler/icons';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const icons = {
    IconTruckLoading,
    IconTruck,
    IconTruckDelivery,
    IconTruckReturn
};

let expedition;
const role = localStorage.getItem('role');

if (role === 'Portaria') {
    expedition = {
        id: 'expedition',
        title: 'expedição',
        type: 'group',
        children: [
            {
                id: 'confirm',
                title: 'Portaria',
                type: 'item',
                url: '/main/expedicao/confirmadas',
                icon: icons.IconTruckLoading,
                breadcrumbs: false
            },

            {
                id: 'progress',
                title: 'Aguardando Liberação',
                type: 'item',
                url: '/main/expedicao/portaria',
                icon: icons.IconTruckLoading,
                breadcrumbs: false
            }
        ]
    };
}

if (role !== 'Portaria') {
    expedition = {
        id: 'expedition',
        title: 'expedição',
        type: 'group',
        children: [
            {
                id: 'dashboardExpedition',
                title: 'Dashboard',
                type: 'item',
                url: '/main/graph-expedition',
                icon: EqualizerIcon,
                breadcrumbs: false
            },

            {
                id: 'prog',
                title: 'Programações de Fábrica',
                type: 'item',
                url: '/main/expedicao/programacaes',
                icon: icons.IconTruck,
                breadcrumbs: false
            },

            {
                id: 'solicitadas',
                title: 'Coletas Solicitadas',
                type: 'item',
                url: '/main/expedicao/solicitadas',
                icon: icons.IconTruckDelivery,
                breadcrumbs: false
            },
            {
                id: 'confirm',
                title: 'Portaria',
                type: 'item',
                url: '/main/expedicao/confirmadas',
                icon: icons.IconTruckLoading,
                breadcrumbs: false
            },

            {
                id: 'progress',
                title: 'Aguardando Liberação',
                type: 'item',
                url: '/main/expedicao/portaria',
                icon: icons.IconTruckReturn,
                breadcrumbs: false
            },
            {
                id: 'carregamento',
                title: 'Em Carregamento',
                type: 'item',
                url: '/main/expedicao/em-carregamento',
                icon: icons.IconTruckLoading,
                breadcrumbs: false
            },
            {
                id: 'finalizadas',
                title: 'Coletas Finalizadas',
                type: 'item',
                url: '/main/expedicao/finalizadas',
                icon: icons.IconTruckLoading,
                breadcrumbs: false
            }
        ]
    };
}
if (role === 'Codificador' || role === 'Comercial/Engenharia') {
    expedition = {
        id: 'expedition',
        title: 'expedição',
        type: 'group',
        children: [
            {
                id: 'finalizadas',
                title: 'Coletas Finalizadas',
                type: 'item',
                url: '/main/expedicao/finalizadas',
                icon: icons.IconTruckLoading,
                breadcrumbs: false
            }
        ]
    };
}

export default expedition;
