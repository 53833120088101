import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Autocomplete, TextField } from '@mui/material';
import { FormWrapper, GeproArea } from './styles';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const FilterModal = ({ data, setIsActiveFilter, setFilteredData, setFilteredTotal, setLoad, setGeproFilter, setOrderFilter }) => {
    const api = useApi();
    const [open, setOpen] = useState(false);

    // Estado para cada filtro
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedObra, setSelectedObra] = useState(null);
    const [selectedGepro, setSelectedGepro] = useState(null);
    const [selectedPedido, setSelectedPedido] = useState(null);
    const [selectedUsername, setSelectedUsername] = useState(null);
    const [selectedVendedor, setSelectedVendedor] = useState(null);

    // Estado para dados únicos
    const [uniqueClientData, setUniqueClientData] = useState([]);
    const [uniqueConstructionData, setUniqueConstructionData] = useState([]);
    const [uniqueUsernameData, setUniqueUsernameData] = useState([]);
    const [uniqueVendedorData, setUniqueVendedorData] = useState([]);
    const [uniqueStatusData, setUniqueStatusData] = useState([]);
    const [uniquePedido, setUniquePedido] = useState([]);
    const [uniqueGepro, setUniqueGepro] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Função para extrair dados únicos
    const extractUniqueData = (key) => [...new Set(data.map((item) => item[key]))].filter(Boolean);

    useEffect(() => {
        const sanitizeAndSort = (data) =>
            extractUniqueData(data)
                .filter(Boolean) // Remove valores como null, undefined, ou falsy
                .map((item) => item.toString()) // Converte todos os itens para string
                .sort((a, b) => a.localeCompare(b)); // Ordena alfabeticamente

        setUniqueClientData(sanitizeAndSort('cliente'));
        setUniqueConstructionData(sanitizeAndSort('obra'));
        setUniqueUsernameData(sanitizeAndSort('username'));
        setUniqueVendedorData(sanitizeAndSort('vendedor'));
        setUniqueStatusData(sanitizeAndSort('status_gepro'));
        setUniqueGepro(extractUniqueData('gepro'));
        setUniquePedido(
            extractUniqueData('pedido')
                .filter(Boolean)
                .sort((a, b) => a - b) // Ordena como números
        );
    }, [data]);

    const handleClear = () => {
        setSelectedClient(null);
        setSelectedStatus(null);
        setSelectedObra(null);
        setSelectedGepro(null);
        setSelectedPedido(null);
        setSelectedUsername(null);
        setSelectedVendedor(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoad(true);

        try {
            const { data } = await api.getAllGestaoObrasView(
                selectedPedido,
                selectedGepro,
                selectedClient,
                selectedObra,
                selectedUsername,
                selectedVendedor,
                selectedStatus
            );
            console.log(data, 'resultado do filtro');

            if (data?.length > 0) {
                handleClear();
                setOpen(false);
                setIsActiveFilter(true);
                setFilteredData(data);
                toast.success('Filtro aplicado com sucesso!');
                setGeproFilter(selectedGepro?.gepro ?? null);
                setOrderFilter(selectedPedido ?? null);
            } else {
                toast.error('Nenhum dado encontrado');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoad(false);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Filtros
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <h2>Filtros</h2>
                    <FormWrapper onSubmit={handleSubmit}>
                        <GeproArea>
                            <Autocomplete
                                options={uniquePedido}
                                getOptionLabel={(item) => item || ''}
                                value={selectedPedido}
                                onChange={(event, newValue) => setSelectedPedido(newValue)}
                                renderInput={(params) => <TextField {...params} label="Pedido" />}
                                sx={{ width: '50%' }}
                            />

                            <Autocomplete
                                options={uniqueGepro}
                                getOptionLabel={(item) => item || ''}
                                value={selectedGepro}
                                onChange={(event, newValue) => setSelectedGepro(newValue)}
                                renderInput={(params) => <TextField {...params} label="Gepro" />}
                                sx={{ width: '50%' }}
                            />
                        </GeproArea>

                        <Autocomplete
                            options={uniqueClientData}
                            getOptionLabel={(client) => client}
                            value={selectedClient}
                            onChange={(event, newValue) => setSelectedClient(newValue)}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />

                        <Autocomplete
                            options={uniqueConstructionData}
                            getOptionLabel={(item) => item}
                            value={selectedObra}
                            onChange={(event, newValue) => setSelectedObra(newValue)}
                            renderInput={(params) => <TextField {...params} label="Obra" />}
                        />

                        <Autocomplete
                            options={uniqueUsernameData}
                            getOptionLabel={(username) => username}
                            value={selectedUsername}
                            onChange={(event, newValue) => setSelectedUsername(newValue)}
                            renderInput={(params) => <TextField {...params} label="Codificador" />}
                        />

                        <Autocomplete
                            options={uniqueVendedorData}
                            getOptionLabel={(vendedor) => vendedor}
                            value={selectedVendedor}
                            onChange={(event, newValue) => setSelectedVendedor(newValue)}
                            renderInput={(params) => <TextField {...params} label="Vendedor" />}
                        />

                        <Autocomplete
                            options={uniqueStatusData}
                            getOptionLabel={(status) => status}
                            value={selectedStatus}
                            onChange={(event, newValue) => setSelectedStatus(newValue)}
                            renderInput={(params) => <TextField {...params} label="Status" />}
                        />

                        <Button variant="contained" color="secondary" type="submit">
                            Filtrar
                        </Button>
                    </FormWrapper>
                </Box>
            </Modal>
        </div>
    );
};

export default FilterModal;
