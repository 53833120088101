import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export function GaugeCharts({ percent, total, totalMeta }) {
    // Formatting the total revenue and goal values
    const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

    // Limiting the percentage to a maximum of 100%
    const boundedPercentage = Math.min(percent, 100);

    return (
        <div style={{ position: 'relative' }}>
            <Gauge
                value={boundedPercentage}
                startAngle={-110}
                endAngle={110}
                width={450}
                height={250}
                sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 20,
                        transform: 'translate(0px, 0px)',
                        fill: 'white'
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                        //A cor até a metade da barra deve ser vermelha e a outra metade azul com um fade entre as duas cores
                        fill: 'url(#gradient1)'
                    },
                    marginTop: '20px'
                }}
            >
                <defs>
                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: 'rgb(204,38,54)', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: 'rgb(0,129,186)', stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
            </Gauge>
            <div style={{ background: '#fff', position: 'absolute', top: '130px', right: '140px' }}>
                <p style={{ textAlign: 'center', fontSize: '15px' }}>Total Venda: {total}</p>
                <p style={{ textAlign: 'center', fontSize: '15px' }}>Meta: {totalMeta}</p>
            </div>
            <div style={{ position: 'absolute', top: '-30px', left: '20px' }}>
                <h3 style={{ textAlign: 'center', fontSize: '15px' }}>Acompanhamento de metas</h3>
            </div>
        </div>
    );
}
