import {
    Autocomplete,
    Badge,
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import AwaitingDistribution from './components/AwaitingDistribution';
import AddNewConstruction from './components/AddNewConstruction';
import { DataGrid } from '@mui/x-data-grid';
import DrawerEdit from './components/DrawerEdit';

import { Row } from './components/Row';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import { ConfigureActivitys } from './components/ConfigureActivits';

const DistributionOfWorks = () => {
    const api = useApi();

    const [openNewDistribuition, setOpenNewDistribuition] = useState(false);
    const [openNewConstruction, setOpenNewConstruction] = useState(false);
    const [currentDistribuition, setCurrentDistribuition] = useState(null);
    const [openConfigureModal, setOpenConfigureModal] = useState(false);

    const [data, setData] = useState([]);
    const [distribute, setDistribute] = useState([]);

    const [load, setLoad] = useState(false);

    const getData = async () => {
        setLoad(true);
        try {
            const { data } = await api.getAllDistributionWorks();

            const firstTable = data.filter((item) => item?.distribution_associated_works?.length > 0).sort((a, b) => b.gepro - a.gepro);

            const secondTable = data.filter((item) => item?.distribution_associated_works?.length === 0);

            const rows = firstTable.map((item) => ({
                ...item,
                gepro: item.gepro,
                cliente: item.client,
                obra: item.obra,
                status: item.in_hold
            }));

            setDistribute(secondTable);
            setData(rows);
            setLoad(false);
        } catch (error) {
            setLoad(false);
            console.log('🚀 ~ file: index.js:39 ~ getData ~ error:', error.message);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    //itens relacionados ao filtro

    const selectTypes = ['Gepro', 'Cliente', 'Obra', 'Codificador'];

    const [selectedField, setSelectedField] = useState(selectTypes[0]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        setFilteredResults(data);
    }, [data]);

    const handleFieldChange = (event) => {
        const newSelectedField = event.target.value;
        setSelectedField(newSelectedField);
        filterResults(newSelectedField, searchTerm);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        filterResults(selectedField, newSearchTerm);
    };

    const filterResults = (field, term) => {
        const results = data.filter((item) => {
            const fieldItem = field.toLowerCase();
            const fieldValue = item[fieldItem];

            if (fieldValue !== undefined) {
                const fieldValueString = String(fieldValue).toLowerCase();

                return fieldValueString.includes(term.toLowerCase());
            }

            return false;
        });

        setFilteredResults(results);
    };
    return (
        <MainCard title={'Distribuição de obras'}>
            {load && <ModalLoadAnimated open={load} />}
            <ConfigureActivitys open={openConfigureModal} setOpen={setOpenConfigureModal} />
            <AwaitingDistribution
                open={openNewDistribuition}
                setOpen={setOpenNewDistribuition}
                data={distribute}
                openNewConstruction={setOpenNewConstruction}
                setCurrentDistribuition={setCurrentDistribuition}
            />

            <Grid container spacing={gridSpacing} sx={{ padding: '20px', position: 'relative' }}>
                <Box sx={{ display: 'flex', position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-62%)' }}>
                    <TextField
                        sx={{ width: '170px', borderRight: 'none' }}
                        onChange={(e) => handleFieldChange(e)}
                        variant="filled"
                        select
                        label="Selecione um filtro"
                        value={selectedField}
                    >
                        {selectTypes.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="filled"
                        onChange={(e) => handleSearchChange(e)}
                        sx={{ width: '250px', marginLeft: '-2px' }}
                        label="Pesquise ..."
                    />
                </Box>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Badge badgeContent={distribute.length} color="error">
                        <Button variant="contained" onClick={() => setOpenNewDistribuition(true)}>
                            Distribuir
                        </Button>
                    </Badge>
                    <Button variant="contained" onClick={() => setOpenConfigureModal(true)}>
                        Configurar atividades
                    </Button>
                </div>

                <div style={{ width: '100%', marginTop: '20px', overflow: 'auto' }}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 340px)', overflow: 'auto' }}>
                        <Table aria-label="collapsible table" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">GEPRO</TableCell>
                                    <TableCell align="center">Cliente</TableCell>
                                    <TableCell align="center">Obra</TableCell>
                                    <TableCell align="center">InHold</TableCell>
                                    <TableCell align="center">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredResults.map((row, index) => (
                                    <Row key={index} row={row} get={getData} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>
        </MainCard>
    );
};

export default DistributionOfWorks;
