import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Divider, IconButton, Tooltip } from '@mui/material';
import InsertParamsModal from './InsertParamsModal';
import { IconEdit } from '@tabler/icons';
import { Delete } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    positiuon: 'relative'
};

export function ParamsModal({ open, setOpen, currentMachine }) {
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [parametersByMega, setParameters] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [openInsertParamsModal, setOpenInsertParamsModal] = React.useState(false);

    async function getParametersByMachine() {
        try {
            const { data } = await api.getMachineParameter(currentMachine.id);

            const row = data.data.map((param) => {
                const associatedParam = parametersByMega.find((paramMega) => paramMega.PERG_IN_CODIGO === param.perg_mega_id);
                return {
                    ...param,
                    vlr_associado: param.numero ? param.numero : associatedParam ? associatedParam.PERG_ST_PERGUNTA : null // Caso não encontre o parâmetro associado, você pode definir um valor padrão como null ou outro valor que preferir
                };
            });

            setRows(row);
        } catch (error) {
            console.log('🚀 ~ getParametersByMachine ~ error:', error);
        }
    }

    async function getParameters() {
        try {
            const { data } = await api.getMachineParameterMega();
            setParameters(data);
        } catch (error) {
            console.log('🚀 ~ getParameters ~ error:', error);
        }
    }

    async function deleteParameter(id) {
        const toastId = toast.loading('Deletando parâmetro...');
        try {
            await api.deleteMachineParameter(id);
            getParameters();
            toast.update(toastId, {
                render: 'Parâmetro deletado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getParametersByMachine();
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao deletar parâmetro',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
            console.log('🚀 ~ deleteParameter ~ error:', error);
        }
    }

    useEffect(() => {
        getParametersByMachine();
        getParameters();
    }, [currentMachine]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.2 },
        { field: 'descricao', headerName: 'Parâmetro', flex: 1 },
        { field: 'vlr_associado', headerName: 'Valor Associado', flex: 1 },
        {
            field: 'action',
            headerName: 'Ação',
            flex: 0.3,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setSelectedRow(params.row);
                                setOpenInsertParamsModal(true);
                            }}
                        >
                            <IconEdit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                        <IconButton onClick={() => deleteParameter(params.row.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box sx={{ position: 'absolute', top: 30, right: 15 }}>
                    <Button onClick={() => setOpenInsertParamsModal(true)} variant="contained">
                        Inserir
                    </Button>
                </Box>
                <InsertParamsModal
                    getParameters={getParametersByMachine}
                    megaParameters={parametersByMega}
                    currentMachine={currentMachine}
                    selectedRow={selectedRow}
                    open={openInsertParamsModal}
                    setOpen={setOpenInsertParamsModal}
                />
                <Box>
                    <h3>Parâmetros - {currentMachine?.descricao}</h3>
                    <Divider />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em', width: '100%' }}>
                    {rows.length ? (
                        <DataGrid columns={columns} rows={rows} sx={{ maxHeight: 600 }} hideFooter />
                    ) : (
                        <h4>Nenhum parâmetro cadastrado</h4>
                    )}
                </Box>
            </Box>
        </Modal>
    );
}
