import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    TablePagination,
    DialogActions,
    TableSortLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';

import DialogContentText from '@mui/material/DialogContentText';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditForm from './Edit/EditForm';
import { useApi } from 'Service/axios';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { OrderButton } from './style';
import formater from 'utils/formatDate';
import { removeSecondsFromTime } from 'utils/formatSeconds';

function Exits() {
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderById, setOrderById] = useState('asc');
    const [orderByNome, setOrderByNome] = useState('asc');
    const [orderByData, setOrderByData] = useState('asc');
    const [employeeName, setEmployeeName] = useState('');
    const [exitID, setExitID] = useState(null);
    const [allExits, setAllExits] = useState([]);
    const [currentUser, sertCurrentUser] = useState('');

    const api = useApi();

    const getExits = async () => {
        const response = await api.getAllExits();
        setAllExits(response.data.exits);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenEdit = (e, employeeName, type) => {
        e.preventDefault();
        setOpenEdit(true);
        sertCurrentUser(type);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        // window.location.reload();
    };

    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setExitID(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleConfirmDelete = async () => {
        setOpenSnackBarDeleted(true);
    };

    const handleClickSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleCloseSnackBarDeleted = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBarDeleted(false);
    };

    const handleCancelDeleteDialog = () => {
        handleCloseDelete();
    };

    const handleConfirmDeleteDialog = async (exitID) => {
        try {
            await api.deleteExit(exitID);
            handleConfirmDelete();
            window.location.reload();
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const handleSortById = () => {
        const sortedItens = [...allExits].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setAllExits(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...allExits].sort((a, b) => {
            const nomeA = a?.Employee?.employeeName.toUpperCase();
            const nomeB = b?.Employee?.employeeName.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllExits(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByData = () => {
        const sortedItens = [...allExits].sort((a, b) => {
            // Converte as datas para objetos Date para permitir a comparação
            const dateA = new Date(a.exitDate);
            const dateB = new Date(b.exitDate);

            if (orderByData === 'asc') {
                // Ordena em ordem crescente (data mais antiga primeiro)
                return dateA - dateB;
            } else {
                // Ordena em ordem decrescente (data mais recente primeiro)
                return dateB - dateA;
            }
        });

        setAllExits(sortedItens);
        setOrderByData(orderByData === 'asc' ? 'desc' : 'asc');
    };

    useEffect(() => {
        getExits();
    }, []);

    const [selectedExit, setSelectedExit] = useState(null);

    return (
        <MainCard title="CONTROLE DE SAÍDAS">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '1em',
                        width: '100%'
                    }}
                >
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '2em' }}>
                        <Link to={'/main/rh/newExit'} style={{ marginBottom: '2em' }}>
                            <Button variant="contained" color="primary">
                                Cadastrar
                            </Button>
                        </Link>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allExits}
                            getOptionLabel={(exit) => exit.Employee?.employeeName}
                            value={selectedExit}
                            onChange={(event, newValue) => {
                                setSelectedExit(newValue);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Nome do colaborador" />}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <TableSortLabel sx={{ width: '20px' }} onClick={handleSortById}>
                                            ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel onClick={handleSortByNome}>NOME</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">SETOR</TableCell>
                                    <TableCell align="center">FILIAL</TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel onClick={handleSortByData}>DATA</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">ENTRADA</TableCell>
                                    <TableCell align="center">SAIDA</TableCell>
                                    <TableCell align="center">RETORNO PREV</TableCell>
                                    <TableCell align="center">MOTIVO</TableCell>
                                    <TableCell align="center">TIPO</TableCell>
                                    <TableCell align="right">AÇÕES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allExits.length > 0 &&
                                    allExits

                                        .filter((item) =>
                                            selectedExit
                                                ? item?.Employee?.employeeName.includes(selectedExit?.Employee?.employeeName)
                                                : true
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((exit) => (
                                            <TableRow>
                                                <TableCell sx={{ width: '15px' }}>{exit?.id}</TableCell>
                                                <TableCell align="center">{exit?.Employee?.employeeName}</TableCell>
                                                <TableCell align="center">{exit?.Employee?.Sector?.sectorName}</TableCell>
                                                <TableCell align="center">{exit?.Employee?.Branch?.branchName}</TableCell>
                                                <TableCell align="center">{formater(exit.exitDate)}</TableCell>
                                                <TableCell align="center">{removeSecondsFromTime(exit?.entryTime)}</TableCell>
                                                <TableCell align="center">{removeSecondsFromTime(exit?.exitHour)}</TableCell>
                                                <TableCell align="center">{removeSecondsFromTime(exit?.returnHour)}</TableCell>
                                                <TableCell align="center">{exit?.Reason?.reasonName}</TableCell>
                                                <TableCell align="center">{exit?.type}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        onClick={(e) => {
                                                            handleClickOpenEdit(e, exit?.Employee?.id, exit);
                                                            setEmployeeName(exit?.Employee?.id);
                                                            setExitID(exit?.id);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={(e) => handleClickOpenDelete(e, exit.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allExits.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Grid>
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogContent>
                    <EditForm user={currentUser} setOpenEdit={setOpenEdit} getExits={getExits} />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleCloseEdit}>Fechar</Button>
                </DialogActions> */}
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDeleteDialog(exitID)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
}

export default Exits;
