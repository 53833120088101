import {
    Autocomplete,
    Dialog,
    DialogContent,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';
import { formatacaoData } from 'utils/formatacaoData';

const AwaitingDistribution = ({ open, setOpen, data, openNewConstruction, setCurrentDistribuition }) => {
    const navigate = useNavigate();
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        background: '#fff',
        padding: '20px',
        borderRadius: '10px'
    };

    const rows =
        data &&
        data.length > 0 &&
        data
            .map((item) => ({
                ...item,
                gepro: item.gepro,
                costumer: item.client,
                construction: item.obra,
                dt_entrega_original: new Date(item.dt_entrega), // Para ordenação
                dt_entrega: formatacaoData(item.dt_entrega) // Para exibição formatada
            }))
            .sort((a, b) => a.dt_entrega_original - b.dt_entrega_original);

    const columns = [
        { field: 'gepro', headerName: 'GEPRO', width: 90 },
        {
            field: 'costumer',
            headerName: 'CLIENTE',
            flex: 0.5,
            editable: true
        },
        {
            field: 'construction',
            headerName: 'OBRA',
            flex: 1,
            editable: true
        },
        {
            field: 'dt_entrega',
            headerName: 'PCP Entrega',
            flex: 0.5
        },
        {
            field: 'actions',
            headerName: 'AÇÔES',
            description: 'Te encaminha para outra página',
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <div>
                    <IconButton
                        onClick={() => {
                            navigate(`/main/engenharia/nova-distribuicao/${params.row.id}`);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={style}>
                <div>
                    <h2>Aguardando distribuição</h2>
                </div>
                <div sx={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} sx={{ maxHeight: '500px' }} />
                </div>
            </div>
        </Modal>
    );
};

export default AwaitingDistribution;
