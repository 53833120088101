import { MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { differenceInMinutes, format } from 'date-fns';
import { useState } from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { formatValuebyHours } from 'utils/formatValueByHour';
import { GaugeCharts } from './GaugeChart';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function ReportModal({ open, setOpen, data, infos }) {
    console.log('🚀 ~ ReportModal ~ infos:', infos);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (!data || data.length === 0) return;

        const groupedActivities = data.reduce((acc, curr) => {
            if (!curr.currentTime || !curr.eventType) return acc;
            const index = acc.findIndex((item) => item.activityType === curr.activityType);

            if (index === -1) {
                acc.push({
                    activityType: curr.activityType,
                    totalTime: curr.currentTime,
                    time: curr.currentTime,
                    id: `${curr.activityType}-${index + 1}`
                });
                return acc;
            }

            // Somando os valores de currentTime para o totalTime
            acc[index].totalTime += curr.currentTime;

            return acc;
        }, []);
        setRows(groupedActivities);
        console.log('🚀 ~ groupedActivities:', groupedActivities);
    }, [data]);

    const handleClose = () => setOpen(false);
    function formatTime(timeInMinutes) {
        const hours = Math.floor(timeInMinutes / 60);
        const remainingMinutes = Math.floor(timeInMinutes % 60);
        const seconds = Math.round((timeInMinutes - Math.floor(timeInMinutes)) * 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const columns = [
        { field: 'activityType', headerName: 'Tipo de atividade', flex: 1 },
        { field: 'totalTime', headerName: 'Tempo total da atividade', flex: 1, renderCell: (params) => formatTime(params.row.totalTime) }
    ];

    const totalHours = rows.reduce((acc, curr) => acc + curr.totalTime, 0);

    function formatTimeMinute(timeInMinutes) {
        const hours = Math.floor(timeInMinutes / 60);
        const remainingMinutes = Math.floor(timeInMinutes % 60);
        const seconds = Math.round((timeInMinutes - Math.floor(timeInMinutes)) * 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const additionalColumns = [
        { field: 'activityType', headerName: 'Tipo de atividade', flex: 1 },
        { field: 'currentTime', headerName: 'Tempo', flex: 1 },
        { field: 'eventDate', headerName: 'Data do evento', flex: 1 },
        { field: 'eventType', headerName: 'Tipo de evento', flex: 1 },
        { field: 'observation', headerName: 'Observação', flex: 2 }
    ];

    const additionalRows = infos?.codificationEvents?.map((item) => ({
        ...item,
        activityType: item.activityType ? item.activityType : 'Distribuição iniciada',
        currentTime: formatTimeMinute(item.currentTime),
        eventDate: format(new Date(item.eventDate), 'dd/MM/yyyy HH:mm'),
        eventType: String(item.eventType)?.toUpperCase(),
        observation: item.observation ? item.observation : 'Sem observação'
    }));

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Relatório de tempos da atividade</h3>
                    <DataGrid
                        columns={additionalColumns}
                        rows={additionalRows}
                        sx={{
                            minWidth: '550px',
                            maxHeight: '400px',
                            marginBottom: '30px',
                            '& .MuiDataGrid-cell': {
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                lineHeight: '1.5 !important',
                                display: 'block'
                            },
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                py: 1
                            },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                py: '15px'
                            },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                py: '22px'
                            }
                        }}
                        getRowHeight={() => 'auto'}
                    />
                    <h3 style={{ textAlign: 'center' }}>Relatório de tempos da atividade</h3>
                    <Box sx={{ display: 'flex' }}>
                        <DataGrid columns={columns} rows={rows} hideFooter sx={{ minWidth: '650px' }} />
                        <GaugeCharts sumActivity={totalHours} totalEstimated={infos?.estimated_hours} />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
