import {
    Autocomplete,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    Modal,
    OutlinedInput,
    TextField,
    Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import formater from 'utils/formatDate';
import ConfirmSendEmailExpedition from 'ui-component/ConfirmSendEmailExpedition';
import { converterData } from 'utils/convertData';
import { toast } from 'react-toastify';
import { formatarCPF, formatarPlaca, formatarTelefone } from 'utils/regex';
import { DataGrid } from '@mui/x-data-grid';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { formatacaoData } from 'utils/formatacaoData';
import { uf } from 'utils/uf';
import { ModalEmailsCC } from 'ui-component/ModalEmailsCC';
import { typeOfTransport } from 'utils/typeOfTransport';
import { converterDataAtual } from 'utils/convertDataAtual';

const ConfirmRequest = ({ open, setOpen, dataProp, getData }) => {
    const api = useApi();
    const [dataRequest, setDataRequest] = useState(null);
    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [dataEmail, setDataEmail] = useState(null);
    const [coletumData, setColetumData] = useState(null);
    const [groupSelected, setGroupSelected] = useState(null);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [idDelete, setIdDelete] = useState(null);

    const [selectedState, setSelectedState] = useState(null);
    const [cityResults, setCityResults] = useState([]);
    const [listEmails, setListEmails] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: '#fefefe',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    const getRequestExpeditionId = async () => {
        try {
            const { data } = await api.getNewRequestPageId(dataProp?.id);
            const row = data.map((item) => ({
                ...item,
                pdv: item?.pedido,
                obra: item?.obra
            }));
            setRows(row);
            setSelectedState(data?.uf_destino);
        } catch (error) {
            console.log('🚀 ~ file: index.js:30 ~ getRequestExpeditionId ~ error:', error.message);
        }
    };

    const handleCityResults = async () => {
        try {
            const { data } = await api.getCitiesByIbge(selectedState);
            console.log('🚀 ~ handleCityResults ~ data:', data);
            setCityResults(data);
        } catch (error) {
            console.log('🚀 ~ handleCityResults ~ error:', error);
        }
    };

    useEffect(() => {
        if (selectedState) {
            handleCityResults();
        }
    }, [selectedState]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90, renderCell: (params) => <p>E {params.row.id}</p> },
        { field: 'pdv', headerName: 'PDV', width: 90 },
        {
            field: 'obra',
            headerName: 'Cliente',
            width: 300
        },
        {
            field: 'agrupamento',
            headerName: 'Agrupamento',
            width: 450,
            renderCell: (params) => params.row.agrupamento.map((item) => <p style={{ marginRight: '5px' }}>{item}</p>)
        },

        {
            field: 'actions',
            headerName: 'Ações',
            width: 110,
            renderCell: (params) => (
                <div>
                    <Tooltip
                        title="Confirmar"
                        onClick={() => {
                            setGroupSelected(params.row);
                            setSelectedState(params.row.uf_destino);
                            setListEmails(params.row.ccEmails);
                        }}
                    >
                        <IconButton>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                        <IconButton
                            onClick={() => {
                                setOpenDelete(true);
                                setIdDelete(params.row.id);
                            }}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    useEffect(() => {
        getRequestExpeditionId();
    }, [open]);

    const typeCar = [
        { id: 1, type: 'Veículo Próprio' },
        { id: 2, type: 'Transportadora' }
    ];

    const handleSubmitFunc = async (value) => {
        setLoading(true);
        const idUser = localStorage.getItem('id');
        const payload = {
            usuario_responsavel_id: idUser,
            agrupamentos: value?.agrupamento,
            pedido: value?.order,
            contato: value?.contact,
            telefone: value?.tel,
            email: value?.email,
            cliente: value?.client,
            obra: value?.constructions,
            responsavel_coleta: value?.type ? value?.client : 'TRANSDUTO',
            a_partir_de: converterDataAtual(groupSelected?.a_partir_de),
            confirmacao: value?.confirm,
            tipo: value?.vehicle,
            telefone_motorista: value?.telMotorista,
            cpf: value.cpf,
            rg: value.rg,
            transportadora: value.shippingCompany,
            placa: value.licensePlate,
            material: 'Duto',
            motorista: value.driverName,
            frotista: value.frotista,
            cidade_destino: value.city,
            uf_destino: value.uf,
            cidade_destino: value.city,
            ccEmails: listEmails
        };

        try {
            const res = await api.confirmRequestExpedition(groupSelected?.id, payload);
            const dataEmail = res.data.confirmacao_solicitacao_coleta;

            setOpenSendEmail(true);

            setDataEmail({
                email: dataEmail?.email,
                cliente: dataEmail?.cliente,
                idSolicitacaoColeta: dataEmail?.solicitacao_id,
                action: 'add',

                agrupamento: dataEmail.agrupamento,
                pedido: groupSelected.pedido,
                obra: groupSelected.obra,
                a_partir_de: groupSelected.a_partir_de,
                periodo: groupSelected.periodo,
                volumes: groupSelected.volumes,
                tipo_volume: groupSelected.tipo_volume,
                periodo: groupSelected.periodo,
                peso_aproximado: groupSelected.peso_aproximado,
                ccEmails: listEmails
            });
            getData();
            toast.success('Confirmação de solicitação');
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ file: index.js:49 ~ handleSubmit ~ error:', error);
            toast.error(error.response.data.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleEditFunc = async (value) => {
        const idUser = localStorage.getItem('id');
        const payload = {
            usuario_responsavel_id: idUser,
            agrupamento: value?.agrupamento,
            pedido: value?.order,
            contato: value?.contact,
            telefone: value?.tel,
            email: value?.email,
            cliente: value?.client,
            obra: value?.constructions,
            responsavel_coleta: value?.type ? value?.client : 'TRANSDUTO',
            a_partir_de: value?.from,
            confirmacao: value?.confirm,
            tipo: value?.vehicle
        };

        if (payload.tipo == '' || payload.confirmacao == '') return toast.warn('Preencha os dados');
        try {
            const res = await api.editConfirmRequestExpedition(coletumData?.expedicao_id, payload);
            const dataEmail = res.data.confirmacao_solicitacao_coleta;
            console.log('🚀 ~ handleEditFunc ~ dataEmail:', dataEmail);

            setOpenSendEmail(true);
            getData();

            setDataEmail({
                email: dataEmail?.email,
                cliente: dataEmail?.cliente,
                idConfirmacaoColeta: dataEmail?.solicitacao_id,
                action: 'update'
            });
            toast.success('Edit de confirmação');
        } catch (error) {
            console.log('🚀 ~ file: index.js:49 ~ handleSubmit ~ error:', error);
            toast.error(error.response.data.message);
        }
    };

    const sendEmail = async () => {
        try {
            console.log(dataEmail, 'dataemail do email');
            setOpen(false);
            setOpenSendEmail(false);
            await api.sendEmailConfirmRequestExpedition(dataEmail);
            toast.success('Email enviado com sucesso');
        } catch (error) {
            console.log('🚀 ~ file: index.js:84 ~ sendEmail ~ error:', error.message);
        }
    };

    const typeDriver = [
        { id: 1, type: 'Frotista' },
        { id: 2, type: 'Autônomo' }
    ];

    const material = [{ type: 'DISTRIBUIÇÃO' }, { type: 'DUTO' }];

    const validationSchema = Yup.object().shape({
        vehicle: Yup.string().required('Tipo de transporte'),
        confirm: Yup.string().required('Data é obrigatório'),
        typeVehicle: Yup.string().required('Tipo de veículo')
    });
    const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);
    const deleteRequestExpedition = async () => {
        setLoadingDeleteModal(true);
        try {
            await api.deleteRequestExpedition(idDelete);
            getData();
            toast.success('Expedição excluida com sucesso');
            setOpenDelete(false);
            setOpen(false);
            setLoadingDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ deleteRequestExpedition ~ error:', error);
        }
    };

    const onClose = () => {
        setOpen(false);
        setGroupSelected(null);
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <ConfirmDeleteModal
                    open={openDelete}
                    setOpen={setOpenDelete}
                    id={idDelete}
                    message={'Deseja mesmo excluir essa expedição solicitada ?'}
                    func={deleteRequestExpedition}
                    loading={loadingDeleteModal}
                />
                <ConfirmSendEmailExpedition
                    open={openSendEmail}
                    setOpen={setOpenSendEmail}
                    func={sendEmail}
                    message={'Deseja enviar o e-mail de confirmação ao cliente?'}
                    funcClose={setOpen}
                    url={'/main/expedicao/solicitadas'}
                />
                {groupSelected ? (
                    <Formik
                        initialValues={{
                            agrupamento: groupSelected?.agrupamento || '',
                            order: groupSelected?.pedido || '',
                            contact: groupSelected?.contato || '',
                            email: groupSelected?.email,
                            client: groupSelected?.cliente,
                            constructions: groupSelected?.obra || '',
                            type: groupSelected?.cif,
                            tel: groupSelected?.telefone || '',
                            vehicle: groupSelected?.tipo_volume || '',
                            from: formatacaoData(groupSelected?.a_partir_de) || '',
                            confirm: converterData(coletumData?.confirmacao) || '',
                            typeVehicle: groupSelected?.transporte || '',
                            driverName: '',
                            telMotorista: '',
                            cpf: '',
                            rg: '',
                            shippingCompany: '',
                            licensePlate: '',
                            material: '',
                            frotista: '',
                            city: groupSelected?.cidade_destino,
                            uf: groupSelected?.uf_destino
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            handleSubmitFunc(values);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ padding: '15px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                            >
                                <h2>Confirmação da solicitação de coleta - E{groupSelected?.id}</h2>
                                <h3>Dados de coleta</h3>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            disabled
                                            sx={{
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            label="Agrupamento"
                                            name="agrupamento"
                                            value={values.agrupamento}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            disabled
                                            sx={{
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            label="PDV"
                                            name="order"
                                            value={values.order}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            disabled
                                            variant="filled"
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            label="Contato"
                                            name="contact"
                                            value={values.contact}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Cliente"
                                            name="client"
                                            value={values.client}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '200px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            variant="filled"
                                            disabled
                                            label="Responsável pela Coleta"
                                            name="type"
                                            value={values.type ? values.client : 'TRANSDUTO'}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            sx={{
                                                width: '200px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Telefone"
                                            name="tel"
                                            value={values.tel}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '200px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            variant="filled"
                                            label="Obra"
                                            name="constructions"
                                            value={values.constructions}
                                        />
                                    </FormControl>{' '}
                                    <FormControl>
                                        <TextField
                                            variant="filled"
                                            sx={{
                                                width: '200px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="A partir de:"
                                            name="from"
                                            value={values.from}
                                        />
                                    </FormControl>
                                </Box>

                                <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />

                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '10px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '320px' }}
                                            label="E-mail"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <ModalEmailsCC list={listEmails} setList={setListEmails} />
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="vehicle"
                                            options={typeCar}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option?.type}
                                            value={typeCar.find((item) => item?.type === values.vehicle) || null}
                                            error={Boolean(errors.vehicle && touched.vehicle)}
                                            helperText={errors.vehicle && touched.vehicle ? errors.vehicle : ''}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, vehicle: newValue.type });
                                                }
                                            }}
                                            required
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tipo do transporte"
                                                    name="vehicle"
                                                    required
                                                    value={values.vehicle}
                                                />
                                            )}
                                        />
                                        {touched.vehicle && errors.vehicle && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.vehicle}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="Veiculo"
                                            options={typeOfTransport}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.type}
                                            value={typeOfTransport.find((item) => item.type === values.typeVehicle)}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, typeVehicle: newValue.type });
                                                }
                                            }}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Veiculo" name="typeVehicle" value={values.typeVehicle} />
                                            )}
                                        />
                                        <ErrorMessage name="typeVehicle" component="span" />
                                    </FormControl>

                                    <FormControl error={Boolean(errors.confirm)}>
                                        <TextField
                                            type="date"
                                            id="confirm-id"
                                            sx={{
                                                width: '200px',
                                                borderColor: errors.confirm ? 'red' : ''
                                            }}
                                            label="Confirmação"
                                            name="confirm"
                                            value={values.confirm}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                            error={touched.confirm && Boolean(errors.confirm)}
                                            helperText={touched.confirm && errors.confirm}
                                        />
                                    </FormControl>
                                </Box>
                                <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />
                                <h3>Dados de transporte</h3>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="Nome do motorista"
                                            name="driverName"
                                            value={values.driverName}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="Telefone"
                                            name="telMotorista"
                                            value={values.telMotorista}
                                            onChange={(e) => setValues({ ...values, telMotorista: formatarTelefone(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="CPF"
                                            name="cpf"
                                            value={values.cpf}
                                            onChange={(e) => setValues({ ...values, cpf: formatarCPF(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="RG"
                                            name="rg"
                                            value={values.rg}
                                            onChange={(e) => setValues({ ...values, rg: e.target.value })}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '200px' }}
                                            label="Transportadora"
                                            name="shippingCompany"
                                            value={values.shippingCompany}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typeDriver"
                                            options={typeDriver}
                                            sx={{ width: 150 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, frotista: newValue.type });
                                            }}
                                            value={typeDriver.find((option) => option.type === values.frotista) || null}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Frotista" name="material" value={values.frotista} />
                                            )}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            sx={{ width: '200px' }}
                                            label="Placa"
                                            name="licensePlate"
                                            value={values.licensePlate}
                                            onChange={(e) => setValues({ ...values, licensePlate: formatarPlaca(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            id="uf"
                                            options={uf}
                                            sx={{ width: 100 }}
                                            getOptionLabel={(option) => option.sigla}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, uf: newValue.sigla });
                                                    setSelectedState(newValue.sigla);
                                                }
                                            }}
                                            value={uf.find((item) => item.sigla === values.uf) || null}
                                            // defaultValue={values.uf}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="UF"
                                                    name="uf"
                                                    value={values.uf}
                                                    error={touched.uf && Boolean(errors.uf)}
                                                    helperText={touched.uf && errors.uf}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="city"
                                            options={cityResults}
                                            sx={{ width: 250 }}
                                            getOptionLabel={(option) => option.nome}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, city: newValue.nome });
                                                }
                                            }}
                                            value={cityResults.find((item) => item.nome === values.city) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Destino da entrega - cidade"
                                                    name="city"
                                                    value={values.city}
                                                    error={touched.city && Boolean(errors.city)}
                                                    helperText={touched.city && errors.city}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        alignItems: 'center',
                                        marginTop: '16px'
                                    }}
                                >
                                    <Button onClick={() => setGroupSelected(null)}>Voltar</Button>
                                    <Button type="submit" disabled={loading} variant="contained" sx={{ width: '300px', margin: '0 auto' }}>
                                        {loading ? 'Carregando...' : 'Confirmar'}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                ) : (
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Escolha uma obra para confirmar a solicitação</h3>
                        <DataGrid rows={rows} hideFooter columns={columns} />
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ConfirmRequest;
