import { Modal, Box, TextField, Button, Badge, Divider, Tooltip, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { ViewImage } from './ViewImage';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DataGrid } from '@mui/x-data-grid';
import SendIcon from '@mui/icons-material/Send';
import { formatacaoData } from 'utils/formatacaoData';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { useNavigate } from 'react-router-dom';
import { ConfirmDeleteModalGenerics } from 'ui-component/ConfirmDeletModalGenerics';
import { LoadAnimated } from 'ui-component/LoadAnimated';

const styleFirst = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '700px',
    overflow: 'auto'
};
const styleSecond = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1100px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '700px',
    overflow: 'auto'
};

export function FinishModalAvulso({ open, setOpen, currentRow, getRequests }) {
    const role = localStorage.getItem('role');
    const api = useApi();

    const [images, setImages] = useState([]);
    const [openViewImage, setOpenViewImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [row, setRow] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadImages, setLoadImages] = useState(false);

    const [finishedData, setFinishedData] = useState(null);
    const [getPureImages, setGetPureImages] = useState([]);

    const getFinishedExpeditionById = async (id) => {
        setLoading(true);
        setLoadImages(true);
        try {
            const { data } = await api.firstGetFinishByExpeditionAvulso(id);
            console.log('🚀 ~ getFinishedExpeditionById ~ data:', data);
            setFinishedData(data);
            const images = data?.registro.img_solicitacao_avulsos?.map((item) => {
                return transformBlodIntoUrlImage(item.Image.data, item.type);
            });
            setGetPureImages(data);
            setSelectedRow(true);
            setImages(images);
            setLoading(false);
            setLoadImages(false);
        } catch (error) {
            console.log('Erro ao buscar expedição finalizada:', error);
            setLoadImages(false);
            return null;
        }
    };

    useEffect(() => {
        if (currentRow?.id) {
            getFinishedExpeditionById(currentRow.id);
        }
    }, [open, currentRow]);

    const transformBlodIntoUrlImage = (data, type) => {
        const base64String = btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        return `data:${type};base64,${base64String}`;
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const handleImageClick = (index) => {
        setSelectedImage(images[index]);
    };

    const handleDownloadImage = (index) => {
        const imageUrl = images[index];
        const fileName = `E-${currentRow?.id}-imagem-${index + 1}.jpg`;

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = fileName;
        link.click();
    };

    //codgo para o delete
    const [openDelete, setOpenDelete] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);
    const navigate = useNavigate();

    const deleteScheduledExpedition = async () => {
        setLoadingDeleteModal(true);
        try {
            await api.deleteFinishExpedition(idDelete);
            navigate('/main/expedicao/programacaes');
            toast.success('Expedição excluida com sucesso');
        } catch (error) {
            console.log('🚀 ~ deleteRequestExpedition ~ error:', error);
            setLoadingDeleteModal(false);
        }
    };

    // referente ao edit de imagens
    const [isPossibleEditImages, setIsPossibleEditImages] = useState(false);
    const [imageFiles, setImageFiles] = useState([]);
    const [openDeleteImageModal, setOpenDeleteImageModal] = useState(false);
    const [currentImagePosition, setCurrentImagePostion] = useState(null);
    const [copyImagesEdit, setCopyImagesEdit] = useState([]);

    const handleFileChange = async (event) => {
        const files = event.target.files;
        const newImageFiles = [...imageFiles];
        setLoadImages(true);
        // Adicionar novos arquivos à matriz existente
        for (let i = 0; i < files.length; i++) {
            newImageFiles.push(files[i]);
        }

        setImageFiles(files);

        const imagesArray = Array.from(files);
        const imagesURLs = imagesArray.map((file) => URL.createObjectURL(file));

        setCopyImagesEdit((prevImages) => [...prevImages, ...imagesURLs]);

        await handleEditImages(newImageFiles);
    };

    const handleDeleteImage = async () => {
        const updatedImages = [...copyImagesEdit];
        setLoadingDeleteModal(true);

        const currentImage = getPureImages.registro.img_solicitacao_avulsos[currentImagePosition];

        console.log('🚀 ~ handleDeleteImage ~ currentImage:', getPureImages);
        updatedImages.splice(currentImagePosition, 1);

        try {
            await api.deleteOneImageExpeditionAV(currentRow?.id, currentImage.id);
            setCopyImagesEdit(updatedImages);
            setLoadingDeleteModal(false);
            toast.success('Imagem excluida com sucesso');
            setOpenDeleteImageModal(false);
            setIsPossibleEditImages(false);
            getFinishedExpeditionById(currentRow.id);
        } catch (error) {
            console.log('🚀 ~ handleDeleteOneImage ~ error:', error);
            setLoadingDeleteModal(false);
        }
    };

    const handleEditImages = async (files) => {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('buffers', file);
            console.log('🚀 ~ imageFiles.forEach ~ file:', file);
        });

        try {
            await api.editExpeditionImagesAV(currentRow?.id, formData);
            toast.success('Imagem adicionada com sucesso!');
            getFinishedExpeditionById(currentRow.id);
            setIsPossibleEditImages(false);
            setImageFiles('');
            setLoadImages(false);
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao editar imagens');
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={styleFirst}>
                <ConfirmDeleteModalGenerics
                    func={handleDeleteImage}
                    open={openDeleteImageModal}
                    setOpen={setOpenDeleteImageModal}
                    loading={loadingDeleteModal}
                    message={'Deseja mesmo deletar a imagem selecionada?'}
                    title={'Deletar imagem'}
                />
                <ViewImage open={openViewImage} setOpen={setOpenViewImage} image={selectedImage} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
                    <h3>Expedição avulsa finalizada</h3>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1, borderRight: '1px solid #222222' }}>
                        <p>Dados do cliente</p>
                        <Box>
                            <p>
                                ID: <strong>E {currentRow?.id}</strong>{' '}
                            </p>
                            <p style={{ marginTop: '-13px' }}>
                                Cliente: <strong> {currentRow?.client}</strong>
                            </p>
                        </Box>
                        <Box sx={{}}></Box>
                    </Box>
                    <Box sx={{ flex: 1, marginLeft: '40px' }}>
                        <p>Dados do motorista</p>
                        <Box>
                            <p>
                                Nome: <strong>{currentRow?.motorista}</strong>{' '}
                            </p>
                            <p style={{ marginTop: '-13px' }}>
                                CPF: <strong>{currentRow?.cpf}</strong>
                            </p>
                        </Box>
                        <Box sx={{}}>
                            <p style={{ marginTop: '-13px' }}>
                                Placa: <strong>{currentRow?.placa}</strong>{' '}
                            </p>
                            <p style={{ marginTop: '-13px' }}>
                                Transporte: <strong>{currentRow?.transporte}</strong>{' '}
                            </p>
                        </Box>
                    </Box>
                </Box>

                <Divider sx={{ margin: '10px' }} />
                {loadImages ? (
                    <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                        <LoadAnimated />
                    </Box>
                ) : (
                    <>
                        {isPossibleEditImages && (
                            <Box>
                                <TextField
                                    type="file"
                                    inputProps={{
                                        multiple: true,
                                        accept: 'image/*'
                                    }}
                                    sx={{ width: '100%' }}
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={handleFileChange}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '20px',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '25px',
                                marginBottom: '25px'
                            }}
                        >
                            {isPossibleEditImages ? (
                                <>
                                    {copyImagesEdit?.map((imageUrl, index) => (
                                        <div key={index} style={{ position: 'relative' }} className={'image-container shake-rotate'}>
                                            <button
                                                style={{ all: 'unset' }}
                                                onClick={() => {
                                                    handleImageClick(index);
                                                    setOpenViewImage(true);
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={imageUrl}
                                                    alt={`Imagem ${index}`}
                                                    style={{
                                                        width: '130px',
                                                        height: '130px',
                                                        objectFit: 'cover',
                                                        cursor: 'pointer',
                                                        boxShadow: '2px 2px 8px #202020'
                                                    }}
                                                />
                                            </button>

                                            <Badge
                                                badgeContent={'x'}
                                                color="error"
                                                sx={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
                                                onClick={() => {
                                                    setOpenDeleteImageModal(true);
                                                    setCurrentImagePostion(index);
                                                }}
                                            />
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {images?.map((imageUrl, index) => (
                                        <div key={index} style={{ position: 'relative' }}>
                                            <button
                                                style={{ all: 'unset' }}
                                                onClick={() => {
                                                    handleImageClick(index);
                                                    setOpenViewImage(true);
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={imageUrl}
                                                    alt={`Imagem ${index}`}
                                                    style={{
                                                        width: '130px',
                                                        height: '130px',
                                                        objectFit: 'cover',
                                                        cursor: 'pointer',
                                                        boxShadow: '2px 2px 8px #202020'
                                                    }}
                                                />
                                            </button>

                                            <Badge
                                                badgeContent={<CloudDownloadIcon />}
                                                color="primary"
                                                title="Baixar imagem"
                                                sx={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
                                                onClick={() => handleDownloadImage(index)}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </Box>
                    </>
                )}

                {isPossibleEditImages && role !== 'Codificador' && role !== 'Comercial/Engenharia' ? (
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setIsPossibleEditImages(false);
                            }}
                        >
                            Cancelar
                        </Button>
                    </Box>
                ) : (
                    role !== 'Codificador' &&
                    role !== 'Comercial/Engenharia' && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                setCopyImagesEdit(images);
                                setIsPossibleEditImages(true);
                            }}
                        >
                            Editar Imagens
                        </Button>
                    )
                )}
            </Box>
        </Modal>
    );
}
