import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';

function Sales() {
    return (
        <MainCard title="Vendas">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <Typography variant="h5">Vendas</Typography>
                </Box>
            </Grid>
        </MainCard>
    );
}

export default Sales;
