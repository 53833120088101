import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, Button } from '@mui/material';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router-dom';

const ObservationCard = ({ onObservationChange, onSubmit }) => {
    const api = useApi();
    const { id } = useParams();
    const [observation, setObservation] = useState('');

    const handleSubmitObservation = async () => {
        try {
            const response = await api.pauseBudget(id, observation);
            await api.changeBudgetStatus(id, 'pausado');
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.error('Erro ao pausar orçamento:', error);
        }
    };

    return (
        <Card sx={{ width: '360px' }}>
            <CardContent>
                <Typography variant="h5" sx={{ marginBottom: 2 }} component="div">
                    Observação:
                </Typography>
                <TextField
                    label="Digite o motivo da pausa"
                    value={observation}
                    onChange={(event) => setObservation(event.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                />
                <Button variant="contained" sx={{ marginTop: 2 }} onClick={handleSubmitObservation}>
                    Enviar
                </Button>
            </CardContent>
        </Card>
    );
};

export default ObservationCard;
