import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box } from '@mui/system';
import { formatarCPF, formatarPlaca, formatarRG, formatarTelefone } from 'utils/regex';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import formater from 'utils/formatDate';

const EditTransportRecordId = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const [data, setData] = useState(null);
    const responsavel = localStorage.getItem(id);
    const typeOfTransport = [
        { type: 'TRUCK' },
        { type: 'TOCO' },
        { type: 'VUC' },
        { type: 'BITRUCK' },
        { type: 'CARRETA' },
        { type: 'BAÚ' },
        { type: 'PICKUP' },
        { type: 'TOCO ABERTO' },
        { type: 'TOCO BAU' },
        { type: 'TOCO SIDER' },
        { type: 'TRUCK ABERTO' },
        { type: 'TRUCK BAU' },
        { type: 'TRUCK SIDER' },
        { type: 'VUC ABERTO' },
        { type: 'VUC BAU' },
        { type: 'VUC SIDER' },
        { type: 'CARRETA ABERTA' },
        { type: 'CARRETA BAU' },
        { type: 'CARRETA SIDER' },
        { type: 'BITRUCK BAU' },
        { type: 'BITRUCK ABERTO' },
        { type: 'BITRUCK SIDER' },
        { type: 'OUTRO' }
    ];

    const material = [{ type: 'Distribuição' }, { type: 'DUTO' }];

    const getId = async () => {
        try {
            const { data } = await api.getCurentTransport(id);
            console.log('🚀 ~ file: index.js:37 ~ getId ~ data:', data);

            const dataRequest = {
                grouping: data.registro?.agrupamento,
                order: data.registro?.pedido,
                contact: data.registro?.contato,
                email: data.registro?.email,
                client: data.registro?.cliente,
                constructions: data.registro?.obra,
                confirm: data.registro?.Solicitacao_coleta?.confirmacao_solicitacao_coletum?.confirmacao,
                period: data.registro?.periodo,
                transport: data.registro?.transporte,
                driverName: data?.registro.motorista,
                tel: data?.registro.telefone,
                cpf: data?.registro.cpf,
                rg: data?.registro.rg,
                shippingCompany: data?.registro.transportadora,
                transportConfirm: data?.registro.transport || '',
                licensePlate: data?.registro.placa,
                material: data?.registro.material,
                observation: data?.registro.observacao,
                id: data?.registro.id
            };

            setData(dataRequest);
        } catch (error) {
            console.log('🚀 ~ file: TransportRecord.js:39 ~ getId ~ error:', error);
        }
    };

    useEffect(() => {
        getId();
    }, []);

    const handleEditTransport = async (values) => {
        const payload = {
            agrupamento: values.agrupamento,
            pedido: values.agrupamento,
            contato: values.contact,
            data_confirmacao: values.confirm,
            cliente: values.client,
            periodo: values.period,
            obra: values.constructions,
            transporte: values.transportConfirm,
            motorista: values.driverName,
            telefone: values.tel,
            cpf: values.cpf,
            rg: values.rg,
            transportadora: values.shippingCompany,
            placa: values.licensePlate,
            material: values.material,
            observacao: values.observation,
            responsavel_id: responsavel
        };

        try {
            await api.editTransportRegister(data.id, id, payload);
            console.log('🚀 ~ file: TransportRecord.js:78 ~ handleSubmit ~ data:', data);
            navigate(-1);
        } catch (error) {
            console.log('🚀 ~ file: TransportRecord.js:71 ~ handleSubmit ~ error:', error);
        }
    };

    return (
        <MainCard title="Registro de transporte">
            <Grid container spacing={gridSpacing}>
                {data && (
                    <Formik
                        initialValues={{
                            agrupamento: data?.grouping || '',
                            order: data?.order || '',
                            contact: data?.contact || '',
                            email: data?.email || '',
                            client: data?.client,
                            constructions: data?.constructions || '',
                            confirm: formater(data?.confirm) || '',
                            period: data?.period || '',
                            transport: data?.transport || '',
                            driverName: data?.driverName,
                            tel: data?.tel,
                            cpf: data?.cpf,
                            rg: data?.rg,
                            shippingCompany: data?.shippingCompany,
                            transportConfirm: data?.transport || '',
                            licensePlate: data?.licensePlate,
                            material: data?.material,
                            observation: data?.observation
                        }}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            // console.log(values);
                            handleEditTransport(values);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ padding: '15px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                            >
                                <h3>Dados agendados</h3>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <FormControl>
                                        <TextField disabled label="Agrupamento" name="agrupamento" value={values.agrupamento} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField disabled label="Pedido" name="order" value={values.order} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField disabled sx={{ width: '300px' }} label="Contato" name="contact" value={values.contact} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="Data de Confirmação"
                                            name="confirm"
                                            value={values.confirm}
                                            disabled
                                            type="data"
                                            onChange={handleChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField sx={{ width: '600px' }} disabled label="Cliente" name="client" value={values.client} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField sx={{ width: '400px' }} disabled label="Periodo" name="period" value={values.period} />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '600px' }}
                                            disabled
                                            label="Obra"
                                            name="constructions"
                                            value={values.constructions}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '400px' }}
                                            disabled
                                            label="Transport"
                                            name="transport"
                                            value={values.transport}
                                        />
                                    </FormControl>
                                </Box>

                                <h3>Dados portaria</h3>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="Nome do motorista"
                                            name="driverName"
                                            value={values.driverName}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="Telefone"
                                            name="tel"
                                            value={values.tel}
                                            onChange={(e) => setValues({ ...values, tel: formatarTelefone(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="CPF"
                                            name="cpf"
                                            value={values.cpf}
                                            onChange={(e) => setValues({ ...values, cpf: formatarCPF(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="RG"
                                            name="rg"
                                            value={values.rg}
                                            onChange={(e) => setValues({ ...values, rg: e.target.value })}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="Transportadora"
                                            name="shippingCompany"
                                            value={values.shippingCompany}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typeTransport"
                                            options={typeOfTransport}
                                            sx={{ width: 230 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, transportConfirm: newValue.type });
                                            }}
                                            value={typeOfTransport.find((option) => option.type === values.transportConfirm) || null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Transporte"
                                                    name="transportConfirm"
                                                    value={values.transportConfirm}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="Placa"
                                            name="licensePlate"
                                            value={values.licensePlate}
                                            onChange={(e) => setValues({ ...values, licensePlate: formatarPlaca(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="material"
                                            options={material}
                                            sx={{ width: 230 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, material: newValue.type });
                                            }}
                                            value={material.find((option) => option.type === values.material) || null}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Material" name="material" value={values.material} />
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginTop: '16px' }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            multiline
                                            rows={2}
                                            name="observation"
                                            label="Observação"
                                            value={values.observation}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'end' }}>
                                    <Button type="submit" variant="contained" sx={{ width: '200px' }}>
                                        Registrar
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </MainCard>
    );
};

export default EditTransportRecordId;
