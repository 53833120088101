import { Button, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'Service/axios';
import { useNavigate } from 'react-router';

const MissWork = ({ reason, employee, currentUser, setOpenEdit, getExits }) => {
    const api = useApi();
    const navigate = useNavigate();
    // validação
    const validationSchema = Yup.object().shape({
        collaborator: Yup.string().required('Campo obrigatório'),
        date: Yup.date().required('Data é obrigatória'),
        reason: Yup.string().required('Campo obrigatório'),
        days: Yup.string().required('Quantidade de dias é obrigatório')
    });

    return (
        <>
            <Formik
                initialValues={{
                    collaborator: currentUser?.employeeName ? currentUser?.employeeName : '',
                    date: currentUser?.exitDate ? currentUser.exitDate.substring(0, 10) : '',
                    reason: currentUser?.Reason?.id ? currentUser?.Reason?.id : '',
                    observation: currentUser?.observation ? currentUser?.observation : '',
                    days: currentUser?.days ? currentUser?.days : ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    const payload = {
                        employeeName: parseInt(values.collaborator),
                        exitDate: values.date,
                        entryTime: null,
                        reason: parseInt(values.reason),
                        observation: values.observation !== '' ? values.observation : null,
                        returnHour: null,
                        days: values.days,
                        realReturnHour: null,
                        type: 'Falta'
                    };
                    console.log('🚀 ~ file: MissWork.js:42 ~ onSubmit={ ~ payload:', payload);

                    try {
                        await api.updateExit(currentUser?.id, payload);
                        // clearValues(values);
                        setOpenEdit(false);
                        getExits();
                    } catch (err) {
                        console.log(err.message);
                    }
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                    <Form style={{ display: 'flex', flexDirection: 'column', gap: '25px', maxWidth: '850px', margin: '0 auto' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="collaborator">Colaborador</InputLabel>
                            <Select
                                id="collaborator"
                                name="collaborator"
                                label="Colaborador"
                                value={values.collaborator}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {employee &&
                                    employee?.map((item) => (
                                        <MenuItem key={item} value={item.id}>
                                            {item.employeeName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error>
                                <ErrorMessage name="collaborator" />
                            </FormHelperText>
                        </FormControl>
                        <Box sx={{ display: ' flex', gap: '30px' }}>
                            <FormControl fullWidth variant="filled">
                                <TextField
                                    label="Data"
                                    id="date"
                                    type="date"
                                    value={values.date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormHelperText error>
                                    <ErrorMessage name="date" />
                                </FormHelperText>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="days">Dias</InputLabel>
                                <OutlinedInput
                                    id="days"
                                    label="Dias"
                                    type="number"
                                    value={values.days}
                                    name="days"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{}}
                                />
                                <FormHelperText error>
                                    <ErrorMessage name="days" />
                                </FormHelperText>
                            </FormControl>
                        </Box>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="reason">Motivo</InputLabel>
                            <Select
                                id="reason"
                                name="reason"
                                label="Motivo"
                                value={values.reason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {reason &&
                                    reason?.map((item) => (
                                        <MenuItem key={item} value={item.id}>
                                            {item.reasonName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error>
                                <ErrorMessage name="reason" />
                            </FormHelperText>
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="observation">Observação</InputLabel>
                            <OutlinedInput
                                id="observation"
                                type="text"
                                label="Observação"
                                value={values.observation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <FormHelperText error>
                                <ErrorMessage name="observation" />
                            </FormHelperText>
                        </FormControl>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button type="submit" variant="contained" style={{ width: '200px', margin: '20px' }}>
                                Salvar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default MissWork;
