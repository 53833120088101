// assets
import { IconAssembly } from '@tabler/icons';

const icons = {
    IconAssembly
};

const control = {
    id: 'controles-2',
    title: 'Controles',
    type: 'group',
    children: [
        {
            id: 'paineis controles',
            title: 'Gestão de Obras',
            type: 'item',
            url: '/main/control/gestao-obras',
            icon: icons.IconAssembly,
            breadcrumbs: false
        }
    ]
};

export default control;
