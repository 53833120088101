import { Autocomplete, Button, Grid, IconButton } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import AddEncoders from './components/AddEncoders';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import EditEnconders from './components/EditEnconders';
import ConfirmDelete from './components/confirmDelete';
import { DataGrid } from '@mui/x-data-grid';

const Encoders = () => {
    const api = useApi();
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [codficators, setCodficators] = useState([]);
    const [selectedCodificators, setSelectedCodificators] = useState(null);
    const [currentCodificators, setCurrentCodificators] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);

    const getCodficator = async () => {
        try {
            const { data } = await api.getCodficator();
            console.log('🚀 ~ file: index.js:40 ~ getCodficator ~ data:', data);

            const rows = data.map((item) => ({
                id: item.id,
                codificator: item.codificator,
                status: item.status
            }));

            setCodficators(rows);
        } catch (error) {
            console.log('🚀 ~ file: index.js:46 ~ getCodficator ~ error:', error.message);
        }
    };

    const handleDelete = async () => {
        try {
            await api.deleteCodficator(currentCodificators.id);
            getCodficator();
        } catch (error) {
            console.log('🚀 ~ file: index.js:50 ~ handleDelete ~ error:', error.mesage);
        }
    };

    useEffect(() => {
        getCodficator();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'codificator',
            headerName: 'Nome',
            flex: 1,
            editable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            editable: true,
            valueGetter: (params) => (params.value ? 'Ativo' : 'Inativo')
        },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
                <div>
                    <IconButton
                        onClick={() => {
                            setShowEdit(true);
                            setCurrentCodificators(params.row);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setOpenDelete(true);
                            setCurrentCodificators(params.row);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <MainCard title={'Cadastro de codficadores'}>
            <AddEncoders open={showAdd} setOpen={setShowAdd} get={getCodficator} />
            <EditEnconders open={showEdit} setOpen={setShowEdit} get={getCodficator} current={currentCodificators} />
            <ConfirmDelete open={openDelete} setOpen={setOpenDelete} func={handleDelete} />
            <Grid container spacing={gridSpacing} sx={{ padding: '20px' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={() => setShowAdd(true)}>
                        Cadastrar codificador
                    </Button>
                </div>

                <div style={{ width: '100%', marginTop: '20px', overflow: 'auto' }}>
                    <DataGrid rows={codficators} columns={columns} hideFooter disableRowSelectionOnClick />
                </div>
            </Grid>
        </MainCard>
    );
};

export default Encoders;
