import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { InsertModal } from './components/InsertModal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IconCalculator, IconClockHour3, IconEdit, IconFile, IconFileAnalytics } from '@tabler/icons';
import { AvailabilityModal } from './components/AvailabilityModal';
import { FormulaModal } from './components/FormulaModal';
import { ParamsModal } from './components/PramsModal';

export function CreateMachine() {
    const api = useApi();
    const [allSectors, setAllSectors] = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [allWorkCenters, setAllWorkCenters] = useState([]);
    const [openInsert, setOpenInsert] = useState(false);
    const [openAvailability, setOpenAvailability] = useState(false);
    const [openFormula, setOpenFormula] = useState(false);
    const [openParams, setOpenParams] = useState(false);
    const [selectedEdite, setSelectedEdite] = useState([]);

    async function getAllSectors() {
        try {
            const { data } = await api.getSector();
            setAllSectors(data);
        } catch (error) {
            console.log('🚀 ~ allSectors ~ error:', error);
        }
    }

    async function getAllWorkCenters() {
        try {
            const { data } = await api.getLocalMachines();
            const rows = data.map((item) => ({
                ...item,
                id: item.id,
                descricao: item.descricao,
                setor: item.setor.descricao_setor,
                centro: item.centro_trabalho.descricao_ct
            }));
            setAllWorkCenters(rows);
        } catch (error) {
            console.log('🚀 ~ allWorkCenters ~ error:', error);
        }
    }

    useEffect(() => {
        getAllSectors();
        getAllWorkCenters();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'setor', headerName: 'Setor', flex: 1 },
        { field: 'centro', headerName: 'Centro de Trabalho', flex: 1 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        {
            field: 'action',
            headerName: 'Ação',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip title="Parâmetros">
                        <IconButton
                            onClick={() => {
                                setOpenParams(true);
                                setSelected(params.row);
                            }}
                        >
                            <IconFileAnalytics />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fórmula">
                        <IconButton
                            onClick={() => {
                                setOpenFormula(true);
                                setSelected(params.row);
                            }}
                        >
                            <IconCalculator />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Disponibilidade">
                        <IconButton
                            onClick={() => {
                                setOpenAvailability(true);
                                setSelected(params.row);
                            }}
                        >
                            <IconClockHour3 />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setSelectedEdite(params.row);
                                setOpenInsert(true);
                            }}
                        >
                            <IconEdit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                        <IconButton
                            onClick={() => {
                                setOpen(true);
                                setSelected(params.row);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    async function deleteSectorFunc() {
        const toastId = toast.loading('Deletando máquinao...');
        try {
            await api.deleteLocalMachine(selected.id);
            getAllWorkCenters();
            toast.update(toastId, {
                render: 'Máquina deletado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            setOpen(false);
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao deletar máquina',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
            console.log('🚀 ~ deleteSector ~ error:', error);
        }
    }

    return (
        <MainCard title="Cadastro de Máquinas">
            <AvailabilityModal open={openAvailability} setOpen={setOpenAvailability} currentMachine={selected} />
            <FormulaModal open={openFormula} setOpen={setOpenFormula} currentMachine={selected} />
            <ParamsModal open={openParams} setOpen={setOpenParams} currentMachine={selected} />
            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: '-55px', right: 0 }}>
                    <InsertModal
                        open={openInsert}
                        setOpen={setOpenInsert}
                        currentMachine={selectedEdite}
                        setCurrentMachine={setSelectedEdite}
                        allSectors={allSectors}
                        getAllWorkCenters={getAllWorkCenters}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em', width: '100%' }}>
                    <DataGrid columns={columns} rows={allWorkCenters} sx={{ maxHeight: 600 }} hideFooter />
                </Box>
            </Grid>

            <>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja deletar o máquina? Após deletar, não será possível recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={deleteSectorFunc}>
                            Deletar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </MainCard>
    );
}
