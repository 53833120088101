// assets
import { IconAssembly } from '@tabler/icons';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PeopleIcon from '@mui/icons-material/People';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { IconUser } from '@tabler/icons';

let OrderManager = {
    id: 'orderManager',
    title: 'Rank de vendas',
    type: 'group',
    children: [
        {
            id: 'dashboardpedidos',
            title: 'Gestão de pedidos',
            type: 'item',
            url: '/main/gestao-pedidos',
            icon: EqualizerIcon,
            breadcrumbs: false
        },
        {
            id: 'painel de vendas',
            title: 'Painel de vendas',
            type: 'item',
            url: '/main/gestao-pedidos/painel-vendas',
            icon: FormatListNumberedIcon,
            breadcrumbs: false
        }
    ]
};

export default OrderManager;
