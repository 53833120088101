import { Modal, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    bgcolor: 'transparent',
    maxHeight: '700px',
    overflow: 'auto'
};

export function ViewImage({ open, setOpen, image }) {
    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <img src={image} alt={`Imagem `} style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }} />
            </Box>
        </Modal>
    );
}
