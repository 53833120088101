import { Box, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import MainCard from 'ui-component/cards/MainCard';
import { formatacaoData } from 'utils/formatacaoData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AnalysisDrawer from './components/Analysis';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@mui/lab';
import Cards from './components/Cards';
import { useMemo } from 'react';

export function Codification() {
    const api = useApi();
    const [rows, setRows] = useState(false);
    const [load, setLoad] = useState(false);
    const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    function compareDates(a, b) {
        let partsA = a.pcp_exite_date.split('/').reverse().map(Number);
        let partsB = b.pcp_exite_date.split('/').reverse().map(Number);

        // Converta para o formato de data do JavaScript (ano, mês, dia)
        let dateA = new Date(partsA[0], partsA[1] - 1, partsA[2]);
        let dateB = new Date(partsB[0], partsB[1] - 1, partsB[2]);

        // Compare as datas
        return dateA - dateB;
    }

    async function getAllRows() {
        const currentCodificator = localStorage.getItem('name');
        const role = localStorage.getItem('role');
        setLoad(true);
        try {
            const { data } = await api.getAllDistributionWorksAssociated();

            const row = data.map((item) => ({
                ...item,
                pcp_exite_date: item?.codification_end ? formatacaoData(item?.codification_end) : 'Data não informada',
                cliente: item.distribution_work?.client,
                gepro: item.distribution_work?.gepro,
                obra: item.distribution_work?.obra,
                codificatorName: item.User?.username
            }));

            if (role === 'Codificador') {
                const filteredRow = row.filter((item) => item.codificatorName === currentCodificator);
                const sortRow = filteredRow.sort(compareDates);
                setRows(sortRow);
                setLoad(false);
                return;
            }

            if (role === 'Comercial/Engenharia') {
                const filteredRow = row.filter((item) => item.codificatorName === currentCodificator);
                const sortRow = filteredRow.sort(compareDates);
                setRows(sortRow);
                setLoad(false);
                return;
            }

            const sortRow = row.sort(compareDates);
            setRows(sortRow);
            setLoad(false);
        } catch (error) {
            setLoad(false);
            console.log('🚀 ~ getAllRows ~ error:', error);
        }
    }

    useEffect(() => {
        getAllRows();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'gepro', headerName: 'GEPRO', flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'cliente', headerName: 'Cliente', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'obra', headerName: 'Obra', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'codificatorName', headerName: 'Codificador', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'pcp_exite_date', headerName: 'Finalização programada', flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Ações',
            sortable: false,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={() => {
                            navigate(`/main/engenharia/codificacao/${params.row.id}`);
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </>
            )
        }
    ];
    const columnsFinish = [
        { field: 'id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'gepro', headerName: 'GEPRO', flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'cliente', headerName: 'Cliente', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'obra', headerName: 'Obra', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'codificatorName', headerName: 'Codificador', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'pcp_exite_date', headerName: 'Finalização programada', flex: 1, align: 'center', headerAlign: 'center' }
    ];

    const activeCount = useMemo(() => {
        if (!Array.isArray(rows)) return 0;

        const uniqueGepro = new Set(
            rows
                .filter(
                    (row) =>
                        row?.status !== 'Concluída' &&
                        row?.distribution_work?.in_hold === false &&
                        row?.distribution_work?.aprovacao === false
                )
                .map((row) => row.gepro)
        );

        return uniqueGepro.size;
    }, [rows]);

    const awaitingApprovalCount = useMemo(() => {
        if (!Array.isArray(rows)) return 0;

        const uniqueGepro = new Set(rows.filter((row) => row?.distribution_work?.aprovacao === true).map((row) => row.gepro));

        return uniqueGepro.size;
    }, [rows]);

    const completedCount = useMemo(() => {
        if (!Array.isArray(rows)) return 0;

        const uniqueGepro = new Set(rows.filter((row) => row?.status === 'Concluída').map((row) => row.gepro));

        return uniqueGepro.size;
    }, [rows]);

    const inHoldCount = useMemo(() => {
        if (!Array.isArray(rows)) return 0;

        const uniqueGepro = new Set(
            rows.filter((row) => row?.distribution_work?.in_hold === true && row.status !== 'Concluída').map((row) => row.gepro)
        );

        return uniqueGepro.size;
    }, [rows]);

    return (
        <MainCard title={'Codificação'}>
            <Grid container spacing={gridSpacing} sx={{ padding: '20px', position: 'relative' }}>
                {load && <ModalLoadAnimated open={load} />}
                <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', position: 'absolute', right: 0, top: '-65px' }}>
                    <Cards name="Ativas" value={activeCount} />
                    <Cards name="Aguardando aprov." value={awaitingApprovalCount} />
                    <Cards name="Finalizadas" value={completedCount} />
                    <Cards name="In Hold" value={inHoldCount} />
                </Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Ativas" value="1" />
                            <Tab label="Aguardando aprovação" value="2" />
                            <Tab label="Finalizada" value="3" />
                            <Tab label="In Hold" value="4" />
                        </Tabs>
                    </Box>

                    <TabPanel value="1" sx={{ width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            sx={{ maxHeight: 'calc(100vh - 370px)' }}
                            rows={
                                Array.isArray(rows)
                                    ? rows
                                          .filter(
                                              (row) =>
                                                  row?.status !== 'Concluída' &&
                                                  row?.distribution_work?.in_hold === false &&
                                                  row?.distribution_work?.aprovacao === false
                                          )
                                          .sort(compareDates)
                                    : []
                            }
                        />
                    </TabPanel>
                    <TabPanel value="2" sx={{ width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            sx={{ maxHeight: 'calc(100vh - 370px)' }}
                            rows={
                                Array.isArray(rows)
                                    ? rows
                                          .filter((row) => row?.distribution_work?.aprovacao === true && row.status !== 'Concluída')
                                          .sort(compareDates)
                                    : []
                            }
                        />
                    </TabPanel>
                    <TabPanel value="3" sx={{ width: '100%' }}>
                        <DataGrid
                            columns={columnsFinish}
                            sx={{ maxHeight: 'calc(100vh - 370px)' }}
                            rows={Array.isArray(rows) ? rows.filter((row) => row?.status === 'Concluída').sort(compareDates) : []}
                        />
                    </TabPanel>
                    <TabPanel value="4" sx={{ width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            sx={{ maxHeight: 'calc(100vh - 370px)' }}
                            rows={
                                Array.isArray(rows)
                                    ? rows
                                          .filter((row) => row?.distribution_work?.in_hold === true && row.status !== 'Concluída')
                                          .sort(compareDates)
                                    : []
                            }
                        />
                    </TabPanel>
                </TabContext>
            </Grid>
        </MainCard>
    );
}
