import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IconArrowRight } from '@tabler/icons';
import { Divider } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useDemandsContext } from 'context/DemandsContext';
import formater from 'utils/formatDate';

export function ModalNextPage({ id }) {
    const api = useApi();
    const [loading, setLoading] = useState(false); // Estado para exibir o carregamento
    const [clickedItems, setClickedItems] = useState([]); // Controla os itens clicados
    const { setDemandsSelected } = useDemandsContext(); // Obtenção do contexto para salvar demandas

    async function loadAndSaveDemands() {
        setLoading(true); // Inicia o estado de carregamento

        try {
            // Carrega as demandas
            const { data } = await api.getByOrdStAlternativo({ ordStAlternativo: id });

            // Formata os dados das demandas
            const formattedDemands = data.map((item) => ({
                ...item,
                CEL_DT_PROGRAMACAO: formater(item.CEL_DT_PROGRAMACAO)
            }));

            // Adiciona as novas demandas ao contexto (sem substituir, apenas concatenando)
            setDemandsSelected((prev) => [...prev, ...formattedDemands]);

            // Atualiza o estado de itens clicados
            setClickedItems((prev) => [...prev, id]);
        } catch (error) {
            console.error('Erro ao carregar demandas:', error);
        } finally {
            setLoading(false); // Finaliza o estado de carregamento
        }
    }

    return (
        <div>
            {/* Botão para carregar e salvar as demandas no contexto, desabilita se o item já foi clicado */}
            <Button
                variant="outlined"
                onClick={loadAndSaveDemands}
                disabled={loading || clickedItems.includes(id)} // Desabilita se já foi clicado ou se está carregando
            >
                {loading ? <CircularProgress size={24} /> : <IconArrowRight />}
            </Button>
        </div>
    );
}
