import { Button, Grid, Tab } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ActionButtonsCollect from './Components/ActionButtonsCollect';
import FadeMenu from './Components/Menu';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import formater from 'utils/formatDate';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FadeMenuLoose from '../newPages/Concierge/components/MenuLoose';

const Collect = () => {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [looseTransportRows, setLooseTransportRows] = useState([]);
    const api = useApi();
    const columns = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'pedido', headerName: 'PDV', flex: 0.5 },
        { field: 'cliente', headerName: 'Cliente', flex: 2 },
        { field: 'obra', headerName: 'Obra', flex: 2 },
        { field: 'confirm', headerName: 'Confirmação', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 0.6 },
        {
            field: 'acoes',
            headerName: 'Ações',
            width: 70,
            sortable: false,
            renderCell: (params) => <FadeMenu rows={params.row} get={getCollectRequest} />
        }
    ];
    const columnsLoose = [
        { field: 'cod', headerName: 'Código', flex: 0.5, valueGetter: (params) => `A ${params.row.cod}` },
        { field: 'pedido', headerName: 'PDV', flex: 0.5 },
        { field: 'cliente', headerName: 'Cliente', flex: 2 },
        { field: 'driver', headerName: 'Motorista', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 0.6 },
        {
            field: 'acoes',
            headerName: 'Ações',
            width: 70,
            sortable: false,
            renderCell: (params) => <FadeMenuLoose rows={params.row} get={getCollectRequest} setValueTab={setValueTab} />
        }
    ];

    const getCollectRequest = async () => {
        try {
            const { data } = await api.getCollectRequest();
            const rows = data.solicitacao_coleta.map((item) => ({
                ...item,
                id: item.id,
                pedido: item.pedido,
                cliente: item.cliente,
                obra: item.obra,
                confirm: item?.confirmacao_solicitacao_coletum
                    ? formater(item?.confirmacao_solicitacao_coletum?.confirmacao)
                    : 'Não Confirmado',
                status: item.status,
                cod: item.expedicao_id
            }));
            console.log('🚀 ~ file: index.js:66 ~ rows ~ rows:', rows);

            const rowsOrder = rows.sort((a, b) => b.id - a.id);

            setRows(rowsOrder);

            const rowsLooseTransport = data.RegistroManual.map((item) => ({
                ...item,
                id: item.id,
                pedido: item?.LiberacaoTransporteAvulso?.pedido,
                cliente: item.cliente,
                obra: item.obra,
                confirm: item?.confirwmacao_solicitacao_coletum
                    ? formater(item?.confirmacao_solicitacao_coletum?.confirmacao)
                    : 'Não Confirmado',
                status: item.status,
                cod: item.id,
                driver: item?.motorista
            }));
            const rowsLooseTransportDecrescente = rowsLooseTransport.sort((a, b) => b.cod - a.cod);
            setLooseTransportRows(rowsLooseTransportDecrescente);
        } catch (error) {
            console.log('🚀 ~ file: index.js:29 ~ getCollectRequest ~ error:', error);
        }
    };

    useEffect(() => {
        getCollectRequest();
    }, []);

    //itens do TAB
    const [valueTab, setValueTab] = useState('1');

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <MainCard title="Agendamento de coletas">
            <Grid container spacing={gridSpacing}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                    <Button variant="contained" onClick={() => navigate('/main/expedicao/transporte-avulso')}>
                        Registro manual
                    </Button>
                </div>

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={valueTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Agendados" value="1" />
                                <Tab label="Avulso" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div style={{ width: '100%', height: '600px' }}>
                                <DataGrid
                                    rows={row}
                                    columns={columns}
                                    checkboxSelection={false}
                                    hideFooter
                                    sx={{ maxWidth: '1200px', margin: '0 auto', maxHeight: '500px' }}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value="2">
                            <div style={{ width: '100%', padding: '1rem', height: '600px' }}>
                                <DataGrid
                                    rows={looseTransportRows}
                                    columns={columnsLoose}
                                    checkboxSelection={false}
                                    hideFooter
                                    sx={{ maxWidth: '1200px', margin: '0 auto', maxHeight: '500px' }}
                                />
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default Collect;
