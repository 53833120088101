import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflow: 'auto',
    height: 'auto'
};

export function AvailabilityModal({ open, setOpen, currentMachine }) {
    const handleClose = () => {
        setOpen(false);
        setWeek([
            { day: 'Segunda', start: '', pause: '', return: '', end: '' },
            { day: 'Terça', start: '', pause: '', return: '', end: '' },
            { day: 'Quarta', start: '', pause: '', return: '', end: '' },
            { day: 'Quinta', start: '', pause: '', return: '', end: '' },
            { day: 'Sexta', start: '', pause: '', return: '', end: '' }
            // { day: 'Sábado', start: '', pause: '', return: '', end: '' },
            // { day: 'Domingo', start: '', pause: '', return: '', end: '' }
        ]);
        setIsEdit(false);
        setAllowCopies(false);
    };
    const [isEdit, setIsEdit] = useState(false);
    const [allowCopies, setAllowCopies] = useState(false);

    const api = useApi();

    const [week, setWeek] = useState([
        { day: 'Segunda', start: '', pause: '', return: '', end: '' },
        { day: 'Terça', start: '', pause: '', return: '', end: '' },
        { day: 'Quarta', start: '', pause: '', return: '', end: '' },
        { day: 'Quinta', start: '', pause: '', return: '', end: '' },
        { day: 'Sexta', start: '', pause: '', return: '', end: '' }
        // { day: 'Sábado', start: '', pause: '', return: '', end: '' },
        // { day: 'Domingo', start: '', pause: '', return: '', end: '' }
    ]);

    function handleChange(e, index, type) {
        const newWeek = [...week];
        newWeek[index][type] = e.target.value;
        setWeek(newWeek);
    }

    async function handleSubmit() {
        const payload = {
            disponibilidade: week.map((day) => ({
                ...day,
                HORA_INICIO: day.start,
                HORA_PAUSA: day.pause,
                HORA_RETORNO: day.return,
                HORA_FIM: day.end
            }))
        };
        const toastId = toast.loading('Atualizando disponibilidade...');
        try {
            if (isEdit) {
                await api.updateMachineAvailability(currentMachine?.id, payload);
                toast.update(toastId, {
                    render: 'Disponibilidade atualizada com sucesso',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                await api.createMachineAvailability(currentMachine?.id, payload);
                toast.update(toastId, {
                    render: 'Disponibilidade adicionada com sucesso',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                });
            }
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.update(toastId, {
                render: 'Falha ao atualizar disponibilidade',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function getOneMachineAvailability() {
        try {
            const { data } = await api.getMachineAvailability(currentMachine?.id);
            const weekResponse = data.data;
            if (weekResponse.length > 0) {
                setWeek(
                    weekResponse.map((day) => ({
                        ...day,
                        day: day.dia_semana,
                        start: day.hora_inicio,
                        pause: day.hora_pausa,
                        return: day.hora_retorno,
                        end: day.hora_fim
                    }))
                );
                setIsEdit(true);
            }
        } catch (error) {
            console.log('🚀 ~ getOneMachineAvailability ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) {
            getOneMachineAvailability();
        }
    }, [currentMachine, open]);

    const handleCopyToOthers = () => {
        setWeek((prevWeek) => {
            const [firstDay, ...restOfTheDays] = prevWeek;
            const updatedWeek = restOfTheDays.map((day) => ({
                ...day,
                start: firstDay.start,
                pause: firstDay.pause,
                return: firstDay.return,
                end: firstDay.end
            }));
            return [firstDay, ...updatedWeek];
        });
    };

    useEffect(() => {
        week[0]['end'] !== '' && setAllowCopies(true);
    }, [week]);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box>
                        <h3 style={{ textAlign: 'center' }}>Disponibilidade - {currentMachine?.descricao}</h3>
                        <Divider />
                    </Box>

                    <Box sx={{ position: 'relative' }}>
                        {allowCopies && isEdit === false && (
                            <Button
                                variant="contained"
                                sx={{ position: 'absolute', top: '-55px', right: '10px' }}
                                onClick={handleCopyToOthers}
                            >
                                Copiar para outros dias
                            </Button>
                        )}

                        {week.map((day, index) => (
                            <div key={index}>
                                <h4 style={{ margin: 5 }}>{day.day}</h4>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        InputLabelProps={{ shrink: 'true' }}
                                        type="time"
                                        label="Início"
                                        variant="outlined"
                                        fullWidth
                                        value={day.start}
                                        onChange={(e) => handleChange(e, index, 'start')}
                                    />
                                    <TextField
                                        InputLabelProps={{ shrink: 'true' }}
                                        type="time"
                                        label="Pausa"
                                        variant="outlined"
                                        fullWidth
                                        value={day.pause}
                                        onChange={(e) => handleChange(e, index, 'pause')}
                                    />
                                    <TextField
                                        InputLabelProps={{ shrink: 'true' }}
                                        type="time"
                                        label="Retorno"
                                        variant="outlined"
                                        fullWidth
                                        value={day.return}
                                        onChange={(e) => handleChange(e, index, 'return')}
                                    />
                                    <TextField
                                        InputLabelProps={{ shrink: 'true' }}
                                        type="time"
                                        label="Fim"
                                        variant="outlined"
                                        fullWidth
                                        value={day.end}
                                        onChange={(e) => handleChange(e, index, 'end')}
                                    />
                                </Box>
                            </div>
                        ))}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant="contained" sx={{ width: '350px' }} onClick={handleSubmit}>
                                {isEdit ? 'Atualizar Disponibilidade' : 'Adicionar Disponibilidade'}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
