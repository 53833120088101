import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { InsertModal } from './components/InsertModal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

export function SectionRegistration() {
    const api = useApi();
    const [allSectors, setAllSectors] = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);

    async function getAllSectors() {
        try {
            const { data } = await api.getSector();
            setAllSectors(data);
        } catch (error) {
            console.log('🚀 ~ allSectors ~ error:', error);
        }
    }

    useEffect(() => {
        getAllSectors();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'id_mega', headerName: 'ID_MEGA', flex: 1 },
        { field: 'descricao_setor', headerName: 'DESCRIÇÃO', flex: 1 },
        {
            field: 'action',
            headerName: 'Ação',
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title="Deletar">
                    <IconButton
                        onClick={() => {
                            setOpen(true);
                            setSelected(params.row);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    async function deleteSectorFunc() {
        const toastId = toast.loading('Deletando setor...');
        try {
            await api.deleteSector(selected.id);
            getAllSectors();
            toast.update(toastId, {
                render: 'Setor deletado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            setOpen(false);
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao deletar setor',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
            console.log('🚀 ~ deleteSector ~ error:', error);
        }
    }

    return (
        <MainCard title="Cadastro de Setor">
            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: '-55px', right: 0 }}>
                    <InsertModal getAllSectors={getAllSectors} />
                </Box>
                {allSectors.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em', width: '100%' }}>
                        <DataGrid columns={columns} rows={allSectors} sx={{ maxHeight: 600 }} hideFooter />
                    </Box>
                )}
            </Grid>

            <>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja deletar o setor? Após deletar, não será possível recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={deleteSectorFunc}>
                            Deletar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </MainCard>
    );
}
