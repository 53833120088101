import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ReplayIcon from '@mui/icons-material/Replay';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { ObservationModal } from './ObservationModal';
import { useState } from 'react';
import { EventModal } from './EventModal';
import { ObservationModalStop } from './ObservationModalStop';

export default function Player({ codificator, codificationEvents, get }) {
    const api = useApi();
    const { id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [observation, setObservation] = useState('');
    const [loadPause, setLoadPause] = useState(false);
    const [openObservation, setOpenObservation] = useState(false);
    const [activity, setActivity] = useState('');
    const [startLoad, setStartLoad] = useState(false);
    const [loadStop, setLoadStop] = useState(false);
    const [openObservationStop, setOpenObservationStop] = useState(false);

    const lastCodificationEvent = useMemo(() => {
        if (codificationEvents?.length > 0) {
            return codificationEvents[codificationEvents?.length - 1];
        } else {
            return null;
        }
    }, [codificationEvents]);

    async function start() {
        setStartLoad(true);
        const payload = {
            activityType: activity
        };
        try {
            await api.startCodification(id, payload);
            get();
            toast.success('Apontamento iniciado com sucesso');
            setOpenModal(false);
            setStartLoad(false);
        } catch (error) {
            console.log('🚀 ~ start ~ error:', error);
            setStartLoad(false);
        }
    }

    async function stop() {
        setLoadStop(true);
        const payload = {
            activityType: lastCodificationEvent.activityType,
            observation: observation
        };
        try {
            await api.finishCodification(id, payload);
            get();
            setLoadStop(false);
            toast.success('Apontamento finalizado com sucesso');
        } catch (error) {
            setLoadStop(false);
            console.log('🚀 ~ start ~ error:', error);
        }
    }

    async function pause() {
        setLoadPause(true);
        if (observation === '') return toast.error('Adicione uma observação');
        const payload = {
            observation: observation,
            activityType: lastCodificationEvent.activityType
        };

        try {
            await api.pauseCodification(id, payload);
            get();
            toast.success('Apontamento finalizado com sucesso');
            setLoadPause(false);
        } catch (error) {
            console.log('🚀 ~ start ~ error:', error);
            setLoadPause(false);
        }
    }

    async function reStart() {
        const payload = {
            activityType: lastCodificationEvent.activityType
        };
        try {
            await api.returnCodification(id, payload);
            get();
            toast.success('Apontamento retomado com sucesso');
        } catch (error) {
            console.log('🚀 ~ start ~ error:', error);
        }
    }

    return (
        <Card sx={{ display: 'flex', boxShadow: '1px 1px 8px #999', width: '450px', marginTop: '30px' }}>
            <EventModal open={openModal} setOpen={setOpenModal} startFunction={start} setActivity={setActivity} startLoad={startLoad} />
            <ObservationModal
                loadPause={loadPause}
                open={openObservation}
                setOpen={setOpenObservation}
                pauseFunction={pause}
                observation={setObservation}
            />
            <ObservationModalStop
                loadPause={loadStop}
                open={openObservationStop}
                setOpen={setOpenObservationStop}
                stopFunction={stop}
                observation={setObservation}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        Progresso do Apontamento:
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        <b> {codificator}</b>
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, gap: '10px' }}>
                    <IconButton
                        aria-label="pause"
                        sx={{ boxShadow: '1px 1px 8px #999' }}
                        title="Pausar"
                        onClick={() => setOpenObservation(true)}
                        disabled={
                            lastCodificationEvent?.activityStatus === false ||
                            (lastCodificationEvent &&
                                (lastCodificationEvent?.eventType === 'finalizado' || lastCodificationEvent?.eventType === 'pausado')) ||
                            !codificationEvents?.length
                        }
                    >
                        <PauseIcon />
                    </IconButton>
                    <IconButton
                        aria-label="play"
                        sx={{ boxShadow: '1px 1px 8px #999' }}
                        title="Iniciar"
                        disabled={lastCodificationEvent?.activityStatus}
                        onClick={() => setOpenModal(true)}
                    >
                        <PlayArrowIcon sx={{ fontSize: '40px' }} />
                    </IconButton>
                    {lastCodificationEvent?.eventType === 'finalizado' || lastCodificationEvent?.eventType === 'pausado' ? (
                        <IconButton aria-label="Retomar" sx={{ boxShadow: '1px 1px 8px #999' }} title="Pausar" onClick={reStart}>
                            <ReplayIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            aria-label="stop"
                            sx={{ boxShadow: '1px 1px 8px #999' }}
                            title="Parar"
                            onClick={() => setOpenObservationStop(true)}
                            disabled={!lastCodificationEvent || lastCodificationEvent?.activityStatus === false}
                        >
                            <StopIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 201, objectFit: 'contain', padding: '10px' }}
                image="https://www.refrin.com.br/static/img/logo4.png"
                alt="Live from space album cover"
            />
        </Card>
    );
}
