import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Pcp() {
    return (
        <MainCard title="PCP">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <Typography variant="h5">Página PCP</Typography>
                </Box>
            </Grid>
        </MainCard>
    );
}

export default Pcp;
