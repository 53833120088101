import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

const ConfirmDelete = ({ open, setOpen, func }) => {
    const handleCloseDelete = () => setOpen(false);
    return (
        <Dialog
            open={open}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title-delete"
            aria-describedby="alert-dialog-description-delete"
        >
            <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        func();
                        handleCloseDelete();
                    }}
                >
                    Sim
                </Button>
                <Button onClick={handleCloseDelete}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDelete;
