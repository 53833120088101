import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { FinishModal } from './components/FinishModal';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { DateAndHour } from 'utils/DateAndHour';
const InProgress = () => {
    const api = useApi();
    const [row, setRow] = useState([]);
    const [openFinishModal, setOpenFinishModal] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const getRequests = async () => {
        try {
            const { data } = await api.getInProgressExpedition();
            console.log('🚀 ~ getRequests ~ data:', data);
            const row = data.expedicoes.map((item) => ({
                ...item,
                client: item?.cliente,
                construction: item?.obra,
                id: `E ${item?.id}`,
                type: 'expedicao'
            }));
            const rowAvulsos = data.registros.map((item) => ({
                ...item,
                client: item?.cliente,
                construction: item?.obra,
                id: `A ${item?.id}`,
                pedidoAvulso: item?.LiberacaoTransporteAvulso.pedido,
                type: 'avulso',
                idWithoutString: item?.id
            }));
            const combineArrays = [...row, ...rowAvulsos];
            setRow(combineArrays);
        } catch (error) {
            console.log('🚀 ~ getRequests ~ error:', error);
        }
    };

    useEffect(() => {
        getRequests();
    }, []);

    //codgo para o delete
    const [openDelete, setOpenDelete] = useState(false);
    const [openDeleteAV, setOpenDeleteAV] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);

    const deleteScheduledExpedition = async () => {
        setLoadingDeleteModal(true);
        try {
            await api.deleteConciergeExpedition(idDelete);
            setOpenDelete(false);
            getRequests();
            toast.success('Expedição excluida com sucesso');
            setLoadingDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ deleteRequestExpedition ~ error:', error);
            setLoadingDeleteModal(false);
        }
    };
    const deleteAV = async () => {
        setLoadingDeleteModal(true);
        try {
            await api.deleteAvProgress(idDelete);
            setOpenDeleteAV(false);
            getRequests();
            toast.success('Expedição excluida com sucesso');
            setLoadingDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ deleteRequestExpedition ~ error:', error);
            setLoadingDeleteModal(false);
        }
    };

    return (
        <MainCard title="Em carregamento">
            <ConfirmDeleteModal
                open={openDelete}
                setOpen={setOpenDelete}
                id={idDelete}
                message={'Deseja mesmo excluir essa expedição em carregamento ?'}
                func={deleteScheduledExpedition}
                loading={loadingDeleteModal}
            />
            <ConfirmDeleteModal
                open={openDeleteAV}
                setOpen={setOpenDeleteAV}
                id={idDelete}
                message={'Deseja mesmo excluir essa expedição em carregamento ?'}
                func={deleteAV}
                loading={loadingDeleteModal}
            />
            <FinishModal open={openFinishModal} setOpen={setOpenFinishModal} currentRow={currentRow} getRequests={getRequests} />
            {row.length === 0 ? (
                <p>Não há registros</p>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="center">Cliente</TableCell>
                                <TableCell align="center">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.map((item) => (
                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {item.id}
                                    </TableCell>
                                    <TableCell align="center">{item.client}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Liberar transporte">
                                            <IconButton
                                                onClick={() => {
                                                    setCurrentRow(item);
                                                    setOpenFinishModal(true);
                                                }}
                                            >
                                                <QueryBuilderIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Excluir"
                                            onClick={() => {
                                                if (item.id[0] === 'E') {
                                                    setIdDelete(item?.Registro_transporte?.Liberacao_transporte?.id);
                                                    setOpenDelete(true);
                                                }

                                                if (item.id[0] === 'A') {
                                                    setIdDelete(item?.LiberacaoTransporteAvulso?.id);
                                                    setOpenDeleteAV(true);
                                                }
                                            }}
                                        >
                                            <IconButton>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </MainCard>
    );
};

export default InProgress;
