export function formatValuebyHours(value) {
    // Verifique se a entrada é um número
    if (typeof value === 'number') {
        const hours = Math.floor(value);
        const minutes = Math.round((value - hours) * 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }
    // Retorna o valor original se não for um número
    return value;
}

export function formatarTempo(segundosTotais) {
    const horas = Math.floor(segundosTotais / 3600); // Calcula horas inteiras
    const minutos = Math.floor((segundosTotais % 3600) / 60); // Calcula os minutos restantes
    const segundos = segundosTotais % 60; // Calcula os segundos restantes

    return `${horas}h ${minutos}min ${segundos}s`;
}
