import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import LooseTransportModal from '../../../collect/Components/LooseTransportModal';
import { useApi } from 'Service/axios';
import ConfirmDelete from 'views/Engenharia/Encoders/components/confirmDelete';
import { toast } from 'react-toastify';

export default function FadeMenuLoose({ rows, get, setValueTab }) {
    console.log('🚀 ~ FadeMenuLoose ~ rows:', rows);
    const api = useApi();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openReleaseOfTransport, setOpenReleaseOfTransport] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const close = async (id) => {
        try {
            await api.releaseClosureAV(id);
            setAnchorEl(null);
            get();
        } catch (error) {
            console.log('🚀 ~ file: MenuLoose.js:27 ~ close ~ error:', error);
        }
    };

    const handleDeleteAv = async (id) => {
        const idNumber = id.replace(/\D/g, '');
        try {
            await api.deleteAvConcierge(idNumber);
            get();
            toast.success('Transporte avulso deletada com sucesso');
        } catch (error) {
            console.log('🚀 ~ handleDeleteAv ~ error:', error);
        }
    };

    return (
        <div>
            {rows.status !== 'Finalizada' && (
                <>
                    <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ color: '#181818' }}
                    >
                        <MoreVertIcon />
                    </Button>
                    <LooseTransportModal
                        open={openReleaseOfTransport}
                        setOpen={setOpenReleaseOfTransport}
                        dataModal={rows}
                        get={get}
                        setValueTab={setValueTab}
                    />

                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button'
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        {rows.status === 'Portaria' && (
                            <>
                                <MenuItem onClick={() => setOpenReleaseOfTransport(true)}>Liberação do Transp.</MenuItem>
                                <MenuItem onClick={() => setOpenDelete(true)}>Delete</MenuItem>

                                <ConfirmDelete open={openDelete} setOpen={setOpenDelete} func={() => handleDeleteAv(rows.id)} />
                            </>
                        )}
                        {rows.status === 'Em carregamento' && <MenuItem onClick={() => close(rows.id)}>Encerrar</MenuItem>}
                    </Menu>
                </>
            )}
        </div>
    );
}
