import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Badge from '@mui/material/Badge';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

export function ModalEmailsCC({ list, setList }) {
    const [currentEmail, setCurrentEmail] = useState('');

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function handleAddEmail() {
        if (currentEmail.length === 0) return toast.warn('Adicione um e-mail ao campo');
        setList([...list, currentEmail]);
        setCurrentEmail('');
    }

    function handleSliceEmail(email) {
        const newEmails = list.filter((item) => item !== email);
        setList(newEmails);
    }

    return (
        <div>
            <Badge badgeContent={list?.length} color="error">
                <Button onClick={handleOpen} variant="contained">
                    Cc:
                </Button>
            </Badge>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3>Adicionar Copias</h3>
                    <form>
                        <FormControl>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <TextField
                                    sx={{ width: '350px' }}
                                    label="E-mail"
                                    id="email-id"
                                    name="email"
                                    value={currentEmail}
                                    onChange={(e) => setCurrentEmail(e.target.value)}
                                />
                                <Button variant="contained" sx={{ fontSize: '20px' }} onClick={handleAddEmail}>
                                    +
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                    <Box sx={{ marginTop: '8px' }}>
                        {list?.map((item) => (
                            <Box
                                key={item}
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '9px',
                                    marginBottom: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography>{item}</Typography>
                                <Button variant="contained" color="error" onClick={() => handleSliceEmail(item)}>
                                    -
                                </Button>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button variant="contained" onClick={handleClose} sx={{ width: '200px' }}>
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
