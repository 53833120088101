import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export function ConfirmDeleteModal({ open, setOpen, id, message, func, loading }) {
    console.log('🚀 ~ ConfirmDeleteModal ~ id:', id);
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
                Excluir Expedição
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} variant="contained" color="error">
                    Cancelar
                </Button>
                <Button onClick={func} variant="contained" disabled={loading}>
                    {loading ? 'Carregando ...' : 'Deletar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
