import {
    Button,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { converterData } from 'utils/convertData';
import { dateForInput } from 'utils/dateForInpute';
import formater from 'utils/formatDate';
import { useApi } from 'Service/axios';
import { formatValuebyHours } from 'utils/formatValueByHour';
import { formatarHorario } from 'utils/FormatarHoraParaPonto';
import { useNavigate } from 'react-router';

const AnalysisData = ({ row, get }) => {
    const [fields, setFields] = useState({});
    const api = useApi();
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();
    //update

    const getFields = () => {
        const fields = {
            descriptionCoding: row?.description,
            codficator: row?.User.username,
            pcp_enter: row?.pcp_enter || '',
            pcp_exit: row?.pcp_exit || '',
            codification_start: row?.codification_start || '',
            codification_end: row?.codification_end || '',
            vulcan_prog: row?.vulcan_prog || '',
            cut: row?.vulcan_cut || '',
            production_fab: row?.production_fab || '',
            sawmill: row?.production_spiro || '',
            painting: row?.production_painting || '',
            production_leaving: row?.production_leaving || '',
            pcpObs: row?.DescriptionWorks[0]?.description || '',
            expeditionObs: row?.DescriptionWorks[1]?.description || '',
            codingObs: row?.DescriptionWorks[2]?.description || '',
            type: '',

            expedition: row?.expedition || '',
            levels: row?.level || '',
            hours: formatValuebyHours(row?.estimated_hours) || '',
            serralheria: row?.production_serralheria || '',
            isolamento: row?.production_isolamento || '',
            solda: row?.production_solda || '',
            pinturaEnvio: row?.paint_envio || '',
            pinturaRetorno: row?.paint_retorno || '',
            finalizacao: row?.finalizacao,
            reprogramming: row?.reprogramming,
            paintType: row?.paint_type,
            packing: row?.packing
        };
        setFields(fields);
    };

    useEffect(() => {
        getFields();
    }, [row]);

    const updateFields = (field, value) => {
        setFields({ ...fields, [field]: value });
    };

    async function handleChangeSwitch() {
        try {
            await api.alterInHold(row.distribution_work.id, !row.distribution_work.in_hold);
            get();
            toast.success('Alteração realizada com sucesso');
        } catch (error) {
            console.log('🚀 ~ handleChangeSwitch ~ error:', error);
        }
    }
    async function handleChangeApproval() {
        try {
            await api.alterApproval(row.distribution_work.id, !row.distribution_work.aprovacao);
            get();
            toast.success('Alteração realizada com sucesso');
        } catch (error) {
            console.log('🚀 ~ handleChangeSwitch ~ error:', error);
        }
    }

    async function finish() {
        const toastId = toast.loading('Finalizando codificação');
        setLoad(true);
        try {
            await api.finishCodigication(row.id);
            navigate(-1);
            toast.update(toastId, { render: 'Alteração realizada com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
            toast.success('Codificação finalizada com sucesso');
            setLoad(false);
        } catch (error) {
            console.log('🚀 ~ finish ~ error:', error);
            setLoad(false);
            toast.update(toastId, { render: 'Erro ao finalizar codificação', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    return (
        <>
            {row && (
                <>
                    <div style={{ width: '700px', padding: '20px 30px' }}>
                        <form>
                            <h3>Programação</h3>
                            <div style={{ marginBottom: '10px', width: '680px' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}>
                                    <div>
                                        <h3>Cliente: {row?.distribution_work?.client}</h3>
                                        <h3>Obra: {row?.distribution_work?.obra}</h3>
                                        <h3>Gepro: {row?.distribution_work?.gepro}</h3>
                                        <h3>Observações</h3>
                                        <p>
                                            Descrição : <span style={{ color: '#ff0000' }}>{fields?.descriptionCoding}</span>
                                        </p>
                                        <p>PCP: {fields?.pcpObs}</p>
                                        <p>Expedição: {fields?.expeditionObs}</p>
                                        <p>Codificação: {fields?.codingObs}</p>
                                    </div>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '300px' }}
                                        onClick={finish}
                                        disabled={row?.status === 'Concluída'}
                                    >
                                        Finalizar Codificação
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControl fullWidth variant="outlined">
                                        <FormControlLabel
                                            label="In Hold"
                                            sx={{
                                                width: '160px'
                                            }}
                                            variant="outlined"
                                            checked={row.distribution_work?.in_hold}
                                            control={
                                                <Switch
                                                    sx={{ marginLeft: '20px' }}
                                                    onChange={handleChangeSwitch}
                                                    size="medium"
                                                    name="inHold"
                                                    disabled={row?.status === 'Concluída'}
                                                />
                                            }
                                        />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined">
                                        <FormControlLabel
                                            label="Aguardando aprovação"
                                            variant="outlined"
                                            checked={row.distribution_work?.aprovacao}
                                            control={
                                                <Switch
                                                    sx={{ marginLeft: '20px' }}
                                                    onChange={handleChangeApproval}
                                                    size="medium"
                                                    name="inHold"
                                                    disabled={row?.status === 'Concluída'}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <>
                                <div>
                                    <span
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            display: 'block',
                                            fontWeight: 'bold',
                                            fontSize: '15px'
                                        }}
                                    >
                                        Informações
                                    </span>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <FormControl>
                                            <TextField
                                                sx={{
                                                    width: '150px'
                                                    // '& input:disabled': {
                                                    //     color: '#000',
                                                    //     fontWeight: 'bold',
                                                    //     backgroundColor: '#fff'
                                                    // },
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                value={fields?.codficator}
                                                label="Codificador"
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                name="levels"
                                                sx={{
                                                    width: '150px',
                                                    '& input:disabled': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontWeight: 'bold' // Define a cor do texto quando desativado
                                                    }
                                                }}
                                                // disabled
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                label="Nível"
                                                type="text"
                                                value={fields.levels || ''}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                sx={{
                                                    width: '150px',
                                                    '& input:disabled': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontWeight: 'bold' // Define a cor do texto quando desativado
                                                    },
                                                    marginLeft: '15px',
                                                    marginRight: '15px'
                                                }}
                                                // disabled
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                label="Horas Estimadas"
                                                type="time"
                                                value={fields.hours || ''}
                                                defaultValue={fields.hours || ''}
                                                // onChange={(e) => updateFields('hours', e.target.value)}
                                            />
                                        </FormControl>{' '}
                                    </div>

                                    <span
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            display: 'block',
                                            fontWeight: 'bold',
                                            fontSize: '15px'
                                        }}
                                    >
                                        PCP
                                    </span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '10px',
                                            gap: '20px'
                                        }}
                                    >
                                        <FormControl>
                                            <TextField
                                                sx={{
                                                    width: '150px',
                                                    '& input:disabled': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontWeight: 'bold' // Define a cor do texto quando desativado
                                                    }
                                                }}
                                                // disabled
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                label="Entrada"
                                                type="date"
                                                name="pcp_enter"
                                                // onChange={(e) => updateFields('pcp_enter', e.target.value)}
                                                value={dateForInput(fields.pcp_enter)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{
                                                    width: '150px',
                                                    '& input:disabled': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontWeight: 'bold' // Define a cor do texto quando desativado
                                                    }
                                                }}
                                                // disabled
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                label="Entrega"
                                                type="date"
                                                // onChange={(e) => updateFields('pcp_exit', e.target.value)}
                                                name="pcp_exit"
                                                value={dateForInput(fields.pcp_exit)}
                                            />
                                        </FormControl>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px',
                                            width: '100%'
                                        }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px'
                                                }}
                                            >
                                                Codificação
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Início"
                                                        type="date"
                                                        name="codification_start"
                                                        value={dateForInput(fields.codification_start)}
                                                        // onChange={(e) => updateFields('codification_start', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Entrega"
                                                        type="date"
                                                        name="codification_start"
                                                        value={dateForInput(fields.codification_end)}
                                                        // onChange={(e) => updateFields('codification_end', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px'
                                                }}
                                            >
                                                Vulcan
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Programação"
                                                        type="date"
                                                        name="vulcan_prog"
                                                        value={dateForInput(fields?.vulcan_prog)}
                                                        // onChange={(e) => updateFields('vulcan_prog', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Corte"
                                                        type="date"
                                                        name="cut"
                                                        value={dateForInput(fields.cut)}
                                                        // onChange={(e) => updateFields('cut', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Produção</span>
                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Setor (TDC/Giro/Esp.)</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                gap: '20px',
                                                marginTop: '10px',
                                                gap: '20px',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Finalização de fabricação"
                                                        type="date"
                                                        name="manufacture"
                                                        value={dateForInput(fields.production_fab)}
                                                        // onChange={(e) => updateFields( 'manufacture', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Spiro"
                                                        type="date"
                                                        name="sawmill"
                                                        value={dateForInput(fields.sawmill)}
                                                        // onChange={(e) => updateFields('sawmill', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Isolamento"
                                                        type="date"
                                                        name="isolamento"
                                                        value={dateForInput(fields.isolamento)}
                                                        // onChange={(e) => updateFields('isolamento', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Solda"
                                                        type="date"
                                                        value={dateForInput(fields.solda)}
                                                        name="solda"
                                                        // onChange={(e) => updateFields('solda', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <div>
                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Suportes/Flanges</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Serralheria"
                                                        type="date"
                                                        value={dateForInput(fields.serralheria)}
                                                        name="serralheria"
                                                        // onChange={(e) => updateFields('serralheria', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Pintura"
                                                        type="date"
                                                        value={dateForInput(fields.painting)}
                                                        name="painting"
                                                        // onChange={(e) => updateFields('painting', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Pintura eletroestática</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Envio"
                                                        type="date"
                                                        value={dateForInput(fields.pinturaEnvio)}
                                                        name="pinturaEnvio"
                                                        // onChange={(e) => updateFields('pinturaEnvio', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Retorno"
                                                        type="date"
                                                        value={dateForInput(fields.pinturaRetorno)}
                                                        name="pinturaRetorno"
                                                        // onChange={(e) => updateFields('pinturaRetorno', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <div>
                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Pintura Refrin</span>
                                            <div>
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            },
                                                            marginTop: '10px'
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Finalização"
                                                        type="date"
                                                        name="finalizacao"
                                                        value={dateForInput(fields.finalizacao)}
                                                        // onChange={(e) => updateFields('finalizacao', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Expedição</span>
                                            <div>
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            },
                                                            marginTop: '10px'
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Previsão"
                                                        type="date"
                                                        value={dateForInput(fields.production_leaving)}
                                                        name="production_leaving"
                                                        // onChange={(e) => updateFields('production_leaving', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px',
                                            width: '100%'
                                        }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px'
                                                }}
                                            >
                                                Tipo de pintura
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Tipo de pintura"
                                                        type="text"
                                                        name="paintType"
                                                        value={fields.paintType || ''}
                                                        // onChange={(e) => updateFields('paintType', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px'
                                                }}
                                            >
                                                Tipo de embalagem
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="packing"
                                                        type="text"
                                                        name="packing"
                                                        value={fields?.packing || ''}
                                                        // onChange={(e) => updateFields('packing', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px'
                                                }}
                                            >
                                                Reprogramação
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            width: '150px',
                                                            '& input:disabled': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                                            }
                                                        }}
                                                        // disabled
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Reprogramação"
                                                        type="date"
                                                        name="reprogramming"
                                                        value={dateForInput(fields?.reprogramming)}
                                                        // onChange={(e) => updateFields('reprogramming', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </form>
                    </div>
                </>
            )}
        </>
    );
};

export default AnalysisData;
