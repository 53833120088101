import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Autocomplete, Divider, gridClasses, IconButton, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { IconEdit, IconSquare, IconSquareRoot, IconSum, IconTrash, IconX } from '@tabler/icons';
import { DataGrid } from '@mui/x-data-grid';
import { InfoFormulas } from './InfoFormulas';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: '90%',
    overflow: 'auto'
};

export function FormulaModal({ open, setOpen, currentMachine }) {
    const handleClose = () => {
        setOpen(false);
        setFormula('');
        setFormulaDescription('');
    };
    const [params, setParams] = useState([]);
    const api = useApi();
    const [formulaDescription, setFormulaDescription] = useState('');
    const [formula, setFormula] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [allParamsSelected, setAllParamsSelected] = useState([]);
    const [allFormulas, setAllFormulas] = useState([]);
    const [exampleIsVisible, setExampleIsVisible] = useState(false);
    const [systemVariablesSelected, setSystemVariablesSelected] = useState('');

    const systemVariables = [
        '$$VELOCIDADE$$',
        '$$FATOR$$',
        '$$VEL_OPERACAO$$',
        '$$LARGURA_ROLO$$',
        '$$MT_LINEAR$$',
        '$$TMP_FAB$$',
        '$$TMP_ABAST$$',
        '$$TMP_CABECOTE$$',
        '$$TMP_MOVIMENTACAO$$',
        '$$TMP_TOTAL$$'
    ];

    async function getParametersByMachine() {
        try {
            const { data } = await api.getMachineParameter(currentMachine.id);
            setParams(data.data);
        } catch (error) {
            console.log('🚀 ~ getParametersByMachine ~ error:', error);
        }
    }

    useEffect(() => {
        getParametersByMachine();
    }, [currentMachine.id]);

    function handleAddParameter(parameter) {
        setFormula(`${formula} $$${parameter?.descricao}$$ `);
    }

    function handleAddSystemVariable(variable) {
        setFormula(`${formula} ${variable} `);
    }

    async function handleSubmitFormula() {
        const transformParams = (params) => {
            return params.reduce((acc, item, index) => {
                const key = item.descricao;
                const value = item.perg_mega_id !== null ? `id:${item.perg_mega_id}` : `number:${item.numero}`;
                acc[key] = value;
                return acc;
            }, {});
        };

        const parametros = transformParams(allParamsSelected);
        if (formulaDescription === '' || formula === '') {
            toast.warn('Preencha todos os campos');
            return;
        }
        const expressaoArray = formula.split('\n').map((line, index) => {
            switch (index) {
                case 0:
                    return `$$VELOCIDADE$$ := ${line.trim()}`;
                case 1:
                    return `$$FATOR$$ := ${line.trim()}`;
                case 2:
                    return `$$VEL_OPERACAO$$ := ${line.trim()}`;
                case 3:
                    return `$$LARGURA_ROLO$$ := ${line.trim()}`;
                case 4:
                    return `$$MT_LINEAR$$ := ${line.trim()}`;
                case 5:
                    return `$$TMP_FAB$$ := ${line.trim()}`;
                case 6:
                    return `$$TMP_ABAST$$ := ${line.trim()}`;
                case 7:
                    return `$$TMP_CABECOTE$$ := ${line.trim()}`;
                case 8:
                    return `$$TMP_MOVIMENTACAO$$ := ${line.trim()}`;
                case 9:
                    return `$$TMP_TOTAL$$ := ${line.trim()}`;
                default:
                    return line.trim();
            }
        });

        if (!formulaDescription) {
            return toast.warn('Adicione uma descrição');
        }

        const payload = {
            descricao: formulaDescription.toUpperCase(),
            expressao: expressaoArray
        };
        console.log('🚀 ~ handleSubmitFormula ~ payload:', payload);

        try {
            if (allFormulas.id) {
                await api.updateMachineFormula(currentMachine.id, allFormulas.id, payload);
                handleClose();
                toast.success('Fórmula atualizada com sucesso');
            } else {
                await api.createMachineFormula(currentMachine.id, payload);
                toast.success('Fórmula salva com sucesso');
                handleClose();
            }
        } catch (error) {
            console.log('🚀 ~ handleSubmitFormula ~ error:', error);
            toast.error('Erro ao salvar a fórmula');
        }
    }

    async function getAllFormulas() {
        try {
            const { data } = await api.getMachineFormula(currentMachine.id);

            if (data.length > 0) {
                const lastFormula = data[data.length - 1];
                setAllFormulas(lastFormula);
            } else {
                setAllFormulas(data);
            }
        } catch (error) {
            console.log('🚀 ~ getAllFormulas ~ error:', error);
        }
    }

    useEffect(() => {
        if (allFormulas) {
            console.log('🚀 ~ allFormulas ~ allFormulas:', allFormulas);

            setFormulaDescription(allFormulas?.descricao);

            const formattedFormula = allFormulas?.formula
                ?.replace(/^\[|\]$/g, '') // Remove os colchetes
                .replace(/","/g, '\n') // Substitui as vírgulas por quebras de linha
                .replace(/"/g, '') // Remove as aspas duplas
                .replace(/.*?:=\s*/g, ''); // Remove tudo que vem antes de `:=` e o próprio `:=`

            setFormula(formattedFormula);
        }
    }, [allFormulas]);

    useEffect(() => {
        if (open) {
            getAllFormulas();
        }
    }, [currentMachine, open]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box sx={{ position: 'absolute', right: '40px', top: '30px' }}>
                    <Button variant="outlined" onClick={() => setExampleIsVisible(true)}>
                        Informações
                    </Button>
                </Box>
                <Box>
                    <h3 style={{ textAlign: 'center' }}>Fórmulas - {currentMachine?.descricao}</h3>
                    <Divider />
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <TextField
                            sx={{ width: 350 }}
                            label="Descrição da fórmula"
                            variant="outlined"
                            value={formulaDescription}
                            onChange={(e) => setFormulaDescription(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />

                        <Autocomplete
                            id="combo-box-demo"
                            options={params}
                            getOptionLabel={(option) => option?.descricao || ''}
                            sx={{ width: 250 }}
                            value={null}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            onChange={(event, value) => {
                                handleAddParameter(value);
                                setInputValue('');
                                setAllParamsSelected([...allParamsSelected, value]);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Parâmetros"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: null
                                    }}
                                />
                            )}
                        />

                        <Autocomplete
                            id="combo-demo"
                            options={systemVariables}
                            sx={{ width: 250 }}
                            value={null}
                            inputValue={systemVariablesSelected}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            onChange={(event, value) => {
                                handleAddSystemVariable(value);
                                setSystemVariablesSelected('');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Variaveis"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: null
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <TextField
                            sx={{
                                marginTop: '20px'
                            }}
                            multiline
                            fullWidth
                            label="Fórmula"
                            value={formula}
                            onChange={(e) => setFormula(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            sx={{ marginTop: '20px', width: '300px' }}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitFormula}
                        >
                            {allFormulas.id ? 'Atualizar Fórmula' : 'Salvar Fórmula'}
                        </Button>
                    </Box>
                </Box>

                {exampleIsVisible && (
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', padding: '0 40px' }}>
                            <span style={{ fontSize: '18px' }}>Informações</span>
                            <IconX style={{ cursor: 'pointer' }} onClick={() => setExampleIsVisible(false)} />
                        </Box>
                        <Divider />
                        <Box>
                            <p>Cada linha é uma expressão matemática referente a um parâmetro do sistema.</p>
                            <p>
                                A expressão "$$PARAMETROX$$ := " é adicionada automaticamente pelo sistema. Oque vem depois é o valor que o
                                usuario inseriu, cada linha tem um parametro especifico seguindo a ordem do exemplo a baixo.
                            </p>
                            <p>Exemplo:</p>
                            <ol>
                                <li>$$VELOCIDADE$$ := 56</li>
                                <li>$$FATOR$$ := </li>
                                <li>$$VEL_OPERACAO$$ := $$VELOCIDADE$$ * ($$FATOR$$ / 100)</li>
                                <li>
                                    $$LARGURA_ROLO$$ := ($$FORMA_CONSTRUTIVA$$ === '3RIB' && $$BITOLA$$ != 18 ? 148 : ($$BITOLA$$ == 18 ?
                                    139.7 : 137))
                                </li>
                                <li>$$MT_LINEAR$$ := $$AREA$$ / ($$LARGURA_ROLO$$ / 1000)</li>
                                <li>$$TMP_FAB$$ := math.round(($$MT_LINEAR$$ / $$VEL_OPERACAO$$) * 60, 0)</li>
                                <li>$$TMP_ABAST$$ := 1800</li>
                                <li>$$TMP_CABECOTE$$ := 1800</li>
                                <li>$$TMP_MOVIMENTACAO$$ := 1800</li>
                                <li>$$TMP_TOTAL$$ := $$TMP_FAB$$ + $$TMP_ABAST$$ + $$TMP_CABECOTE$$ + $$TMP_MOVIMENTACAO$$</li>
                            </ol>
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
}
