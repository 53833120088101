import { useApi } from 'Service/axios';

import { useTheme } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent
} from '@mui/lab';

import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { width } from '@mui/system';
import { createTheme } from '@mui/material/styles';

export default function CustomizedTimeline() {
    const api = useApi();
    const { id } = useParams();
    const [billingData, setBillingData] = useState([]);
    const [budget, setBudget] = useState([]);
    const paper = {
        p: 2.5
    };

    useEffect(() => {
        const getBudgetEvent = async () => {
            try {
                const response = await api.getBudgetEventById(id);
                setBillingData(response.data);
            } catch (err) {
                console.error('Erro ao buscar dados de cobrança', err);
            }
        };

        const getBudget = async () => {
            try {
                const response = await api.getBudgetById(id);
                setBudget(response.data);
                console.log(response.data);
            } catch (err) {
                console.error('Erro ao buscar dados de orçamento', err);
            }
        };

        getBudgetEvent();
        getBudget();
    }, [id]);

    const getStatusIcon = (statusBudget) => {
        if (statusBudget === 'iniciado' || statusBudget === 'retomado') return <PlayCircleOutlineOutlinedIcon />;
        if (statusBudget === 'pausado') return <StopCircleOutlinedIcon />;
        if (statusBudget === 'finalizado') return <DoneAllOutlinedIcon />;
        return <PlayCircleOutlineOutlinedIcon />;
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2085c3'
            },
            secondary: {
                main: '#cc2623'
            }
        }
    });

    return (
        <Timeline position="alternate" theme={theme}>
            {[
                {
                    title: 'Email Recebido',
                    date: budget.mailDate,
                    icon: <MailOutlinedIcon />
                },
                {
                    title: 'Atendimento Distribuido',
                    date: budget.validateDate,
                    icon: <MarkEmailReadOutlinedIcon />
                },
                ...billingData.map((item, idx) => {
                    let title = '';
                    let observation = '';
                    if (item.eventType === 'iniciado') {
                        title = 'Atendimento Iniciado';
                    } else if (item.eventType === 'pausado') {
                        title = 'Atendimento Pausado';
                        observation = item.observation;
                    } else if (item.eventType === 'finalizado') {
                        title = 'Atendimento Finalizado';
                    } else if (item.eventType === 'retomado') {
                        title = 'Atendimento Retomado';
                    }

                    return {
                        title,
                        date: item.createdAt,
                        icon: getStatusIcon(item.eventType),
                        observation,
                        eventType: item.eventType
                    };
                })
            ].map((item, idx) => (
                <TimelineItem key={idx}>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            {item.date ? new Date(item.date).toLocaleString() : 'Not validated yet'}
                        </Typography>
                        {item.eventType === 'pausado' && item.observation && (
                            <Typography variant="body2" color="textSecondary">
                                Observação: {item.observation}
                            </Typography>
                        )}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            sx={{
                                backgroundColor: idx % 2 === 0 ? theme.palette.secondary.main : theme.palette.primary.main
                            }}
                        >
                            {item.icon}
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} sx={paper}>
                            <Typography variant="h5" component="h1">
                                {item.title}
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}
