import { TableCell, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { style } from '@mui/system';

export const OrderButton = styled(TableCell)({
    padding: '.35em',
    cursor: 'pointer',
    transition: 'all .15s ease',

    '&:hover': {
        backgroundColor: 'rgba(204, 38, 54, 0.55)'
    }
});
