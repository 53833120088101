import dashboard from './dashboard';
import budget from './budget';
import request from './request';
import sales from './sales';
import pcp from './pcp';
import rh from './rh';
import engenharia from './engenharia';
import expedition from './expedition';
import OrderManager from './orderManager';
import control from './control';

// ==============================|| MENU ITEMS ||============================== //

const role = localStorage.getItem('role');
// novo controle de menus2
let menuItems;

if (role === 'Orcamentista') {
    menuItems = {
        items: [request, control]
    };
} else if (role === 'Vendedor') {
    menuItems = {
        items: [request, sales, control]
    };
} else if (role === 'Engenharia') {
    menuItems = {
        items: [engenharia, pcp, control]
    };
} else if (role === 'Rh') {
    menuItems = {
        items: [rh]
    };
} else if (role === 'adm-comercial') {
    menuItems = {
        items: [request, sales, control]
    };
} else if (role === 'Codificador') {
    menuItems = {
        items: [engenharia, expedition, control]
    };
} else if (role === 'Expedicao') {
    menuItems = {
        items: [expedition, control]
    };
} else if (role === 'Portaria') {
    menuItems = {
        items: [expedition, control]
    };
} else if (role === 'Comercial/Engenharia') {
    menuItems = {
        items: [request, sales, engenharia, expedition, control]
    };
} else {
    menuItems = {
        items: [dashboard, request, sales, rh, pcp, engenharia, expedition, OrderManager, control]
    };
}

export default menuItems;
