import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Divider, IconButton, TextField, Tooltip } from '@mui/material';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { IconTransform } from '@tabler/icons';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export default function InsertParamsModal({ currentMachine, megaParameters, getParameters, selectedRow, open, setOpen }) {
    const [chandInputCompleteOrNumber, setChandInputCompleteOrNumber] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const [questionSelected, setQuestionSelected] = React.useState(null);
    const [number, setNumber] = React.useState('');
    const [isEdit, setIsEdit] = React.useState(false);
    const api = useApi();

    const handleClose = () => {
        setOpen(false);
        setIsEdit(false);
        setDescription('');
        setQuestionSelected(null);
        setNumber('');
    };

    async function handleSubmit(event) {
        event.preventDefault();
        const payload = {
            descricao: description.toLocaleUpperCase(),
            ...(questionSelected?.PERG_IN_CODIGO ? { perg_mega_id: questionSelected.PERG_IN_CODIGO } : { numero: number })
        };
        const toastId = toast.loading('Enviando...');
        try {
            await api.createMachineParameter(currentMachine.id, payload);
            handleClose();
            getParameters();
            setDescription('');
            setQuestionSelected(null);
            setNumber('');
            toast.update(toastId, {
                render: 'Parâmetro inserido com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.update(toastId, {
                render: 'Falha ao inserir parâmetro',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleSubmitEdit(event) {
        event.preventDefault();
        const payload = {
            descricao: description,
            ...(questionSelected?.PERG_IN_CODIGO ? { perg_mega_id: questionSelected.PERG_IN_CODIGO } : { numero: number })
        };
        const toastId = toast.loading('Enviando...');
        try {
            await api.updateMachineParameter(selectedRow.id, payload, selectedRow.maquina_id);
            handleClose();
            getParameters();
            setDescription('');
            setQuestionSelected(null);
            setNumber('');
            toast.update(toastId, {
                render: 'Parâmetro alterado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ handleSubmitEdit ~ error:', error);
            toast.update(toastId, {
                render: 'Falha ao alterar parâmetro',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    useEffect(() => {
        if (selectedRow) {
            setDescription(selectedRow.descricao || '');
            const autoCompleteValue = megaParameters.find((param) => param.PERG_IN_CODIGO === selectedRow.perg_mega_id) || null;
            setQuestionSelected(autoCompleteValue);
            setNumber(selectedRow.numero || '');
            setChandInputCompleteOrNumber(!!selectedRow.numero);
            setIsEdit(true);
        } else {
            setDescription('');
            setQuestionSelected(null);
            setNumber('');
            setIsEdit(false);
        }
    }, [selectedRow, megaParameters]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box>
                    <h3>Parâmetros - {currentMachine?.descricao}</h3>
                    <Divider />
                </Box>

                <form onSubmit={isEdit ? handleSubmitEdit : handleSubmit}>
                    <Box sx={{ display: 'flex', gap: 2, marginTop: '10px' }}>
                        <TextField
                            value={description}
                            onChange={(e) => setDescription(e.target.value.replace(/\s/g, '_'))}
                            label="Descrição"
                            variant="outlined"
                        />
                        {chandInputCompleteOrNumber ? (
                            <TextField
                                sx={{ width: 300 }}
                                onChange={(e) => setNumber(e.target.value)}
                                label="Número"
                                variant="outlined"
                                type="number"
                                value={number}
                            />
                        ) : (
                            <Autocomplete
                                id="combo-box-demo"
                                options={megaParameters}
                                getOptionLabel={(option) => option?.PERG_ST_PERGUNTA || ''}
                                sx={{ width: 300 }}
                                value={questionSelected}
                                onChange={(e, value) => setQuestionSelected(value)}
                                renderInput={(params) => <TextField {...params} label="Perg. Mega" />}
                            />
                        )}
                        {isEdit === false && (
                            <Tooltip title="Pergunta Completa ou Número">
                                <IconButton onClick={() => setChandInputCompleteOrNumber(!chandInputCompleteOrNumber)}>
                                    <IconTransform />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button type="submit" variant="contained" sx={{ width: '350px' }}>
                            {isEdit ? 'Editar Parâmetro' : 'Inserir Parâmetro'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}
