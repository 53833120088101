import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Fab, Tab, Tabs, TextField } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { height, maxHeight } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { formatValueToReal, parseFormattedValue } from 'utils/regex';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import { SearchGoals } from './SearchGoals';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    overflow: 'hidden',
    height: '700px',
    padding: '10px'
};

export function GoalsModal({ getRanking }) {
    const api = useApi();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState('one');
    const [sellersData, setSellersData] = React.useState([]);
    const [monthSelected, setMonthSelected] = React.useState(formatDate(new Date(), 'yyyy-MM'));
    const [load, setLoad] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function getAllSeller() {
        try {
            const { data } = await api.getSellerGoals();
            setSellersData(data);
        } catch (error) {
            console.log('🚀 ~ getAllSeller ~ error:', error);
        }
    }

    useEffect(() => {
        getAllSeller();
    }, []);

    const handleMetaChange = (event, sellerId) => {
        const newMetaValue = formatValueToReal(event.target.value);

        setSellersData((prev) => {
            const newMetaValueSeller = prev.map((seller) => {
                if (seller.agn_in_codigo === sellerId) {
                    return { ...seller, meta: newMetaValue };
                }
                return seller;
            });

            return newMetaValueSeller;
        });
    };

    async function handleSendGoals() {
        setLoad(true);
        const goalsRegistred = sellersData.filter((seller) => seller.meta);

        const [year, month] = monthSelected.split('-').map(Number);
        const lastDayOfMonth = new Date(year, month, 0);

        const dtFim = formatDate(lastDayOfMonth, 'yyyy-MM-dd');

        const payload = goalsRegistred.map((seller) => ({
            agn_in_codigo: seller.agn_in_codigo,
            meta: parseFormattedValue(seller.meta),
            vendedor: seller.vendedor,
            data: dtFim
        }));

        const toastPromisseId = toast.loading('Cadastrando metas...');

        try {
            await api.createGoals(payload);
            const updatedSellersData = sellersData.map((seller) => {
                const { meta, ...rest } = seller;
                return rest;
            });

            setSellersData(updatedSellersData);
            handleClose();
            toast.update(toastPromisseId, {
                render: 'Metas cadastradas com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getRanking();
        } catch (error) {
            console.log('🚀 ~ handleSendGoals ~ error:', error);
        } finally {
            setLoad(false);
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Metas
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <TabContext value={value}>
                        <Box sx={{ width: '100%', position: 'relative' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="secondary tabs example" fixed>
                                <Tab value="one" label="Cadastrar Metas" />
                                <Tab value="two" label="Pesquisar metas passadas" />
                            </Tabs>
                            <TabPanel value="one" index="one" sx={{ height: '600px', overflow: 'auto' }}>
                                <TextField
                                    label="Mês"
                                    InputLabelProps={{ shrink: true }}
                                    type="month"
                                    value={monthSelected}
                                    onChange={(e) => setMonthSelected(e.target.value)}
                                />
                                {sellersData.map((seller, index) => (
                                    <div
                                        key={index} // Use o id do vendedor como chave única
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <p>{seller.vendedor}</p>
                                        <TextField
                                            id={`meta-${seller.agn_in_codigo}`}
                                            label="Meta"
                                            value={sellersData[index]?.meta || ''}
                                            onChange={(event) => handleMetaChange(event, seller.agn_in_codigo)}
                                        />
                                    </div>
                                ))}
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    title="Adicionar Meta"
                                    onClick={handleSendGoals}
                                    sx={{ position: 'fixed', bottom: 10, right: 25 }}
                                    disabled={load}
                                >
                                    <AddIcon />
                                </Fab>
                            </TabPanel>
                            <TabPanel value="two" index="two">
                                <SearchGoals sellersData={sellersData} />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Modal>
        </div>
    );
}
