import { MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function EventModal({ open, setOpen, startFunction, setActivity, startLoad }) {
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [activityList, setActivityList] = useState([]);

    async function getActivits() {
        try {
            const { data } = await api.getAllActivits();
            setActivityList(data);
        } catch (error) {
            console.log('🚀 ~ getActivits ~ error:', error);
        }
    }

    useEffect(() => {
        getActivits();
    }, []);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Apontamento</h3>

                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Selecione a atividade"
                        sx={{ width: '100%' }}
                        onChange={(e) => setActivity(e.target.value)}
                    >
                        {activityList.map((option) => (
                            <MenuItem key={option} value={option.activityType}>
                                {option.activityType}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button variant="contained" sx={{ width: '170px', marginTop: '20px' }} onClick={startFunction}>
                        {startLoad ? 'salvando...' : 'Iniciar Apontamento'}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
