/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import moment from 'moment';

moment.locale('pt-br');

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function ModalData({ open, setOpen, eventData }) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h2 style={{ textAlign: 'center' }}>Dados do evento</h2>
                    <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', marginBottom: '10px' }}>
                        <span>Obra:</span>
                        <h4 style={{ margin: 0 }}>{eventData?.obra}</h4>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', marginBottom: '10px' }}>
                        <span>Cliente:</span>
                        <h4 style={{ margin: 0 }}>{eventData?.client}</h4>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', marginBottom: '10px' }}>
                        <span>Codificador:</span>
                        <h4 style={{ margin: 0 }}>{eventData?.title}</h4>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', marginBottom: '10px' }}>
                        <span>Observação:</span>
                        <p style={{ margin: 0 }}>{eventData?.desc}</p>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
