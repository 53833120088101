import { Autocomplete, Button, Dialog, DialogTitle, FormControl, TextField } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import formater from 'utils/formatDate';

const ReleaseOfTransport = ({ open, setOpen, currentRow, get }) => {
    const api = useApi();
    const currentUserName = localStorage.getItem('name');
    const [data, setData] = useState([]);

    const localData = [
        { id: 1, type: 'DOCA 01' },
        { id: 2, type: 'DOCA 02' },
        { id: 3, type: 'DOCA 03' },
        { id: 4, type: 'DOCA TDC' },
        { id: 6, type: 'EXPEDIÇÃO' },
        { id: 7, type: 'RECEPÇÃO' }
    ];

    const getData = async () => {
        try {
            const { data } = await api.getCurentTransport(currentRow.id);

            setData(data.registro);
        } catch (error) {
            console.log('🚀 ~ file: ReleaseOfTransport.js:18 ~ getData ~ error:', error);
        }
    };

    const sendEmail = async (id) => {
        const payloadEmail = {
            email: currentRow.email,
            usuarioLogado: currentUserName
        };
        try {
            await api.releaseEmail(id, payloadEmail);
        } catch (error) {
            console.log('🚀 ~ file: ReleaseOfTransport.js:35 ~ cosntsendEmail= ~ error:', error);
        }
    };

    const transportReleaseRecord = async (values) => {
        const payload = {
            transporte_solicitacao: values?.transportRegister,
            data_confirmacao: values?.confirmData,
            transportadora_portaria: values?.shippingCompany,
            local: values?.local,
            transporte_portaria: values?.transport,
            observacao: values?.observation,
            responsavel_id: data?.Solicitacao_coleta?.usuario_responsavel_id
        };

        try {
            const res = await api.transportRelease(data.id, payload);
            console.log('🚀 ~ file: ReleaseOfTransport.js:57 ~ transportReleaseRecord ~ data:', res.data);
            toast.success('Liberação concluida');
            sendEmail(res.data?.liberacaoTransporte?.id);
            setOpen(false);
            get();
        } catch (error) {
            console.log('🚀 ~ file: ReleaseOfTransport.js:43 ~ transportReleaseRecord ~ error:', error);
        }
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <Dialog onClose={() => setOpen(false)} open={open}>
            <div style={{ width: '550px', padding: '10px' }}>
                <DialogTitle fontSize={25} textAlign={'center'}>
                    Liberação do Transporte - E{currentRow.id}
                </DialogTitle>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <h4>Agrupamento: {data?.agrupamento}</h4>
                    <h4>Cliente: {data?.cliente}</h4>
                </div>

                <Formik
                    initialValues={{
                        transport: data?.transporte || '',
                        confirmData: currentRow?.Registro_transporte?.a_partir_de || '',
                        shippingCompany: data?.transportadora || '',
                        local: '',
                        observation: '',
                        transportRegister: data?.Solicitacao_coleta?.transporte
                    }}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        transportReleaseRecord(values);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{ padding: '5px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                        >
                            <h3>Dados Agendados</h3>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl>
                                    <TextField
                                        sx={{
                                            width: '300px',
                                            '& input:disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                            }
                                        }}
                                        disabled
                                        label="Transporte"
                                        name="transportRegister"
                                        value={values.transportRegister}
                                        inputProps={{ style: { padding: '12px' } }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        disabled
                                        label="Data de confirmação"
                                        name="confirmData"
                                        sx={{
                                            '& input:disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                            }
                                        }}
                                        value={values.confirmData}
                                        inputProps={{ style: { padding: '12px' } }}
                                    />
                                </FormControl>
                            </div>

                            <h3>Dados Portaria</h3>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <FormControl>
                                    <TextField
                                        sx={{
                                            width: '300px',
                                            padding: '0',
                                            '& input:disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                            }
                                        }}
                                        disabled
                                        label="Transportadora"
                                        name="shippingCompany"
                                        value={values.shippingCompany}
                                        inputProps={{ style: { padding: '12px' } }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        disabled
                                        label="Transporte"
                                        name="transport"
                                        value={values.transport}
                                        sx={{
                                            '& input:disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                            }
                                        }}
                                        inputProps={{ style: { padding: '12px' } }}
                                    />
                                </FormControl>
                            </div>
                            <div style={{ marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="local"
                                        options={localData}
                                        sx={{ width: 230 }}
                                        getOptionLabel={(option) => option.type}
                                        onChange={(e, newValue) => {
                                            if (newValue) setValues({ ...values, local: newValue.type });
                                        }}
                                        value={localData.find((option) => option.type === values.local) || null}
                                        renderInput={(params) => <TextField {...params} label="Local" name="local" value={values.local} />}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        multiline
                                        rows={3}
                                        sx={{ width: '100%' }}
                                        label="Observação"
                                        name="observation"
                                        value={values.observation}
                                        inputProps={{ style: { padding: '12px' } }}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </div>
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button variant="contained" color="error">
                                    Cancelar
                                </Button>
                                <Button variant="contained" type="submit">
                                    Liberar
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
};

export default ReleaseOfTransport;
