import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormLabel,
    useMediaQuery,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    TablePagination,
    DialogActions,
    TableSortLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';
import { Link, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditForm from './Edit/EditForm';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { OrderButton } from './style';
function RegisterReason({ ...others }) {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const api = useApi();
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [reasonID, setReasonID] = useState(null);
    const [selectedReason, setSelectedReason] = useState(null);
    const [orderById, setOrderById] = useState('asc');
    const [orderByNome, setOrderByNome] = useState('asc');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getReasons = async () => {
        const response = await api.getAllReason();
        setReasons(response.data.allReason);
    };
    const handleClickOpenEdit = (e) => {
        e.preventDefault();
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
        window.location.reload();
    };

    const handleRegister = async (values) => {
        try {
            const data = await api.registerReason(values);
            //console.log(values);
            window.location.reload();
        } catch (error) {
            console.log(error);
            setSubmitError(error.response.data.message);
        }
    };

    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setReasonID(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleConfirmDelete = async () => {
        setOpenSnackBarDeleted(true);
    };

    const handleClickSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleCloseSnackBarDeleted = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBarDeleted(false);
    };

    const handleCancelDeleteDialog = () => {
        handleCloseDelete();
    };

    const handleSortById = () => {
        const sortedItens = [...reasons].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setReasons(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...reasons].sort((a, b) => {
            const nomeA = a?.reasonName.toUpperCase();
            const nomeB = b?.reasonName.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setReasons(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    const handleConfirmDeleteDialog = async (reasonID) => {
        try {
            await api.deleteReason(reasonID);
            window.location.reload();
        } catch (error) {
            window.alert(error.response.data.message);
            window.location.reload();
            throw new Error(error.message);
        }
    };

    useEffect(() => {
        getReasons();
    }, []);

    return (
        <MainCard title="CADASTRAR MOTIVO">
            <Grid container spacing={gridSpacing}>
                {formOpen && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto',
                            padding: '1em',
                            width: '70%'
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setFormOpen(false)}
                            sx={{ maxWidth: '50%', margin: '0 auto', marginBottom: '3em' }}
                        >
                            Ver Tabela
                        </Button>
                        <Formik
                            initialValues={{
                                reasonName: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                reasonName: Yup.string().max(255).required('Nome do motivo é obrigatorio')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                    handleRegister(values);
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.msg });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit} {...others} style={{ width: '100%' }}>
                                    <FormControl
                                        fullWidth
                                        sx={{ ...theme.typography.customInput }}
                                        error={Boolean(touched.reasonName && errors.reasonName) || submitError}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-reasonName-register">Nome do Motivo</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-reasonName-register"
                                            type="text"
                                            value={values.reasonName}
                                            name="reasonName"
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.reasonName && errors.reasonName && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.reasonName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <Grid container alignItems="center" justifyContent="space-between"></Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}

                                    {submitError && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{submitError}</FormHelperText>
                                        </Box>
                                    )}

                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                CADASTRAR MOTIVO
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                )}

                {!formOpen && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '1em',
                            width: '100%'
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '2em' }}>
                            <Button variant="contained" color="primary" onClick={() => setFormOpen(true)}>
                                Cadastrar
                            </Button>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={reasons}
                                getOptionLabel={(reason) => reason.reasonName}
                                value={selectedReason}
                                onChange={(event, newValue) => {
                                    setSelectedReason(newValue);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Nome do motivo" />}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel onClick={handleSortById}>ID</TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={handleSortByNome}>MOTIVO</TableSortLabel>
                                        </TableCell>

                                        <TableCell align="right">AÇÕES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reasons.length > 0 &&
                                        reasons

                                            .filter((item) =>
                                                selectedReason ? item?.reasonName.includes(selectedReason?.reasonName) : true
                                            )
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((reason) => (
                                                <TableRow>
                                                    <TableCell>{reason?.id}</TableCell>
                                                    <TableCell>{reason?.reasonName}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                handleClickOpenEdit(e, reason.id);
                                                                setReasonID(reason.id);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={(e) => handleClickOpenDelete(e, reason.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {reasons.length > 9 && (
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={reasons.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </Box>
                )}
            </Grid>
            <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md" fullWidth>
                <DialogContent>
                    <EditForm reasonID={reasonID} handleCloseEdit={handleCloseEdit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Fechar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDeleteDialog(reasonID)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
}

export default RegisterReason;
