// assets
import { IconAssembly } from '@tabler/icons';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PeopleIcon from '@mui/icons-material/People';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { IconUser, IconCalendar } from '@tabler/icons';

const icons = {
    IconAssembly
};

let engenharia;
const role = localStorage.getItem('role');
if (role === 'Codificador') {
    engenharia = {
        id: 'engenharia',
        title: 'Engenharia',
        type: 'group',
        children: [
            {
                id: 'dashboardEngenharia',
                title: 'Dashboard',
                type: 'item',
                url: '/main/graph-engenharia',
                icon: EqualizerIcon,
                breadcrumbs: false
            },

            {
                id: 'Codificação',
                title: 'Codificação',
                type: 'item',
                url: '/main/engenharia/codificacao',
                icon: IconUser,
                breadcrumbs: false
            },
            {
                id: 'calendar',
                title: 'Calendário de Obras',
                type: 'item',
                url: '/main/engenharia/calendario-obras',
                icon: IconCalendar,
                breadcrumbs: false
            }
        ]
    };
} else {
    engenharia = {
        id: 'engenharia',
        title: 'Engenharia',
        type: 'group',
        children: [
            {
                id: 'dashboardEngenharia',
                title: 'Dashboard',
                type: 'item',
                url: '/main/graph-engenharia',
                icon: EqualizerIcon,
                breadcrumbs: false
            },

            {
                id: 'painel dist obras',
                title: 'Distribuição de obras',
                type: 'item',
                url: '/main/engenharia/distribuicao-obras',
                icon: FormatListNumberedIcon,
                breadcrumbs: false
            },
            {
                id: 'Codificação',
                title: 'Codificação',
                type: 'item',
                url: '/main/engenharia/codificacao',
                icon: IconUser,
                breadcrumbs: false
            },
            {
                id: 'calendar',
                title: 'Calendário de Obras',
                type: 'item',
                url: '/main/engenharia/calendario-obras',
                icon: IconCalendar,
                breadcrumbs: false
            }
        ]
    };
}

export default engenharia;
