import { Badge, Box, Button, Divider, IconButton, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { IconTrash } from '@tabler/icons';

import { useDemandsContext } from 'context/DemandsContext';
import { useState } from 'react';
import { useNavigate } from 'react-router';

export function SelectedDemandsModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const { demandsSelected, setDemandsSelected } = useDemandsContext();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const columns = [
        { field: 'AGN_ST_FANTASIA', headerName: 'Cliente', flex: 1, headerAlign: 'center', align: 'center' },
        {
            field: 'DIAMETRO_GERAL',
            headerName: 'Diâmetro',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => Number(params.value).toFixed(0)
        },
        { field: 'QUANTIDADE_PECAS', headerName: 'Quantidade', flex: 0.5, headerAlign: 'center', align: 'center' },
        { field: 'FORMA_CONSTRUTIVA', headerName: 'Forma', flex: 0.5, headerAlign: 'center', align: 'center' },
        { field: 'BITOLA', headerName: 'Bitola', flex: 0.5, headerAlign: 'center', align: 'center' },
        { field: 'CEL_DT_PROGRAMACAO', headerName: 'Data da necessidade', flex: 1, headerAlign: 'center', align: 'center' },
        {
            field: 'action',
            headerName: 'Ações',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        title="Deletar"
                        onClick={() => {
                            const filteredItens = demandsSelected.filter((item) => item.id !== params.row.id);
                            setDemandsSelected(filteredItens);
                        }}
                    >
                        <IconTrash />
                    </IconButton>
                );
            }
        }
    ];
    return (
        <div>
            <Badge badgeContent={demandsSelected.length} color="secondary">
                <Button variant="contained" onClick={handleOpen}>
                    Demandas selecionadas
                </Button>
            </Badge>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Demandas Selecionadas</h3>
                    <Divider />
                    {demandsSelected.length > 0 ? (
                        <>
                            <DataGrid sx={{ margin: '20px 0', maxHeight: '300px' }} rows={demandsSelected} columns={columns} hideFooter />
                            <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'end' }}>
                                <Button variant="contained" onClick={() => navigate('/main/pcp/planejamento')}>
                                    Programar
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <p style={{ textAlign: 'center', margin: '20px 0' }}>Sem demandas disponíveis</p>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
