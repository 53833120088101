import {
    Button,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { converterData } from 'utils/convertData';

import formater from 'utils/formatDate';
import { useApi } from 'Service/axios';
import { formatValuebyHours } from 'utils/formatValueByHour';
import { formatarHorario } from 'utils/FormatarHoraParaPonto';

const DrawerEdit = ({ open, setOpen, editData, allInfos }) => {
    const api = useApi();
    const optionsSelect = [
        { id: 0, name: 'PCP' },
        { id: 1, name: 'Expedição' },
        { id: 2, name: 'Codificação' }
    ];

    const dificultLevels = [{ level: 'Nível 1' }, { level: 'Nível 2' }, { level: 'Nível 3' }, { level: 'Nível 4' }];
    const [typeDescription, setTypeDescription] = useState(null);
    const [fields, setFields] = useState({});
    const [codificatorData, setCodficatorData] = useState([]);
    const [load, setLoad] = useState(false);
    //update

    const getDescriptionByType = (type) => {
        const descriptionWork = editData?.DescriptionWorks.find((work) => work.type === type);
        return descriptionWork ? descriptionWork.description : '';
    };

    const dateForInput = (date) => {
        if (!date) return ''; // Se a data for nula ou indefinida, retorna uma string vazia

        const dateObj = new Date(date);
        // Checa se a data é inválida e retorna uma string vazia se for
        if (isNaN(dateObj.getTime())) return '';

        // Formata a data no formato 'yyyy-mm-dd' para inputs de data
        return dateObj.toISOString().split('T')[0];
    };
    const getFields = () => {
        const fields = {
            descriptionCoding: editData?.description,
            codficator: editData?.User?.id,
            pcp_enter: editData?.pcp_enter || '',
            pcp_exit: editData?.pcp_exit || '',
            codification_start: dateForInput(editData?.codification_start),
            codification_end: dateForInput(editData?.codification_end),
            vulcan_prog: dateForInput(editData?.vulcan_prog),
            cut: dateForInput(editData?.vulcan_cut),
            production_fab: dateForInput(editData?.production_fab),
            sawmill: dateForInput(editData?.production_spiro),
            painting: dateForInput(editData?.production_painting),
            production_leaving: dateForInput(editData?.production_leaving),
            pcpObs: getDescriptionByType('pcp'),
            expeditionObs: getDescriptionByType('expedition'),
            codingObs: getDescriptionByType('coding'),
            type: '',
            expedition: dateForInput(editData?.expedition),
            levels: editData?.level,
            hours: formatValuebyHours(editData?.estimated_hours),
            serralheria: dateForInput(editData?.production_serralheria),
            isolamento: dateForInput(editData?.production_isolamento),
            solda: dateForInput(editData?.production_solda),
            pinturaEnvio: dateForInput(editData?.paint_envio),
            pinturaRetorno: dateForInput(editData?.paint_retorno),
            finalizacao: dateForInput(editData?.finalizacao),
            inHold: allInfos?.in_hold,
            reprogramming: dateForInput(editData?.reprogramming),
            paintType: editData?.paint_type || '',
            packing: editData?.packing || ''
        };

        setFields(fields);
    };

    useEffect(() => {
        getFields();
    }, [editData]);

    const updateFields = (field, value) => {
        setFields({ ...fields, [field]: value });
    };

    const handleEdit = async () => {
        const getDescriptionByType = (type) => {
            return editData?.DescriptionWorks.find((work) => work.type === type);
        };

        let descriptionWorks = [];

        const pcpWork = getDescriptionByType('pcp');
        if (fields?.pcpObs && fields.pcpObs.trim() !== '' && fields.pcpObs !== pcpWork?.description) {
            descriptionWorks.push({
                description: fields.pcpObs,
                type: 'pcp',
                id: pcpWork?.id
            });
        }

        const expeditionWork = getDescriptionByType('expedition');
        if (fields?.expeditionObs && fields.expeditionObs.trim() !== '' && fields.expeditionObs !== expeditionWork?.description) {
            descriptionWorks.push({
                description: fields.expeditionObs,
                type: 'expedition',
                id: expeditionWork?.id
            });
        }

        const codingWork = getDescriptionByType('coding');
        if (fields?.codingObs && fields.codingObs.trim() !== '' && fields.codingObs !== codingWork?.description) {
            descriptionWorks.push({
                description: fields.codingObs,
                type: 'coding',
                id: codingWork?.id
            });
        }

        setLoad(true);
        const payload = {
            gepro: allInfos.gepro || currentWork.gepro,
            client: allInfos.client || currentWork.client,
            obra: allInfos.obra || currentWork.obra,
            pcp_enter: fields?.pcp_enter || fields.Prohibited || null,
            pcp_exit: fields?.pcp_exit || fields.delivery || null,
            codification_end: fields?.codification_end || fields.codingDelivery || null,
            codification_start: fields?.codification_start || fields.codingStart || null,
            vulcan_prog: fields?.vulcan_prog || fields.programming || null,
            vulcan_cut: fields?.cut || fields.cut || null,
            production_fab: fields?.production_fab || null,
            production_spiro: fields?.sawmill || fields.production_spiro,
            production_painting: fields?.painting || fields.painting || null,
            production_leaving: fields?.production_leaving || fields.expedition || null,
            estimated_hours: formatarHorario(fields?.hours || fields.hours),
            level: fields?.levels || fields.dificultLevels,
            codificators: [
                {
                    id: fields?.codficator || fields.codficator,
                    description: fields?.descriptionCoding || fields.descriptionCoding
                }
            ],
            DescriptionWorks: descriptionWorks.length > 0 ? descriptionWorks : [],
            in_hold: fields?.inHold || false,
            production_serralheria: fields?.serralheria || null,
            production_isolamento: fields?.isolamento || null,
            production_solda: fields?.solda || null,
            paint_envio: fields?.pinturaEnvio || null,
            paint_retorno: fields?.pinturaRetorno || null,
            finalizacao: fields?.finalizacao || null,
            description: fields?.descriptionCoding || fields.descriptionCoding,
            codificatorsId: fields?.codficator || fields.codficator,
            paint_type: fields?.paintType || fields?.paintType,
            packing: fields?.packing || fields?.packing,
            reprogramming: fields?.reprogramming || null
        };

        try {
            await api.EditDistributionWorks(editData.id, payload);
            // navigate(-1);
            toast.success('Distribuição editada com sucesso!');
            setLoad(false);
        } catch (error) {
            toast.error('Erro ao editar');
            console.log(error);
            setLoad(false);
        }
    };

    const getEncoders = async () => {
        try {
            const { data } = await api.getAllUsers();
            const filteredCodificator = data.filter((item) => item.role === 'Codificador' || item.role === 'Comercial/Engenharia');
            setCodficatorData(filteredCodificator);
        } catch (error) {
            console.log('🚀 ~ file: index.js:62 ~ getEncoders ~ error:', error.message);
        }
    };

    useEffect(() => {
        if (open) {
            getEncoders();
        }
    }, [open]);

    const packingTypesList = [
        'N/A',
        'PAPELÃO',
        'ENGRADADO + PAPELÃO',
        'ENGRADADO',
        'ENGRADADO + FILME STRECH',
        'ENGRADADO + FILME TERMORETRATIL',
        'PALLET',
        'PALLET + FILME TERMORETRATIL',
        'PALLET + FILME STRECH',
        'TAMPONAMENTO'
    ];

    const paintTypesList = [
        'N/A',
        'ALUMINIO ALTA TEMPERATURA',
        'ELÉTROSTATICA (AGUARDANDO DEFINIÇÃO DO RAL)',
        'FUNDO GALVITE',
        'MUNSELL 2,5 (VERIFICAR O ACABAMENTO)',
        'MUNSELL 5GY 6/1 SEMI BRILHO (CINZA)',
        'MUNSELL 5R4/14 BRILHANTE',
        'MUNSELL N3,5 FOSCO',
        'MUNSELL N6,5 BRILHANTE',
        'MUNSELL N6.5 SEMI BRILHO',
        'MUNSELL N8 SEMI BRILHO',
        'MUNSELL N9,5 SEMI BRILHO',
        'OXIDO DE FERRO',
        'RAL 1003 SEMI BRILHO',
        'RAL 1013 FOSCO',
        'RAL 1015 BRILHANTE',
        'RAL 1016 FOSCO',
        'RAL 5015 BRILHANTE',
        'RAL 5015 FOSCO',
        'RAL 5017 SEMI BRILHO',
        'RAL 7010 SEMI BRILHO',
        'RAL 7024 FOSCO',
        'RAL 9001 SEMI BRILHO',
        'RAL 9001 SEMI BRILHO',
        'RAL 9002 FOSCO',
        'RAL 9003 BRILHANTE',
        'RAL 9003 FOSCO',
        'RAL 9003 SEMI BRILHO',
        'RAL 9005 BRILHANTE',
        'RAL 9005 FOSCO',
        'RAL 9005 SEMI BRILHO',
        'RAL 9007 SEMI BRILHO',
        'RAL 9010 FOSCO',
        'RAL 9010 SEMI BRILHO',
        'RAL 9011 SEMI BRILHO',
        'RAL 9016 SEMI BRILHO',
        'RETOQUE ALUMINIO',
        'SUVINIL OVELHA (AMOSTRA)'
    ];
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => {
                setOpen(false);
            }}
        >
            {editData && (
                <>
                    <div style={{ width: '1000px', padding: '20px 30px' }}>
                        <form>
                            <h3>Atualizar Distribuição</h3>
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Cliente"
                                        name="client"
                                        value={allInfos.client}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField label="Obra" name="obra" value={allInfos.obra} disabled InputLabelProps={{ shrink: true }} />
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        label="Gepro"
                                        name="gepro"
                                        value={allInfos.gepro}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </div>
                            <>
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    <FormControlLabel
                                        label="In Hold"
                                        control={
                                            <Switch
                                                onChange={(e) => updateFields('inHold', e.target.checked)}
                                                sx={{ marginLeft: '20px' }}
                                                size="medium"
                                                name="inHold"
                                                checked={fields.inHold} // Correção aqui
                                            />
                                        }
                                    />
                                </div>
                                <Divider />

                                <div>
                                    <span style={{ marginTop: '10px', marginBottom: '10px', display: 'block' }}>Informações</span>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <FormControl>
                                            <InputLabel id="demo-multiple-name-label">Codificador</InputLabel>
                                            <Select
                                                sx={{ width: '220px' }}
                                                label="Codificador"
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                type="select"
                                                name="codficator"
                                                value={fields.codficator || ''}
                                                defaultValue={fields.codficator || ''}
                                                onChange={(e) => updateFields('codficator', e.target.value)}
                                            >
                                                {codificatorData.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.username}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                value={fields?.descriptionCoding}
                                                label="Descrição"
                                                type="text"
                                                name="descriptionCoding"
                                                onChange={(e) => updateFields('descriptionCoding', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel id="demo-nivel">Nível</InputLabel>
                                            <Select
                                                sx={{ width: '220px' }}
                                                label="Nível"
                                                labelId="demo-nivel"
                                                id="demo-nivel"
                                                name="levels"
                                                defaultValue={fields.levels || ''}
                                                value={fields.levels || ''}
                                                onChange={(e) => updateFields('levels', e.target.value)}
                                            >
                                                {dificultLevels.map((item) => (
                                                    <MenuItem key={item.level} value={item.level}>
                                                        {item.level}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px', marginLeft: '15px', marginRight: '15px' }}
                                                label="Horas Estimadas"
                                                type="time"
                                                value={fields.hours || ''}
                                                defaultValue={fields.hours || ''}
                                                onChange={(e) => updateFields('hours', e.target.value)}
                                            />
                                        </FormControl>{' '}
                                    </div>

                                    <span style={{ marginTop: '10px', marginBottom: '10px', display: 'block' }}>PCP</span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '10px',
                                            gap: '20px'
                                        }}
                                    >
                                        <FormControl>
                                            <TextField
                                                label="Entrada"
                                                type="date"
                                                variant="standard"
                                                name="pcp_enter"
                                                sx={{
                                                    width: '220px',
                                                    '& input:disabled': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontWeight: 'bold'
                                                    }
                                                }}
                                                onChange={(e) => updateFields('pcp_enter', e.target.value)}
                                                value={dateForInput(fields.pcp_enter)}
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Entrega"
                                                type="date"
                                                variant="standard"
                                                onChange={(e) => updateFields('pcp_exit', e.target.value)}
                                                name="pcp_exit"
                                                disabled
                                                value={dateForInput(fields.pcp_exit)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}
                                    >
                                        <div>
                                            <span style={{ marginTop: '10px', marginBottom: '10px', display: 'block' }}>Codificação</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Início"
                                                        type="date"
                                                        name="codification_start"
                                                        value={fields.codification_start}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(e) => updateFields('codification_start', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Entrega"
                                                        type="date"
                                                        name="codification_end"
                                                        value={fields.codification_end}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(e) => updateFields('codification_end', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{ marginTop: '10px', marginBottom: '10px', display: 'block' }}>Vulcan</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '20px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Programação"
                                                        type="date"
                                                        name="vulcan_prog"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields?.vulcan_prog}
                                                        onChange={(e) => updateFields('vulcan_prog', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Corte"
                                                        type="date"
                                                        name="cut"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.cut}
                                                        onChange={(e) => updateFields('cut', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <span>Produção</span>
                                            <span>Setor (TDC/Giro/Esp.)</span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                gap: '20px',
                                                marginTop: '10px',
                                                justifyContent: 'space-between',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Finalização de fabricação"
                                                        type="date"
                                                        name="manufacture"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.production_fab}
                                                        onChange={(e) => updateFields('production_fab', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Spiro"
                                                        type="date"
                                                        name="sawmill"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.sawmill}
                                                        onChange={(e) => updateFields('sawmill', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Isolamento"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        name="isolamento"
                                                        value={fields.isolamento}
                                                        onChange={(e) => updateFields('isolamento', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Solda"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.solda}
                                                        name="solda"
                                                        onChange={(e) => updateFields('solda', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>
                                            <span>Suportes/Flanges</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Serralheria"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.serralheria}
                                                        name="serralheria"
                                                        onChange={(e) => updateFields('serralheria', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Pintura"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.painting}
                                                        name="painting"
                                                        onChange={(e) => updateFields('painting', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Pintura eletroestática</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Envio"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.pinturaEnvio}
                                                        name="pinturaEnvio"
                                                        onChange={(e) => updateFields('pinturaEnvio', e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px' }}
                                                        label="Retorno"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.pinturaRetorno}
                                                        name="pinturaRetorno"
                                                        onChange={(e) => updateFields('pinturaRetorno', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        <div>
                                            <span>Pintura Refrin</span>
                                            <div>
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px', marginTop: '10px' }}
                                                        label="Finalização"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        name="finalizacao"
                                                        value={fields.finalizacao}
                                                        onChange={(e) => updateFields('finalizacao', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Expedição</span>
                                            <div>
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px', marginTop: '10px' }}
                                                        label="Previsão"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.production_leaving}
                                                        name="production_leaving"
                                                        onChange={(e) => updateFields('production_leaving', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Reprogramação</span>
                                            <div>
                                                <FormControl>
                                                    <TextField
                                                        sx={{ width: '220px', marginTop: '10px' }}
                                                        label="Reprogramação"
                                                        type="date"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={fields.reprogramming}
                                                        name="reprogramming"
                                                        onChange={(e) => updateFields('reprogramming', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '20px',
                                            marginTop: '20px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <FormControl>
                                            <InputLabel id="Tipo de pintura">Tipo de pintura</InputLabel>
                                            <Select
                                                sx={{ width: '220px' }}
                                                label="Tipo de pintura"
                                                labelId="Tipo de pintura"
                                                id="Tipo de pintura"
                                                type="select"
                                                name="paintType"
                                                value={fields.paintType || ''}
                                                onChange={(e) => updateFields('paintType', e.target.value)}
                                            >
                                                {paintTypesList.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel id="Tipo de embalagem">Tipo de embalagem</InputLabel>
                                            <Select
                                                sx={{ width: '220px' }}
                                                label="Tipo de embalagem"
                                                labelId="Tipo de embalagem"
                                                id="Tipo de embalagem"
                                                type="select"
                                                name="packing"
                                                value={fields.packing || ''}
                                                onChange={(e) => updateFields('packing', e.target.value)}
                                            >
                                                {packingTypesList.map((item, index) => (
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <Divider />
                                    <div style={{ marginTop: '10px', marginBottom: '10px', display: 'block' }}>Observação</div>
                                    <div style={{ display: 'flex', width: '500px', gap: '20px' }}>
                                        <div>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="selectID">Tipo</InputLabel>
                                                <Select
                                                    sx={{ width: '150px' }}
                                                    label="Tipo"
                                                    labelId="selectID"
                                                    name="type"
                                                    onChange={(e) => {
                                                        setTypeDescription(e.target.value);
                                                        updateFields('type', e.target.value);
                                                    }}
                                                >
                                                    {optionsSelect.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {typeDescription !== null && (
                                            <>
                                                {(() => {
                                                    let label, name;

                                                    if (typeDescription === 0) {
                                                        label = 'Observação PCP';
                                                        name = 'pcpObs';
                                                    } else if (typeDescription === 1) {
                                                        label = 'Observação expedição';
                                                        name = 'expeditionObs';
                                                    } else if (typeDescription === 2) {
                                                        label = 'Observação codificação';
                                                        name = 'codingObs';
                                                    }

                                                    return (
                                                        <TextField
                                                            sx={{ width: '400px' }}
                                                            label={label}
                                                            multiline
                                                            rows={4}
                                                            value={fields[name]}
                                                            name={name}
                                                            InputLabelProps={{ shrink: true }}
                                                            onChange={(e) => updateFields(name, e.target.value)}
                                                        />
                                                    );
                                                })()}
                                            </>
                                        )}
                                    </div>
                                    <div style={{ margin: '10px 0', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button sx={{ textAlign: 'end' }} disabled={load} onClick={handleEdit} variant="contained">
                                            {load ? 'Carregando...' : 'Salvar alterações'}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </form>
                    </div>
                </>
            )}
        </Drawer>
    );
};

export default DrawerEdit;
