import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormLabel,
    useMediaQuery,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// third party
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';
import { Link, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import Absence from './components/Absence';
import WorkOut from './components/WorkOut';
import MissWork from './components/MissWork';

function RegisterExitView({ ...others }) {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const api = useApi();
    const navigate = useNavigate();

    const [submitError, setSubmitError] = useState(null);

    const [allEmployee, setAllEmployee] = useState([]);
    const [allReason, setAllReason] = useState([]);

    // form statte
    const [selectedType, setSelectedType] = useState('');

    //select options
    const optionsSelect = ['Atraso', 'Falta', 'Saída'];

    // validação
    const validationSchema = Yup.object().shape({
        selectedType: Yup.string().required('Campo obrigatório')
    });

    const getAllEmployees = async () => {
        const response = await api.getAllEmployee();
        setAllEmployee(response.data.allEmployees);
    };

    const getAllReasons = async () => {
        const response = await api.getAllReason();
        setAllReason(response.data.allReason);
    };

    useEffect(() => {
        getAllEmployees();
        getAllReasons();
    }, []);

    const handleRegister = async (values) => {
        try {
            const data = await api.registerExit(values);
            console.log(values);
            window.location.reload();
        } catch (error) {
            console.log(error);
            setSubmitError(error.response.data.message);
        }
    };

    return (
        <MainCard title={`Cadastrar ${selectedType}`}>
            <gridSpacing container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 auto',
                        padding: '1em',
                        width: '70%'
                    }}
                >
                    <Formik
                        initialValues={{ selectedType: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            console.log(values);
                        }}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                            <Form>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="main-select">Selecione o tipo de Saída</InputLabel>
                                    <Select
                                        style={{ height: '60px', width: '300px', marginBottom: '30px' }}
                                        id="main-select"
                                        name="selectedType"
                                        label="Selecione o tipo de Saída"
                                        value={values.selectedType}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setSelectedType(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                    >
                                        {optionsSelect.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        <ErrorMessage name="selectedType" />
                                    </FormHelperText>
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <Box>
                    {selectedType === 'Atraso' && <Absence employee={allEmployee} reason={allReason} />}
                    {selectedType === 'Saída' && <WorkOut employee={allEmployee} reason={allReason} />}
                    {selectedType === 'Falta' && <MissWork employee={allEmployee} reason={allReason} />}
                </Box>
            </gridSpacing>
        </MainCard>
    );
}

export default RegisterExitView;
