import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4
};

export function ObservationModalStop({ open, setOpen, stopFunction, observation, loadStop }) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Adicione uma observação</h3>
                    <TextField label="Observação" sx={{ width: '100%' }} onChange={(e) => observation(e.target.value)} />
                    <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button onClick={handleClose} variant="contained" color="error">
                            Cancelar
                        </Button>
                        <Button onClick={stopFunction} variant="contained" disabled={loadStop}>
                            {loadStop ? 'Carregando...' : 'Finalizar Atividade'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
