export const date = (data) => {
    const dateObj = new Date(data);

    if (!isNaN(dateObj)) {
        const dia = String(dateObj.getUTCDate()).padStart(2, '0');
        const mes = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Os meses em JavaScript começam de 0
        const ano = dateObj.getUTCFullYear();
        return `${ano}-${mes}-${dia}`;
    }

    return null; // Se a conversão falhar, retorna null.
};
