/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '900px',
    bgcolor: 'transparent',
    maxHeight: '700px',
    overflow: 'auto'
};

export function ViewImage({ open, setOpen, image }) {
    const [zoomLevel, setZoomLevel] = useState(1);

    const handleZoomIn = () => {
        setZoomLevel((prevZoom) => prevZoom + 0.1); // Aumenta o zoom em 10%
    };

    const handleZoomOut = () => {
        setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.1)); // Reduz o zoom em 10%, com um valor mínimo de 10%
    };
    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <img
                    src={image}
                    alt="Imagem"
                    style={{
                        width: `${100 * zoomLevel}%`,
                        height: `${100 * zoomLevel}%`,
                        objectFit: 'cover',
                        cursor: 'zoom-in',
                        transition: 'width 0.3s ease, height 0.3s ease'
                    }}
                    onClick={handleZoomIn}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        handleZoomOut();
                    }}
                />
            </Box>
        </Modal>
    );
}
