import { Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ConfirmRequest from './components/ConfirmRequest';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { LoadAnimated } from 'ui-component/LoadAnimated';
import { Box } from '@mui/system';

const RequestedPage = () => {
    const api = useApi();
    const [row, setRow] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [loading, setLoading] = useState(false);
    const getRequests = async () => {
        setLoading(true);
        try {
            const { data } = await api.getNewRequestPage();
            console.log('🚀 ~ getRequests ~ data:', data);
            const row = data.map((item) => ({
                ...item,
                id: item?.id,
                client: item?.cliente,
                construction: item?.obra,
                gepro: item?.gepro
            }));
            setRow(row);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ getRequests ~ error:', error);
        }
    };

    useEffect(() => {
        getRequests();
    }, []);

    const columns = [
        { field: 'gepro', headerName: 'Gepro', flex: 1 },
        {
            field: 'client',
            headerName: 'Cliente',
            flex: 1
        },
        {
            field: 'construction',
            headerName: 'Obra',
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.4,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Confirmar">
                        <IconButton
                            onClick={() => {
                                setOpenConfirm(true);
                                setCurrentRow(params.row);
                            }}
                        >
                            <QueryBuilderIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <MainCard title="Coletas solicitadas">
            <ConfirmRequest open={openConfirm} setOpen={setOpenConfirm} getData={getRequests} dataProp={currentRow} />

            <Grid container spacing={gridSpacing}>
                {loading ? (
                    <Box
                        sx={{
                            marginTop: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            width: '100%'
                        }}
                    >
                        <LoadAnimated />
                    </Box>
                ) : row.length > 0 ? (
                    <DataGrid rows={row} columns={columns} sx={{ width: '100%', maxHeight: '500px' }} hideFooter />
                ) : (
                    <p style={{ marginLeft: '20px' }}>Nenhuma coleta solicitada</p>
                )}
            </Grid>
        </MainCard>
    );
};

export default RequestedPage;
