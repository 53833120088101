// assets
import { IconUsers, IconUserPlus, IconAssembly, IconAnalyze, IconQuestionMark, IconAdjustments, IconLayoutDashboard } from '@tabler/icons';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const icons = {
    IconUsers,
    IconUserPlus,
    IconAssembly,
    IconAnalyze,
    IconQuestionMark,
    IconAdjustments,
    IconLayoutDashboard
};

const rh = {
    id: 'rh',
    title: 'Rh',
    type: 'group',
    children: [
        {
            id: 'rhDashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/main/rh/dashboard',
            icon: EqualizerIcon,
            breadcrumbs: false
        },
        {
            id: 'cadastros',
            title: 'Cadastros',
            type: 'collapse',
            icon: icons.IconAdjustments,
            children: [
                {
                    id: 'registerSector',
                    title: 'Cadastrar Setor',
                    type: 'item',
                    url: '/main/rh/registerSector',
                    icon: icons.IconAssembly,
                    breadcrumbs: false
                },
                {
                    id: 'registerBranch',
                    title: 'Cadastrar Filial',
                    type: 'item',
                    url: '/main/rh/registerBranch',
                    icon: icons.IconAnalyze,
                    breadcrumbs: false
                },
                {
                    id: 'registerReason',
                    title: 'Cadastrar Motivo',
                    type: 'item',
                    url: '/main/rh/registerReason',
                    icon: icons.IconQuestionMark,
                    breadcrumbs: false
                },
                {
                    id: 'registerEmployee',
                    title: 'Cadastrar colaborador',
                    type: 'item',
                    url: '/main/rh/registerEmployee',
                    icon: icons.IconUserPlus,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'rh',
            title: 'Controle de saídas',
            type: 'item',
            url: '/main/rh/exits',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export default rh;
