import { Box, IconButton, Tooltip } from '@mui/material';
import { IconTrash } from '@tabler/icons';
import { DateAndHour, DateAndHourPreview } from 'utils/DateAndHour';
import { formatacaoData } from 'utils/formatacaoData';
import { formatarTempo } from 'utils/formatValueByHour';

export function Row({ data, setOpenConfirmDeleteModal, setSelectedRow }) {
    console.log('🚀 ~ Row ~ data:', data);
    function calculateStatus(cel_dt_programacao, tempo_previsto_termino) {
        const dateOne = new Date(cel_dt_programacao); // Data programada
        const dateTwo = new Date(tempo_previsto_termino); // Data de término previsto

        // Normaliza ambas as datas para o início do dia (meia-noite)
        dateOne.setUTCHours(0, 0, 0, 0);
        dateTwo.setUTCHours(0, 0, 0, 0);

        // Verifica as comparações entre as datas
        if (dateOne.getTime() === dateTwo.getTime()) {
            return 'Risco de Atraso'; // Ambas as datas são iguais (mesmo dia)
        } else if (dateOne < dateTwo) {
            return 'Atrasado'; // Data programada é anterior à data de término
        } else {
            return 'Em andamento'; // Data programada é posterior à data de término
        }
    }

    const statusRow = calculateStatus(data?.cel_dt_programacao, data?.tempo_previsto_termino);

    const backgroundColor = statusRow === 'Atrasado' ? 'red' : statusRow === 'Em andamento' ? 'green' : '#FFFF00';

    const textColor = backgroundColor === 'green' || backgroundColor === 'red' ? 'white' : 'black';

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                padding: '0 10px',
                alignItems: 'center',
                backgroundColor: '#fafafa'
            }}
        >
            {/* <p>{data?.machineData?.id}</p> */}
            <p>{data?.machineData?.cliente}</p>
            <p>{data?.machineData?.agrupamentos}</p>
            <p>{data?.quantidade_pecas}</p>
            <p>{data?.ord_in_codigo}</p>
            <p>{data?.diametro_geral?.split('.')[0]}</p>
            <p>{data?.forma_construtiva}</p>
            <p>{data?.bitola}</p>
            <p>{formatarTempo(data?.tempo_total_fab)}</p>
            <p>{formatacaoData(data?.cel_dt_programacao)}</p>
            <p>{DateAndHourPreview(data?.tempo_previsto_termino)}</p>

            <div
                style={{
                    background: backgroundColor,
                    color: textColor, // Ajuste da cor do texto
                    fontWeight: 'bold',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {statusRow}
            </div>

            <Tooltip title="Delete" sx={{ width: '50px', marginLeft: '20px' }}>
                <IconButton
                    onClick={() => {
                        setOpenConfirmDeleteModal(true);
                        setSelectedRow(data);
                    }}
                >
                    <IconTrash color={'red'} />
                </IconButton>
            </Tooltip>
        </Box>
    );
}
