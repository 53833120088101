export const typeOfTransport = [
    { type: 'HR ABERTA - C. 3,00 x L 1,80' },
    { type: 'HR BAÚ - C. 3,00 x L 1,80 x A 2,00' },
    { type: 'VUC ABERTO - C. 4,00 x L 2,00' },
    { type: 'VUC BAU - C. 4,00 x L 2,00 x A 2,20' },
    { type: '3/4 ABERTO - C. 5,00 x L 2,00 x' },
    { type: '3/4 BAU - C. 5,00 x L 2,00 x A 2,50' },
    { type: 'TOCO ABERTO - C. 6,00 x L 2,40 x' },
    { type: 'TOCO BAÚ - C. 6,00 x L 2,40 x A 2,70' },
    { type: 'TRUCK ABERTO - C. 8,00 x L 2,40 x' },
    { type: 'TRUCK BAÚ - C. 8,00 x L 2,40 x A 2,70' },
    { type: 'CARRETA ABERTA - C. 12,00 x L 2,40 x' },
    { type: 'CARRETA BAU - C. 14,50 x L 2,40 x A 2,70' },
    { type: 'PICK UP - Montana/Saveiro' }
];
