import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Modal,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';

const SalesOrder = () => {
    return (
        <MainCard title="Pedidos de venda">
            <Grid container spacing={gridSpacing}>
                <Typography>Pedidos de venda</Typography>
            </Grid>
        </MainCard>
    );
};

export default SalesOrder;
