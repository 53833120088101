import styled from '@emotion/styled';

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    height: 110px;
`;

export const ContainerShandow = styled.div`
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 2px 4px 8px 0px #999;
`;

export const RightArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const TableArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    width: 850px;
`;

export const CardArea = styled.div`
    display: flex;
    gap: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -110px;
    height: 80px;
`;
