const formater = (date) => {
    const dataObj = new Date(date);
    const dia = dataObj.getUTCDate();
    const mes = dataObj.getUTCMonth() + 1;
    const ano = dataObj.getUTCFullYear();
    const diaFormatado = dia < 10 ? '0' + dia : dia;
    const mesFormatado = mes < 10 ? '0' + mes : mes;
    return `${diaFormatado}/${mesFormatado}/${ano}`;
};
module.exports = formater;
