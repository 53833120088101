import { Modal, Box, TextField, Button, Badge } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { ViewImage } from './ViewImage';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import '../../../../../assets/scss/shake.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '700px',
    overflow: 'auto'
};

export function FinishModal({ open, setOpen, currentRow, getRequests }) {
    const api = useApi();

    const [images, setImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [openViewImage, setOpenViewImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        const files = event.target.files;
        const newImageFiles = [...imageFiles];

        // Adicionar novos arquivos à matriz existente
        for (let i = 0; i < files.length; i++) {
            newImageFiles.push(files[i]);
        }

        setImageFiles(newImageFiles);

        const imagesArray = Array.from(files);
        const imagesURLs = imagesArray.map((file) => URL.createObjectURL(file));
        setImages((prevImages) => [...prevImages, ...imagesURLs]);
    };

    const handleClose = () => {
        setOpen(false);
        setImages([]);
        setImageFiles([]);
    };

    const handleImageClick = (index) => {
        setSelectedImage(images[index]);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();

        // Adicionar cada arquivo e seu respectivo ID ao FormData
        imageFiles.forEach((file) => {
            formData.append('buffers', file);
        });
        try {
            await api.postFinishExpedition(currentRow?.Registro_transporte?.id, formData);
            toast.success('Expedição finalizada com sucesso!');
            setLoading(false);
            getRequests();
            setImageFiles([]);
            setImages([]);
            setOpen(false);
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao encerrar expedição');
            setImageFiles([]);
            setImages([]);
            setLoading(false);
        }
    };

    const close = async () => {
        setLoading(true);
        const formData = new FormData();

        // Adicionar cada arquivo e seu respectivo ID ao FormData
        imageFiles.forEach((file) => {
            formData.append('buffers', file);
        });
        try {
            await api.releaseClosureAV(currentRow.idWithoutString, formData);
            toast.success('Expedição finalizada com sucesso!');
            setLoading(false);
            getRequests();
            setImageFiles([]);
            setImages([]);
            setOpen(false);
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao encerrar expedição');
            setImageFiles([]);
            setImages([]);
            setLoading(false);
        }
    };

    const handleDeleteImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <ViewImage open={openViewImage} setOpen={setOpenViewImage} image={selectedImage} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
                    <h3>Finalizar expedição</h3>
                    <CloseIcon sx={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} onClick={handleClose} />
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p>ID: {currentRow?.id}</p>
                        <p>Cliente: {currentRow?.client}</p>
                    </Box>
                    <Box sx={{ marginTop: '-25px' }}>
                        {currentRow.pedidoAvulso ? (
                            <p>Pedido: {currentRow?.pedidoAvulso}</p>
                        ) : (
                            <>
                                {' '}
                                <p>Pedido: {currentRow?.pedido}</p>
                                <p style={{ marginTop: '-13px' }}>Obra: {currentRow?.obra}</p>
                            </>
                        )}
                    </Box>
                </Box>
                <Box>
                    <p>Adicionar fotos</p>
                    <TextField
                        type="file"
                        inputProps={{
                            multiple: true,
                            accept: 'image/*'
                        }}
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleFileChange}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        gap: '13px',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '25px',
                        marginBottom: '25px'
                    }}
                >
                    {images.length > 0 &&
                        images?.map((imageUrl, index) => (
                            <div key={index} style={{ position: 'relative' }} className={'image-container shake-rotate'}>
                                <button
                                    style={{ all: 'unset' }}
                                    onClick={() => {
                                        handleImageClick(index);
                                        setOpenViewImage(true);
                                    }}
                                >
                                    <img
                                        key={index}
                                        src={imageUrl}
                                        alt={`Imagem ${index}`}
                                        style={{
                                            width: '130px',
                                            height: '130px',
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                            boxShadow: '2px 2px 8px #202020'
                                        }}
                                    />
                                </button>
                                <Badge
                                    badgeContent={'x'}
                                    color="error"
                                    sx={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
                                    onClick={() => handleDeleteImage(index)}
                                />
                            </div>
                        ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{ width: '250px' }}
                        disabled={loading}
                        onClick={() => {
                            if (currentRow.type === 'expedicao') {
                                handleSubmit();
                            } else {
                                close();
                            }
                        }}
                    >
                        {loading ? 'Finalizando...' : 'Finalizar expedição'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
