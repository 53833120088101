import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Register from 'views/pages/authentication/authentication3/Register3';
import EditUser from 'views/users/EditUser';
import BudgetView from 'views/BudgetView';
import NotAllowed from 'views/NotAllowed';
import Request from 'views/Request';
import Sales from 'views/Sales';
import Pcp from 'views/Pcp';
import MailView from 'views/Request/MailView/MailView';
import BudgetPage from 'views/BudgetView/BudgetPage/BudgetPage';
import Archive from '../views/Request/ArchiveView/Archive';
import ModalPreOrc from 'views/Sales/BI/ModalPreOrc';
import Painel from 'views/Sales/Painel';
import IndicadorVendas from 'views/Sales/BI/IndicadorVendas';
import ProducaoSeriada from 'views/Sales/BI/ProdSeriada';
import GestaoObras from 'views/Sales/BI/GestaoObras';
import Orcamento from 'views/Sales/BI/Orcamento';
import VisaoGeral from 'views/Sales/BI/VisaoGeral';
import SalesOrder from 'views/SalesOrder';
import Exits from 'views/RH/Exits';
import RegisterEmployee from 'views/RH/RegisterEmployee';
import RegisterSector from 'views/RH/RegisterSector';
import RegisterBranch from 'views/RH/RegisterBranch';
import RegisterReason from 'views/RH/RegisterReason';
import RegisterExitView from 'views/RH/Exits/Create/createExit';
import Suporte from 'views/Suporte';
import IndicadorCodificador from 'views/Engenharia/BI';
import CargaFabricaEng from 'views/Engenharia/BI/cargaFabrica';
import GestaoObrasEng from 'views/Engenharia/BI/gestaoObras';
import CodificacaoEng from 'views/Engenharia/BI/codificacao';
import RhDashboard from 'views/RH/Dashboard';
import OrcamentosTESTE from 'views/Request/BI/preOrc';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

import { getAccessLevel } from 'utils/getAccessLevel';
import Encoders from 'views/Engenharia/Encoders';
import DistributionOfWorks from 'views/Engenharia/DistributionOfWorks';
import AddNewConstruction from 'views/Engenharia/DistributionOfWorks/components/AddNewConstruction';
import Programming from 'views/Expredition/Programming';
import ProgrammingID from 'views/Expredition/ProgrammingID';
import Collect from 'views/Expredition/collect';
import TransportRecord from 'views/Expredition/collect/TransportRecordID/TransportRecord';
import LooseTransport from 'views/Expredition/LooseTransport';
import EditTransportRecordId from 'views/Expredition/collect/EditTransportRecordId';
import SelectGroupings from 'views/Expredition/Programming/components/SelectGroupings';

import PainelOrc from 'views/Request/Painel';
import PreOrc from 'views/Request/BI/preOrc';
import Orc from 'views/Request/BI/orc';
import PainelPcp from 'views/Pcp/Painel';
import cargaFabrica from 'views/Pcp/BI/cargaFabrica';
import CargaFabrica from 'views/Pcp/BI/cargaFabrica';
import GestaoObrasPcp from 'views/Pcp/BI/gestaoObras';
import CodificacaoPcp from 'views/Pcp/BI/codificacao';
import ControleProjetoPcp from 'views/Pcp/BI/controleProjeto';
import ProducaoSeriadaPcp from 'views/Pcp/BI/producaoSeriadaPcp';
import VisaoGeralPcp from 'views/Pcp/BI/visaoGeral';
import SolicitacaoCompraPcp from 'views/Pcp/BI/solicitacaoCompras';
import ControlePinturaPcp from 'views/Pcp/BI/pinturaExterna';
import PainelExpedition from 'views/Expredition/Painel';
import GestaoObrasExpedition from 'views/Expredition/BI/gestaoObras';
import ObrasExpedition from 'views/Expredition/BI/obraExpedicao';
import PinturaExpedition from 'views/Expredition/BI/pinturaExpedition';
import PainelEngenharia from 'views/Engenharia/Painel';

import RequestedPage from 'views/Expredition/newPages/Requested';
import ScheduledPage from 'views/Expredition/newPages/Scheduled';
import ConciergePage from 'views/Expredition/newPages/Concierge';
import FinishedPge from 'views/Expredition/newPages/Finished';
import InProgress from 'views/Expredition/newPages/InProgress';
import { Codification } from 'views/Engenharia/Codification';
import { TimeLineCodification } from 'views/Engenharia/TimeLine';
import { OrderManager } from 'views/SalesRank/OrderManager';
import { SalesPainel } from 'views/SalesRank/SalesPainel';
import { Calendar, CalendarPage } from 'views/Engenharia/Calendar';
import { SectionRegistration } from 'views/Pcp/sector-registration';
import { WorkCenter } from 'views/Pcp/work-center';
import { WorkCell } from 'views/Pcp/WorkCell';
import { CreateMachine } from 'views/Pcp/createMachine';
import { Demands } from 'views/Pcp/Demands';
import { Planning } from 'views/Pcp/Demands/Planning';
import { MachineScreen } from 'views/Pcp/machineScreen';
import { element } from 'prop-types';
import { PainelGestaoObras } from 'views/PanelConstruction';
import { MachineSchedule } from 'views/Pcp/MachineSchedule';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/main',
    element: <MainLayout />,
    children: [
        {
            path: '/main/dashboard',
            element: getAccessLevel() === 'Administrador' ? <DashboardDefault /> : <NotAllowed />
        },
        {
            path: '/main/register',
            element: getAccessLevel() === 'Administrador' ? <Register /> : <NotAllowed />
        },
        {
            path: '/main/edituser',
            element: getAccessLevel() === 'Administrador' ? <EditUser /> : <NotAllowed />
        },
        {
            path: '/main/request',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Orcamentista' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ||
                getAccessLevel() === 'Vendedor' ? (
                    <Request />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/archive',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Orcamentista' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ||
                getAccessLevel() === 'Vendedor' ? (
                    <Archive />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/budget',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Orcamentista' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <BudgetView />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/budget/:id',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Orcamentista' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <BudgetPage />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/sales',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <Sales />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/sales_order',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <SalesOrder />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/modal/preorc',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <ModalPreOrc />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/modal/indic_vendas',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <IndicadorVendas />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/modal/prod_seriada',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <ProducaoSeriada />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/modal/gestao_obras',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <GestaoObras />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/modal/orcamento',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <Orcamento />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/modal/visao_geral',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <VisaoGeral />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/painel',
            element:
                getAccessLevel() === 'Administrador' ||
                getAccessLevel() === 'Vendedor' ||
                getAccessLevel() === 'admComercial' ||
                getAccessLevel() === 'Comercial/Engenharia' ? (
                    <Painel />
                ) : (
                    <NotAllowed />
                )
        },
        {
            path: '/main/pcp',
            element: getAccessLevel() === 'Administrador' ? <Pcp /> : <NotAllowed />
        },
        {
            path: '/main/mailview/:id',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Orçamentista' ? <MailView /> : <NotAllowed />
        },
        {
            path: '/main/rh/exits',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <Exits /> : <NotAllowed />
        },
        {
            path: '/main/rh/dashboard',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <RhDashboard /> : <NotAllowed />
        },
        {
            path: '/main/rh/registerEmployee',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <RegisterEmployee /> : <NotAllowed />
        },
        {
            path: '/main/rh/registerSector',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <RegisterSector /> : <NotAllowed />
        },
        {
            path: '/main/rh/registerBranch',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <RegisterBranch /> : <NotAllowed />
        },
        {
            path: '/main/rh/registerReason',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <RegisterReason /> : <NotAllowed />
        },
        {
            path: '/main/rh/newExit',
            element: getAccessLevel() === 'Administrador' || getAccessLevel() === 'Rh' ? <RegisterExitView /> : <NotAllowed />
        },
        {
            path: '/main/graph-orcamento',
            element: <PainelOrc />
        },
        {
            path: '/main/graph-orcamento/pre-orc',
            element: <PreOrc />
        },
        {
            path: '/main/graph-orcamento/orc',
            element: <Orc />
        },
        {
            path: '/main/graph-pcp',
            element: <PainelPcp />
        },
        {
            path: '/main/graph-pcp/carga-fabrica',
            element: <CargaFabrica />
        },
        {
            path: '/main/graph-pcp/gestao-obra',
            element: <GestaoObrasPcp />
        },
        {
            path: '/main/graph-pcp/codificacao',
            element: <CodificacaoPcp />
        },
        {
            path: '/main/graph-pcp/controle-projetos',
            element: <ControleProjetoPcp />
        },
        {
            path: '/main/graph-pcp/producao-seriada',
            element: <ProducaoSeriadaPcp />
        },
        {
            path: '/main/graph-pcp/visao-geral',
            element: <VisaoGeralPcp />
        },
        {
            path: '/main/graph-pcp/solicitacao-compra',
            element: <SolicitacaoCompraPcp />
        },
        {
            path: '/main/graph-pcp/pintura-externa',
            element: <ControlePinturaPcp />
        },
        {
            path: '/main/graph-expedition',
            element: <PainelExpedition />
        },
        {
            path: '/main/graph-expedition/gestao-obras',
            element: <GestaoObrasExpedition />
        },
        {
            path: '/main/graph-expedition/obra-expedicao',
            element: <ObrasExpedition />
        },
        {
            path: '/main/graph-expedition/controle-pintura',
            element: <PinturaExpedition />
        },
        {
            path: '/main/graph-engenharia',
            element: <PainelEngenharia />
        },
        {
            path: '/main/graph-engenharia/indicador-codificacao',
            element: <IndicadorCodificador />
        },
        {
            path: '/main/graph-engenharia/carga-fabrica',
            element: <CargaFabricaEng />
        },
        {
            path: '/main/graph-engenharia/gestao-obras',
            element: <GestaoObrasEng />
        },
        {
            path: '/main/graph-engenharia/codificacao',
            element: <CodificacaoEng />
        },
        {
            path: '/main/suporte',
            element: <Suporte />
        },
        {
            path: '/main/engenharia/p_codif',
            element: <IndicadorCodificador />
        },
        {
            path: '/main/engenharia/codificadores',
            element: <Encoders />
        },
        {
            path: '/main/controles/paineis',
            element: <IndicadorCodificador />
        },
        {
            path: '/main/engenharia/distribuicao-obras',
            element: <DistributionOfWorks />
        },
        {
            path: '/main/engenharia/codificacao',
            element: <Codification />
        },
        {
            path: '/main/engenharia/codificacao/:id',
            element: <TimeLineCodification />
        },
        {
            path: '/main/engenharia/nova-distribuicao/:id',
            element: <AddNewConstruction />
        },
        {
            path: '/main/expedicao/programacaes',
            element: <Programming />
        },
        {
            path: '/main/expedicao/programacoes',
            element: <ProgrammingID />
        },
        {
            path: '/main/expedicao/agrupamentos/:id',
            element: <SelectGroupings />
        },
        {
            path: '/main/expedicao/coleta',
            element: <Collect />
        },
        {
            path: '/main/expedicao/coleta/:id',
            element: <TransportRecord />
        },
        {
            path: '/main/expedicao/coleta-edit/:id',
            element: <EditTransportRecordId />
        },
        {
            path: '/main/expedicao/transporte-avulso',
            element: <LooseTransport />
        }, //novas rotas de expedição
        {
            path: '/main/expedicao/solicitadas',
            element: <RequestedPage />
        },
        {
            path: '/main/expedicao/confirmadas',
            element: <ScheduledPage />
        },
        {
            path: '/main/expedicao/portaria',
            element: <ConciergePage />
        },
        {
            path: '/main/expedicao/em-carregamento',
            element: <InProgress />
        },
        {
            path: '/main/expedicao/finalizadas',
            element: <FinishedPge />
        },
        {
            path: '/main/gestao-pedidos',
            element: <OrderManager />
        },
        {
            path: '/main/gestao-pedidos/painel-vendas',
            element: <SalesPainel />
        },
        {
            path: '/main/engenharia/calendario-obras',
            element: <CalendarPage />
        },
        {
            path: '/main/pcp/sector-registration',
            element: <SectionRegistration />
        },
        {
            path: '/main/pcp/centro-de-trabalho',
            element: <WorkCenter />
        },
        {
            path: '/main/pcp/celula-de-trabalho',
            element: <WorkCell />
        },
        {
            path: '/main/pcp/cadastro-maquinas',
            element: <CreateMachine />
        },
        {
            path: '/main/pcp/demandas',
            element: <Demands />
        },
        {
            path: '/main/pcp/planejamento',
            element: <Planning />
        },
        {
            path: '/main/pcp/maquinas',
            element: <MachineScreen />
        },
        {
            path: '/main/control/gestao-obras',
            element: <PainelGestaoObras />
        },
        {
            path: '/main/pcp/cronograma-maquinas',
            element: <MachineSchedule />
        }
    ]
};

export default MainRoutes;
