import { CircularProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import SendIcon from '@mui/icons-material/Send';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function ConfigureActivitys({ open, setOpen }) {
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [activity, setActivity] = useState('');
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [loadDelete, setLoadDelete] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [currentId, setCurrentId] = useState('');

    async function getAllActivity() {
        try {
            const { data } = await api.getAllActivits();
            setData(data);
        } catch (error) {
            console.log('🚀 ~ getAllActivity ~ error:', error);
        }
    }

    async function addActivity() {
        setLoad(true);
        if (activity === '') {
            toast.error('Atividade não pode ser vazia');
            setLoad(false);
            return;
        }
        try {
            await api.createNewActivity({ activityType: activity });
            getAllActivity();
            setActivity('');
            toast.success('Atividade adicionada com sucesso');
            setLoad(false);
        } catch (error) {
            setLoad(false);
            console.log('🚀 ~ addActivity ~ error:', error);
        }
    }

    async function deleteActivity(id) {
        setLoadDelete(true);
        try {
            await api.deleteActivity(id);
            getAllActivity();
            toast.success('Atividade deletada com sucesso');
            setLoadDelete(false);
        } catch (error) {
            setLoadDelete(false);
            console.log('🚀 ~ deleteActivity ~ error:', error);
        }
    }

    async function handleEdit() {
        setLoad(true);
        if (activity === '') return toast.error('Atividade não pode ser vazia');
        try {
            await api.editeActivity(currentId, { activityType: activity });
            getAllActivity();
            setActivity('');
            setEditActive(false);
            toast.success('Atividade editada com sucesso');
            setLoad(false);
        } catch (error) {
            setLoad(false);
            console.log('🚀 ~ addActivity ~ error:', error);
        }
    }

    useEffect(() => {
        getAllActivity();
    }, []);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h2 style={{ textAlign: 'center' }}>Gerenciador de atividades</h2>

                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            label="Adicionar atividade"
                            sx={{ flex: 1 }}
                            value={activity}
                            onChange={(e) => setActivity(e.target.value)}
                        />
                        {editActive ? (
                            <Button sx={{ marginLeft: '10px' }} variant="contained" disabled={load} onClick={handleEdit}>
                                {load ? <CircularProgress size={20} /> : <EditIcon />}
                            </Button>
                        ) : (
                            <Button sx={{ marginLeft: '10px' }} variant="contained" disabled={load} onClick={addActivity}>
                                {load ? <CircularProgress size={20} /> : <SendIcon />}
                            </Button>
                        )}
                    </Box>

                    <Box>
                        {data.map((item) => (
                            <Box
                                key={item.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    border: '1px solid #ccc',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    marginTop: '10px'
                                }}
                            >
                                <p>{item.activityType}</p>
                                <Box>
                                    <Button
                                        sx={{ marginLeft: '10px' }}
                                        variant="contained"
                                        color="error"
                                        onClick={() => deleteActivity(item.id)}
                                        disabled={loadDelete}
                                    >
                                        {loadDelete ? <CircularProgress size={20} /> : <DeleteIcon />}
                                    </Button>
                                    <Button
                                        sx={{ marginLeft: '10px' }}
                                        variant="contained"
                                        onClick={() => {
                                            setEditActive(true);
                                            setActivity(item.activityType);
                                            setCurrentId(item.id);
                                        }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
