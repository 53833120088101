import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Button, Grid, Typography, TextareaAutosize, IconButton } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { Link, useParams } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EmailForm from '../../Request/Form/EmailForm';
import EditBudgetPage from './EditBudgetPage';
import { boxSizing, display, grid, margin } from '@mui/system';
import useStatusState from '../../../hooks/useStatusState';
import Timeline from '../../../components/TimeLine/index';
import EditIcon from '@mui/icons-material/Edit';
import ObservationCard from 'components/ObservationCard';

function BudgetPage() {
    const api = useApi();
    const { id } = useParams();

    const [budget, setBudget] = useState([]);
    const [budgetId, setBudgetId] = useState(null);
    const [newDate, setNewDate] = useState(null);
    const [dateForecast, setDateForecast] = useState(null);
    const [validate, setValidate] = useState(null);
    const [preBudgetCode, setPreBudgetCode] = useState(null);
    const [budgetReviewCode, setBudgetReviewCode] = useState(null);
    const [emailCC, setEmailCC] = useState(null);
    const [budgetCategory, setBudgetCategory] = useState(null);
    const [budgetObservation, setBudgetObservation] = useState(null);
    const [status, setStatus] = useState('');
    const [statusBudget, setStatusBudget] = useStatusState('');
    const [click, setCliked] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [observationValue, setObservationValue] = useState('');

    const handleStart = async () => {
        if (statusBudget === undefined) {
            setStatusBudget('iniciado');
            try {
                const response = await api.startBudget(id);
                await api.changeBudgetStatus(id, 'iniciado');
                window.location.reload();
            } catch (error) {
                console.error('Erro ao iniciar orçamento:', error);
            }
        }
    };

    const handlePause = async () => {
        if (statusBudget === 'iniciado' || statusBudget === 'retomado') {
            setOpen(true);
        }
    };

    const handleReturn = async () => {
        if (statusBudget === 'pausado' || statusBudget === 'finalizado') {
            setStatusBudget('retomado');
            try {
                const response = await api.returnBudget(id); // Chamada à função returnBudget
                await api.changeBudgetStatus(id, 'retomado');
                window.location.reload();
            } catch (error) {
                console.error('Erro ao retornar orçamento:', error);
            }
        }
    };

    const handleFinish = async () => {
        if (statusBudget === 'iniciado' || statusBudget === 'retomado') {
            setStatusBudget('finalizado');
            await api.changeBudgetStatus(id, 'finalizado');
            window.location.reload();
            try {
                const response = await api.finishBudget(id);
            } catch (error) {
                console.error('Erro ao finalizar orçamento:', error);
            }
        }
    };

    function handleStartKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleStart();
        }
    }

    function handlePauseKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handlePause();
        }
    }

    function handleFinishKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleFinish();
        }
    }

    function handleReturnKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleReturn();
        }
    }

    useEffect(() => {
        setBudgetId(id);
        const get = async () => {
            const response = await api.getBudgetById(id);
            setBudget(response.data);
            setNewDate(response.data.mailDate);
            setDateForecast(response.data.endForecast);
            setValidate(response.data.validateDate);
            setPreBudgetCode(response.data.preBudgetCode);
            setBudgetReviewCode(response.data.budgetReviewCode);
            setEmailCC(response.data.emailCC);
            setBudgetCategory(response.data.budgetCategory);
            setBudgetObservation(response.data.budgetObservation);
            return response;
        };
        get(budgetId);
    }, [id]);

    useEffect(() => {
        const getStatus = async () => {
            try {
                const response = await api.getStatusBudgetEvent(id);
                setStatusBudget(response.data[0]);
            } catch (error) {
                console.error('Erro ao obter o status do orçamento:', error);
            }
        };

        getStatus();
    }, [id]);

    const opcoes = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    const datetime = new Date(newDate).toLocaleString('pt-BR', opcoes);
    const forecast = new Date(dateForecast).toJSON().slice(0, 10).split('-').reverse().join('/');
    const validateDate = new Date(validate).toLocaleString('pt-BR', opcoes);

    let title = `Atendimento A${1000 + Number(budgetId)} | Assunto - ${budget.subject}`;

    return (
        <>
            <MainCard
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span>{title}</span>
                        <IconButton onClick={() => setOpenEdit(true)} aria-label="edit">
                            <EditIcon />
                        </IconButton>
                    </div>
                }
            >
                <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
                    <DialogContent>
                        <EditBudgetPage id={id} setOpenEdit={setOpenEdit} />
                    </DialogContent>
                </Dialog>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '1rem',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                        minWidth: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            marginBottom: '1rem',
                            backgroundColor: '#C0C0C0'
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                marginLeft: '1rem'
                            }}
                        >
                            Detalhes da Proposta
                        </Typography>
                    </Box>
                    <Grid
                        container
                        spacing={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Grid item xs={12} sm={6} md={1}>
                            <Box sx={{ marginBottom: '1rem', width: '18.75rem' }}>
                                <Typography variant="h6" marginLeft={'1rem'}>
                                    Vendedor:
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '0.5rem'
                                    }}
                                >
                                    <input
                                        style={{ borderRadius: '10px', height: '30px' }}
                                        value={'    ' + budget.sellerName}
                                        readOnly
                                        disabled
                                    />
                                </Typography>
                            </Box>
                            <Box sx={{ marginBottom: '1rem', width: '18.75rem' }}>
                                {' '}
                                <Typography variant="h6" marginLeft={'1rem'}>
                                    Categoria:
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '0.5rem'
                                    }}
                                >
                                    <input
                                        style={{
                                            borderRadius: '10px',
                                            height: '30px'
                                        }}
                                        value={'    ' + budget.budgetCategory}
                                        readOnly
                                        disabled
                                    />
                                </Typography>
                            </Box>
                            <Box sx={{ marginBottom: '1rem', width: '18.75rem' }}>
                                {' '}
                                <Typography variant="h6" marginLeft={'1rem'}>
                                    Negócio:
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '0.5rem'
                                    }}
                                >
                                    <input
                                        style={{
                                            borderRadius: '10px',
                                            height: '30px'
                                        }}
                                        value={'    ' + budget.business}
                                        readOnly
                                        disabled
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ marginLeft: 'auto' }}>
                            <Box sx={{ marginBottom: '1rem', width: '35rem' }}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="h6" marginLeft={'1rem'}>
                                            Orçamentista:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '0.5rem'
                                            }}
                                        >
                                            <input
                                                style={{ borderRadius: '10px', height: '30px' }}
                                                value={'    ' + budget.estimatorName}
                                                readOnly
                                                disabled
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.8}>
                                        <Typography variant="h6" marginLeft={'1rem'}>
                                            Pré-orçamento:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '0.5rem'
                                            }}
                                        >
                                            <input
                                                style={{ borderRadius: '10px', height: '30px', maxWidth: '120px' }}
                                                value={'    ' + budget.preBudgetCode}
                                                readOnly
                                                disabled
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" marginLeft={'1rem'}>
                                            Revisão:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '0.5rem'
                                            }}
                                        >
                                            <input
                                                style={{ borderRadius: '10px', height: '30px', maxWidth: '100px' }}
                                                value={'    ' + budget.budgetReviewCode}
                                                readOnly
                                                disabled
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ width: '450px' }}>
                                <Typography variant="h6" marginLeft={'1rem'}>
                                    Observação:
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        marginTop: '0.5rem'
                                    }}
                                >
                                    <textarea
                                        style={{
                                            width: '100%',
                                            height: '100px',
                                            borderRadius: '10px',
                                            resize: 'none',
                                            overflow: 'auto',
                                            padding: '5px',
                                            border: '1px solid',
                                            borderColor: 'rgba(0, 0, 0, 0.5)',
                                            fontFamily: 'inherit',
                                            fontSize: 'inherit'
                                        }}
                                        value={'  ' + budget.budgetObservation}
                                        onlyRead
                                        disabled
                                    />
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3.5}
                            sx={{
                                marginLeft: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyItems: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    width: '21rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography variant="h6" marginRight={'9rem'}>
                                    Remetente:
                                </Typography>
                                <input
                                    style={{ borderRadius: '10px', height: '30px', width: '250px', marginLeft: '0.5rem' }}
                                    value={'    ' + budget.from}
                                    readOnly
                                    disabled
                                />
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginTop: '1rem'
                                    }}
                                >
                                    <Typography variant="h6">Progresso do Atendimento:</Typography>
                                </Box>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    sx={{
                                        minWidth: '90%',
                                        height: '60%',
                                        marginTop: '1rem',
                                        borderRadius: '10px',
                                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '1rem',
                                        backgroundColor: '#2085C3',
                                        boxSizing: 'content-box'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            maxHeight: '50px',
                                            gridGap: '10px'
                                        }}
                                    >
                                        {statusBudget === undefined ? (
                                            <>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#4caf50',
                                                        color: '#fff',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handleStart}
                                                >
                                                    Iniciar
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#9e9e9e',
                                                        color: '#fff',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handlePause}
                                                    disabled
                                                >
                                                    Pausar
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#9e9e9e',
                                                        color: '#fff',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handleFinish}
                                                    disabled
                                                >
                                                    Finalizar
                                                </Button>
                                            </>
                                        ) : null}

                                        {statusBudget === 'pausado' || statusBudget === 'finalizado' ? (
                                            <>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#4caf50',
                                                        color: '#fff',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handleReturn}
                                                >
                                                    Retomar
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#9e9e9e',
                                                        color: '#fff'
                                                    }}
                                                    disabled
                                                >
                                                    Pausado
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#9e9e9e',
                                                        color: '#fff'
                                                    }}
                                                    disabled
                                                >
                                                    Finalizar
                                                </Button>
                                            </>
                                        ) : null}

                                        {statusBudget === 'iniciado' || statusBudget === 'retomado' ? (
                                            <>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#9e9e9e',
                                                        color: '#fff'
                                                    }}
                                                    disabled
                                                >
                                                    Em andamento
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: 'rgba(220, 255, 0, 0.8)',
                                                        color: '#fff',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handlePause}
                                                >
                                                    Pausar
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#cc2636',
                                                        color: '#fff',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handleFinish}
                                                >
                                                    Finalizar
                                                </Button>

                                                <Dialog open={open} onClose={() => setOpen(false)}>
                                                    <ObservationCard
                                                        observation={observationValue}
                                                        // onObservationChange={handleObservationChange}
                                                        // onSubmit={handleSubmitObservation}
                                                    />
                                                </Dialog>
                                            </>
                                        ) : null}
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <h1>Linha do Tempo</h1>
                        <Timeline />
                    </Box>
                </Box>
            </MainCard>
        </>
    );
}

export default BudgetPage;
