import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { formatarTempo } from 'utils/formatValueByHour';
import { DateAndHour } from 'utils/DateAndHour';
import { formatacaoData } from 'utils/formatacaoData';
import './datagridstyle.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: '80vh'
};

export function PreviewPlanningModal({ items2, selectedMachine, programinFunc }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [data, setData] = useState([]);

    async function handlePreviewPlanning() {
        const payload = {
            demandas: items2.map((item, index) => {
                return {
                    ord_in_codigo: item?.ordem,
                    maquinaId: selectedMachine?.id,
                    prioridade: index
                };
            })
        };

        try {
            const { data } = await api.prevDemands(payload);
            const row = data.map((item, index) => ({
                ...item,
                id: index
            }));
            setData(row);
        } catch (error) {
            console.log('🚀 ~ handlePreviewPlanning ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) {
            handlePreviewPlanning();
        }
    }, [open, items2]);

    const columns = [
        { field: 'ord_in_codigo', headerName: 'Ordem', flex: 0.5, headerAlign: 'center', align: 'center' },
        {
            field: 'diametro_geral',
            headerName: 'Diametro',
            flex: 0.5,
            valueGetter: (params) => params.value.slice(0, 3),
            headerAlign: 'center',
            align: 'center'
        },
        { field: 'quantidade_pecas', headerName: 'Quantidade', flex: 0.5, headerAlign: 'center', align: 'center' },
        { field: 'forma_construtiva', headerName: 'Construção', flex: 0.5, headerAlign: 'center', align: 'center' },
        { field: 'bitola', headerName: 'Bitola', flex: 0.5, headerAlign: 'center', align: 'center' },
        // {
        //     field: 'data_inicio',
        //     headerName: 'Início',
        //     flex: 1,
        //     valueGetter: (params) => DateAndHour(params.value),
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'tempo_previsto_termino',
            headerName: 'Previsão',
            flex: 1,
            valueGetter: (params) => DateAndHour(params.value),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'tempo_total_fab',
            headerName: 'Tempo Total',
            flex: 1,
            valueGetter: (params) => formatarTempo(params.value),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'cel_dt_programacao',
            headerName: 'Data da necessidade',
            flex: 1,
            valueGetter: (params) => formatacaoData(params.value),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => {
                let statusRow;
                const dateOne = new Date(params.row.cel_dt_programacao); // Data programada
                const dateTwo = new Date(params.row.tempo_previsto_termino); // Data de término previsto

                // Normaliza ambas as datas para o início do dia (meia-noite) em UTC
                dateOne.setUTCHours(0, 0, 0, 0);
                dateTwo.setUTCHours(0, 0, 0, 0);

                // Comparação das datas normalizadas
                if (dateOne.getTime() === dateTwo.getTime()) {
                    statusRow = 'Risco de Atraso'; // Ambas as datas são iguais
                } else if (dateOne < dateTwo) {
                    statusRow = 'Atrasado'; // Data programada é anterior à data de término
                } else {
                    statusRow = 'Em andamento'; // Data programada é posterior à data de término
                }

                return <p>{statusRow}</p>;
            },

            cellClassName: (params) => {
                // Obtemos as duas datas
                const dateOne = new Date(params.row.cel_dt_programacao);
                const dateTwo = new Date(params.row.tempo_previsto_termino);

                // Normalizamos as duas datas para o início do dia (meia-noite) em UTC
                dateOne.setUTCHours(0, 0, 0, 0);
                dateTwo.setUTCHours(0, 0, 0, 0);

                // Fazemos a comparação das datas normalizadas
                if (dateOne.getTime() === dateTwo.getTime()) {
                    return 'status-risco-atraso'; // Amarelo
                } else if (dateOne < dateTwo) {
                    return 'status-atrasado'; // Vermelho
                } else {
                    return 'status-em-andamento'; // Verde
                }
            },
            headerAlign: 'center',
            align: 'center'
        }
    ];

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" disabled={!items2.length}>
                Preview
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography variant="h3" sx={{ textAlign: 'center' }}>
                        Programando demandas
                    </Typography>
                    <Divider sx={{ margin: '20px 0' }} />
                    <DataGrid columns={columns} rows={data} pageSize={10} hideFooter sx={{ maxHeight: '300px' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button
                            onClick={() => {
                                programinFunc();
                            }}
                            variant="contained"
                        >
                            Programar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
