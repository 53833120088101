import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router';

const ConfirmSendEmailExpedition = ({ open, setOpen, func, message, funcClose, loadingEmail, url }) => {
    const navigate = useNavigate();
    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-label="modal de confirmação para enviar email ao cliente">
            <DialogTitle sx={{ fontSize: '16px' }}>{message}</DialogTitle>
            <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '15px' }}>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        setOpen(false);
                        if (funcClose) {
                            funcClose(false);
                        }
                        navigate(url ? url : '/main/expedicao/programacaes');
                    }}
                >
                    Não
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        func();
                    }}
                    disabled={loadingEmail}
                >
                    {loadingEmail ? 'Eviando...' : 'Enviar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmSendEmailExpedition;
