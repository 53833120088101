import React from 'react';
import { Button, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'moment/locale/pt-br'; // Importe a localização para português do Brasil
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalData } from './components/ModalData';

moment.locale('pt-br');
const DragAndDropCalendar = withDragAndDrop(Calendar);

export function CalendarPage() {
    const api = useApi();
    const [events, setEvents] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const currentRole = localStorage.getItem('role');
    const currentUser = localStorage.getItem('name');
    const [showCompleted, setShowCompleted] = useState(false);
    const messages = {
        allDay: 'Dia todo',
        previous: 'Anterior',
        next: 'Próximo',
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        agenda: 'Agenda',
        date: 'Data',
        time: 'Hora',
        event: 'Evento',
        noEventsInRange: 'Não há eventos neste período.',
        showMore: (total) => `+ ver mais (${total})`
    };

    const [selectedEvent, setSelectedEvent] = useState(null);

    const localizer = momentLocalizer(moment);
    const eventStyleGetter = (event, start, end, isSelected) => {
        console.log('🚀 ~ eventStyleGetter ~ event:', event);
        let color;

        if (event.status === 'Concluída') {
            color = '#999';
        } else if (end < new Date()) {
            color = '#dc3545';
        } else {
            color = '#007bff';
        }

        const style = {
            backgroundColor: color,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    };

    async function getEvents() {
        const toastId = toast.loading('Carregando eventos...');
        try {
            const { data } = await api.getAllDistributionWorksAssociated();

            if (currentRole === 'Codificador' || currentRole === 'Comercial/Engenharia') {
                const events = data
                    .filter((cod) => cod?.User?.username === currentUser)
                    .map((item) => {
                        // Criar objetos moment a partir das datas ISO e ajustar a hora
                        const start = moment
                            .utc(item.codification_start)
                            .startOf('day')
                            .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                            .toDate();
                        const end = moment
                            .utc(item.codification_end)
                            .startOf('day')
                            .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
                            .toDate();

                        return {
                            ...item,
                            id: item.id,
                            title: item?.User?.username + ' - GEPRO - ' + item?.distribution_work?.gepro,
                            start: start,
                            end: end,
                            desc: item?.description || 'Sem descrição',
                            obra: item?.distribution_work?.obra,
                            client: item.distribution_work?.client
                        };
                    });

                // Verificando a estrutura dos dados antes de filtrar
                // Filtrando eventos onde distribution_work.in_hold é false
                const filteredEvents = events.filter((item) => {
                    return item?.distribution_work?.in_hold === false;
                });

                // Verificando os eventos filtrados
                console.log('🚀 ~ filteredEvents:', filteredEvents);

                setEvents(filteredEvents);
                toast.update(toastId, { render: 'Eventos carregados com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
                return console.log('🚀 ~ getEvents ~ filteredEvents:', filteredEvents);
            }

            const events = data.map((item) => {
                // Criar objetos moment a partir das datas ISO e ajustar a hora
                const start = moment
                    .utc(item.codification_start)
                    .startOf('day')
                    .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                    .toDate();
                const end = moment
                    .utc(item.codification_end)
                    .startOf('day')
                    .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
                    .toDate();

                return {
                    ...item,
                    id: item.id,
                    title: item?.User?.username + ' - GEPRO - ' + item?.distribution_work?.gepro,
                    start: start,
                    end: end,
                    desc: item?.description || 'Sem descrição',
                    obra: item?.distribution_work?.obra,
                    client: item.distribution_work?.client
                };
            });

            const filteredEvents = events.filter((item) => {
                return item?.distribution_work?.in_hold === false;
            });

            setEvents(filteredEvents);
            console.log('🚀 ~ getEvents ~ events:', filteredEvents);
            toast.update(toastId, { render: 'Eventos carregados com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
        } catch (error) {
            toast.dismiss(toastId);
            toast.error('Erro ao carregar eventos');
            console.error('Erro ao carregar eventos:', error);
        }
    }

    const handleEdit = async ({ event, end, start }) => {
        if (currentRole === 'Codificador') return;
        // Converta as datas sem alterar as horas fornecidas pelo usuário
        const adjustedStart = moment(start).utc().toDate();
        const adjustedEnd = moment(end).utc().toDate();

        const payload = {
            gepro: event?.distribution_work?.gepro,
            client: event?.distribution_work?.client,
            obra: event?.distribution_work?.obra,
            pcp_enter: event?.pcp_enter || null,
            pcp_exit: event?.pcp_exit || null,
            codification_end: adjustedEnd.toISOString() || null,
            codification_start: adjustedStart.toISOString() || null,
            vulcan_prog: event?.vulcan_prog || null,
            vulcan_cut: event?.cut || null,
            production_fab: event?.production_fab || null,
            production_cut: event?.sawmill || null,
            production_painting: event?.painting || null,
            production_leaving: event?.production_leaving || null,
            estimated_hours: event?.hours,
            level: event?.levels,
            codificators: [
                {
                    id: event?.User?.id,
                    description: event?.description
                }
            ],
            DescriptionWorks: event?.DescriptionWorks,
            in_hold: event?.distribution_work?.in_hold
        };

        const toastId = toast.loading('Editando evento...');
        const newEvents = events.map((item) => {
            if (item.id === event.id) {
                return {
                    ...item,
                    start: adjustedStart,
                    end: adjustedEnd
                };
            }
            return item;
        });

        setEvents(newEvents);

        try {
            await api.EditDistributionWorks(event?.id, payload);
            toast.update(toastId, { render: 'Evento editado com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
        } catch (error) {
            console.log(error);
            toast.update(toastId, { render: 'Erro ao editar evento', type: 'error', isLoading: false, autoClose: 2000 });
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    function handleSelectEvent(event) {
        console.log('🚀 ~ handleSelectEvent ~ event:', event);
        setOpenModal(true);
        setSelectedEvent(event);
    }

    const toggleFilter = () => {
        setShowCompleted((prev) => !prev);
    };

    const filteredEvents = showCompleted
        ? events.filter((item) => item.status === 'Concluída')
        : events.filter((item) => item.status !== 'Concluída');

    return (
        <MainCard title={'Calendário de obras'}>
            <ModalData open={openModal} setOpen={setOpenModal} eventData={selectedEvent} />
            <Grid container spacing={gridSpacing} sx={{ padding: '10px', position: 'relative' }}>
                <Button sx={{ position: 'absolute', left: '300px', top: '5px' }} variant="contained" onClick={toggleFilter}>
                    {showCompleted ? 'Mostrar Ativos' : 'Mostrar Concluídos'}
                </Button>
                <DragAndDropCalendar
                    localizer={localizer}
                    events={filteredEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 680, width: '100vw' }}
                    onSelectEvent={handleSelectEvent}
                    messages={messages}
                    onEventDrop={handleEdit}
                    onEventResize={handleEdit}
                    views={['month', 'day', 'week', 'agenda']}
                    eventPropGetter={eventStyleGetter}
                />
            </Grid>
        </MainCard>
    );
}
