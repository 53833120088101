import { Button, Grid } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useGroupContext } from 'context/groupContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modalnv3 from './components/Modalnv3';

const SelectGroupings = () => {
    const navigate = useNavigate();
    const api = useApi();
    const { id } = useParams();
    const [rows, setRows] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [openNv3, setOpenNv3] = useState(false);
    const [currentGroup, setCurrentGroup] = useState('');

    const { setGroups, setCurrentGepro } = useGroupContext();

    const getGroups = async () => {
        try {
            const { data } = await api.getProgNv2(id);

            const row = data.map((item) => ({
                ...item,
                agrupamento: item.agrupamento,
                cliente: item.cliente,
                obra: item.obra
            }));

            setRows(row);
            setCurrentGepro(id);
        } catch (error) {
            console.log('🚀 ~ getGroups ~ error:', error);
        }
    };

    const columns = [
        { field: 'agrupamento', headerName: 'Agrupamento', flex: 1 },
        {
            field: 'cliente',
            headerName: 'Cliente',
            flex: 1
        },
        {
            field: 'obra',
            headerName: 'Obra',
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} title="Análisar">
                    <VisibilityIcon
                        onClick={() => {
                            setOpenNv3(true);
                            setCurrentGroup(params.row.agrupamento);
                        }}
                    />
                </div>
            )
        }
    ];

    useEffect(() => {
        getGroups();
    }, [id]);

    const selectedGroups = () => {
        if (rowSelectionModel.length === 0) return toast.error('Selecione pelo menos um agrupamento para solicitar');

        setGroups(rowSelectionModel);
        navigate(`/main/expedicao/programacoes`);
    };

    return (
        <MainCard title="Agrupamentos">
            <Modalnv3 open={openNv3} setOpen={setOpenNv3} currentGroup={currentGroup} />
            <Grid container spacing={gridSpacing}>
                {rows.length > 0 ? (
                    <>
                        <Box sx={{ maxHeight: 500, width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                checkboxSelection
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', margin: '20px' }}>
                            <Button variant="contained" onClick={selectedGroups} title="Solicitar grupos selecionados">
                                Solicitar
                            </Button>
                        </Box>
                    </>
                ) : (
                    <p style={{ marginLeft: '20px' }}>Sem agrupamentos cadastrados</p>
                )}
            </Grid>
        </MainCard>
    );
};

export default SelectGroupings;
