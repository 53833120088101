import { Grid, IconButton, Tab, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { FinishModal } from './components/FinishModal';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/system';
import { FinishModalAvulso } from './components/FinishModalAvulso';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { LoadAnimated } from 'ui-component/LoadAnimated';

const FinishedPge = () => {
    const role = localStorage.getItem('role');
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rowAvulso, setRowAvulso] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [loadDelete, setLoadDelete] = useState(false);

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await api.getFinishedExpedition();
            getDataAvulso();
            const row = data.map((item) => ({
                ...item,
                id: item.id,
                gepro: item.gepro,
                client: item.cliente,
                obra: item.obra
            }));
            setRows(row);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ getData ~ error:', error);
            setLoading(false);
        }
    };

    const getDataAvulso = async () => {
        try {
            const { data } = await api.getFinishedExpeditionAvulso();
            console.log('🚀 ~ getDataAvulso ~ data:', data);
            const row = data.registros.map((item) => ({
                ...item,
                id: item.id,
                gepro: item.gepro,
                client: item.cliente,
                obra: item.obra
            }));
            setRowAvulso(row);
        } catch (error) {
            console.log('🚀 ~ getDataAvulso ~ error:', error);
        }
    };

    const columns = [
        { field: 'gepro', headerName: 'GEPRO', flex: 0.5 },
        { field: 'client', headerName: 'Cliente', flex: 1 },
        { field: 'obra', headerName: 'Obra', flex: 1 },
        {
            field: 'action',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip
                            title="Ver detalhes"
                            onClick={() => {
                                setCurrentRow(params.row);
                                setOpen(true);
                            }}
                        >
                            <IconButton>
                                <AnalyticsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    const [openFinishdModalAvulso, setOpenFinishedModalAvulso] = useState(false);
    const [currentRowAvulso, setCurrentRowAvulso] = useState(null);

    const columnsAvulso = [
        { field: 'id', headerName: 'Código', flex: 1, renderCell: (params) => <p>A {params.row.id}</p> },
        { field: 'client', headerName: 'Cliente', flex: 1 },
        { field: 'transporte', headerName: 'Transporte', flex: 1 },
        {
            field: 'action',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip
                            title="Ver detalhes"
                            onClick={() => {
                                setCurrentRowAvulso(params.row);
                                setOpenFinishedModalAvulso(true);
                            }}
                        >
                            <IconButton>
                                <AnalyticsIcon />
                            </IconButton>
                        </Tooltip>
                        {role !== 'Codificador' && role !== 'Comercial/Engenharia' && (
                            <Tooltip title="Deletar">
                                <IconButton
                                    onClick={() => {
                                        setOpenDeleteModal(true);
                                        setCurrentRowAvulso(params.row);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        }
    ];

    useEffect(() => {
        getData();
    }, []);

    const [valueTab, setValueTab] = useState('1');
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    async function handleDeleteAvulso() {
        setLoadDelete(true);
        try {
            await api.deleteAvFinish(currentRowAvulso.id);
            setLoadDelete(false);
            setOpenDeleteModal(false);
            getDataAvulso();
        } catch (error) {
            console.log('🚀 ~ handleDeleteAvulso ~ error:', error);
        }
    }

    return (
        <MainCard title="Finalizadas">
            <FinishModal currentRow={currentRow} open={open} setOpen={setOpen} />
            <FinishModalAvulso currentRow={currentRowAvulso} open={openFinishdModalAvulso} setOpen={setOpenFinishedModalAvulso} />
            <ConfirmDeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                func={handleDeleteAvulso}
                loading={loadDelete}
                message={'Após deletado, a expedição volta para a página anterior.'}
            />
            <Grid container spacing={gridSpacing}>
                <TabContext value={valueTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Duto" value="1" />
                            <Tab label="Fornecedor" value="2" />
                            <Tab label="Distribuição" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ width: '100%' }}>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LoadAnimated />
                            </Box>
                        ) : rows.length > 0 ? (
                            <DataGrid columns={columns} rows={rows} hideFooter sx={{ width: '100%' }} />
                        ) : (
                            <p>Nenhuma coleta finalizada</p>
                        )}
                    </TabPanel>
                    <TabPanel value="2" sx={{ width: '100%' }}>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LoadAnimated />
                            </Box>
                        ) : rowAvulso.length > 0 ? (
                            <DataGrid
                                columns={columnsAvulso}
                                rows={rowAvulso.filter((row) => row.material !== 'Distribuição')}
                                hideFooter
                                sx={{ width: '100%' }}
                            />
                        ) : (
                            <p>Nenhuma coleta avulsa finalizada</p>
                        )}
                    </TabPanel>
                    <TabPanel value="3" sx={{ width: '100%' }}>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LoadAnimated />
                            </Box>
                        ) : rowAvulso.length > 0 ? (
                            <DataGrid
                                columns={columnsAvulso}
                                rows={rowAvulso.filter((row) => row.material === 'Distribuição')}
                                hideFooter
                                sx={{ width: '100%' }}
                            />
                        ) : (
                            <p>Nenhuma coleta avulsa finalizada</p>
                        )}
                    </TabPanel>
                </TabContext>
            </Grid>
        </MainCard>
    );
};

export default FinishedPge;
