import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SuporteForm from './Form';

function Suporte() {
    return (
        <MainCard title="Suporte">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '1em',
                        width: '100%'
                    }}
                >
                    <Typography variant="h5">Enviar Email para o suporte</Typography>
                    <Box sx={{ width: '80%' }}>
                        <SuporteForm />
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
}

export default Suporte;
