import { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';

const DemandsContext = createContext();

export const DemandsContextProvider = ({ children }) => {
    const [demandsSelected, setDemandsSelected] = useState([]);
    const [machineSelected, setMachineSelected] = useState([]);

    useEffect(() => {
        console.log('🚀 ~ demandsSelected ~ demandsSelected:', demandsSelected);
    }, [demandsSelected]);

    return (
        <DemandsContext.Provider value={{ machineSelected, setMachineSelected, demandsSelected, setDemandsSelected }}>
            {children}
        </DemandsContext.Provider>
    );
};

export const useDemandsContext = () => {
    const context = useContext(DemandsContext);

    if (!context) {
        throw new Error('useGroupContext must be used within a TodoProvider');
    }

    return context;
};
