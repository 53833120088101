export const getAccessLevel = () => {
    const role = localStorage.getItem('role');

    switch (role) {
        case 'Administrador':
            return 'Administrador';
        case 'adm-comercial':
            return 'admComercial';
        case 'Lider':
            return 'Líder';
        case 'Orcamentista':
            return 'Orcamentista';
        case 'Vendedor':
            return 'Vendedor';
        case 'Rh':
            return 'RH';
        case 'Engenharia':
            return 'Engenharia';
        case 'Comercial/Engenharia':
            return 'Comercial/Engenharia';
        default:
            return 'Usuário comum';
    }
};
