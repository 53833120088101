import {
    Autocomplete,
    Button,
    CircularProgress,
    Divider,
    Fab,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Switch,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import ConfirmSendEmailExpedition from 'ui-component/ConfirmSendEmailExpedition';
import formater from '../../../utils/formatDate';
import { converterData } from 'utils/convertData';
import { toast } from 'react-toastify';
import { date } from 'utils/date';
import { formatarTelefone } from 'utils/regex';
import { useGroupContext } from 'context/groupContext';
import { dateForInput } from 'utils/dateForInpute';
import { formatarDateEn } from 'utils/formatDateEn';
import ReplyIcon from '@mui/icons-material/Reply';
import SelectedGroups from './components/SelectedGroups';
import { uf } from 'utils/uf';
import { ModalEmailsCC } from 'ui-component/ModalEmailsCC';
import { typeOfTransport } from 'utils/typeOfTransport';

const ProgrammingID = () => {
    const { id } = useParams();
    const theme = useTheme();
    const api = useApi();
    const [data, setData] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [emailData, setEmailData] = useState([]);
    const [coletumData, setColetumData] = useState(null);
    const { groups, setGroups, currentGepro } = useGroupContext();
    const [selectedState, setSelectedState] = useState(null);
    const [cityResults, setCityResults] = useState([]);
    const [emailList, setEmailList] = useState([]);

    const time = [{ period: '08h às 12h' }, { period: '13h às 16h/ sex. ás 15h' }];

    const volumeType = [{ type: 'A GRANEL' }, { type: 'ENGRADADO' }];
    const [groupsSelecteds, setGroupsSelecteds] = useState([]);

    const getGroups = async () => {
        try {
            const { data } = await api.getProgNv2(currentGepro);

            const filterSelected = data.filter((item) => groups.some((group) => group === item.id));

            setGroupsSelecteds(filterSelected);
            let dataInfo = {
                grouping: data[0]?.agrupamento,
                order: data[0]?.pedido,
                contact: '',
                email: '',
                client: data[0]?.cliente,
                constructions: data[0]?.obra,
                manufacturing: data[0]?.previsao_fabricacao,
                done: data[0]?.fabricacao_realizada,
                expedition: data[0]?.previsao_expedicao,
                type: false,
                telefone: '',
                pc: data[0]?.pç,
                previsao_fabricacao: data[0]?.previsao_fabricacao,
                abricacao_realizada: data[0]?.fabricacao_realizada,
                previsao_expedicao: data[0]?.previsao_expedicao,
                kg: data[0]?.kg
            };

            setData(dataInfo);
        } catch (error) {
            console.log('🚀 ~ getGroups ~ error:', error);
        }
    };

    useEffect(() => {
        // getData();
        getGroups();
    }, []);

    const [loadingSubmit, setLoading] = useState(false);

    const handleSubmit = async (value) => {
        setLoading(true);
        const idUser = localStorage.getItem('id');
        const payload = {
            usuario_responsavel_id: idUser,
            pedido: value.order,
            contato: value.contact,
            telefone: value.tel,
            email: value.email,
            cliente: value.client,
            obra: value.constructions,
            previsao_fabricacao: data.previsao_fabricacao ? data.previsao_fabricacao : null,
            fabricacao_realizada: data.fabricacao_realizada ? data.fabricacao_realizada : null,
            previsao_expedicao: data.previsao_expedicao ? data.previsao_expedicao : null,
            transporte: value.typeOfTransport,
            a_partir_de: value.from,
            periodo: value.period,
            fob: value.type ? false : true,
            cif: value.type ? true : false,
            volumes: value.volume,
            tipo_volume: value.typeOfVolume,
            peso_aproximado: value.weight,
            observacao: value.observation,
            agrupamentos: groupsSelecteds?.map((item) => item.agrupamento),
            uf_destino: value.uf,
            cidade_destino: value.city,
            ccEmails: emailList
        };

        try {
            const res = await api.createRequestExpedition(id, payload);
            const dataRequest = res.data.solicitacao;
            console.log('🚀 ~ file: index.js:98 ~ handleSubmit ~ dataRequest:', dataRequest);
            setEmailData({
                idSolicitacaoColeta: dataRequest.id,
                agrupamento: dataRequest.agrupamento,
                pedido: dataRequest.pedido,
                email: dataRequest.email,
                cliente: dataRequest.cliente,
                obra: dataRequest.obra,
                a_partir_de: value.from,
                periodo: dataRequest.periodo,
                volumes: dataRequest.volumes,
                tipo_volume: dataRequest.tipo_volume,
                peso_aproximado: dataRequest.peso_aproximado,
                observacao: dataRequest.observacao,
                action: 'add',
                ccEmails: emailList
            });
            setOpenConfirm(true);
            toast.success('Solicitação de coleta criada com sucesso!');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('🚀 ~ file: index.js:68 ~ handleSubmit ~ error:', error.message);
            toast.error(error.message);
        }
    };
    const navigate = useNavigate();

    const [loadingEmail, setLoadingEmail] = useState(false);

    const sendEmail = async () => {
        console.log('🚀 ~ sendEmail ~ emailData:', emailData);
        setLoadingEmail(true);
        try {
            await api.sendEmailExpedition(emailData);
            navigate('/main/expedicao/programacaes');
            setLoadingEmail(false);
        } catch (error) {
            console.log('🚀 ~ file: index.js:119 ~ sendEmail ~ error:', error.message);
        } finally {
            setOpenConfirm(false);
        }
    };

    const validationSchema = Yup.object().shape({
        contact: Yup.string().required('Contato é obrigatório'),

        typeOfTransport: Yup.string().required('Transporte é obrigatório'),
        from: Yup.string().required('Data de partida é obrigatório'),
        period: Yup.string().required('Período é obrigatório'),
        typeOfVolume: Yup.string().required('O campo Tipo do volume é obrigatório')
    });

    const handleCityResults = async () => {
        try {
            const { data } = await api.getCitiesByIbge(selectedState);
            console.log('🚀 ~ handleCityResults ~ data:', data);
            setCityResults(data);
        } catch (error) {
            console.log('🚀 ~ handleCityResults ~ error:', error);
        }
    };

    useEffect(() => {
        if (selectedState) {
            handleCityResults();
        }
    }, [selectedState]);

    return (
        <MainCard title="Solicitação de coleta">
            <ConfirmSendEmailExpedition
                open={openConfirm}
                setOpen={setOpenConfirm}
                func={sendEmail}
                loadingEmail={loadingEmail}
                message={'Deseja enviar o e-mail de solicitação ao cliente?'}
            />

            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Fab variant="extended" sx={{ position: 'fixed', bottom: '20px', left: '50px' }}>
                    <ReplyIcon sx={{ mr: 1 }} />
                    Voltar
                </Fab>
                {data && (
                    <Formik
                        initialValues={{
                            order: data?.order || '',
                            contact: data?.contact || '',
                            email: coletumData?.email || data?.email || '',
                            client: data?.client,
                            constructions: data.constructions || '',
                            manufacturing: data.manufacturing ? formater(data.manufacturing) : 'Não informado',
                            done: data.done ? formater(data?.done) : 'Não informado',
                            expedition: data?.expedition ? formater(data?.expedition) : 'Não informado',
                            typeOfTransport: coletumData?.transporte || '',
                            from: date(coletumData?.a_partir_de) || '',
                            period: coletumData?.periodo || '',
                            type: coletumData ? (coletumData?.fob ? false : true) : false,
                            volume: data.pc || '',
                            typeOfVolume: coletumData?.tipo_volume || false,
                            weight: data.kg || '',
                            observation: coletumData?.observacao || '',
                            tel: data?.telefone || '',
                            uf: '',
                            city: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            handleSubmit(values);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ padding: '15px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                            >
                                <Box sx={{ maxWidth: '500px', marginBottom: '16px' }}>
                                    <SelectedGroups groups={groupsSelecteds} />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <FormControl>
                                        <TextField
                                            disabled
                                            sx={{
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            label="PDV"
                                            variant="filled"
                                            name="order"
                                            value={values.order}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '350px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            variant="filled"
                                            disabled
                                            label="Cliente"
                                            name="client"
                                            value={values.client}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '350px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Obra"
                                            name="constructions"
                                            value={values.constructions}
                                            variant="filled"
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Prev. Fabricação"
                                            name="manufacturing"
                                            value={values.manufacturing}
                                            variant="filled"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Fab. Realizada"
                                            name="done"
                                            value={values.done}
                                            variant="filled"
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{
                                                width: '300px',
                                                '& input:disabled': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold' // Define a cor do texto quando desativado
                                                }
                                            }}
                                            disabled
                                            label="Prev. Expedição"
                                            name="expedition"
                                            value={values.expedition}
                                            variant="filled"
                                        />
                                    </FormControl>
                                </Box>

                                <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '8x',
                                        marginBottom: '8px'
                                    }}
                                >
                                    <FormControl error={Boolean(touched.contact && errors.contact)}>
                                        <TextField
                                            sx={{ width: '350px', borderColor: touched.contact && errors.contact ? 'red' : '' }}
                                            label="Contato"
                                            id="contact-id"
                                            name="contact"
                                            onChange={handleChange}
                                            value={values.contact}
                                            error={touched.contact && Boolean(errors.contact)}
                                            helperText={touched.contact && errors.contact ? errors.contact : ''}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            sx={{ width: '270px' }}
                                            label="Telefone"
                                            name="tel"
                                            onChange={(e) => setValues({ ...values, tel: formatarTelefone(e.target.value) })}
                                            value={values.tel}
                                        />
                                    </FormControl>
                                    <FormControl error={Boolean(touched.email && errors.email)}>
                                        <TextField
                                            sx={{ width: '270px' }}
                                            label="E-mail"
                                            id="email-id"
                                            name="email"
                                            value={values.email}
                                            onChange={(e) => setValues({ ...values, email: e.target.value })}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email ? errors.email : ''}
                                        />
                                    </FormControl>

                                    <ModalEmailsCC list={emailList} setList={setEmailList} />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8x' }}>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typeTransport"
                                            options={typeOfTransport}
                                            sx={{ width: 340 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, typeOfTransport: newValue.type });
                                            }}
                                            value={typeOfTransport.find((option) => option.type === values.typeOfTransport) || null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Transporte"
                                                    name="typeOfTransport"
                                                    value={values.typeOfTransport}
                                                    error={touched.typeOfTransport && Boolean(errors.typeOfTransport)}
                                                    helperText={touched.typeOfTransport && errors.typeOfTransport}
                                                />
                                            )}
                                        />
                                    </FormControl>

                                    <FormControl error={Boolean(errors.from)}>
                                        <TextField
                                            type="date"
                                            sx={{ width: '200px' }}
                                            label="A partir de:"
                                            name="from"
                                            value={values.from}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                            error={touched.from && Boolean(errors.from)}
                                            helperText={touched.from && errors.from}
                                        />
                                    </FormControl>

                                    <FormControl error={Boolean(errors.period)}>
                                        <Autocomplete
                                            disablePortal
                                            id="transport"
                                            options={time}
                                            sx={{ width: 300 }}
                                            getOptionLabel={(option) => option.period}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, period: newValue.period });
                                                }
                                            }}
                                            value={time.find((item) => item.period === values.period) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Periodo"
                                                    name="period"
                                                    value={values.period}
                                                    error={touched.period && Boolean(errors.period)}
                                                    helperText={touched.period && errors.period}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: '#d9d5f5',
                                            width: '130px',
                                            justifyContent: 'center',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        <p>FOB</p>
                                        <Switch
                                            size="medium"
                                            defaultChecked={values.type}
                                            onChange={(e) => setValues({ ...values, type: e.target.checked })}
                                        />
                                        <p>CIF</p>
                                    </Box>

                                    <TextField
                                        type="number"
                                        sx={{ width: '130px' }}
                                        label="volume"
                                        name="volume"
                                        value={values.volume}
                                        onChange={handleChange}
                                        inputProps={{ min: 0 }}
                                    />

                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typevolume"
                                            options={volumeType}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, typeOfVolume: newValue.type });
                                                }
                                            }}
                                            value={volumeType.find((item) => item.type === values.typeOfVolume) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tipo do volume"
                                                    name="typeOfVolume"
                                                    value={values.typeOfVolume}
                                                    error={touched.typeOfVolume && Boolean(errors.typeOfVolume)}
                                                    helperText={touched.typeOfVolume && errors.typeOfVolume}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="uf"
                                            options={uf}
                                            sx={{ width: 100 }}
                                            getOptionLabel={(option) => option?.sigla}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, uf: newValue?.sigla });
                                                }
                                                setSelectedState(newValue?.sigla);
                                            }}
                                            value={uf.find((item) => item.sigla === values.uf) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="UF"
                                                    name="uf"
                                                    value={values.uf}
                                                    error={touched.uf && Boolean(errors.uf)}
                                                    helperText={touched.uf && errors.uf}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="city"
                                            options={cityResults}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.nome}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, city: newValue.nome });
                                                }
                                            }}
                                            value={cityResults.find((item) => item.nome === values.city) || null}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Cidade"
                                                    name="city"
                                                    value={values.city}
                                                    error={touched.city && Boolean(errors.city)}
                                                    helperText={touched.city && errors.city}
                                                />
                                            )}
                                        />
                                    </FormControl>

                                    <TextField
                                        type="number"
                                        sx={{ width: '130px' }}
                                        label="Peso Aproximado"
                                        name="weight"
                                        value={values.weight}
                                        aria-label="peso"
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">KG</InputAdornment>
                                        }}
                                        adormen
                                        aria-describedby="outlined-weight-helper-text"
                                    />
                                </Box>
                                <Box sx={{ marginTop: '16px', width: '100%' }}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Observação"
                                        sx={{ width: '100%' }}
                                        multiline
                                        rows={4}
                                        value={values.observation}
                                        name="observation"
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '110px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={loadingSubmit}
                                        sx={{ marginTop: '16px', width: '300px' }}
                                    >
                                        {loadingSubmit ? 'Salvando ...' : 'Salvar'}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </MainCard>
    );
};

export default ProgrammingID;
