import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { set } from 'date-fns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4
};

export function InsertModal({ allSectors, getAllWorkCenters, currentMachine, open, setOpen, setCurrentMachine }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCurrentMachine([]);
    };
    const api = useApi();
    const [sectorSelected, setSectorSelected] = React.useState('');
    const [allWorkCentersMega, setAllWorkCentersMega] = React.useState([]);
    const [centerWorkSelected, setCenterWorkSelected] = React.useState({});
    const [description, setDescription] = React.useState('');

    async function getCurrentMachine() {
        if (!currentMachine?.id) return;
        try {
            const { data } = await api.getCurrentMachine(currentMachine?.id);
            setDescription(data.descricao);
            setSectorSelected(data.setor);
            setCenterWorkSelected(data.centro_trabalho);
        } catch (error) {
            console.log('🚀 ~ getCurrentMachine ~ error:', error);
        }
    }

    async function updateMachineFunction(e) {
        e.preventDefault();
        const toastId = toast.loading('Atualizando máquina...');
        const payload = {
            descricao: description,
            id_setor: sectorSelected.id,
            id_centro_trabalho: centerWorkSelected.id
        };
        try {
            await api.updateMachine(currentMachine.id, payload);
            toast.update(toastId, {
                render: 'Máquina atualizada com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            // Fechar o modal e limpar os campos
            setOpen(false);
            setSectorSelected('');
            setDescription('');
            setCenterWorkSelected([]);
            setCurrentMachine([]);
            getAllWorkCenters();
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao atualizar máquina',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    useEffect(() => {
        if (open) {
            getCurrentMachine();
        }
    }, [open]);

    useEffect(() => {
        if (!open) {
            setCurrentMachine([]); // Limpa o currentMachine quando o modal fecha
            setSectorSelected(''); // Limpa os campos relacionados
            setCenterWorkSelected([]);
            setDescription('');
        }
    }, [open]);

    async function getWorkCentersByMega() {
        try {
            const { data } = await api.getWorkCenterLocal(sectorSelected.id);
            if (data.length == 0) return toast.warning('Nenhum centro de trabalho encontrado');
            setAllWorkCentersMega(data);
        } catch (error) {
            console.log('🚀 ~ getWorkCentersByMega ~ error:', error);
            setAllWorkCentersMega([]);
            return toast.warning('Nenhum centro de trabalho encontrado');
        }
    }

    useEffect(() => {
        if (sectorSelected !== '') getWorkCentersByMega();
    }, [sectorSelected]);

    async function handleSubmit(event) {
        event.preventDefault();
        const toastId = toast.loading('Inserindo setor...');
        if (!sectorSelected) {
            return toast.update(toastId, {
                render: 'Selecione um setor',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
        if (!centerWorkSelected) {
            return toast.update(toastId, {
                render: 'Selecione um centro de trabalho',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }

        const payload = {
            descricao: description,
            id_setor: sectorSelected.id,
            id_centro_trabalho: centerWorkSelected.id
        };
        try {
            await api.createLocalMachine(payload);
            toast.update(toastId, {
                render: 'Máquina inserida com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            setOpen(false);
            setSectorSelected('');
            setDescription('');
            getAllWorkCenters();
            setCenterWorkSelected([]);
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao inserir máquina',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Inserir
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>{currentMachine.id ? 'Editar Máquina' : 'Incluir Máquina'}</h3>
                    <form onSubmit={currentMachine.id ? updateMachineFunction : handleSubmit}>
                        <TextField
                            id="description"
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            label="Descrição"
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allSectors}
                            value={sectorSelected.id ? sectorSelected : null}
                            getOptionLabel={(option) => option?.descricao_setor}
                            sx={{ marginBottom: 2 }}
                            onChange={(event, newValue) => {
                                setSectorSelected(newValue);
                                setAllWorkCentersMega([]);
                                setCenterWorkSelected({});
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} label="Setor" />}
                        />
                        {allWorkCentersMega.length > 0 && (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={allWorkCentersMega}
                                getOptionLabel={(option) => option?.descricao_ct}
                                sx={{ marginBottom: 2 }}
                                value={centerWorkSelected.id ? centerWorkSelected : null}
                                onChange={(event, newValue) => {
                                    setCenterWorkSelected(newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} label="Buscar centro de trabalho" />}
                            />
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button type="submit" variant="contained" sx={{ width: '200px' }}>
                                {currentMachine.id ? 'Editar' : 'Confirmar'}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
