import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import { getAccessLevel } from 'utils/getAccessLevel';

const Archive = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const [openReativar, setOpenReativar] = useState(false);
    const [openClear, setOpenClear] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarDeleted, setOpenSnackBarDeleted] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [id, setId] = useState(null);

    const api = useApi();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleCloseSnackBarDeleted = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBarDeleted(false);
    };

    const handleReactivate = async (id) => {
        setOpenReativar(true);
        setId(id);
    };

    const handleConfirmReativar = async () => {
        try {
            await api.resetMailStatus(id);
            setOpenReativar(false);
            const updatedMails = mails.filter((mail) => mail.id !== id);
            setMails(updatedMails);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseReativar = () => {
        setOpenReativar(false);
    };

    const handleDelete = async (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setId(id);
    };

    const handleConfirmDelete = async () => {
        try {
            await api.deleteMail(id);
            setOpenDelete(false);
            const updatedMails = mails.filter((mail) => mail.id !== id);
            setMails(updatedMails);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleAllDelete = () => {
        setOpenClear(true);
    };

    const handleClearArchived = async () => {
        try {
            await api.deleteAllMailArchived();
            setOpenClear(false);
            setMails([]);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseClear = () => {
        setOpenClear(false);
    };

    const [mails, setMails] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const get = async () => {
            try {
                const response = await api.getAllArchivedMail();
                const mails = response.data.map((mail) => {
                    const dataHora = new Date(mail.date);
                    const opcoes = {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    };
                    const dataHoraFormatada = dataHora.toLocaleString('pt-BR', opcoes);
                    return { ...mail, date: dataHoraFormatada };
                });
                setMails(mails);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        get();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    //datagrid

    const columns = [
        { field: 'from', headerName: 'Remetente', flex: 5 },
        { field: 'date', headerName: 'Data', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip title="Reativar" onClick={() => handleReactivate(params.row.id)}>
                        <IconButton disabled={getAccessLevel() === 'Vendedor'}>
                            <ArrowOutwardIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir" onClick={(e) => handleDelete(e, params.row.id)}>
                        <IconButton disabled={getAccessLevel() === 'Vendedor'}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    return (
        <>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle>Excluir Registro</DialogTitle>
                <DialogContent>
                    <DialogContentText>Tem certeza que deseja excluir o registro?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDelete}>Sim</Button>
                    <Button onClick={handleCloseDelete}>Cancelar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openReativar} onClose={handleCloseReativar}>
                <DialogTitle>Reativar Registro</DialogTitle>
                <DialogContent>
                    <DialogContentText>Tem certeza que deseja reativar o registro?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmReativar}>Sim</Button>
                    <Button onClick={handleCloseReativar}>Cancelar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openClear} onClose={handleCloseClear}>
                <DialogTitle>Limpar Arquivos</DialogTitle>
                <DialogContent>
                    <DialogContentText>Deseja realmente limpar todos os arquivos arquivados?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClear} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleClearArchived} color="secondary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

            <MainCard>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    <h2>Solicitações Arquivadas</h2>
                    <Button variant="contained" onClick={handleAllDelete} sx={{ marginLeft: 2, backgroundColor: '#f44336', color: '#fff' }}>
                        Limpar Arquivos
                    </Button>
                </div>
                {loading ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    // <TableContainer>
                    //     <Table>
                    //         <TableHead>
                    //             <TableRow>
                    //                 <TableCell>Remetente</TableCell>
                    //                 <TableCell>Data</TableCell>
                    //                 <TableCell align="right">Ações</TableCell>
                    //             </TableRow>
                    //         </TableHead>
                    //         <TableBody>
                    //             {mails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    //                 <TableRow key={row.id}>
                    //                     <TableCell>{row.from}</TableCell>
                    //                     <TableCell>{row.date}</TableCell>
                    //                     <TableCell align="right">
                    //                         <Tooltip title="Reativar" onClick={() => handleReactivate(row.id)}>
                    //                             <IconButton>
                    //                                 <ArrowOutwardIcon />
                    //                             </IconButton>
                    //                         </Tooltip>
                    //                         <Tooltip title="Excluir" onClick={(e) => handleDelete(e, row.id)}>
                    //                             <IconButton>
                    //                                 <DeleteIcon />
                    //                             </IconButton>
                    //                         </Tooltip>
                    //                     </TableCell>
                    //                 </TableRow>
                    //             ))}
                    //         </TableBody>
                    //     </Table>
                    // </TableContainer>

                    <DataGrid
                        rows={mails}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        pagination
                        sx={{ maxHeight: 'calc(100vh - 250px)' }}
                    />
                )}
            </MainCard>
        </>
    );
};

export default Archive;
