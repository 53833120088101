import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { formatarData } from 'utils/regex';
import { DataGrid } from '@mui/x-data-grid';
import formater from 'utils/formatDate';

const Modalnv3 = ({ open, setOpen, currentGroup }) => {
    const api = useApi();
    const [rows, setRows] = useState([]);

    const getCurrentNv3 = async () => {
        try {
            const { data } = await api.getGroupsNv3(currentGroup);
            const row = data.map((item) => ({
                ...item,
                setor: item.setor,
                fab: item.percentual_peso + '%',
                prevFab: item.previsao_fabricacao ? formater(item.previsao_fabricacao) : '',
                fabFinalizada: item.fabricacao_realizada ? formater(item.fabricacao_realizada) : '',
                status: item.status_setor,
                id: item.id
            }));
            setRows(row);
            console.log('🚀 ~ getCurrentNv3 ~ res:', data);
        } catch (error) {
            console.log('🚀 ~ getCurrentNv3 ~ error:', error);
        }
    };

    const columns = [
        { field: 'setor', headerName: 'Setor', flex: 1 },
        {
            field: 'fab',
            headerName: '%Fabricado',
            flex: 1
        },
        {
            field: 'prevFab',
            headerName: 'Prev. Fabricação',
            flex: 1
        },
        {
            field: 'fabFinalizada',
            headerName: 'Fab. Finalizada',
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                let statusColor = '';
                if (params.row.status === 'EP') {
                    statusColor = 'red';
                } else if (params.row.status === 'EF') {
                    statusColor = 'yellow';
                } else if (params.row.status === 'FA') {
                    statusColor = 'green';
                }

                return (
                    <div>
                        <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: statusColor }} />
                    </div>
                );
            }
        }
    ];

    useEffect(() => {
        getCurrentNv3();
    }, [currentGroup]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };
    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center', margin: '10px' }}>Análise do agrupamento {currentGroup}</h3>
                <Box sx={{ maxHeight: 500, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter />
                </Box>
            </Box>
        </Modal>
    );
};

export default Modalnv3;
