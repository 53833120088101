import MainCard from 'ui-component/cards/MainCard';
import { Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import GraficoRh from './Grafico';
import TotalSaidasRh from './Card';
import SecondaryCard from './Card/SecondaryCard';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import axios from 'axios';
import BonusCard from './Card/BonusCard';
import { useApi } from 'Service/axios';

function RhDashboard() {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [averageExitsPerMonth, setAverageExitsPerMonth] = useState(0);
    const [averageExitsPerDayThisMonth, setAverageExitsPerDayThisMonth] = useState(0);
    const [returnedPerMonth, setReturnedPerMonth] = useState(0);

    console.log('POR MES', averageExitsPerMonth);
    console.log('POR Dia ESTE MES', averageExitsPerDayThisMonth);

    const [chartDataRh, setChartDataRh] = useState({
        totalSaidas: 0,
        returnedTotal: 0,
        saidasMesAtual: 0,
        height: 480,
        type: 'bar',
        options: {
            chart: {
                id: 'rh',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Roboto', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true
            }
        },
        series: [
            {
                name: 'Saídas',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // Initialize with zeros for the months
            }
        ]
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await api.getAllExits();
                const apiData = response.data;

                const dataByMonth = {
                    Jan: [],
                    Fev: [],
                    Mar: [],
                    Abr: [],
                    Mai: [],
                    Jun: [],
                    Jul: [],
                    Ago: [],
                    Set: [],
                    Out: [],
                    Nov: [],
                    Dez: []
                };

                apiData.exits.forEach((exit) => {
                    const exitDate = new Date(exit.exitDate);
                    const month = String(exitDate.getMonth() + 1).padStart(2, '0');
                    let monthName;
                    switch (month) {
                        case '01':
                            monthName = 'Jan';
                            break;
                        case '02':
                            monthName = 'Fev';
                            break;
                        case '03':
                            monthName = 'Mar';
                            break;
                        case '04':
                            monthName = 'Abr';
                            break;
                        case '05':
                            monthName = 'Mai';
                            break;
                        case '06':
                            monthName = 'Jun';
                            break;
                        case '07':
                            monthName = 'Jul';
                            break;
                        case '08':
                            monthName = 'Ago';
                            break;
                        case '09':
                            monthName = 'Set';
                            break;
                        case '10':
                            monthName = 'Out';
                            break;
                        case '11':
                            monthName = 'Nov';
                            break;
                        case '12':
                            monthName = 'Dez';
                            break;
                        default:
                            monthName = '';
                            break;
                    }
                    dataByMonth[monthName].push(exit);
                });

                const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

                const updatedChartData = {
                    ...chartDataRh,
                    series: [
                        {
                            name: 'Saídas',
                            data: months.map((month) => dataByMonth[month].length)
                        }
                    ],
                    totalSaidas: Object.values(dataByMonth).reduce((acc, monthData) => acc + monthData.length, 0),
                    returnedTotal: apiData.exits.filter((exit) => exit.returned === 2).length,
                    saidasMesAtual: dataByMonth[String(months[new Date().getMonth()])].length
                };

                // Calcular a média do atributo returned igual a 2 por mês
                const returnedAtributoMediaPorMes = {};
                Object.entries(dataByMonth).forEach(([month, exits]) => {
                    const totalReturned2Exits = exits.filter((exit) => exit.returned === 1).length;
                    const currentMonthIndex = new Date().getMonth();
                    const averageReturned2ExitsPerMonth = totalReturned2Exits / (currentMonthIndex + 1);
                    returnedAtributoMediaPorMes[month] = averageReturned2ExitsPerMonth;
                });

                // Exemplo de como acessar a média do atributo returned igual a 2 para o mês atual
                const mediaReturned2MesAtual = returnedAtributoMediaPorMes[months[new Date().getMonth()]];
                setReturnedPerMonth(mediaReturned2MesAtual.toFixed(2));

                setChartDataRh(updatedChartData);

                const totalExits = updatedChartData.totalSaidas;
                const exitsThisMonth = updatedChartData.saidasMesAtual;
                const currentMonthIndex = new Date().getMonth(); // 0-based index of the current month
                const monthsCompleted = currentMonthIndex + 1; // Adding 1 to include the current month
                const averageExitsPerMonth = totalExits / monthsCompleted;
                setAverageExitsPerMonth(averageExitsPerMonth);

                // const currentDate = new Date();
                // const currentMonthData = dataByMonth[String(currentMonthIndex + 1).padStart(2, '0')];
                // const totalExitsThisMonth = currentMonthData.length;
                // const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentMonthIndex + 1, 0).getDate();
                // const daysPassedInCurrentMonth = currentDate.getDate();

                // // Calculate the average number of exits per day for the current month
                // const averageExitsPerDayThisMonth2 = totalExitsThisMonth / daysPassedInCurrentMonth;
                // setAverageExitsPerDayThisMonth(averageExitsPerDayThisMonth2);
            } catch (error) {
                window.alert('Error ao buscar dados:', error.message);
            }
        }

        fetchData();
    }, []);

    return (
        <MainCard title="Dashboard saídas">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TotalSaidasRh isLoading={isLoading} chartDataRh={chartDataRh} />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <SecondaryCard isLoading={isLoading} averageExitsPerMonth={averageExitsPerMonth} />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <BonusCard isLoading={isLoading} returned={returnedPerMonth} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12}>
                            <GraficoRh isLoading={isLoading} chartDataRh={chartDataRh} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default RhDashboard;
