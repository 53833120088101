export function DateAndHour(dt_chegada) {
    if (typeof dt_chegada !== 'string') {
        return '-- / --';
    }

    const match = dt_chegada.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/);

    if (match) {
        const data_formatada = `${match[3]}/${match[2]} às ${match[4]}:${match[5]}`;
        return data_formatada;
    } else {
        return '-- / --';
    }
}

export function DateAndHourPreview(dt_chegada) {
    if (typeof dt_chegada !== 'string') {
        return '-- / --';
    }

    const match = dt_chegada.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/);

    if (match) {
        const date = new Date(dt_chegada);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        const data_formatada = `${day}/${month} às ${hours}:${minutes}`;
        return data_formatada;
    } else {
        return '-- / --';
    }
}
