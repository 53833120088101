import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export function GaugeCharts({ sumActivity, totalEstimated }) {
    console.log('sumActivity:', sumActivity);
    console.log('totalEstimated:', totalEstimated);

    // Convertendo totalEstimated de horas para minutos
    const totalEstimatedInMinutes = totalEstimated * 60;

    // Calculando a porcentagem com base no tempo total estimado e na soma das atividades
    const percentage = (sumActivity / totalEstimatedInMinutes) * 100;

    console.log('percentage:', percentage);

    // Arredondando o valor de sumActivity para duas casas decimais
    function formatTimeMinute(timeInMinutes) {
        const hours = Math.floor(timeInMinutes / 60);
        const remainingMinutes = Math.floor(timeInMinutes % 60);
        const seconds = Math.round((timeInMinutes - Math.floor(timeInMinutes)) * 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    function formatTime(estimatedMinutes) {
        const hours = Math.floor(estimatedMinutes / 60);
        const minutes = Math.floor(estimatedMinutes % 60);
        const seconds = Math.floor((estimatedMinutes % 1) * 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    // Formatando totalEstimated de minutos para horas
    const formattedTotalEstimated = formatTime(totalEstimatedInMinutes);

    // Limitando a porcentagem a um máximo de 100%
    const boundedPercentage = Math.min(percentage, 100);
    console.log('boundedPercentage:', boundedPercentage);

    return (
        <Gauge
            value={boundedPercentage}
            startAngle={-110}
            endAngle={110}
            width={250}
            height={200}
            sx={{
                [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 20,
                    transform: 'translate(0px, 0px)'
                },
                [`& .${gaugeClasses.valueArc}`]: {
                    fill: percentage > 100 ? 'red' : 'blue'
                }
            }}
            text={() => `${formatTimeMinute(sumActivity)} / ${formattedTotalEstimated}`}
        />
    );
}
