import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import { formatacaoData } from 'utils/formatacaoData';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router';
import DrawerEdit from './DrawerEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import ArticleIcon from '@mui/icons-material/Article';
import { ReportModal } from './ReportModal';

export function Row({ row, get }) {
    const api = useApi();
    const navigate = useNavigate();
    const [openCollapse, setOpenCollapse] = useState(false);
    const [editData, setEditData] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);

    async function handleDelete(id) {
        const toastId = toast.loading('Deletando obra...');
        try {
            await api.deleteDistribuctionWorks(id);
            toast.update(toastId, {
                render: 'Obra deletada com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
            get();
        } catch (error) {
            console.log(error);
            toast.update(toastId, {
                render: 'Erro ao deletar obra!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    return (
        <React.Fragment>
            <DrawerEdit open={openEdit} setOpen={setOpenEdit} editData={editData} allInfos={row} />
            <ReportModal open={openReportModal} setOpen={setOpenReportModal} infos={row} />
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapse(!openCollapse)}>
                        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">{row.gepro}</TableCell>
                <TableCell component="th" scope="row" align="center">
                    {row.client}
                </TableCell>
                <TableCell align="center">{row.obra}</TableCell>
                <TableCell align="center">{row.in_hold ? 'Inativo' : 'Ativo'}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Novo apontamento">
                        <IconButton
                            disabled={row?.status_gepro === 'GEPRO FINALIZADO' || row?.status_gepro === 'CODIFICAÇÃO ENCERRADA'}
                            onClick={() => navigate(`/main/engenharia/nova-distribuicao/${row.id}`)}
                        >
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Relatório de atividades" onClick={() => setOpenReportModal(true)}>
                        <IconButton>
                            <ArticleIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Codificador</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Criado Em</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.distribution_associated_works
                                    // .filter((item) => item.status !== 'Concluída')
                                    .map((work, index) => (
                                        <TableRow sx={{ backgroundColor: '#eee' }} key={index}>
                                            <TableCell component="th" scope="row">
                                                {work.id}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {work?.User?.username}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {work.description}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {formatacaoData(work.createdAt)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {work.status}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Tooltip title="Editar">
                                                    <IconButton
                                                        onClick={() => {
                                                            setEditData(work);
                                                            setOpenEdit(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip
                                                    title="Delete"
                                                    onClick={() => {
                                                        handleDelete(work.id);
                                                    }}
                                                >
                                                    <IconButton>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
