import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';

export function FilterFialog({ setInital, setFinal, setIsFilter, countFilter }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleFilter() {
        setIsFilter(true);
        setOpen(false);
        countFilter((prev) => prev + 1);
    }

    return (
        <React.Fragment>
            <Button variant="contained" onClick={handleClickOpen}>
                Filtro
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <h2>Filtro de datas</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ display: 'flex', gap: '5px', padding: '10px 5px' }}>
                        <TextField
                            type="month"
                            name="initial"
                            onChange={(e) => setInital(e.target.value)}
                            label="Data inicial"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            type="month"
                            name="final"
                            onChange={(e) => setFinal(e.target.value)}
                            label="Data final"
                            InputLabelProps={{ shrink: true }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleFilter}>
                        Filtrar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
