import styled from '@emotion/styled';

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 10px;
`;

export const GeproArea = styled.div`
    display: flex;
    gap: 20px;
`;
