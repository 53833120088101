import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridCellEditStopReasons } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { set } from 'date-fns';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

export function SearchGoals({ sellersData }) {
    const api = useApi();
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [goalsData, setGoalsData] = useState([]);

    const format = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    async function handleSelected() {
        // Mostrar o toast de progresso
        const toastId = toast.loading('Carregando dados...');

        try {
            const { data } = await api.getSellerSelected(selectedSeller?.agn_in_codigo);
            const row = data.map((item) => ({
                ...item,
                dataFormat: new Date(item?.data).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long' }),
                meta: format.format(item?.meta)
            }));
            setGoalsData(row);

            // Atualizar o toast de progresso para sucesso
            toast.update(toastId, {
                render: 'Dados carregados com sucesso!',
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: 3000 // Fecha automaticamente após 3 segundos
            });
        } catch (error) {
            console.log('🚀 ~ handleSelected ~ error:', error);

            // Atualizar o toast de progresso para erro
            toast.update(toastId, {
                render: 'Erro ao carregar dados!',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000 // Fecha automaticamente após 5 segundos
            });
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'dataFormat', headerName: 'Data', flex: 1 },
        { field: 'meta', headerName: 'Meta', flex: 1, editable: true },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title="Delete"
                        onClick={() => {
                            handleDeleteGoal(params.row.id);
                        }}
                    >
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    ];

    async function handleDeleteGoal(id) {
        const toastId = toast.loading('Deletando meta...');
        try {
            await api.deleteGoals(id);

            toast.update(toastId, {
                render: 'Meta deletada com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            handleSelected();
        } catch (error) {
            console.log('🚀 ~ handleDeleteGoal ~ error:', error);
        }
    }

    async function EditGoal(params, event) {
        let value = event.target.value;

        value = value.replace('R$', '').trim();

        value = value.replace(/\./g, '');

        value = value.replace(',', '.');

        const numericValue = parseFloat(value);

        const payload = { ...params.row, meta: numericValue };

        const toastId = toast.loading('Editando meta...');

        try {
            await api.editableGoals(params.row.id, payload);

            handleSelected();
            toast.update(toastId, {
                render: 'Meta editada com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ EditGoal ~ error:', error);
        }
    }

    useEffect(() => {
        if (selectedSeller) {
            handleSelected();
        }
    }, [selectedSeller]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '600px' }}>
            <div>
                <Autocomplete
                    options={sellersData}
                    getOptionLabel={(seller) => seller.vendedor}
                    onChange={(event, newValue) => {
                        setSelectedSeller(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Pesquisar metas do vendedor" variant="outlined" />}
                />
                <div style={{ marginTop: '10px' }}>
                    {selectedSeller &&
                        (goalsData.length > 0 ? (
                            <DataGrid
                                columns={columns}
                                rows={goalsData}
                                onCellEditStop={EditGoal}
                                hideFooter={true}
                                sx={{
                                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                        py: 1
                                    },
                                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                        py: '15px'
                                    },
                                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                        py: '22px'
                                    },
                                    maxHeight: '400px',
                                    marginTop: '20px'
                                }}
                                getRowHeight={() => 'auto'}
                            />
                        ) : (
                            <h3>Nenhuma meta cadastrada para esse vendedor !</h3>
                        ))}
                </div>
            </div>

            <h4 style={{ position: 'absolute', bottom: 0 }}>
                OBS.: Para editar o valor da meta, basta dar um duplo clique ná celula desejada !
            </h4>
        </div>
    );
}
