import { Button, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'Service/axios';
import { useNavigate } from 'react-router';

const Absence = ({ reason, employee }) => {
    const api = useApi();
    const navigate = useNavigate();

    // validação

    const validationSchema = Yup.object().shape({
        collaborator: Yup.string().required('Campo obrigatório'),
        date: Yup.date().required('Data é obrigatória'),
        reason: Yup.string().required('Campo obrigatório'),
        entryTime: Yup.string().required('Horário de entrada é obrigatório')
    });

    const clearValues = (values) => {
        values.collaborator = '';
        values.date = '';
        values.reason = '';
        values.observation = '';
        values.entryTime = '';
    };

    const handleRegister = async (values) => {
        const payload = {
            employeeName: parseInt(values.collaborator),
            exitDate: values.date,
            entryTime: values.entryTime,
            reason: parseInt(values.reason),
            observation: values.observation !== '' ? values.observation : null,
            returnHour: null,
            days: null,
            realReturnHour: null,
            type: 'Atraso'
        };

        try {
            await api.registerExit(payload);
            navigate(-1);
            clearValues(values);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    collaborator: '',
                    date: '',
                    reason: '',
                    observation: '',
                    entryTime: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        console.log('🚀 ~ file: Absence.js:37 ~ onSubmit={ ~ values:', values);
                        handleRegister(values);
                    } catch (err) {
                        console.log(err.message);
                    }
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                    <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '850px', margin: '0 auto' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="collaborator">Colaborador</InputLabel>
                            <Select
                                id="collaborator"
                                name="collaborator"
                                label="Colaborador"
                                value={values.collaborator}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {employee &&
                                    employee?.map((item) => (
                                        <MenuItem key={item} value={item.id}>
                                            {item.employeeName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error>
                                <ErrorMessage name="collaborator" />
                            </FormHelperText>
                        </FormControl>
                        <Box sx={{ display: ' flex', gap: '30px' }}>
                            <FormControl fullWidth variant="filled">
                                <TextField
                                    id="date"
                                    type="date"
                                    label="Data"
                                    value={values.date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormHelperText error>
                                    <ErrorMessage name="date" />
                                </FormHelperText>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="time"
                                    type="time"
                                    value={values.entryTime}
                                    name="entryTime"
                                    label="Horário de entrada"
                                    onChange={handleChange}
                                    inputProps={{}}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormHelperText error>
                                    <ErrorMessage name="entryTime" />
                                </FormHelperText>
                            </FormControl>
                        </Box>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="reason">Motivo</InputLabel>
                            <Select
                                id="reason"
                                name="reason"
                                label="Motivo"
                                value={values.reason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {reason &&
                                    reason?.map((item) => (
                                        <MenuItem key={item} value={item.id}>
                                            {item.reasonName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error>
                                <ErrorMessage name="reason" />
                            </FormHelperText>
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="observation">Observação</InputLabel>
                            <OutlinedInput
                                id="observation"
                                type="text"
                                label="Observação"
                                value={values.observation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <FormHelperText error>
                                <ErrorMessage name="observation" />
                            </FormHelperText>
                        </FormControl>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="submit" variant="contained" style={{ width: '200px' }}>
                                Salvar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Absence;
