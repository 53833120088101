import React from 'react';

import MainCard from 'ui-component/cards/MainCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

function MailView() {
    const api = useApi();
    const { id } = useParams();

    const [mail, setMail] = useState([]);
    const [mailId, setMailId] = useState(null);
    const [newDate, setNewDate] = useState(null);

    useEffect(() => {
        setMailId(id);
        const get = async () => {
            const response = await api.getMailById(id);
            setMail(response.data);
            setNewDate(response.data.date);
            return response;
        };
        get(mailId);
    }, [id]);

    const date2 = new Date(newDate);
    const formatedDate = date2.toLocaleDateString();

    return (
        <MainCard title="Conteúdo do Email">
            <Grid
                container
                spacing={gridSpacing}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '1.4rem' }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Typography variant="h5" sx={{ marginRight: '.5rem' }}>
                        Data de envio do email:
                    </Typography>
                    {formatedDate}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Typography variant="h5" sx={{ marginRight: '.5rem' }}>
                        Enviado de:
                    </Typography>
                    {mail.from}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Typography variant="h5" sx={{ marginRight: '.5rem' }}>
                        Para:
                    </Typography>
                    {mail.to}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4rem' }}>
                    <Typography>
                        <Link to={'/main/request'}>
                            <Button>Voltar</Button>
                        </Link>
                    </Typography>
                </Box>
            </Grid>
        </MainCard>
    );
}

export default MailView;
