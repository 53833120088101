import { Autocomplete, Button, Dialog, DialogTitle, FormControl, TextField } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box } from '@mui/system';
import { useApi } from 'Service/axios';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useState } from 'react';

const LooseTransportModal = ({ open, setOpen, dataModal, cuurrentLooseTransport, get, setValueTab }) => {
    const navigate = useNavigate();
    const api = useApi();

    const handleClose = () => {
        setOpen(false);
    };

    const currentUser = localStorage.getItem('id');

    const localData = [
        { id: 1, type: 'DOCA 01' },
        { id: 2, type: 'DOCA 02' },
        { id: 3, type: 'DOCA 03' },
        { id: 4, type: 'DOCA TDC' },
        { id: 6, type: 'EXPEDIÇÃO' },
        { id: 7, type: 'RECEPÇÃO' }
    ];

    const validationSchema = Yup.object().shape({
        local: Yup.string().required('Campo obrigatório'),
        pedido: Yup.string().required('Campo obrigatório')
    });

    const sendEmail = async (data) => {
        try {
            await api.looseTransportEmail(data.id, data);
        } catch (error) {
            console.log('🚀 ~ sendEmail ~ error:', error);
        }
    };
    const [loading, setLoading] = useState(false);
    const releaseOfSeparateTransportFunc = async (values) => {
        setLoading(true);
        const payload = {
            transportadora: dataModal.transportadora,
            transporte: dataModal.transporte,
            local: values.local,
            pedido: values.pedido,
            responsavel_id: parseInt(currentUser)
        };
        try {
            const { data } = await api.releaseOfSeparateTransport(dataModal?.cod, payload);
            console.log('🚀 ~ releaseOfSeparateTransportFunc ~ data:', data);
            const dataEmail = {
                id: data?.liberacaoTransporte?.id,
                pedido: data?.liberacaoTransporte?.pedido,
                email: dataModal.email,
                ccEmails: dataModal.ccEmails,
                usuarioLogado: parseInt(currentUser)
            };
            sendEmail(dataEmail);
            setLoading(false);
            get();
            setValueTab('2');
            setOpen(false);
            toast.success(`Pedido ${values.pedido} liberado!`);
        } catch (error) {
            setLoading(false);
            console.log('🚀 ~ file: LooseTransportModal.js:50 ~ releaseOfSeparateTransportFunc ~ error:', error);
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={'md'}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Formik
                initialValues={{
                    shippingCompany: dataModal?.transportadora || '',
                    transport: dataModal?.transporte || '',
                    local: '',
                    pedido: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    releaseOfSeparateTransportFunc(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit} style={{ padding: '15px' }}>
                            <h2>Liberação de Transporte Avulso</h2>
                            <Box>
                                <h4>Cliente: {dataModal?.cliente}</h4>
                            </Box>
                            <h3>Datos da Portaria</h3>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <FormControl>
                                    <TextField
                                        sx={{ width: '300px' }}
                                        disabled
                                        value={values.shippingCompany}
                                        name="shippingCompany"
                                        label="Transportadora"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        sx={{ width: '300px' }}
                                        value={values.transport}
                                        disabled
                                        name="transport"
                                        label="Transporte"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '20px' }}>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="local"
                                        options={localData}
                                        sx={{ width: '300px' }}
                                        getOptionLabel={(option) => option.type}
                                        onChange={(e, newValue) => {
                                            if (newValue) setValues({ ...values, local: newValue.type });
                                        }}
                                        value={localData.find((option) => option.type === values.local) || null}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Local"
                                                name="local"
                                                value={values.local}
                                                error={touched.local && Boolean(errors.local)}
                                                helperText={touched.local && errors.local}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        sx={{ width: '300px' }}
                                        value={values.pedido}
                                        name="pedido"
                                        label="Pedido"
                                        onChange={handleChange}
                                        error={touched.pedido && Boolean(errors.pedido)}
                                        helperText={touched.pedido && errors.pedido}
                                    />
                                </FormControl>
                            </Box>

                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'end' }}>
                                <Button type="submit" variant="contained" disabled={loading}>
                                    {loading ? 'Carregando...' : 'Liberar'}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default LooseTransportModal;
