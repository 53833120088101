import { useState, useEffect } from 'react';
import { Badge, Box, Button, Fab, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import PodiumStep from './components/Podium';
import { LatestGoals } from './components/LatestGoals';
import { GaugeCharts } from './components/GaugeCharts';
import { CardArea, ContainerShandow, Header, RightArea, TableArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import CardDark from './components/CardDark';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FlagIcon from '@mui/icons-material/Flag';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { GoalsModal } from './components/GoalsModal';
import { useApi } from 'Service/axios';
import { dateForInput } from 'utils/dateForInpute';
import { format as formatDate } from 'date-fns';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import { FilterFialog } from './components/FilterDiolog';

export function SalesPainel() {
    const api = useApi();

    const [sellers, setSellers] = useState([]);
    const [podium, setPodium] = useState([]);

    const [loadModal, setLoadModal] = useState(false);
    const [cardsInfo, setCardsInfo] = useState({});
    const [percent, setPercent] = useState(0);
    const [lastRankingData, setLastRankingData] = useState([]);
    const [dtInitialFilter, setDtInitialFilter] = useState(null);
    const [dtFinalFilter, setDtFinalFilter] = useState(null);
    const [isFilter, setIsFilter] = useState(false);
    const [countFilter, setCountFilter] = useState(0);
    const [initialDateFormmat, setInitialDateFormmat] = useState('');
    const [finalDateFormmat, setFinalDateFormmat] = useState('');

    const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

    function bufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return window.btoa(binary);
    }

    async function getRanking() {
        setLoadModal(true);
        const today = new Date();
        let firstDayOfMonth;
        let lastDayOfMonth;

        if (isFilter) {
            // Obtém os valores dos inputs de data (tipo month) e converte para Date
            const initialFilterDate = new Date(`${dtInitialFilter}-01T00:00:00`);
            const finalFilterDate = new Date(`${dtFinalFilter}-01T00:00:00`);
            // Define o primeiro dia do mês do filtro inicial
            firstDayOfMonth = new Date(initialFilterDate?.getFullYear(), initialFilterDate?.getMonth(), 1);

            // Define o último dia do mês do filtro final
            lastDayOfMonth = new Date(finalFilterDate?.getFullYear(), finalFilterDate?.getMonth() + 1, 0);
            setFinalDateFormmat(formatDate(lastDayOfMonth, 'dd/MM/yyyy'));
            setInitialDateFormmat(formatDate(firstDayOfMonth, 'dd/MM/yyyy'));
        } else {
            firstDayOfMonth = new Date(today?.getFullYear(), today?.getMonth(), 1);
            lastDayOfMonth = new Date(today?.getFullYear(), today?.getMonth() + 1, 0);
        }

        // Formata as datas para o padrão 'yyyy-MM-dd'
        const dtInicio = formatDate(firstDayOfMonth, 'yyyy-MM-dd');
        const dtFim = formatDate(lastDayOfMonth, 'yyyy-MM-dd');

        const defaultImageUrl = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';

        try {
            // Chama a API para obter os dados do ranking
            const { data } = await api.getAllRanking(dtInicio, dtFim);

            // Ordena os dados pelo valor total de vendas
            const sortedData = data.sort((a, b) => b?.valor_total - a?.valor_total);

            // Mapeia os dados adicionando id e valor formatado
            const row = sortedData.map((item, index) => {
                let imageSrc = defaultImageUrl;
                if (item?.imagem_vendedor && item?.imagem_vendedor?.data) {
                    const imageData = bufferToBase64(item?.imagem_vendedor?.data);
                    imageSrc = `data:image/jpeg;base64,${imageData}`;
                }

                return {
                    ...item,
                    id: index + 1,
                    valorFormat: format.format(item.vendas),
                    imagem: imageSrc
                };
            });

            // Seleciona os três primeiros para o pódio
            const podium = row.slice(0, 3);
            const order = [];
            podium.forEach((item, index) => {
                if (index === 0) {
                    order.push({ ...item, order: 1 });
                } else if (index === 1) {
                    order.push({ ...item, order: 0 });
                } else if (index === 2) {
                    order.push({ ...item, order: 2 });
                }
            });

            // Reordena o pódio conforme a ordem desejada
            const reorderedPodium = order.sort((a, b) => a?.order - b?.order);
            // Atualiza o estado com os dados do pódio e vendedores
            setPodium(reorderedPodium);
            setSellers(row);
        } catch (error) {
            console.log('🚀 ~ getRanking ~ error:', error);
        } finally {
            setLoadModal(false);
        }
    }

    async function getCards() {
        const today = new Date();
        let firstDayOfMonth;
        let lastDayOfMonth;

        if (isFilter) {
            // Obtém os valores dos inputs de data (tipo month) e converte para Date
            const initialFilterDate = new Date(`${dtInitialFilter}-01T00:00:00`);
            const finalFilterDate = new Date(`${dtFinalFilter}-01T00:00:00`);

            // Define o primeiro dia do mês do filtro inicial
            firstDayOfMonth = new Date(initialFilterDate?.getFullYear(), initialFilterDate?.getMonth(), 1);

            // Define o último dia do mês do filtro final
            lastDayOfMonth = new Date(finalFilterDate?.getFullYear(), finalFilterDate?.getMonth() + 1, 0);
        } else {
            firstDayOfMonth = new Date(today?.getFullYear(), today?.getMonth(), 1);
            lastDayOfMonth = new Date(today?.getFullYear(), today?.getMonth() + 1, 0);
        }

        // Formata as datas para o padrão 'yyyy-MM-dd'
        const dtInicio = formatDate(firstDayOfMonth, 'yyyy-MM-dd');
        const dtFim = formatDate(lastDayOfMonth, 'yyyy-MM-dd');
        try {
            // Chama a API para obter os dados dos cartões
            const { data } = await api.getCardGoalsView(dtInicio, dtFim);

            // Formata os dados retornados
            const formatData = data.map((item) => ({
                ...item,
                metas_totais: format.format(item?.metas_totais),
                vendas_totais: format.format(item?.vendas_totais)
            }));

            // Calcula a performance
            const performance = (data[0]?.vendas_totais / data[0]?.metas_totais) * 100;
            setPercent(performance.toFixed(2));

            // Atualiza o estado com as informações formatadas
            setCardsInfo(formatData);
        } catch (error) {
            console.log('🚀 ~ getCards ~ error:', error);
        }
    }

    async function lastRanking() {
        try {
            const { data } = await api.getLastRanking();
            setLastRankingData(data);
        } catch (error) {
            console.log('🚀 ~ lastRanking ~ error:', error);
        }
    }

    useEffect(() => {
        getRanking();
        getCards();
        lastRanking();
        let elemento = document.documentElement;
        elemento.requestFullscreen();
    }, [countFilter]);

    const columns = [
        {
            field: 'imagem',
            headerName: 'Ranking',
            flex: 1,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <p style={{ fontSize: '14px' }}>#{params?.row?.id}</p>
                    <img
                        src={params?.row?.imagem}
                        alt="Avatar"
                        style={{ width: '55px', height: '55px', borderRadius: '50%', objectFit: 'cover' }}
                    />
                </div>
            )
        },
        { field: 'vendedor', headerName: 'Vendedor', flex: 1 },
        { field: 'valorFormat', headerName: 'Valor Total', flex: 1 },
        {
            field: 'percentual',
            headerName: 'Performance',
            flex: 1,
            renderCell: (params) => `${Number(params?.row?.percentual).toFixed(2)} %`
        }
    ];

    return (
        <Grid container spacing={gridSpacing} sx={{ flexDirection: 'column', padding: '10px', position: 'relative' }}>
            {loadModal && <ModalLoadAnimated open={loadModal} />}
            <Header>
                <h2>Painel de vendas</h2>
                <div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {isFilter && (
                            <Button
                                onClick={() => {
                                    setIsFilter(false);
                                    setDtFinalFilter(null);
                                    setDtInitialFilter(null);
                                    setCountFilter(0);
                                }}
                                variant="outlined"
                                color="error"
                            >
                                Cancelar Filtros
                            </Button>
                        )}

                        <FilterFialog
                            setInital={setDtInitialFilter}
                            setFinal={setDtFinalFilter}
                            setIsFilter={setIsFilter}
                            countFilter={setCountFilter}
                        />
                        <GoalsModal getRanking={getRanking} />
                    </div>
                    {isFilter && (
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <p>
                                <b>De:</b> {initialDateFormmat}
                            </p>
                            <p>
                                <b>Até:</b>
                                {finalDateFormmat}
                            </p>
                        </div>
                    )}
                </div>
            </Header>
            <Box
                sx={{
                    display: 'flex',
                    margin: '0px 30px',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: '20px',
                    position: 'relative'
                }}
            >
                <ContainerShandow
                    style={{ display: 'flex', padding: '30px 50px', gap: '20px', height: '650px', position: 'relative', width: '430px' }}
                >
                    {podium.map((winner, index) => (
                        <PodiumStep key={winner.id} podium={podium} winner={winner} index={index} />
                    ))}
                    <h3 style={{ position: 'absolute', top: '0', left: '10px' }}>Ranking de vendedores</h3>
                </ContainerShandow>

                <RightArea>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '20px' }}>
                        <ContainerShandow>
                            <LatestGoals data={lastRankingData} />
                        </ContainerShandow>
                        <ContainerShandow>
                            <GaugeCharts percent={percent} total={cardsInfo[0]?.vendas_totais} totalMeta={cardsInfo[0]?.metas_totais} />
                        </ContainerShandow>
                    </Box>
                    <CardArea>
                        <CardDark name="Performance" icon={<StackedLineChartIcon />} value={`${percent} %`} />
                        <CardDark name="Total Vendido" icon={<LocalAtmIcon />} value={cardsInfo[0]?.vendas_totais} />
                        <CardDark name="Total metas" icon={<FlagIcon />} value={cardsInfo[0]?.metas_totais} />
                    </CardArea>
                    <ContainerShandow style={{ width: '880px' }}>
                        <TableArea>
                            <DataGrid rows={sellers} columns={columns} hideFooter={true} sx={{ maxHeight: '320px' }} />
                        </TableArea>
                    </ContainerShandow>
                </RightArea>
            </Box>
        </Grid>
    );
}
