import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, Modal, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Link } from 'react-router-dom';

const ProducaoSeriada = () => {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '98%', display: 'flex', justifyContent: 'space-between', marginBottom: '2em' }}>
                <h2>Painel Produção Seriada</h2>
                <Button type="button" variant="contained">
                    <Link style={{ textDecoration: 'none', color: 'white' }} to={'/main/painel'}>
                        Voltar
                    </Link>
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '1em',
                    width: '100%',
                    height: '600px'
                }}
            >
                <iframe
                    frameborder="0"
                    allowFullScreen="true"
                    target="_blank"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="pre-orc"
                    src="https://app.powerbi.com/view?r=eyJrIjoiM2RjMmNhOGYtOTdkMC00NDQ4LThlMmItMThkNTY3OTc3YjQ1IiwidCI6IjkwZTZjYTgxLWM1ZTAtNDVmNC1iMzE3LWRlOTIxYzUyMTMzMyJ9"
                />
            </Box>
        </div>
    );
};

export default ProducaoSeriada;
