import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    FormLabel,
    useMediaQuery,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    TablePagination,
    DialogActions,
    TableSortLabel
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';
import { Link, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import EditForm from './Edit/EditForm';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import { OrderButton } from './style';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function RegisterEmployee({ ...others }) {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [openEdit, setOpenEdit] = useState(false);

    const api = useApi();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allEmployee, setAllEmployee] = useState([]);
    const [setores, setSetores] = useState([]);
    const [filial, setFilial] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [employeeID, setEmployeeID] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [orderById, setOrderById] = useState('asc');
    const [orderByNome, setOrderByNome] = useState('asc');

    const [selectedBranch, setSelectedBranch] = useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getSector = async () => {
        const response = await api.getAllSector();
        setSetores(response.data.allSectors);
    };

    const getBranch = async () => {
        const response = await api.getAllBranch();
        setFilial(response.data.allBranch);
    };

    const getAllRegistredEmployees = async () => {
        const response = await api.getAllEmployee();
        setAllEmployee(response.data.allEmployees);
    };

    console.log(allEmployee);
    const handleClickOpenEdit = (e, employeeName) => {
        e.preventDefault();
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        window.location.reload();
    };

    const handleRegister = async (values) => {
        const data = await api.registerEmployee(values);
        console.log(values);
        window.location.reload();
    };

    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setEmployeeID(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleConfirmDelete = async () => {
        setOpenSnackBarDeleted(true);
    };

    const handleClickSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleCancelDeleteDialog = () => {
        handleCloseDelete();
    };

    const handleSortById = () => {
        const sortedItens = [...allEmployee].sort((a, b) => {
            if (orderById === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setAllEmployee(sortedItens);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItens = [...allEmployee].sort((a, b) => {
            const nomeA = a?.employeeName.toUpperCase();
            const nomeB = b?.employeeName.toUpperCase();

            if (orderByNome === 'asc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllEmployee(sortedItens);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    const handleConfirmDeleteDialog = async (employeeID) => {
        try {
            await api.deleteEmployee(employeeID);
            handleConfirmDelete();
            window.location.reload();
        } catch (error) {
            window.alert(error.response.data.message);
            window.location.reload();
            throw new Error(error.message);
        }
    };

    useEffect(() => {
        getSector();
        getBranch();
        getAllRegistredEmployees();
    }, []);

    return (
        <MainCard title="CADASTRAR COLABORADOR">
            <Grid container spacing={gridSpacing}>
                {formOpen && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',
                            padding: '1em',
                            width: '70%'
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setFormOpen(false)}
                            sx={{ maxWidth: '50%', margin: '0 auto', marginBottom: '3em' }}
                        >
                            Ver Tabela
                        </Button>
                        <Formik
                            initialValues={{
                                employeeName: '',
                                sector: '',
                                branch: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                employeeName: Yup.string().max(255).required('Nome é obrigatorio'),
                                sector: Yup.string().max(255).required('Setor é obrigatorio'),
                                branch: Yup.string().max(255).required('Fiial é obrigatorio')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                    handleRegister(values);
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.msg });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit} {...others} style={{ width: '100%' }}>
                                    <FormControl
                                        fullWidth
                                        sx={{ ...theme.typography.customInput }}
                                        error={Boolean(touched.employeeName && errors.employeeName)}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-employeeName-register">Nome do colaborador</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-employeeName-register"
                                            type="text"
                                            value={values.employeeName}
                                            name="employeeName"
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.employeeName && errors.employeeName && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.employeeName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        sx={{ ...theme.typography.customInput }}
                                        error={Boolean(touched.sector && errors.sector)}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-sector-register">Setor</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-sector-register"
                                            type="text"
                                            value={values.sector}
                                            name="sector"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            {setores.length > 0 &&
                                                setores.map((setor) => (
                                                    <MenuItem key={setor.id} value={setor.id}>
                                                        {setor.sectorName}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {touched.sector && errors.sector && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.sector}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        sx={{ ...theme.typography.customInput }}
                                        error={Boolean(touched.branch && errors.branch)}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-branch-register">Filial</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-branch-register"
                                            type="text"
                                            value={values.branch}
                                            name="branch"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            {filial.length > 0 &&
                                                filial.map((filial) => (
                                                    <MenuItem key={filial.id} value={filial.id}>
                                                        {filial.branchName}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {touched.branch && errors.branch && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.branch}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <Grid container alignItems="center" justifyContent="space-between"></Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}

                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Cadastrar Colaborador
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                )}

                {!formOpen && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '1em',
                            width: '100%'
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '2em' }}>
                            <Button variant="contained" color="primary" onClick={() => setFormOpen(true)} sx={{ marginBottom: '2em' }}>
                                Cadastrar
                            </Button>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={allEmployee}
                                getOptionLabel={(employee) => employee.employeeName}
                                value={selectedEmp}
                                onChange={(event, newValue) => {
                                    setSelectedEmp(newValue);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Nome do colaborador" />}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel onClick={handleSortById}>ID</TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={handleSortByNome}>Nome</TableSortLabel>
                                        </TableCell>
                                        <TableCell>SETOR</TableCell>
                                        <TableCell>FILIAL</TableCell>
                                        <TableCell align="right">AÇÕES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allEmployee.length > 0 &&
                                        allEmployee

                                            .filter((item) => (selectedEmp ? item?.employeeName.includes(selectedEmp?.employeeName) : true))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((employee) => (
                                                <TableRow>
                                                    <TableCell>{employee?.id}</TableCell>
                                                    <TableCell>{employee?.employeeName}</TableCell>
                                                    <TableCell>{employee?.Sector?.sectorName}</TableCell>
                                                    <TableCell>{employee?.Branch?.branchName}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                handleClickOpenEdit(e, employee.id);
                                                                setEmployeeID(employee.id);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={(e) => handleClickOpenDelete(e, employee.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={allEmployee.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )}
            </Grid>
            <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md" fullWidth>
                <DialogContent>
                    <EditForm employeeID={employeeID} handleCloseEdit={handleCloseEdit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Fechar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDeleteDialog(employeeID)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
}

export default RegisterEmployee;
