import React, { createContext, useContext, useState } from 'react';

const GroupContextExpedition = createContext();

export const GroupContextExpeditionProvider = ({ children }) => {
    const [groups, setGroups] = useState([]);
    const [currentGepro, setCurrentGepro] = useState(null);

    return (
        <GroupContextExpedition.Provider value={{ groups, setGroups, currentGepro, setCurrentGepro }}>
            {children}
        </GroupContextExpedition.Provider>
    );
};

export const useGroupContext = () => {
    const context = useContext(GroupContextExpedition);

    if (!context) {
        throw new Error('useGroupContext must be used within a TodoProvider');
    }

    return context;
};
