import React from 'react';
import { Grid, Typography } from '@mui/material';
const Footer = () => {
    return (
        <>
            <Grid
                md={12}
                item
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#2196F3', overflow: 'hidden' }}
            >
                <Typography fontSize={12} color="#fff" textAlign="center">
                    © 2023 Refrin Refrigeração Industrial Ltda. Todos os Direitos Reservados | Desenvolvido por BW Tecnologia v 0.0.12
                </Typography>
            </Grid>
        </>
    );
};

export default Footer;
