import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';

import LoginRoutes from './LoginRoute';
import RegisterRoute from './RegisterRoute';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes, RegisterRoute]);
}
