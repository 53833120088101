import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    MenuItem
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useApi } from 'Service/axios';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const SuporteForm = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);
    const [loading, setLoading] = useState(false);
    const userId = localStorage.getItem('id');

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    useEffect(() => {
        changePassword('123456');
    }, []);

    const api = useApi();
    const navigate = useNavigate();

    const handleRegister = async (values) => {
        setLoading(true);
        const sendEmail = await api.sendSuportEmail(userId, values);
        console.log(values);
        setLoading(false);
        window.location.reload();
    };

    return (
        <Formik
            initialValues={{
                subject: '',
                cc: '',
                content: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                content: Yup.string().required('Conteúdo obrigatorio')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    handleRegister(values);
                } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                    <Grid container spacing={matchDownSM ? 0 : 2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-subject-register">Assunto</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-subject-register"
                                    type="text"
                                    value={values.subject}
                                    name="subject"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-cc-register">CC</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-cc-register"
                                    type="email"
                                    value={values.cc}
                                    name="cc"
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <TextField
                            id="outlined-adornment-content-register"
                            value={values.content}
                            name="content"
                            placeholder="Conteúdo..."
                            onChange={handleChange}
                            multiline
                            rows={20}
                            variant="outlined"
                            inputProps={{ style: { height: '100px', overflow: 'auto', minWidth: '360px' } }}
                        />
                    </FormControl>

                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={isSubmitting || loading}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {!loading ? 'Enviar Email' : 'Aguarde...'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default SuporteForm;
