import { Autocomplete, Button, CircularProgress, Divider, FormControl, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import formater from 'utils/formatDate';
import ConfirmSendEmailExpedition from 'ui-component/ConfirmSendEmailExpedition';
import { converterData } from 'utils/convertData';
import { toast } from 'react-toastify';
import { formatarCPF, formatarPlaca, formatarTelefone } from 'utils/regex';

const ConfirmRequest = ({ open, setOpen, data, getData }) => {
    const api = useApi();
    const [dataRequest, setDataRequest] = useState(null);
    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [dataEmail, setDataEmail] = useState(null);
    const [coletumData, setColetumData] = useState(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: '#fefefe',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
    };

    const getRequestExpeditionId = async () => {
        try {
            const res = await api.getConfirmEx(data);
            setDataRequest(res.data);
            console.log('🚀 ~ file: index.js:36 ~ getRequestExpeditionId ~ res.data:', res.data);
            const coletum = res.data.confirmacao_solicitacao_coletum ? res.data.confirmacao_solicitacao_coletum : '';
            setColetumData(coletum);
        } catch (error) {
            console.log('🚀 ~ file: index.js:30 ~ getRequestExpeditionId ~ error:', error.message);
        }
    };

    useEffect(() => {
        getRequestExpeditionId();
    }, [data]);

    const typeCar = [
        { id: 1, type: 'Veículo Próprio' },
        { id: 2, type: 'Transportadora' }
    ];

    const handleSubmitFunc = async (value) => {
        const idUser = localStorage.getItem('id');
        const payload = {
            usuario_responsavel_id: idUser,
            agrupamento: value?.agrupamento,
            pedido: value?.order,
            contato: value?.contact,
            telefone: value?.tel,
            email: value?.email,
            cliente: value?.client,
            obra: value?.constructions,
            responsavel_coleta: value?.type ? value?.client : 'TRANSDUTO',
            a_partir_de: value?.from,
            confirmacao: value?.confirm,
            tipo: value?.vehicle,
            telefone_motorista: value?.telMotorista,
            cpf: value.cpf,
            rg: value.rg,
            transportadora: value.shippingCompany,
            placa: value.licensePlate,
            material: value.material,
            motorista: value.driverName,
            frotista: value.frotista
        };
        if (payload.tipo == '' || payload.confirmacao == '') return toast.warn('Preencha os dados');
        try {
            const res = await api.confirmRequestExpedition(dataRequest?.id, payload);
            const dataEmail = res.data.confirmacao_solicitacao_coleta;

            console.log('🚀 ~ file: index.js:67 ~ handleSubmitFunc ~ res:', res);
            setOpenSendEmail(true);

            setDataEmail({
                email: dataEmail?.email,
                cliente: dataEmail?.cliente,
                idConfirmacaoColeta: dataRequest?.id,
                action: 'add'
            });
            getData();
            toast.success('Confirmação de solicitação');
        } catch (error) {
            console.log('🚀 ~ file: index.js:49 ~ handleSubmit ~ error:', error);
            toast.error(error.response.data.message);
        }
    };

    const handleEditFunc = async (value) => {
        const idUser = localStorage.getItem('id');
        const payload = {
            usuario_responsavel_id: idUser,
            agrupamento: value?.agrupamento,
            pedido: value?.order,
            contato: value?.contact,
            telefone: value?.tel,
            email: value?.email,
            cliente: value?.client,
            obra: value?.constructions,
            responsavel_coleta: value?.type ? value?.client : 'TRANSDUTO',
            a_partir_de: value?.from,
            confirmacao: value?.confirm,
            tipo: value?.vehicle
        };

        if (payload.tipo == '' || payload.confirmacao == '') return toast.warn('Preencha os dados');
        try {
            const res = await api.editConfirmRequestExpedition(coletumData?.expedicao_id, payload);
            const dataEmail = res.data.confirmacao_solicitacao_coleta;

            console.log('🚀 ~ file: index.js:67 ~ handleSubmitFunc ~ res:', res);
            setOpenSendEmail(true);
            getData();

            setDataEmail({
                email: dataEmail?.email,
                cliente: dataEmail?.cliente,
                idConfirmacaoColeta: dataRequest?.id,
                action: 'update'
            });
            toast.success('Edit de confirmação');
        } catch (error) {
            console.log('🚀 ~ file: index.js:49 ~ handleSubmit ~ error:', error);
            toast.error(error.response.data.message);
        }
    };

    const sendEmail = async () => {
        try {
            setOpen(false);
            setOpenSendEmail(false);
            await api.sendEmailConfirmRequestExpedition(dataEmail);
        } catch (error) {
            console.log('🚀 ~ file: index.js:84 ~ sendEmail ~ error:', error.message);
        }
    };

    const typeOfTransport = [
        { type: 'TRUCK' },
        { type: 'TOCO' },
        { type: 'VUC' },
        { type: 'BITRUCK' },
        { type: 'CARRETA' },
        { type: 'BAÚ' },
        { type: 'PICKUP' },
        { type: 'TOCO ABERTO' },
        { type: 'TOCO BAU' },
        { type: 'TOCO SIDER' },
        { type: 'TRUCK ABERTO' },
        { type: 'TRUCK BAU' },
        { type: 'TRUCK SIDER' },
        { type: 'VUC ABERTO' },
        { type: 'VUC BAU' },
        { type: 'VUC SIDER' },
        { type: 'CARRETA ABERTA' },
        { type: 'CARRETA BAU' },
        { type: 'CARRETA SIDER' },
        { type: 'BITRUCK BAU' },
        { type: 'BITRUCK ABERTO' },
        { type: 'BITRUCK SIDER' },
        { type: 'OUTRO' }
    ];

    const typeDriver = [
        { id: 1, type: 'Frotista' },
        { id: 2, type: 'Autônomo' }
    ];

    const material = [{ type: 'Distribuição' }, { type: 'DUTO' }];

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <ConfirmSendEmailExpedition
                    open={openSendEmail}
                    setOpen={setOpenSendEmail}
                    func={sendEmail}
                    message={'Deseja enviar o e-mail de confirmação ao cliente?'}
                    funcClose={setOpen}
                />
                {dataRequest?.id === data ? (
                    <Formik
                        initialValues={{
                            agrupamento: dataRequest?.agrupamento || '',
                            order: dataRequest?.pedido || '',
                            contact: dataRequest?.contato || '',
                            email: coletumData?.email || dataRequest?.email,
                            client: dataRequest?.cliente,
                            constructions: dataRequest?.obra || '',
                            type: dataRequest?.Solicitacao_coletum?.fob ? true : false,
                            tel: dataRequest?.telefone || '',
                            vehicle: coletumData?.tipo || '',
                            from: formater(dataRequest?.Solicitacao_coletum?.a_partir_de) || '',
                            confirm: converterData(coletumData?.confirmacao) || '',
                            typeVehicle: dataRequest?.Solicitacao_coletum?.transporte,
                            driverName: '',
                            telMotorista: '',
                            cpf: '',
                            rg: '',
                            shippingCompany: '',
                            licensePlate: '',
                            material: '',
                            frotista: ''
                        }}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            if (coletumData) {
                                handleEditFunc(values);
                            } else {
                                handleSubmitFunc(values);
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setValues }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ padding: '15px', width: '100%', maxWidth: '1050px', margin: '0 auto' }}
                            >
                                <h2>Confirmação da solicitação de coleta</h2>
                                <h3>Dados de coleta</h3>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <FormControl>
                                        <TextField disabled label="Agrupamento" name="agrupamento" value={values.agrupamento} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField disabled label="PDV" name="order" value={values.order} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField disabled sx={{ width: '300px' }} label="Contato" name="contact" value={values.contact} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="E-mail"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField sx={{ width: '300px' }} disabled label="Cliente" name="client" value={values.client} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField sx={{ width: '300px' }} disabled label="Telefone" name="tel" value={values.tel} />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            disabled
                                            label="Obra"
                                            name="constructions"
                                            value={values.constructions}
                                        />
                                    </FormControl>
                                </Box>

                                <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />

                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '10px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            disabled
                                            label="Responsável pela Coleta"
                                            name="type"
                                            value={values.type ? values.client : 'TRANSDUTO'}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="vehicle"
                                            options={typeCar}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.type}
                                            value={typeCar.find((item) => item.type === values.vehicle)}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, vehicle: newValue.type });
                                                }
                                            }}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Tipo do transporte" name="vehicle" value={values.vehicle} />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="Veiculo"
                                            options={typeOfTransport}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.type}
                                            value={typeOfTransport.find((item) => item.type === values.typeVehicle)}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setValues({ ...values, vehicle: newValue.type });
                                                }
                                            }}
                                            clearText={false}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Veiculo" name="vehicle" value={values.typeVehicle} />
                                            )}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '10px' }}>
                                    <FormControl>
                                        <TextField sx={{ width: '300px' }} disabled label="Apartir de:" name="from" value={values.from} />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            type="date"
                                            sx={{ width: '200px' }}
                                            label="Confirmação"
                                            name="confirm"
                                            value={values.confirm}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Box>
                                <h3>Dados de transporte</h3>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="Nome do motorista"
                                            name="driverName"
                                            value={values.driverName}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="Telefone"
                                            name="telMotorista"
                                            value={values.telMotorista}
                                            onChange={(e) => setValues({ ...values, telMotorista: formatarTelefone(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="CPF"
                                            name="cpf"
                                            value={values.cpf}
                                            onChange={(e) => setValues({ ...values, cpf: formatarCPF(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '230px' }}
                                            label="RG"
                                            name="rg"
                                            value={values.rg}
                                            onChange={(e) => setValues({ ...values, rg: e.target.value })}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                                    <FormControl>
                                        <TextField
                                            sx={{ width: '300px' }}
                                            label="Transportadora"
                                            name="shippingCompany"
                                            value={values.shippingCompany}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="typeDriver"
                                            options={typeDriver}
                                            sx={{ width: 150 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, frotista: newValue.type });
                                            }}
                                            value={typeDriver.find((option) => option.type === values.frotista) || null}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Frotista" name="material" value={values.frotista} />
                                            )}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <TextField
                                            sx={{ width: '200px' }}
                                            label="Placa"
                                            name="licensePlate"
                                            value={values.licensePlate}
                                            onChange={(e) => setValues({ ...values, licensePlate: formatarPlaca(e.target.value) })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="material"
                                            options={material}
                                            sx={{ width: 200 }}
                                            getOptionLabel={(option) => option.type}
                                            onChange={(e, newValue) => {
                                                if (newValue) setValues({ ...values, material: newValue.type });
                                            }}
                                            value={material.find((option) => option.type === values.material) || null}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Material" name="material" value={values.material} />
                                            )}
                                        />
                                    </FormControl>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                        alignItems: 'center',
                                        marginTop: '16px'
                                    }}
                                >
                                    <Button type="submit" variant="contained" sx={{ width: '300px', margin: '0 auto' }}>
                                        {coletumData ? 'Editar' : 'Confirmar'}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                ) : (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ConfirmRequest;
