import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

export function LatestGoals({ data }) {
    const [avatar, setAvatar] = React.useState([]);

    useEffect(() => {
        const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

        function bufferToBase64(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }

            return window.btoa(binary);
        }

        const defaultImageUrl = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';

        const avatar = data.map((item, index) => {
            let imageSrc = defaultImageUrl;
            if (item?.imagem_vendedor && item?.imagem_vendedor?.data) {
                const imageData = bufferToBase64(item?.imagem_vendedor?.data);
                imageSrc = `data:image/jpeg;base64,${imageData}`;
            }

            return {
                ...item,
                id: index + 1,
                imagem: imageSrc
            };
        });

        setAvatar(avatar);
    }, [data]);

    const monthOfYears = [
        '',
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];

    return (
        <List
            sx={{
                width: '100%',
                width: 370,
                bgcolor: 'background.paper',
                padding: '10px',
                borderRadius: '8px',
                height: 270,
                position: 'relative'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <h3 style={{ top: 0 }}>Metas anteriores</h3>
                <h3 style={{ top: 0 }}>Performance</h3>
            </div>
            <div style={{ position: 'absolute', top: '60px', width: '100%' }}>
                {data.length > 0 ? (
                    avatar?.map((seller, index) => (
                        <ListItem key={index} alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src={seller?.imagem} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <span>{seller?.vendedor} 🥇</span>
                                        <span style={{ marginRight: '30px' }}>{Number(seller?.percentual).toFixed(2)}%</span>
                                    </div>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                            {`${monthOfYears[seller?.mes]} / ${seller.ano} `}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    ))
                ) : (
                    <h3>Nenhuma meta encontrada.</h3>
                )}
            </div>
        </List>
    );
}
