export function formatarTelefone(input) {
    let numeroLimpo = input.replace(/\D/g, '');
    numeroLimpo = numeroLimpo.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');

    if (numeroLimpo.length > 15) {
        numeroLimpo = numeroLimpo.substring(0, 15);
    }

    return numeroLimpo;
}

export function formatarCPF(input) {
    let cpfLimpo = input.replace(/\D/g, '');
    cpfLimpo = cpfLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    if (cpfLimpo.length > 14) {
        cpfLimpo = cpfLimpo.substring(0, 14);
    }
    return cpfLimpo;
}

export function formatarRG(input) {
    let rgLimpo = input.replace(/\D/g, '');
    rgLimpo = rgLimpo.replace(/(\d{1})(\d{3})(\d{3})/, '$1.$2.$3');
    if (rgLimpo.length > 9) {
        rgLimpo = rgLimpo.substring(0, 9);
    }
    return rgLimpo;
}

export function formatarPlaca(input) {
    let placaLimpa = input.replace(/[^a-zA-Z0-9]/g, '');
    placaLimpa = placaLimpa.replace(/([a-zA-Z]{3})(\d{2})([a-zA-Z0-9]{1})(\d{1})/, '$1-$2$3$4');
    if (placaLimpa.length > 8) {
        placaLimpa = placaLimpa.substring(0, 8);
    }
    return placaLimpa.toUpperCase();
}

export function formatarData(dataIso) {
    console.log('🚀 ~ file: regex.js:40 ~ formatarData ~ dataIso:', dataIso);
    // Obtenha a data no formato "YYYY-MM-DD"
    const data = new Date(dataIso);

    // Verifique se a conversão para Date foi bem-sucedida
    if (isNaN(data.getTime())) {
        return null; // Retorna null se a conversão falhar
    }

    // Formate a data no estilo brasileiro (DD/MM/YYYY)
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = String(data.getFullYear());

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
}

export const formatValueToReal = (value) => {
    // Remove caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');

    // Converte para número e divide por 100 para considerar os centavos
    const numericAmount = parseFloat(numericValue) / 100;

    // Formata o valor como moeda (R$)
    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(numericAmount);

    return formattedValue;
};

export const parseFormattedValue = (formattedValue) => {
    // Remove caracteres de formatação (moeda, pontos e espaços)
    const numericValue = formattedValue.replace(/[^0-9,-]+/g, '');

    // Converte para número e divide por 100 para considerar os centavos
    const numericAmount = parseFloat(numericValue.replace(',', '.'));

    return numericAmount;
};
