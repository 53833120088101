import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import AuthWrapper1 from '../../../pages/authentication/AuthWrapper1';
import AuthCardWrapper from '../../../pages/authentication/AuthCardWrapper';
import EditExitForm from './EditExitForm';
import Absence from './components/Absence';
import WorkOut from './components/WorkOut';
import MissWork from './components/MissWork';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

const EditForm = ({ user, setOpenEdit, getExits }) => {
    const api = useApi();
    const [allEmployee, setAllEmployee] = useState([]);
    const [allReason, setAllReason] = useState([]);
    console.log('🚀 ~ file: EditForm.js:12 ~ EditForm ~ user:', user);

    const getAllEmployees = async () => {
        const response = await api.getAllEmployee();
        setAllEmployee(response.data.allEmployees);
    };

    const getAllReasons = async () => {
        const response = await api.getAllReason();
        setAllReason(response.data.allReason);
    };

    useEffect(() => {
        getAllEmployees();
        getAllReasons();
    }, []);

    return (
        <div style={{ width: '400px', height: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <h2>Editar {user?.type}</h2>
            </div>
            <div>
                {user?.type === 'Atraso' && (
                    <Absence reason={allReason} employee={allEmployee} currentUser={user} setOpenEdit={setOpenEdit} getExits={getExits} />
                )}
                {user?.type === 'Saida' && (
                    <WorkOut reason={allReason} employee={allEmployee} currentUser={user} setOpenEdit={setOpenEdit} getExits={getExits} />
                )}
                {user?.type === 'Falta' && (
                    <MissWork reason={allReason} employee={allEmployee} currentUser={user} setOpenEdit={setOpenEdit} getExits={getExits} />
                )}
            </div>
        </div>
    );
};

export default EditForm;
