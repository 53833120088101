import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4
};

export function InsertModal({ allSectors, getAllWorkCenters }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [sectorSelected, setSectorSelected] = React.useState('');
    const [allWorkCentersMega, setAllWorkCentersMega] = React.useState([]);
    const [centerWorkSelected, setCenterWorkSelected] = React.useState([]);
    const [description, setDescription] = React.useState('');

    async function getWorkCentersByMega() {
        try {
            const { data } = await api.getWorkCenterLocal(sectorSelected.id);
            if (data.length == 0) return toast.warning('Nenhum centro de trabalho encontrado');
            setAllWorkCentersMega(data);
        } catch (error) {
            console.log('🚀 ~ getWorkCentersByMega ~ error:', error);
            setAllWorkCentersMega([]);
            return toast.warning('Nenhum centro de trabalho encontrado');
        }
    }

    useEffect(() => {
        if (sectorSelected !== '') getWorkCentersByMega();
    }, [sectorSelected]);

    async function handleSubmit(event) {
        event.preventDefault();
        const toastId = toast.loading('Inserindo setor...');
        if (!sectorSelected) {
            return toast.update(toastId, {
                render: 'Selecione um setor',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
        if (!centerWorkSelected) {
            return toast.update(toastId, {
                render: 'Selecione um centro de trabalho',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }

        const payload = {
            descricao: description,
            setor_id: sectorSelected.id,
            centro_trabalho_ids: centerWorkSelected.map((item) => item.id)
        };
        try {
            await api.createWorkCellLocal(payload);
            toast.update(toastId, {
                render: 'Célula de trabalho inserido com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            setOpen(false);
            setSectorSelected('');
            setDescription('');
            getAllWorkCenters();
        } catch (error) {
            toast.update(toastId, {
                render: 'Falha ao inserir célula de trabalho',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Inserir setor
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Incluir célula de trabalho</h3>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="description"
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            label="Descrição"
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allSectors}
                            getOptionLabel={(option) => option?.descricao_setor}
                            sx={{ marginBottom: 2 }}
                            onChange={(event, newValue) => {
                                setSectorSelected(newValue);
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} label="Setor" />}
                        />
                        {allWorkCentersMega.length > 0 && (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={allWorkCentersMega}
                                getOptionLabel={(option) => option?.descricao_ct}
                                sx={{ marginBottom: 2 }}
                                multiple
                                onChange={(event, newValue) => {
                                    setCenterWorkSelected(newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} label="Buscar centro de trabalho" />}
                            />
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button type="submit" variant="contained" sx={{ width: '200px' }}>
                                Confirmar
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
