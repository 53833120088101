// assets
import { IconWallet } from '@tabler/icons';

const icons = {
    IconWallet
};

const budget = {
    id: 'budget',
    title: 'Atendimento',
    type: 'group',
    children: [
        {
            id: 'budget',
            title: 'Atendimento',
            type: 'item',
            url: '/main/budget',
            icon: icons.IconWallet,
            breadcrumbs: false
        }
    ]
};

export default budget;
