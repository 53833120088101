import MainCard from 'ui-component/cards/MainCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { gridSpacing } from 'store/constant';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import { Box, Grid, TableSortLabel, Typography } from '@mui/material';
import { useApi } from 'Service/axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import budgetStatus from 'utils/budgetStatus';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DataGrid } from '@mui/x-data-grid';

function BudgetView() {
    const api = useApi();
    const id = localStorage.getItem('id');
    const role = localStorage.getItem('role');
    const name = localStorage.getItem('name');
    const [budgets, setBudgets] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [filteredMails, setFilteredMails] = useState([]);
    const [mails, setMails] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [rows, setRows] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = async (id) => {
            const response = await api.getUserById(id);
            setUser(response.data);
            setUsername(response.data.user.username);
            return;
        };
        getUser(id);

        const adminGet = async () => {
            setLoading(true);
            const response = await api.getAllBudgets();
            const mails = response.data.map((item) => {
                const email = new Date(item.mailDate);
                const forecast = new Date(item.endForecast);
                const validado = new Date(item.validateDate);
                const opcoes = {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                const dataHoraFormatada = email.toLocaleString('pt-BR', opcoes);
                const previsaoFormatada = forecast.toLocaleString('pt-BR', opcoes);
                const validadoFormatado = validado.toLocaleString('pt-BR', opcoes);
                return {
                    ...item,
                    mailDate: dataHoraFormatada,
                    validateDate: validadoFormatado
                };
            });
            setLoading(false);
            setBudgets(mails);
            const row = mails.map((item) => ({
                id: `A${1000 + Number(item.id)}`,
                remetente: item.from,
                assunto: item.subject,
                validado: item.validateDate,
                vendedor: item.sellerName,
                orcamentista: item.estimatorName,
                prev: new Date(item.endForecast).toJSON().slice(0, 10).split('-').reverse().join('/'),
                status: item.status,
                pureId: item.id
            }));
            setRows(row);
            setFilteredMails(mails);
            return response;
        };

        const estimatorGet = async (name) => {
            setLoading(true);
            const response = await api.getAllEstimatorBudget(name);
            const estimatorData = await api.getUserById(id);
            setUsername(estimatorData.data.user.username);
            const mails = response.data.map((item) => {
                const email = new Date(item.mailDate);
                const forecast = new Date(item.endForecast);
                const validado = new Date(item.validateDate);
                const opcoes = {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                const dataHoraFormatada = email.toLocaleString('pt-BR', opcoes);
                const previsaoFormatada = forecast.toLocaleString('pt-BR', opcoes);
                const validadoFormatado = validado.toLocaleString('pt-BR', opcoes);
                return {
                    ...item,
                    mailDate: dataHoraFormatada,
                    validateDate: validadoFormatado
                };
            });
            const row = mails.map((item) => ({
                id: `A${1000 + Number(item.id)}`,
                remetente: item.from,
                assunto: item.subject,
                validado: item.validateDate,
                vendedor: item.sellerName,
                orcamentista: item.estimatorName,
                prev: new Date(item.endForecast).toJSON().slice(0, 10).split('-').reverse().join('/'),
                status: item.status,
                pureId: item.id
            }));
            setMails(mails);
            setLoading(false);
            setBudgets(mails);
            setRows(row);
            setFilteredMails(mails);
            return response;
        };

        const get = async (name) => {
            setLoading(true);
            const response = await api.getAllSellerBudget(name);
            if (!response.data.length > 0) {
                return setLoading(false);
            }
            const sellerData = await api.getUserById(id);
            setUsername(sellerData.data.user.username);
            const mails = response.data.map((item) => {
                const email = new Date(item.mailDate);
                const forecast = new Date(item.endForecast);
                const validado = new Date(item.validateDate);
                const opcoes = {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                const dataHoraFormatada = email.toLocaleString('pt-BR', opcoes);
                const previsaoFormatada = forecast.toLocaleString('pt-BR', opcoes);
                const validadoFormatado = validado.toLocaleString('pt-BR', opcoes);
                return {
                    ...item,
                    mailDate: dataHoraFormatada,
                    validateDate: validadoFormatado
                };
            });
            const row = mails.map((item) => ({
                id: `A${1000 + Number(item.id)}`,
                remetente: item.from,
                assunto: item.subject,
                validado: item.validateDate,
                vendedor: item.sellerName,
                orcamentista: item.estimatorName,
                prev: new Date(item.endForecast).toJSON().slice(0, 10).split('-').reverse().join('/'),
                status: item.status,
                pureId: item.id
            }));
            setLoading(false);
            setBudgets(mails);
            setRows(row);
            return response;
        };

        if (role === 'Administrador' || role === 'Vendedor' || role === 'Comercial/Engenharia' || role === 'adm-comercial') {
            adminGet();
        } else if (role === 'Orcamentista') {
            estimatorGet(name);
        } else {
            get(name);
        }
    }, []);
    const handleStatus = (e, id) => {
        e.preventDefault();
    };
    useEffect(() => {
        if (searchValue === '') {
            setFilteredMails([...budgets]);
        } else {
            const filteredMails = budgets.filter((budget) => {
                const remetenteIncluido = budget.from.toLowerCase().includes(searchValue.toLowerCase());
                const negocioIncluido = budget.subject.toLowerCase().includes(searchValue.toLowerCase());
                return remetenteIncluido || negocioIncluido;
            });
            setFilteredMails([...filteredMails]);
        }
        setPage(0);
    }, [searchValue, budgets]);

    useEffect(() => {
        const filteredMails = mails.filter((mail) => mail.from.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredMails([...filteredMails]);
        setPage(0);
    }, [searchValue, mails]);

    const handleSearch = () => {
        if (searchValue === '') {
            setFilteredMails(budgets);
        } else {
            const filteredMails = budgets.filter((budget) => {
                const remetenteIncluido = budget.from.toLowerCase().includes(searchValue.toLowerCase());
                const negocioIncluido = budget.subject.toLowerCase().includes(searchValue.toLowerCase());
                return remetenteIncluido || negocioIncluido;
            });
            setFilteredMails(filteredMails);
        }
        setPage(0);
    };

    useEffect(() => {
        handleSearch();
    }, [searchValue, budgets]);

    const compareValues = (a, b, direction) => {
        if (direction === 'asc') {
            return a < b ? -1 : 1;
        } else {
            return a > b ? -1 : 1;
        }
    };

    const handleSort = (column) => {
        let direction = 'asc';
        if (sortColumn === column && sortDirection === 'asc') {
            direction = 'desc';
        }
        setSortColumn(column);
        setSortDirection(direction);

        const sortedMails = [...budgets].sort((a, b) => {
            if (column === 'id') {
                return compareValues(a.id, b.id, direction);
            } else if (column === 'from') {
                return compareValues(a.from.toLowerCase(), b.from.toLowerCase(), direction);
            } else if (column === 'validateDate') {
                const dateA = new Date(a.validateDate.split('/').reverse().join('-'));
                const dateB = new Date(b.validateDate.split('/').reverse().join('-'));
                return compareValues(dateA, dateB, direction);
            } else if (column === 'endForecast') {
                return compareValues(new Date(a.endForecast), new Date(b.endForecast), direction);
            } else if (column === 'status') {
                return compareValues(a.status, b.status, direction);
            } else if (column === 'vendedor') {
                return compareValues(a.from.toLowerCase(), b.from.toLowerCase(), direction);
            }
            return 0;
        });

        setFilteredMails(sortedMails);
        setBudgets(sortedMails);
        setPage(0);
    };

    // if (loading) {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //             <CircularProgress />
    //         </div>
    //     );
    // }

    if (!budgets.length > 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h4">Nenhum orçamento encontrado</Typography>
            </div>
        );
    }

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'remetente', headerName: 'Remetente', flex: 1 },
        { field: 'assunto', headerName: 'Assunto', flex: 2 },
        {
            field: 'validado',
            headerName: 'Validado',
            flex: 1
        },
        {
            field: 'vendedor',
            headerName: 'Vendedor',
            flex: 1
        },
        {
            field: 'orcamentista',
            headerName: 'Orçamentista',
            flex: 1
        },
        {
            field: 'prev',
            headerName: 'Previsão',
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                <div
                    style={{
                        backgroundColor:
                            params.row.status === 0
                                ? '#D9B310'
                                : params.row.status === 1 || params.row.status === 2
                                ? 'rgba(255, 225, 0, 0.51)'
                                : params.row.status === 3
                                ? '#d3d3d3'
                                : params.row.status === 4
                                ? '#2085C3'
                                : '',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {budgetStatus(params.row.status)}
                </div>
            )
        },
        {
            field: 'action',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Abrir">
                        <Link to={`/main/budget/${params.row.pureId}`}>
                            <IconButton>
                                <EmailIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <>
            {!loading && (
                <MainCard title="Caixa de Entrada">
                    <Grid container spacing={gridSpacing}>
                        <div style={{ width: '100%' }}>
                            <DataGrid rows={rows} columns={columns} sx={{ maxHeight: 'calc(100vh - 230px)' }} />
                        </div>
                    </Grid>
                </MainCard>
            )}
        </>
    );
}
export default BudgetView;
