import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { ErrorMessage, Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useApi } from 'Service/axios';
import { useNavigate } from 'react-router';

const WorkOut = ({ reason, employee }) => {
    const [switchValue, setSwitchValue] = useState(false);

    const api = useApi();
    const navigate = useNavigate();

    // validação
    const validationSchema = Yup.object().shape({
        collaborator: Yup.string().required('Campo obrigatório'),
        date: Yup.date().required('Data é obrigatória'),
        reason: Yup.string().required('Campo obrigatório'),
        departureTime: Yup.string().required('Horário de entrada é obrigatório')
    });

    return (
        <>
            <Formik
                initialValues={{
                    collaborator: '',
                    date: '',
                    reason: '',
                    observation: '',
                    departureTime: '',
                    expectedReturn: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    const payload = {
                        employeeName: parseInt(values.collaborator),
                        exitDate: values.date,
                        entryTime: null,
                        reason: parseInt(values.reason),
                        observation: values.observation !== '' ? values.observation : null,
                        returnHour: values.expectedReturn !== '' ? values.expectedReturn : null,
                        days: null,
                        realReturnHour: null,
                        exitHour: values.departureTime,
                        type: 'Saida'
                    };

                    try {
                        await api.registerExit(payload);
                        navigate(-1);
                    } catch (err) {
                        console.log(err.message);
                    }
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                    <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '850px', margin: '0 auto' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="collaborator">Colaborador</InputLabel>
                            <Select
                                id="collaborator"
                                name="collaborator"
                                label="Colaborador"
                                value={values.collaborator}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {employee &&
                                    employee?.map((item) => (
                                        <MenuItem key={item} value={item.id}>
                                            {item.employeeName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error>
                                <ErrorMessage name="collaborator" />
                            </FormHelperText>
                        </FormControl>
                        <Box sx={{ display: ' flex', gap: '30px' }}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="date"
                                    type="date"
                                    label="Data"
                                    value={values.date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />

                                <FormHelperText error>
                                    <ErrorMessage name="date" />
                                </FormHelperText>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="time"
                                    type="time"
                                    label="Horário de saída"
                                    value={values.departureTime}
                                    name="departureTime"
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormHelperText error>
                                    <ErrorMessage name="departureTime" />
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: ' flex', gap: '30px' }}>
                            <FormControl fullWidth variant="outlined">
                                <FormControlLabel
                                    label="Retornará?"
                                    control={
                                        <Switch size="medium" checked={switchValue} onChange={(e) => setSwitchValue(e.target.checked)} />
                                    }
                                />
                            </FormControl>
                            {switchValue && (
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        id="time"
                                        type="time"
                                        label="Retorno previsto"
                                        value={values.expectedReturn}
                                        name="expectedReturn"
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            )}
                        </Box>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="reason">Motivo</InputLabel>
                            <Select
                                id="reason"
                                name="reason"
                                label="Motivo"
                                value={values.reason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {reason &&
                                    reason?.map((item) => (
                                        <MenuItem key={item} value={item.id}>
                                            {item.reasonName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error>
                                <ErrorMessage name="reason" />
                            </FormHelperText>
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="observation">Observação</InputLabel>
                            <OutlinedInput
                                id="observation"
                                type="text"
                                label="Observação"
                                value={values.observation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <FormHelperText error>
                                <ErrorMessage name="observation" />
                            </FormHelperText>
                        </FormControl>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="submit" variant="contained" style={{ width: '200px' }}>
                                Salvar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default WorkOut;
