import { Button, Grid, IconButton, Tab, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ReleaseOfTransport from './components/ReleaseOfTransport';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/system';
import FadeMenuLoose from './components/MenuLoose';
import { ConfirmDeleteModal } from 'ui-component/ConfirmDeletModal';
import { DateAndHour } from 'utils/DateAndHour';
import { LoadAnimated } from 'ui-component/LoadAnimated';

const ConciergePage = () => {
    const api = useApi();
    const [looseTransportRows, setLooseTransportRows] = useState([]);
    const navigate = useNavigate();
    const [row, setRow] = useState([]);
    const [openLiberacao, setOpenLiberacao] = useState(false);
    const [currentRow, setCurrentRow] = useState({});

    const [loading, setLoading] = useState(false);
    const getRequests = async () => {
        setLoading(true);
        try {
            const { data } = await api.getConcierge();
            console.log('🚀 ~ getRequests ~ data:', data);
            const formattedData = data?.map((item) => ({
                ...item,
                client: item?.cliente,
                construction: item?.obra,
                id: `E ${item?.id}`,
                name: item?.Registro_transporte?.motorista,
                chegada: DateAndHour(item?.Registro_transporte?.dt_chegada),
                type: 'expedicao'
            }));

            // Ordena os dados com base na hora de chegada
            formattedData.sort((a, b) => {
                const horaChegadaA = new Date(a.chegada).getTime();
                const horaChegadaB = new Date(b.chegada).getTime();
                return horaChegadaA - horaChegadaB;
            });

            setRow(formattedData.reverse());
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ getRequests ~ error:', error);
        }
    };

    const columnsLoose = [
        { field: 'cod', headerName: 'Código', flex: 0.5, valueGetter: (params) => `A ${params.row.cod}` },
        { field: 'pedido', headerName: 'PDV', flex: 0.5 },
        { field: 'cliente', headerName: 'Cliente', flex: 2 },
        { field: 'driver', headerName: 'Motorista', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 0.6 },
        {
            field: 'acoes',
            headerName: 'Ações',
            width: 70,
            sortable: false,
            renderCell: (params) => <FadeMenuLoose rows={params.row} get={getCollectRequest} setValueTab={setValueTab} />
        }
    ];

    const [loadingAvulsa, setLoadingAvulsa] = useState(false);

    const getCollectRequest = async () => {
        setLoadingAvulsa(true);
        try {
            const { data } = await api.getCollectRequest();

            const rowsLooseTransport = data.RegistroManual.map((item) => ({
                ...item,
                id: `A ${item.id}`,
                pedido: item?.LiberacaoTransporteAvulso?.pedido,
                client: item.cliente,
                obra: item.obra,
                confirm: item?.confirwmacao_solicitacao_coletum
                    ? formater(item?.confirmacao_solicitacao_coletum?.confirmacao)
                    : 'Não Confirmado',
                status: item.status,
                cod: item.id,
                name: item?.motorista,
                type: 'avulsa',
                construction: 'Não informado',
                chegada: '-- / --'
            }));
            const rowsLooseTransportDecrescente = rowsLooseTransport.sort((a, b) => b.cod - a.cod);

            setLooseTransportRows(rowsLooseTransportDecrescente);
            setLoadingAvulsa(false);
        } catch (error) {
            console.log('🚀 ~ file: index.js:29 ~ getCollectRequest ~ error:', error);
        }
    };

    useEffect(() => {
        getCollectRequest();
    }, []);

    useEffect(() => {
        getRequests();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.3, minWidth: 65, renderCell: (params) => <p>{`${params.id}`}</p> },
        {
            field: 'client',
            headerName: 'Cliente',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'construction',
            headerName: 'Obra',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'name',
            headerName: 'Motorista',
            flex: 0.8,
            minWidth: 150
        },
        {
            field: 'chegada',
            headerName: 'Hora de chegada',
            flex: 0.6,
            minWidth: 135
        },
        {
            field: 'transporte',
            headerName: 'Transporte',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params) => {
                if (params.row.type === 'expedicao') {
                    return (
                        <div>
                            <Tooltip title="Liberar transporte">
                                <IconButton
                                    onClick={() => {
                                        setOpenLiberacao(true);
                                        setCurrentRow(params.row);
                                    }}
                                >
                                    <QueryBuilderIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Excluir"
                                onClick={() => {
                                    setIdDelete(params.row?.Registro_transporte?.id);
                                    setOpenDelete(true);
                                }}
                            >
                                <IconButton>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                } else {
                    return <FadeMenuLoose rows={params.row} get={getCollectRequest} setValueTab={setValueTab} />;
                }
            }
        }
    ];
    const [valueTab, setValueTab] = useState('1');
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    //codgo para o delete
    const [openDelete, setOpenDelete] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);

    const deleteScheduledExpedition = async () => {
        setLoadingDeleteModal(true);
        try {
            await api.deleteRegisterExpedition(idDelete);
            setOpenDelete(false);
            getRequests();
            toast.success('Expedição excluida com sucesso');
            setLoadingDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ deleteRequestExpedition ~ error:', error);
            setLoadingDeleteModal(false);
        }
    };

    return (
        <MainCard title="Aguardando liberação" sx={{ position: 'relative' }}>
            <ConfirmDeleteModal
                open={openDelete}
                setOpen={setOpenDelete}
                id={idDelete}
                message={'Deseja mesmo excluir essa expedição confirmada ?'}
                func={deleteScheduledExpedition}
                loading={loadingDeleteModal}
            />
            <ReleaseOfTransport currentRow={currentRow} open={openLiberacao} setOpen={setOpenLiberacao} get={getRequests} />
            <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
                <Button variant="contained" onClick={() => navigate('/main/expedicao/transporte-avulso')}>
                    Registro manual
                </Button>
            </div>

            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '100%', typography: 'body1', marginTop: '10px' }}>
                    <div style={{ width: '100%' }}>
                        {loading ? (
                            <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                                <LoadAnimated />
                            </Box>
                        ) : row.length > 0 || looseTransportRows.length > 0 ? (
                            <DataGrid
                                rows={[...row, ...looseTransportRows]}
                                columns={columns}
                                checkboxSelection={false}
                                hideFooter
                                sx={{ width: '100%', maxHeight: 'calc(100vh - 230px)' }}
                            />
                        ) : (
                            <p>Nenhum veiculo confirmado</p>
                        )}
                    </div>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default ConciergePage;
