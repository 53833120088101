import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import AnalysisData from './components/Analysis';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Player from './components/Player';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import TimeLineCodificator from './components/TimelineCodification';
import { ReportModal } from './components/ReportModal';

export function TimeLineCodification() {
    const api = useApi();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);

    async function getOneRow() {
        setLoading(true);
        try {
            const { data } = await api.getOneDistributionWorksAssociated(id);

            setData(data);
            setLoading(false);
        } catch (error) {
            console.log('🚀 ~ getOneRow ~ error:', error);
        }
    }

    useEffect(() => {
        getOneRow();
    }, []);

    return (
        <MainCard title={'Codificação'}>
            <ReportModal open={openReportModal} setOpen={setOpenReportModal} data={data?.codificationEvents} infos={data} />
            <Grid container spacing={gridSpacing} sx={{ padding: '20px' }}>
                {loading ? (
                    <ModalLoadAnimated open={loading} />
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around',
                            width: '100%',
                            position: 'relative'
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => setOpenReportModal(true)}
                            sx={{ position: 'absolute', top: '-70px', right: '40px' }}
                        >
                            Visualizar relatório de horas
                        </Button>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Player codificator={data?.User.username} get={getOneRow} codificationEvents={data?.codificationEvents} />
                            {data?.codificationEvents.length > 0 && (
                                <TimeLineCodificator codificationEvents={data?.codificationEvents} get={getOneRow} />
                            )}
                        </div>
                        <AnalysisData row={data} get={getOneRow} />
                    </div>
                )}
            </Grid>
        </MainCard>
    );
}
