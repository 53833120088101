import { Autocomplete, Box, Button, Dialog, DialogActions, DialogTitle, Divider, Grid, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { Row } from './components/Row';
import { useApi } from 'Service/axios';
import { useDemandsContext } from 'context/DemandsContext';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { PreviewPlanningModal } from './components/PreviewPlanningModal';
import { useNavigate } from 'react-router';

// Função auxiliar para reordenar os itens
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

// Função auxiliar para mover itens entre duas listas
const move = (source, destination, droppableSource, droppableDestination) => {
    if (!source || !destination) {
        return;
    }
    if (source.status === null) return toast.error('Esse item já foi programado');

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export function Planning() {
    const api = useApi();
    const { machineSelected, demandsSelected, setDemandsSelected } = useDemandsContext();
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [allMachines, setAllMachines] = useState([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    async function getDemands() {
        try {
            const row = demandsSelected.map((item) => ({
                ...item,
                cliente: item.AGN_ST_FANTASIA,
                agrupamentos: item.ORD_ST_ALTERNATIVO,
                ordem: item.ORD_IN_CODIGO,
                diametro: item.DIAMETRO_GERAL,
                construcao: item.NORMA_DUTOS,
                bitola: item.BITOLA,
                status: null
            }));
            setItems1(row);
        } catch (error) {
            console.log('🚀 ~ getDemands ~ error:', error);
        }
    }

    async function getAllMachines() {
        try {
            const { data } = await api.getLocalMachines();

            setAllMachines(data);
        } catch (error) {
            console.log('🚀 ~ getAllMachines ~ error:', error);
        }
    }

    useEffect(() => {
        getDemands();
        getAllMachines();
    }, [machineSelected, demandsSelected]);

    const [items1, setItems1] = useState([]);
    const [items2, setItems2] = useState([]);

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // Se não há destino (solta o item fora da área dropável)
        if (!destination) {
            return;
        }

        // Reordena os itens na mesma lista
        if (source.droppableId === destination.droppableId) {
            const items = reorder(source.droppableId === 'droppable1' ? items1 : items2, source.index, destination.index);

            if (source.droppableId === 'droppable1') {
                setItems1(items);
            } else {
                setItems2(items);
            }
        } else {
            const sourceList = source.droppableId === 'droppable1' ? items1 : items2;
            const destinationList = source.droppableId === 'droppable1' ? items2 : items1;
            const item = sourceList[source.index];

            // Verifica se o item tem `status === null` e impede de ir para `items1`
            if (destination.droppableId === 'droppable1' && item.status !== null) {
                toast.error('Esse item não pode ser movido para "Disponível"');
                return;
            }

            const result = move(sourceList, destinationList, source, destination);
            setItems1(result['droppable1'] || items1);
            setItems2(result['droppable2'] || items2);
        }
    };

    async function createMachineDemandFunc() {
        const toastId = toast.loading('Programando demandas...');
        if (!selectedMachine) {
            return toast.update(toastId, {
                render: 'Selecione uma maquina!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }

        const payload = items2.map((item, index) => {
            return {
                ord_in_codigo: Number(item?.ordem),
                maquinaId: selectedMachine?.id,
                prioridade: index
            };
        });

        try {
            await api.createMachineDemand(payload);
            toast.update(toastId, {
                render: 'Demandas programadas com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getDemands();
            setSelectedMachine(null);

            setDemandsSelected(items1);
        } catch (error) {
            console.log('🚀 ~ createMachineDemandFunc ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao programar demandas!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleDeleteDemand(id) {
        const toastId = toast.loading('Deleting demands...');
        try {
            await api.deleteDemands(id);
            toast.update(toastId, {
                render: 'Demands deleted!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getDemandsInProgressFunc();
            setOpenConfirmDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ handleDeleteDemand ~ error:', error);
            toast.update(toastId, {
                render: 'Error deleting demands!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function getDemandsInProgressFunc() {
        try {
            const { data } = await api.getDemandsInProgress(selectedMachine?.id);
            const row = data.map((item) => ({
                ...item,
                cliente: item.demanda_programada.AGN_ST_FANTASIA,
                agrupamentos: item.demanda_programada.ORD_ST_ALTERNATIVO,
                ordem: Number(item.demanda_programada.ORD_IN_CODIGO),
                diametro: item.demanda_programada.DIAMETRO_GERAL,
                construcao: item.demanda_programada.NORMA_DUTOS,
                bitola: item.demanda_programada.BITOLA,
                id: item.demanda_programada.id,
                status: item.status ? item.status : null
            }));
            setItems2(row);
        } catch (error) {
            console.log('🚀 ~ getDemandsInProgressFunc ~ error:', error);
        }
    }

    useEffect(() => {
        getDemandsInProgressFunc();
    }, [selectedMachine]);

    return (
        <MainCard title="Demandas" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing} sx={{ minHeight: '300px' }}>
                <Autocomplete
                    disablePortal
                    options={allMachines}
                    getOptionLabel={(option) => option.descricao}
                    sx={{ width: '200px', position: 'absolute', top: 10, right: 10 }}
                    onChange={(e, newValue) => {
                        setSelectedMachine(newValue);
                    }}
                    value={selectedMachine}
                    renderInput={(params) => <TextField {...params} label="Maquinas" />}
                />
                {selectedMachine ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                marginTop: '20px',
                                gap: '20px',
                                marginLeft: '20px'
                            }}
                        >
                            {/* Primeira área de drop */}

                            <div style={{ width: '50%' }}>
                                <h3 style={{ margin: 0, marginBottom: '-15px' }}>Disponivel</h3>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
                                    <p>Cliente</p>
                                    <p>Agrupamentos</p>
                                    <p>Ordem</p>
                                    <p>Diametro</p>
                                    <p>Construcao</p>
                                    <p>Bitola</p>
                                    <p>Ações</p>
                                </div>
                                <Droppable droppableId="droppable1">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{
                                                padding: '10px',
                                                minHeight: '200px',
                                                backgroundColor: '#f0f0f0',
                                                maxHeight: 'calc(100vh - 370px)',
                                                overflow: 'auto'
                                            }}
                                        >
                                            {items1.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                backgroundColor: snapshot.isDragging ? '#d3d3d3' : '#ffffff',
                                                                border: '1px solid #ccc',
                                                                ...provided.draggableProps.style
                                                            }}
                                                        >
                                                            <Row
                                                                data={item}
                                                                setOpenConfirmDeleteModal={setOpenConfirmDeleteModal}
                                                                setSelectedRow={setSelectedRow}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>

                            {/* Segunda área de drop */}

                            <div style={{ width: '50%' }}>
                                <h3 style={{ margin: 0, marginBottom: '-15px' }}>Programado</h3>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
                                    <p>Cliente</p>
                                    <p>Agrupamentos</p>
                                    <p>Ordem</p>
                                    <p>Diametro</p>
                                    <p>Construcao</p>
                                    <p>Bitola</p>
                                    <p>Ações</p>
                                </div>
                                <Droppable droppableId="droppable2">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{
                                                padding: '10px',
                                                minHeight: '200px',
                                                backgroundColor: '#f0f0f0',
                                                maxHeight: 'calc(100vh - 370px)',
                                                overflow: 'auto'
                                            }}
                                        >
                                            {items2.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                backgroundColor: snapshot.isDragging ? '#d3d3d3' : '#ffffff',
                                                                border: '1px solid #ccc',
                                                                ...provided.draggableProps.style
                                                            }}
                                                        >
                                                            <Row
                                                                data={item}
                                                                setOpenConfirmDeleteModal={setOpenConfirmDeleteModal}
                                                                setSelectedRow={setSelectedRow}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </DragDropContext>
                ) : (
                    <div style={{ width: '100%', marginTop: '30px' }}>
                        <h3 style={{ textAlign: 'center' }}>Selecione uma maquina!</h3>
                    </div>
                )}
                {selectedMachine && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px', width: '100%' }}>
                        {/* <Button variant="contained" onClick={() => createMachineDemandFunc()}>
                            Programar
                        </Button> */}
                        <PreviewPlanningModal items2={items2} selectedMachine={selectedMachine} programinFunc={createMachineDemandFunc} />
                    </div>
                )}

                <Dialog
                    open={openConfirmDeleteModal}
                    onClose={() => setOpenConfirmDeleteModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" variant="h3">
                        {'Deseja mesmo deletar esta programação?'}
                    </DialogTitle>
                    <Divider />
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setOpenConfirmDeleteModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={() => handleDeleteDemand(selectedRow?.ord_in_codigo)}>
                            Deletar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </MainCard>
    );
}
